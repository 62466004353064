import { AxiosResponse } from 'axios';
import { clone, flow, getParent, Instance, types } from 'mobx-state-tree';
import { AppState } from '../state';
import {
  addCalculation,
  AddCalculationParametersModel,
  applyCustomer,
  Asset,
  AssetTypesModel,
  BusinessPartnerRef,
  /*CalculationConfigResponse,
  CalculationRequestModel,
  CalculationResponse,
  CalculationResponseModel,
  CalculationSheet,*/
  createAsset,
  createLoan,
  createProposal,
  CreateProposalResponseType,
  createUpgrade,
  CustomerSearchResultItem,
  CustomerSearchResultItemModel,
  deleteAsset,
  deleteUpgrade,
  FindCustomersOfIntroducerResponseModel,
  getAssets,
  getAssetConfig,
  getCalculationConfiguration,
  GetCalculationConfigurationParametersModel,
  getDocuments,
  GetDocumentsResponseType,
  getProposal,
  getUpgrades,
  Lessor,
  MarketProduct,
  MarketProductModel,
  Period,
  PeriodModel,
  PriceList,
  ProposalCreateModel,
  sendOfferDocumentByMail,
  SendOfferDocumentByMailRequestBodyModel,
  UpgradeModel,
  getLoanPurposes,
  GetLoanPurposesResponseModel,
  getLoans,
  Loan,
  deleteLoan,
  BusinessPartnerRefModel,
  updateProposal,
  UpdateProposalRequestBodyModel,
  PaymentLine,
  PaymentLineModel,
  CalculationSheet,
  CalculationResponse,
  CalculationResponseModel,
  CalculationConfigResponse,
  CalculationRequestModel,
  Proposal,
  ProposalModel,
} from '../tools/SiemensApi';
import { AssetCondition, AssetFormModel } from './AssetForm.model';
import { CompanyTypeQuery, CustomerFormModel } from './CustomerForm.model';
import { FinanceFormModel } from './FinanceForm.model';
import { useTranslation } from 'react-i18next';
import { ExtendedProposalSummary } from './Dashboard.model';
import marketProductsConfig from '../tools/constants-config';

export const StepModel = types.enumeration([
  'customer',
  'finance',
  'asset',
  'summary',
  'success',
  'referred',
  'reset',
  'expire',
]);
export type StepType = Instance<typeof StepModel>;
export const operationModel = types.enumeration([
  'CALC_RENTAL',
  'CALC_ASSET_VALUE',
]);

export type Operation = Instance<typeof operationModel>;
const loanMarketProductId = marketProductsConfig.validValues[1].id;

export const CalculatorModel = types
  .model({
    assetForm: AssetFormModel,
    calculation: types.maybeNull(CalculationResponseModel),
    currentStep: types.maybeNull(StepModel),
    customerForm: CustomerFormModel,
    financeForm: FinanceFormModel,
    hasSeenTooltip: types.optional(types.boolean, false),
    marketProducts: types.array(MarketProductModel),
    // eslint-disable-next-line sort-keys
    calculationPaymentPeriod: types.maybeNull(PeriodModel),
    operation: types.maybeNull(operationModel),
    paymentLine: types.maybeNull(PaymentLineModel),
    paymentPeriod: types.maybeNull(PeriodModel),
    proposal: types.maybeNull(ProposalModel),
    settingsSaved: types.optional(types.boolean, false),
    upgrades: types.array(UpgradeModel),
  })
  .views((self) => ({
    get upgradeValue(): number {
      return self.financeForm.upgradeValue ? self.financeForm.upgradeValue : 0;
    },
  }))
  .views((self) => ({
    get allowUpgrade(): boolean {
      const calculation = self.calculation;
      if (calculation!.sheet!.marketProduct!.id === loanMarketProductId) {
        //MARKET PRODUCTS HARDCODED
        return false;
      } else {
        return true;
      }
    },
    get amountFinanced(): number | null {
      // const assetPrice = self.assetForm.total;
      // return assetPrice === null ? null : assetPrice + self.upgradeValue;
      const calculation = self.calculation;
      if (
        calculation &&
        calculation.sheet &&
        calculation.sheet.amountFinanced
      ) {
        return calculation.sheet.amountFinanced;
      } else {
        return null;
      }
    },
    get hasAsset(): boolean {
      const calculation = self.calculation;
      return calculation!.sheet!.marketProduct!.id !== loanMarketProductId; //MARKET PRODUCTS HARDCODED
    },
    get rentalExFee(): number | null {
      const calculation = self.calculation;
      if (
        calculation &&
        calculation.sheet &&
        calculation.sheet.payment &&
        calculation.sheet.payment.avgPaymentPerPeriod &&
        typeof calculation.sheet.payment.avgPaymentPerPeriod === 'number'
      ) {
        return self.calculation.sheet.payment.avgPaymentPerPeriod;
      } else {
        return null;
      }
    },
    get serviceFeeFactor(): number {
      const monthsPerPeriod = self.calculation!.sheet!.payment.paymentLines[0]
        .period.monthsPerPeriod;
      if (typeof monthsPerPeriod === 'number') {
        return self.assetForm.feeIncluded ? 0.01 * monthsPerPeriod : 0;
      } else {
        throw new Error('no monthsPerPeriod available');
      }
    },
  }))
  .views((self) => ({
    get assetValue(): number | null {
      const calculation = self.calculation;
      if (calculation && calculation.sheet && calculation.sheet.assetValue) {
        return calculation.sheet.assetValue;
      } else {
        return null;
      }
    },
    getRentalValidationMessage(
      total: number | null,
      operation: string
    ): string | null {
      const { t } = useTranslation();
      if (operation === 'CALC_RENTAL') {
        return null;
      }
      const calculation = self.calculation;
      if (calculation && calculation.sheet && calculation.errors) {
        if (calculation.sheet.assetValue! < 0) {
          return `${t(
            'upgradeNotCovered',
            'The rental does not cover the upgrade'
          )}`;
        }
        if (calculation.errors[0]) {
          if (
            calculation.errors[1] &&
            calculation.errors[1].indexOf('out of its bounds') > -1
          ) {
            return `${t(
              'outOfBounds',
              'The rental does not cover the minimum amount financed'
            )}`;
          } else {
            return `${t(
              'upgradeNotCovered',
              'The rental does not cover the upgrade'
            )}`;
          }
        }

        return null;
      } else {
        return `${t(
          'cantValidateAssetPrice',
          'Cannot validate the asset price'
        )}`;
      }
    },
    get introducerId(): number {
      return getParent<AppState>(self).introducer!.id;
    },
    get marketProduct(): number | null {
      const calculation = self.calculation;
      if (calculation && calculation.sheet && calculation.sheet.marketProduct) {
        return calculation.sheet.marketProduct.id;
      } else {
        return null;
      }
    },
    get officeId(): number {
      return getParent<AppState>(self).office!.id;
    },
    get paymentPerMonth(): number | null {
      if (
        self.calculation &&
        self.calculation.sheet &&
        self.calculation.sheet.payment
      ) {
        const paymentPerMonth =
          self.calculation.sheet.payment.avgPaymentPerMonth;
        return paymentPerMonth === undefined ? null : paymentPerMonth;
      } else {
        return null;
      }
    },
    get periodsFitInTerm(): boolean {
      return !!(
        self.calculation &&
        self.calculation.sheet &&
        self.calculation.sheet.term &&
        self.calculation.sheet.payment.paymentLines[0].period &&
        self.calculation.sheet.payment.paymentLines[0].period.monthsPerPeriod &&
        !(
          self.calculation.sheet.term %
          self.calculation.sheet.payment.paymentLines[0].period.monthsPerPeriod
        )
      );
    },

    get rental(): number | null {
      const calculation = self.calculation;
      if (
        calculation &&
        calculation.sheet &&
        calculation.sheet.payment &&
        calculation.sheet.payment.paymentLines
      ) {
        const serviceFee = self.amountFinanced * self.serviceFeeFactor;
        return self.rentalExFee + serviceFee;
      } else {
        return null;
      }
    },
  }))
  .actions((self) => ({
    loadUpgrades: flow(function* loadUpgrades(proposalId: number) {
      const {
        headers: { etag: ifMatch },
        data: upgrades,
      } = yield getUpgrades({
        introducerId: self.introducerId,
        proposalId,
      });
      self.upgrades = upgrades!;

      return ifMatch;
    }),
    reset() {
      Object.assign(self, calculatorDefaults);
    },
  }))
  .actions((self) => ({
    loadProposal: flow(function* loadProposal(proposalId: number) {
      const {
        headers: { etag: ifMatch },
        data: proposal,
      } = yield getProposal({
        introducerId: self.introducerId,
        proposalId,
      });
      self.proposal = proposal!;
      //self.loadUpgrades(proposalId);
      return ifMatch;
    }),
  }))

  .actions((self) => ({
    createUpgrade: async (
      lessor: Lessor,
      contractNumber: string,
      quoteTotalValue: number | null
    ) => {
      const proposalId = self.proposal!.id!;

      const ifMatch = await self.loadProposal(proposalId);
      await createUpgrade(
        {
          ifMatch,
          introducerId: self.introducerId,
          proposalId: self.proposal!.id!,
        },
        {
          contractNumber,
          id: null,
          lessor,
          quoteTotalValue,
          rentalDate: null,
        }
      );
      await self.loadProposal(proposalId);
    },
    deleteUpgrades: flow(function* deleteUpgrades(proposalId: number) {
      let ifMatch = yield self.loadProposal(proposalId);

      for (const upgrade of self.upgrades) {
        const response = yield deleteUpgrade({
          ifMatch,
          introducerId: self.introducerId,
          proposalId,
          upgradeId: upgrade.id!,
        });
        ifMatch = response.headers.etag;
      }
      ifMatch = yield self.loadProposal(proposalId);
      return ifMatch;
    }),
    initializeProposal: flow(function* initializeProposal() {
      let requestBody = null;
      if (self.customerForm!.salesContactPersonId !== 0) {
        requestBody = ProposalCreateModel.create({
          officeId: self.officeId,
          salesContactId: self.customerForm!.salesContactPersonId!,
        });
      } else {
        requestBody = ProposalCreateModel.create({
          officeId: self.officeId,
        });
      }
      const {
        headers: { etag: ifMatch },
        data: proposal,
      }: AxiosResponse<CreateProposalResponseType> = yield createProposal(
        { introducerId: self.introducerId },
        requestBody
      );
      self.proposal = proposal;

      return ifMatch;
    }),
    setCalculation(calculationResponse: CalculationResponse) {
      self.calculation = calculationResponse;
    },
    setCalculationConfigResponse(
      calculationConfigResponse: CalculationConfigResponse
    ) {
      self.calculation = CalculationResponseModel.create({
        config: calculationConfigResponse.config as any,
        errors: [],
        sheet: calculationConfigResponse.calculationSheet as any,
      });
    },
    setCurrentStep(step: StepType) {
      self.currentStep = step;
    },
    setHasSeenTooltip() {
      self.hasSeenTooltip = true;
    },
    setIntroducerPricelist(introducerPricelist: PriceList) {
      if (self.calculation && self.calculation.sheet) {
        self.calculation.sheet.introducerPricelist = clone(introducerPricelist);
      }
    },
    setIntroducerPricelistId(introducerPricelistId: PriceList['id']) {
      if (self.calculation && self.calculation.sheet) {
        self.calculation.sheet.introducerPricelistId = introducerPricelistId;
      }
    },
    setMarketProducts(marketProducts: Array<MarketProduct>) {
      self.marketProducts = marketProducts;
    },
    setPaymentLine(paymentLine: PaymentLine) {
      self.paymentLine = paymentLine;
    },
    setPaymentPeriod(paymentPeriod: Period) {
      self.paymentPeriod = paymentPeriod;
    },
    setProposal(proposal: Proposal) {
      self.proposal = proposal;
    },
    setSettingsSaved(value: boolean) {
      self.settingsSaved = value;
    },
    setOperation(value: string) {
      self.operation = value;
    },
    updatePaymentLine(paymentLine: PaymentLine) {
      if (
        self.calculation &&
        self.calculation.sheet &&
        self.calculation.sheet.payment
      ) {
        self.paymentLine = paymentLine;
      }
    },
    // updateRental(rental: number) {
    //   if (
    //     self.calculation &&
    //     self.calculation.sheet &&
    //     self.calculation.sheet.payment &&
    //     self.calculation.sheet.payment.avgPaymentPerPeriod
    //   ) {
    //     const payment = self.calculation.sheet.payment as Payment;
    //     payment.setAvgPaymentPerPeriod(rental);
    //   }
    // },
    updateSheet(update: Partial<CalculationSheet>) {
      if (self.calculation) {
        Object.assign(self.calculation.sheet, update);
      }
    },
  }))
  .actions((self) => ({
    saveUpgradeToDraft: flow(function* saveUpgradeToDraft() {
      const introducerId = self.introducerId;

      // ifMatch = yield self.proposal
      //   ? self.loadProposal(self.proposal.id!)
      //   : self.initializeProposal();

      if (!self.proposal) {
        yield self.initializeProposal();
      }

      const proposalId = self.proposal!.id!;
      // @ts-ignore
      const upgrade = clone(self.financeForm.upgrade);
      const findProposalByIdResponse = yield getProposal({
        introducerId,
        proposalId,
      });
      if (upgrade) {
        self.proposal = findProposalByIdResponse.data!;
        if (self.proposal!.upgradeSummary!.numberOfUpgrades) {
          yield self.deleteUpgrades(self.proposal!.id!);
        }
        yield self.createUpgrade(
          // @ts-ignore
          upgrade.lessor,
          upgrade.contractNumber || '',
          upgrade.quoteTotalValue
        );
        const findProposalByIdResponse2 = yield getProposal({
          introducerId,
          proposalId,
        });
        self.proposal = findProposalByIdResponse2.data!;

        getParent<AppState>(self).dashboard.invalidateCache(proposalId);
      }
    }),
  }))
  .actions((self) => ({
    saveAssetToDraft: flow(function* saveAssetToDraft() {
      const introducerId = self.introducerId;
      const proposalId = self.proposal!.id!;
      const findProposalByIdResponse = yield getProposal({
        introducerId,
        proposalId,
      });
      self.proposal = findProposalByIdResponse.data!;
      let ifMatch = findProposalByIdResponse.headers.etag;
      if (self.proposal!.assetSummary!.numberOfAssets) {
        const getAssetsResponse = yield getAssets({
          introducerId,
          proposalId,
        });
        // ifMatch = getAssetsResponse.headers.etag;
        const assets = getAssetsResponse.data.data!.map(
          (asset: Asset) => asset
        );
        for (const asset of assets) {
          const deleteResponse = yield deleteAsset({
            assetId: asset.id!,
            ifMatch,
            introducerId,
            proposalId,
          });
          ifMatch = deleteResponse.headers.etag;
        }
      }
      yield createAsset(
        {
          ifMatch,
          introducerId,
          proposalId,
        },
        self.assetForm.asset!
      );
      const findProposalByIdResponse2 = yield getProposal({
        introducerId,
        proposalId,
      });
      self.proposal = findProposalByIdResponse2.data!;
      getParent<AppState>(self).dashboard.invalidateCache(proposalId);
    }),
    saveCalculationToDraft: flow(function* saveCalculationToDraft(
      operation?: string
    ) {
      const introducerId = self.introducerId;

      let ifMatch;
      ifMatch = yield self.proposal
        ? self.loadProposal(self.proposal.id!)
        : self.initializeProposal();

      const proposalId = self.proposal!.id!;

      //if (self.financeForm.isUpgrade) {
      //  yield self.saveUpgradeToDraft();
      //}
      const findProposalByIdResponse = yield getProposal({
        introducerId,
        proposalId,
      });
      self.proposal = findProposalByIdResponse.data!;

      ifMatch = findProposalByIdResponse.headers.etag;
      const calculationResponse = yield addCalculation(
        AddCalculationParametersModel.create({
          ifMatch,
          introducerId,
          proposalId,
        }),
        CalculationRequestModel.create({
          operation: operation ? operation : ('CALC_RENTAL' as any),
          sheet: clone(self.calculation!.sheet!) as any,
        })
      );
      if (calculationResponse) {
        self.proposal = calculationResponse.data.proposal;
        const {
          proposal: _,
          ...newCalculationResponse
        } = calculationResponse.data;

        self.setCalculation(newCalculationResponse);
      }
    }),
    saveLoanToDraft: flow(function* saveLoanToDraft() {
      const introducerId = self.introducerId;
      const proposalId = self.proposal!.id!;
      const findProposalByIdResponse = yield getProposal({
        introducerId,
        proposalId,
      });
      self.proposal = findProposalByIdResponse.data!;
      let ifMatch = findProposalByIdResponse.headers.etag;

      if (self.proposal!.loanSummary!.numberOfLoans) {
        const getLoansResponse = yield getLoans({
          introducerId,
          proposalId,
        });
        const loans = getLoansResponse.data!.map((loan: Loan) => loan);
        for (const loan of loans) {
          const deleteResponse = yield deleteLoan({
            ifMatch,
            introducerId,
            loanId: loan.id!,
            proposalId,
          });
          ifMatch = deleteResponse.headers.etag;
        }
      }

      yield createLoan(
        {
          ifMatch,
          introducerId,
          proposalId,
        },
        self.assetForm.loan!
      );
      const findProposalByIdResponse2 = yield getProposal({
        introducerId,
        proposalId,
      });
      self.proposal = findProposalByIdResponse2.data!;
      getParent<AppState>(self).dashboard.invalidateCache(proposalId);
    }),
  }))
  .actions((self) => ({
    createProposalWithUpgrade: flow(function* create(
      extendedProposal: ExtendedProposalSummary
    ) {
      self.reset();
      const ifMatch = yield self.initializeProposal();

      if (self.proposal && self.proposal.id) {
        const parameters = {
          ifMatch,
          introducerId: self.introducerId,
          proposalId: self.proposal.id,
        };

        const businessPartner = extendedProposal.customer!.businessPartner!;

        const businessPartnerRef = BusinessPartnerRefModel.create({
          companyType: null,
          registrationNumber: businessPartner!.registrationNumber!,
        });

        yield applyCustomer(
          parameters,
          businessPartnerRef as BusinessPartnerRef //ignore as it works
        );
      }
      return self.proposal!.id!;
    }),
    initialize: flow(function* initialize(proposalId?: number) {
      if (getParent<AppState>(self).proposalConfig!.assetsAllowed) {
        const { data: assetConfig } = yield getAssetConfig({
          introducerId: self.introducerId,
          officeId: self.officeId,
          proposalId: null,
        });
        self.assetForm.assetTypesResult = AssetTypesModel.create(
          assetConfig.assetTypes
        );
      }

      const { data: loanPurposes } = yield getLoanPurposes({
        introducerId: self.introducerId,
      });
      self.assetForm.loanPurposesResult = GetLoanPurposesResponseModel.create(
        loanPurposes
      );

      const { data: response } = yield getCalculationConfiguration(
        GetCalculationConfigurationParametersModel.create({
          introducerId: self.introducerId,
          officeId: self.officeId,
        })
      );
      try {
        self.setCalculationConfigResponse(response);
      } catch (error) {
        //return null;
      }

      if (response && response.config.marketProductsConfig) {
        self.setMarketProducts(
          response.config.marketProductsConfig.validValues
        );
      }
      /**
       * V2 changs mean including new statements to set specific values in state
       */
      if (response) {
        self.setPaymentPeriod(
          response.calculationSheet.payment.paymentLines[0].period
        );
      }

      self.assetForm.selectedAssetTypeId = null;
      self.assetForm.selectedAssetSubTypeId = null;
      self.customerForm.selectedBusinessPartnerRef = null;

      if (typeof proposalId === 'number') {
        const ifMatch = yield self.loadProposal(proposalId);
        if (self.proposal) {
          if (
            self.proposal.customer &&
            self.proposal.customer.businessPartner
          ) {
            const businessPartner = self.proposal.customer.businessPartner;
            const businessPartnerRef = {
              companyType: null,
              registrationNumber: businessPartner.registrationNumber || '',
            };
            const customerSearchResultItem: CustomerSearchResultItem = CustomerSearchResultItemModel.create(
              {
                address1: businessPartner.officialAddress.address1,
                address2: businessPartner.officialAddress.address2,
                businessPartnerRef,
                city: businessPartner.officialAddress.city,
                name: businessPartner.name,
                postalCode: businessPartner.officialAddress.postalCode,
              }
            );
            self.customerForm.customers = FindCustomersOfIntroducerResponseModel.create(
              [customerSearchResultItem]
            );
            self.customerForm.selectedBusinessPartnerRef = businessPartnerRef; //proposal loads dispite this
          }
          if (self.proposal.assetSummary!.numberOfAssets) {
            const getAssetsResponse = yield getAssets({
              introducerId: self.introducerId,
              proposalId,
            });
            // ifMatch = getAssetsResponse.headers.etag;
            const asset = getAssetsResponse.data.data![0];
            Object.assign(self.assetForm, {
              assetCondition: asset.condition,
              assetName: asset.description,
              quantity: asset.quantity,
              selectedAssetSubTypeId: asset.assetSubType.id,
              selectedAssetTypeId: asset.assetSubType.assetTypeId,
              unitPrice:
                typeof asset.valuePerAsset === 'number'
                  ? asset.valuePerAsset
                  : asset.totalValue / asset.quantity,
            });
          }
          if (self.proposal.loanSummary!.numberOfLoans) {
            const getLoansResponse = yield getLoans({
              introducerId: self.introducerId,
              proposalId,
            });
            const loan = getLoansResponse.data![0];
            Object.assign(self.assetForm, {
              loanValue: loan.value,
              selectedLoanPurposeId: loan.loanPurpose.id,
            });
          }
          if (self.proposal.currentCalculation) {
            self.calculation!.sheet = clone(self.proposal.currentCalculation);

            if (self.calculation!.sheet.fees) {
              const maintenanceFee = self
                .calculation!.sheet.fees.filter((fee) => fee.feeType!.id === 12)
                .map((fee) => ({ fee }));
              if (
                maintenanceFee &&
                maintenanceFee.length > 0 &&
                maintenanceFee[0].fee &&
                maintenanceFee[0].fee.amount
              ) {
                self.financeForm.setMaintenanceValue(
                  maintenanceFee[0].fee.amount
                );
              }
            }
          }
          return ifMatch;
        }
      }
      self.proposal = null;
    }),
    saveCustomerToDraft: flow(function* saveCustomerToDraft() {
      const introducerId = self.introducerId;

      // let ifMatch = yield self.proposal
      //   ? self.loadProposal(self.proposal.id!)
      //   : self.initializeProposal();

      let ifMatch = yield self.loadProposal(self.proposal.id!);

      const existsCurrentCustomer =
        self.proposal!.customer!.businessPartner !== null;

      const changeNeeded =
        !existsCurrentCustomer ||
        self.proposal!.customer!.businessPartner!.registrationNumber !==
          self.customerForm.customer!.businessPartnerRef!.registrationNumber;

      if (
        self.proposal!.salesContact!.id !==
        self.customerForm.salesContactPersonId!
      ) {
        // Patch the Proposal with newly modified sales contact Id
        const requestBody = UpdateProposalRequestBodyModel.create({
          salesContactId: self.customerForm.salesContactPersonId!,
        });
        const {
          headers: { etag: responseEtag },
        } = yield updateProposal(
          {
            ifMatch,
            introducerId,
            proposalId: self.proposal!.id!,
          },
          requestBody
        );
        ifMatch = responseEtag;
      }

      if (changeNeeded) {
        if (existsCurrentCustomer) {
          ifMatch = yield self.initializeProposal();
        }
        const parameters = {
          ifMatch,
          introducerId,
          proposalId: self.proposal!.id!,
        };

        //customer is missing certain data from customerForm Output
        const _customer = {
          registrationNumber:
            self.customerForm.selectedBusinessPartnerRef.registrationNumber,
          companyType: self.customerForm.companyTypeQuery, // or 'NONLIMITED'
          sourceCountryCode:
            self.customerForm.selectedBusinessPartnerRef.sourceCountryCode,
        } as BusinessPartnerRef;
        /**
         * legalForm, professionType, numberOfPartners, partners
         * Not sure why these are needed here but not
         */
        const customer = { customer: _customer };
        /**create new customer object to apply from exisiting data */
        yield applyCustomer(
          //TODO: the wrong value is being passed into apply customer
          parameters,
          customer //No longer throwing 400 error now 204 which is correct
        );
      }
      yield self.loadProposal(self.proposal!.id!);
    }),

    send: flow(function* send(emailTo: string) {
      const {
        data: documents,
      }: AxiosResponse<GetDocumentsResponseType> = yield getDocuments({
        introducerId: self.introducerId,
        proposalId: self.proposal!.id!,
      });
      const parameters = {
        introducerId: self.introducerId,
        proposalId: self.proposal!.id!,
      };
      const body = SendOfferDocumentByMailRequestBodyModel.create({
        documentIds: documents
          .filter((document) => document.sendByEmailAllowed)
          .map((document) => ({ id: document.id })),
        emailTo,
      });
      yield sendOfferDocumentByMail(parameters, body);
    }),
  }));

export type CalculatorState = Instance<typeof CalculatorModel>;

export const calculatorDefaults = {
  assetForm: {
    assetCondition: 'NEW' as AssetCondition,
    assetName: '',
    assetTypesResult: null,
    contractNumber: null,
    lessor: null,
    loanPurposesResult: null,
    loanValue: null,
    quantity: 1,
    selectedAssetSubTypeId: null,
    selectedAssetTypeId: null,
    selectedLoanPurposeId: null,
    serviceFee: true,
    unitPrice: null,
  },
  calculation: null,
  customerForm: {
    companyTypeQuery: 'LIMITED' as CompanyTypeQuery,
    customers: null,
    nameQuery: '',
    postcodeQuery: '',
    registrationNumberQuery: '',
    salesContactPersonId: 0,
    selectedCustomer: null,
    sentCompanyTypeQuery: '',
    sentNameQuery: '',
    sentPostcodeQuery: '',
    sentRegistrationNumberQuery: '',
  },
  financeForm: {
    contractNumber: null,
    isUpgrade: false,
    lessor: null,
    maintenanceValue: null,
    quantity: 1,
    quoteTotalValue: null,
    serviceFee: true,
    upgradeValue: null,
  },
  marketProducts: [],
  paymentPeriod: null,
  // eslint-disable-next-line sort-keys
  paymentLine: null,
  calulationPaymentPeriod: null,
  operation: 'CALC_RENTAL',
  proposal: null,
  upgrades: [],
};
