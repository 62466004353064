import React, { ReactElement } from 'react';

export default (): ReactElement => (
  <svg width="19px" height="11px" viewBox="0 0 19 11">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Label-input/blank-long-clean-inactive"
        transform="translate(-529.000000, -69.000000)"
        stroke="#373839"
        strokeWidth="2"
      >
        <path
          d="M530,70.6599243 L537.647746,78.7655596 L530,70.6599243 Z M546.161274,70.6599243 L538.080637,78.7655596 L546.161274,70.6599243 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
