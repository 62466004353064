import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardState, FilterType } from '../../model/Dashboard.model';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';

interface Props {
  state: DashboardState;
}

const Reset = ({ state }: Props) => {
  const { filter } = useParams<{ filter?: FilterType }>();
  const navigate = useNavigate();
  const appState = getParent<AppState>(state);
  useEffect(() => {
    (async () => {
      await new Promise((resolve) => resolve(state.reset()));
      appState.setIsLoading(true);
      state.setActiveFilter(filter);
      state.getProposals('', false, filter);

      //call set proposalscounts from the dashboard state
      state.getStatusCounts('');
      navigate('/app/myprops');
    })();
  }, []);

  return <div>xx</div>;
};

export default Reset;
