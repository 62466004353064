import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { ToggleIcon } from '../../../images/ToggleIcon';
import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import React, { useState } from 'react';
import Loader from '../../../components/Loader';
import { COLORS } from '../../../constants/style-constants';
import theme from '../../../constants/theme';

import {
  DashboardState,
  ExtendedProposalSummary,
} from '../../../model/Dashboard.model';
import { AppState } from '../../../state';
import { formatCurrency, formatDate, proposalStatus } from '../../../tools';

import {
  Contract,
  ContractUpgradeQuote,
  getAssets,
  GetAssetsParametersModel,
  getProposal,
  GetProposalParametersModel,
  Lessor,
  LessorModel,
} from '../../../tools/SiemensApi';
import marketProductsConfig from '../../../tools/constants-config';

import CardDetail from '../namevalues/CardDetail';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../../components/ErrorDialog';
import * as Sentry from '@sentry/browser';
import AccordionDetail from './AccordionDetail';
import StatusBadge from './StatusBadge';

interface Props {
  expandedItem: number | null;
  proposal: ExtendedProposalSummary;
  setExpanded: (id: number | null) => void;
  openProposal: () => void;
  deleteProposal: (proposalId: number) => void;
  openMoreDetails: (proposalId: number) => void;
  amendProposal: (proposalId: number) => void;
  state: DashboardState;
  className: string;
  activeFilter: string;
  showDesktop: boolean;
}

const useStyles = makeStyles({
  root: {
    '&:before': {
      display: 'none',
    },
    borderRadius: theme.spacing(1),
    margin: '10px 0',
  },
});

const useSummaryStyles = makeStyles({
  content: {
    '& #status': {
      flexBasis: 96,
    },
    '& .desktop': {
      [theme.breakpoints.up('sm')]: {
        display: 'contents',
      },
      '& .MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      display: 'none',
      padding: 0,
    },
    '& .mobile': {
      '& .row': {
        display: 'flex',
      },
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 6,
      padding: 0,
      width: '100%',
    },
    '& > div': {
      '& .figure': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      borderRight: `1px solid ${COLORS.lightGrey}`,
      overflow: 'hidden',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    margin: 0,
    overflow: 'hidden',
  },
});

const useDetailsStyles = makeStyles({
  root: {
    '& .column': {
      padding: '0 1em',
    },
    '& .column:last-child': {
      flexGrow: 1,
    },
    '& .deleteButton': {
      margin: theme.spacing(2, 0),
    },
    '& .row': {
      display: 'flex',
    },
    '& > .column + .column': {
      borderLeft: `1px solid ${COLORS.lightGrey}`,
    },
    borderTop: `1px solid ${COLORS.lightGrey}`,
    padding: 0,
  },
});

export default observer(
  ({
    expandedItem,
    proposal,
    setExpanded,
    openProposal,
    deleteProposal,
    openMoreDetails,
    amendProposal,
    state,
    className,
    activeFilter,
    showDesktop,
  }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isUpgradeAction, setIsUpgradeAction] = useState<boolean>(false);
    const AppState = getParent<AppState>(state);

    const loanMarketProductId = marketProductsConfig.validValues[1].id;

    const onChange = async (expanded: boolean) => {
      const introducer = getParent<AppState>(state).introducer;

      setExpanded(expanded ? proposal!.id! : null);

      if (
        expanded &&
        !state.getProposalInfo(proposal!.id!) &&
        !state.getAssets(proposal!.id!)
      ) {
        setIsLoading(true);

        let proposalDetails;
        let proposalAssets;

        try {
          proposalDetails = await getProposal(
            GetProposalParametersModel.create({
              introducerId: introducer!.id,
              proposalId: proposal!.id!,
            })
          );
        } catch (error) {
          proposalDetails = error.response;
        }

        state.setProposalsInfo(proposal!.id!, proposalDetails.data);

        if (getParent<AppState>(state).proposalConfig!.assetsAllowed) {
          try {
            proposalAssets = await getAssets(
              GetAssetsParametersModel.create({
                introducerId: introducer!.id,
                proposalId: proposal!.id!,
              })
            );
          } catch (error) {
            proposalAssets = error.response;
          }
          state.setProposalsAssets(proposal!.id!, proposalAssets.data.data);
        }
        setIsLoading(false);
      }
    };

    const status = proposalStatus(proposal.status);
    const assets = state.getAssets(proposal!.id!);
    const proposalInfo = state.getProposalInfo(proposal!.id!);

    // const contract = state.contracts.find(
    //   item => item.contractNumber === proposal.contractNumber
    // )!;

    const canBeContinued =
      getParent<AppState>(state).calculator.marketProduct ===
        loanMarketProductId || //MARKET PRODUCTS HARDCODED
      (!!assets &&
        (assets.length === 0 ||
          (assets.length === 1 && assets[0].quantity === 1)));

    const createProposalWithUpgrade = (
      extendedProposal: ExtendedProposalSummary
    ) => {
      setIsUpgradeAction(true);

      (async () => {
        const parentState = getParent<AppState>(state);

        // STEP 1: Create a new proposal for upgrade with customer details
        const proposalId = await parentState.calculator.createProposalWithUpgrade(
          extendedProposal
        );

        // STEP 2: Reload the newly created proposal in the calculator state with customer details
        await parentState.calculator.initialize(proposalId);

        let contractObj: Contract;

        // STEP 3: Get the existing contract details which requires upgrade
        try {
          contractObj = await parentState.getContractObj(
            extendedProposal.contractNumber!
          );
        } catch (error) {
          raiseError(t('invalidContract', 'Invalid Contract'));
          return;
        }

        let upgradeQuoteObj: ContractUpgradeQuote;

        // Step 4: Fetch the upgrade quote value for the contract
        if (contractObj!.id) {
          upgradeQuoteObj = await parentState.getUpgradeQuote(contractObj!.id);
        } else {
          raiseError(t('invalidContract', 'Invalid Contract'));
          return;
        }

        // Step 5: Create the upgrade details objects
        if (upgradeQuoteObj != null && upgradeQuoteObj.values != null) {
          const upgradeQuoteValueObj = upgradeQuoteObj.values[0];
          // @ts-ignore
          if (upgradeQuoteValueObj!.quoteValue) {
            parentState.calculator.financeForm.setUpgradeValue(
              upgradeQuoteValueObj!.quoteValue
            );
          }
          parentState.calculator.financeForm.setContractNumber(
            extendedProposal.contractNumber!
          );

          const lessorObj: Lessor = LessorModel.create({
            id: 91,
            name: 'Siemens',
            siemensLessor: true,
          });
          parentState.calculator.financeForm.setLessor(lessorObj);
        }

        // Step 6: Save the upgrade object and load it in to the calculator state
        try {
          await parentState.calculator.saveUpgradeToDraft();
        } catch (error) {
          raiseError(error.response.data.errors[0].errorMessage);
          return;
        }

        // Step 7: Set the isUpgrade flag true in the calculator state
        parentState.calculator.financeForm.setIsUpgrade(true);

        setIsUpgradeAction(false);
        navigate(`/app/calculator/finance`);
      })();
    };

    const raiseError = (errorString: string) => {
      setErrorMessage(errorString);
      Sentry.captureException(errorString);
      setIsError(true);
      setIsUpgradeAction(false);
    };

    //const showDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <Accordion
        className={className}
        square={true}
        classes={useStyles()}
        onChange={(event, expanded) => onChange(expanded)}
        expanded={expandedItem === proposal.id}
      >
        {!showDesktop && (
          <AccordionSummary
            expandIcon={<ToggleIcon />}
            classes={useSummaryStyles()}
          >
            <div className={'mobile'}>
              <div className={'row'}>
                <CardDetail title={t('customer', 'Customer')}>
                  {proposal.customer!.businessPartner !== null
                    ? proposal.customer!.businessPartner!.name
                    : 'T.B.C'}
                </CardDetail>
              </div>
              <div className={'row'}>
                <CardDetail
                  title={
                    proposal.isActive ? 'Contract Number' : 'Proposal Number'
                  }
                >
                  {proposal.isActive
                    ? proposal.contractNumber
                    : proposal.proposalNumber!}
                </CardDetail>
              </div>

              <div className={'row'}>
                <CardDetail title={t('dateCreated', 'Date Created')}>
                  {formatDate(proposal.created!)}
                </CardDetail>
                <CardDetail title={t('status', 'Status')}>
                  <StatusBadge label={status} />
                </CardDetail>
              </div>
            </div>
          </AccordionSummary>
        )}
        {showDesktop && (
          <AccordionSummary
            expandIcon={<ToggleIcon />}
            classes={useSummaryStyles()}
          >
            <CardDetail
              title={proposal.isActive ? 'Contract Number' : 'Proposal Number'}
            >
              {proposal.isActive
                ? proposal.contractNumber
                : proposal.proposalNumber!}
            </CardDetail>
            <CardDetail title={t('customer', 'Customer')}>
              {proposal.customer!.businessPartner !== null
                ? proposal.customer!.businessPartner!.name
                : 'T.B.C'}
            </CardDetail>
            <CardDetail title={t('amountFinanced', 'Amount Financed')}>
              {proposal.assetValue
                ? formatCurrency(
                    proposal.extendedCurrency.format,
                    proposal.assetValue || 0,
                    proposal.extendedCurrency.symbol
                  )
                : 'T.B.C'}
            </CardDetail>

            <CardDetail title={t('dateCreated', 'Date Created')}>
              {formatDate(proposal.created!)}
            </CardDetail>
            <CardDetail title={t('dateUpdated', 'Date Updated')}>
              {formatDate(proposal.updated!)}
            </CardDetail>
            <CardDetail id={'status'} title={t('status', 'Status')}>
              <StatusBadge label={status} />
            </CardDetail>
          </AccordionSummary>
        )}
        {/*</AccordionSummary>*/}
        <AccordionDetails classes={useDetailsStyles()}>
          {isLoading || (!proposalInfo && !assets) ? (
            <Loader text="Loading" />
          ) : !proposalInfo ? (
            <p className="no-results-found">
              t('noInfoFound', 'No info found')
            </p>
          ) : (
            <AccordionDetail
              state={state}
              proposal={proposal}
              proposalInfo={proposalInfo}
              assets={assets}
              openMoreDetails={openMoreDetails}
              openProposal={openProposal}
              amendProposal={amendProposal}
              deleteProposal={deleteProposal}
              isUpgradeAction={isUpgradeAction}
              createProposalWithUpgrade={createProposalWithUpgrade}
              canBeContinued={canBeContinued}
              status={status}
              activeFilter={activeFilter}
            />
          )}
        </AccordionDetails>
        <ErrorDialog
          open={isError}
          onOk={() => setIsError(false)}
          handleClose={() => setIsError(false)}
          okCaption="OK"
          title={t('upgradeResponse', 'Upgrade Response')}
          state={AppState}
        >
          <p>{errorMessage}</p>
          <p>
            For help, please email{' '}
            <a href="mailto:mysfs.uk@siemens.com">mysfs.uk@siemens.com</a>
          </p>
        </ErrorDialog>
      </Accordion>
    );
  }
);
