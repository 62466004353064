import React, { useState } from 'react';
import Panel from '../../../components/Panel';
import { KonicaState } from '../../../model/Konica.model';
import Button from '../../../components/Button';
import { makeStyles } from '@material-ui/core';
import data from './data.json';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import config from '../../../config';
import { StateInterface } from './Konica';
import { DropzoneArea } from 'material-ui-dropzone';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles({
  ListItem: {
    '&:hover, &.Mui-selected': {
      backgroundColor: '#00FFB9 !important',
      color: '#000028',
      fontWeight: 700,
    },
    border: 'solid #D1D5DB 1px',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    marginBottom: 25,
    width: 205,
  },
  ListItemText: {
    '& span': {
      color: 'inherit',
      fontWeight: 'inherit',
      marginLeft: 10,
      padding: 0,
    },
  },
  documentTypes: {
    '& .MuiList-padding': {
      paddingTop: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginRight: 15,
  },
  dropZone: {
    '& .MuiDropzonePreviewList-root': {
      justifyContent: 'center',
    },
    display: 'flex',
    flexBasis: '80%',
    flexDirection: 'column',
  },
  previewChip: {
    maxWidth: 210,
    minWidth: 160,
  },
  submit: {
    '& .submitText': {
      marginBottom: 20,
    },
    borderTop: 'solid black 1px',
    paddingTop: 20,
  },
  uploadButton: {
    '& div': {
      maxWidth: 'unset',
    },
    marginBottom: 10,
    marginTop: 10,
  },
  uploadedDocs: {
    borderTop: 'solid black 1px',
    paddingBottom: 10,
    paddingTop: 10,
  },
  uploader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
});

interface Props {
  localState: StateInterface;
  state: KonicaState;
  setSnackBarOpen: (value) => void;
  setSnackbar: (value) => void;
}

const UploadDocs = ({
  localState,
  state,
  setSnackBarOpen,
  setSnackbar,
}: Props) => {
  const [docTypeSelected, setDocTypeSelected] = useState();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const classes = useStyles();
  const appState = getParent<AppState>(state);

  const finaliseProposal = async (): Promise<any> => {
    const payload = {
      ticket: {
        comment: {
          body: `New Konica Framework ticket created - ${state.proposal.id} ${state.proposal.customer.businessPartner.name} ${localState.onBaseNumber}`,
        },
        customFields: [
          {
            id: config.zendesk?.proposalIdField,
            value: [state.proposal.id],
          },
          {
            id: config.zendesk?.salesChannelField,
            value: ['vendor'],
          },
          {
            id: config.zendesk?.requestField,
            value: ['Other'],
          },
          {
            id: config.zendesk?.agreementField,
            value: [state.proposal.id],
          },
        ],
        followers: [
          { user_id: 388091257218 }, //Mark Taylor, Zendesk Sandbox
          { user_id: 360378730554 }, //Sabrina Sappal, Zendesk Prod
          { user_id: 360988323534 }, //Lorraine Smith, Zendesk Prod
        ],
        groupId: config.zendesk?.konicaGroup,
        requester: { name: 'Konica portal' },
        subject: `${state.proposal.id} ${state.proposal.customer.businessPartner.name} ${localState.onBaseNumber}`,
        ticketFormId: config.zendesk?.ticketFormId,
      },
    };
    await state.createTicket(payload);
    setSnackbar({
      message: 'Submitted successfully',
      severity: 'success',
    });
    setSnackBarOpen(true);
  };

  const getBase64 = (file: File | Blob): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  interface DocType {
    code?: number | null;
    id: number | null;
    name: string;
  }

  const uploadFile = async (
    file: any,
    alias: string,
    name: string,
    content: any,
    docType: DocType
  ): Promise<any> => {
    const payload = {
      alias: alias,
      fileContent: content,
      filename: name,
      type: docType,
    };

    const upload = await state.uploadDocSiesmart(payload);

    if (!upload) {
      appState.setIsLoading(false);
      setSnackbar({ message: 'Error uploading file', severity: 'error' });
      setSnackBarOpen(true);
      return;
    }
    setDocTypeSelected(null);
    appState.setIsLoading(false);
    setSnackbar({
      message: 'File uploaded successfully',
      severity: 'success',
    });
    setSnackBarOpen(true);
  };

  const handleDropzoneFiles = (files): void => {
    if (files[0]) {
      setSelectedFile(files[0]);
    }
  };

  const handleUpload = (): void => {
    if (selectedFile) {
      let doc: DocType = {
        id: null,
        name: '',
      };

      appState.setIsLoading(true);
      doc = {
        id: docTypeSelected,
        name: '',
      };

      const file = selectedFile;

      if (
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const alias = file.name.split('.')[0];
        const fileName = file.name
          .replace(/[.](?=.*[.])/g, '-')
          .replace(/[\])}[{(]/g, '');
        try {
          getBase64(file).then((data) => {
            uploadFile(file, alias, fileName, data.split(',')[1], doc).then(
              () => {
                setDocTypeSelected(null);
                setKey(key + 1);
                setSelectedFile(null);
              }
            );
          });
        } catch (err) {
          appState.setIsLoading(false);
          setSnackbar({ message: err.message, severity: 'error' });
          setSnackBarOpen(true);
          setDocTypeSelected(null);
          setKey(key + 1);
          setSelectedFile(null);
        }
      }
    }
  };

  return (
    <Panel
      title={'Deal Pack upload'}
      allowExpand={true}
      expanded={
        state.proposal && state.proposal.vendorReference === 'MySFS:PDF'
      }
    >
      <div>
        <div>
          Select the document type from the list on the left, choose your file
          with the box on the right, then click 'Upload'
        </div>
        <div className={classes.uploader}>
          <div className={classes.documentTypes}>
            <List>
              {data.documentType.map((option) => (
                <ListItem
                  key={option.id}
                  selected={docTypeSelected === option.id}
                  className={classes.ListItem}
                  onClick={() => {
                    setDocTypeSelected(option.id);
                  }}
                >
                  <ListItemText
                    className={classes.ListItemText}
                    primary={option.name}
                  />
                </ListItem>
              ))}
            </List>
          </div>

          <div className={classes.dropZone}>
            <DropzoneArea
              key={debounceKey}
              onChange={(files) => handleDropzoneFiles(files)}
              acceptedFiles={[
                'application/pdf',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/msword',
              ]}
              filesLimit={1}
              onDropRejected={() => {
                alert('Only PDF or Excel files are allowed');
              }}
              showPreviewsInDropzone={true}
              useChipsForPreview
              previewGridProps={{ container: { direction: 'row', spacing: 1 } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              showAlerts={['error']}
              clearOnUnmount={true}
            />
          </div>
        </div>
        <div className={classes.uploadButton}>
          <Button
            disabled={!(selectedFile && docTypeSelected)}
            onClick={() => handleUpload()}
            label={'Upload'}
          />
        </div>
        <div className={classes.uploadedDocs}>
          <strong>Uploaded docs</strong>
          {!state.documents ||
            (state.documents && state.documents.length == 0 && (
              <div>No documents</div>
            ))}
          {state.documents &&
            state.documents.length > 0 &&
            state.documents.map((doc) => (
              <div key={doc.id.value}>{doc.documentType.name}</div>
            ))}
        </div>

        <div className={classes.submit}>
          <div className={'submitText'}>
            Once you are finished uploading your docs please click 'Submit'
            below:
          </div>
          <div className={'submitButton'}>
            <Button onClick={() => finaliseProposal()} label={'Submit'} />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default UploadDocs;
