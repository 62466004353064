import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import React, { ChangeEvent } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Panel from '../../components/Panel';
import { Select, SelectOptions } from '../../components/Select';
import { CalculatorState } from '../../model/Calculator.model';
import { AppState } from '../../state';
import { AssetType, LoanPurpose } from '../../tools/SiemensApi';
import { useTranslation } from 'react-i18next';
import { AssetCondition } from '../../model/AssetForm.model';
import marketProductsConfig from '../../tools/constants-config';

interface Props {
  state: CalculatorState;
  next: () => void;
  reference: any;
}

export default observer(
  ({ state, state: { assetForm }, next, reference }: Props) => {
    const { t } = useTranslation();
    const appState = getParent<AppState>(state);
    const loanMarketProductId = marketProductsConfig.validValues[1].id;

    const descriptionValidator = (value: string) => {
      const minLength = 1;
      const maxLength = 60;
      const length = value.length;
      if (length < minLength) {
        return t('makePrompt', 'Please enter a make and model');
      } else if (length > maxLength) {
        return t('makeLength', 'Please enter between 1 and 60 characters');
      } else {
        return '';
      }
    };

    const assetTypesOptions: SelectOptions = assetForm.assetTypes
      ? (assetForm.assetTypes as Array<AssetType>).map(
          (assetType: AssetType) => ({
            label: assetType.name,
            value: assetType.id,
          })
        )
      : [];

    const categoriesOptions: SelectOptions = assetForm.selectableAssetSubTypes.map(
      (assetSubType) => ({
        label: assetSubType.name,
        value: assetSubType.id,
      })
    );

    const loanPurposeOptions: SelectOptions = assetForm.loanPurposesResult
      ? (assetForm.loanPurposesResult as Array<LoanPurpose>).map(
          (loanPurpose: LoanPurpose) => ({
            label: loanPurpose.name,
            value: loanPurpose.id,
          })
        )
      : [];

    const handleTypeChange = (assetOption: any) => {
      assetForm.setSelectedAssetType(assetOption.value);
      assetForm.setSelectedAssetSubType(null);
    };

    const handleSubTypeChange = (assetOption: any) => {
      assetForm.setSelectedAssetSubType(assetOption.value);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      assetForm.setAssetName(value);
    };

    const handleLoanPurposeChange = (loanPurposeOption: any) => {
      assetForm.setSelectedLoanPurpose(loanPurposeOption.value);
    };

    const conditionOptions = [
      { label: t('new', 'New'), value: 'NEW' },
      { label: t('refurbished', 'Refurbished'), value: 'REFURBISHED' },
      { label: t('used', 'Used'), value: 'USED' },
      { label: t('remanufactured', 'Remanufactured'), value: 'REMANUFACTURED' },
      { label: t('preRegistered', 'Pre-registered'), value: 'PREREGISTERED' },
    ];

    const calculator = appState.calculator;
    if (
      //state.marketProduct !== loanMarketProductId && //MARKET PRODUCTS HARDCODED - this is causing asset to break
      !calculator.calculation ||
      !calculator.calculation.config ||
      !assetForm.assetTypesResult ||
      !calculator.calculation.config.assetValueConfig ||
      typeof calculator.calculation.config.assetValueConfig.minValue !==
        'number' ||
      typeof calculator.calculation.config.assetValueConfig.maxValue !==
        'number' ||
      !calculator.calculation.config.currency.format ||
      !calculator.calculation.config.currency.currencyCode
    ) {
      return <> </>;
    }

    const title =
      state.marketProduct === loanMarketProductId
        ? 'Choose loan purpose'
        : 'Choose asset'; //MARKET PRODUCTS HARDCODED

    return (
      <div ref={reference} style={{ width: '100%' }}>
        <Panel title={title} number={3}>
          {state.marketProduct === loanMarketProductId && ( //MARKET PRODUCTS HARDCODED
            <Select
              options={loanPurposeOptions}
              label={t('loanPurpose', 'Loan purpose')}
              placeholder={t('selectLoanPurpose', 'Select loan purpose')}
              onChange={handleLoanPurposeChange}
              value={
                assetForm.selectedLoanPurpose &&
                loanPurposeOptions.find(
                  (option) => option.value === assetForm.selectedLoanPurposeId
                )
              }
            />
          )}
          {state.marketProduct !== loanMarketProductId && ( //MARKET PRODUCTS HARDCODED
            <>
              <Select
                options={assetTypesOptions}
                label={t('assetType', 'Asset type')}
                placeholder={t('selectType', 'Select type')}
                onChange={handleTypeChange}
                value={
                  assetForm.selectedAssetType &&
                  assetTypesOptions.find(
                    (option) => option.value === assetForm.selectedAssetType!.id
                  )
                }
              />
              <Select
                options={categoriesOptions}
                label={t('assetCategory', 'Asset category')}
                placeholder={t('selectCategory', 'Select category')}
                disabled={categoriesOptions.length === 0}
                onChange={handleSubTypeChange}
                value={
                  assetForm.selectedAssetSubType &&
                  categoriesOptions.find(
                    (option) =>
                      option.value === assetForm.selectedAssetSubType!.id
                  )
                }
              />
              <Input
                label={t('makeModel', 'Make and model')}
                value={assetForm.assetName}
                placeholder={t('makeModel', 'Make and model')}
                onChange={handleNameChange}
                validate={descriptionValidator}
                deferValidation={true}
              />
              <Select
                options={conditionOptions.filter(
                  (option) => option.value !== 'PREREGISTERED'
                )}
                label={t('condition', 'Condition')}
                placeholder={t('selectCondition', 'Select condition')}
                onChange={({ value }: any) => {
                  assetForm.setAssetCondition(value as AssetCondition);
                }}
                value={conditionOptions.find(
                  (option) => option.value === assetForm.assetCondition
                )}
              />
            </>
          )}
          {state.proposal &&
            state.proposal.assetSummary &&
            state.proposal.assetSummary.numberOfAssets < 1 && (
              <Button
                disabled={
                  (state.marketProduct === loanMarketProductId && //MARKET PRODUCTS HARDCODED
                    !assetForm.selectedLoanPurpose) ||
                  (state.marketProduct !== loanMarketProductId &&
                    !assetForm.asset) //MARKET PRODUCTS HARDCODED
                }
                onClick={() => next()}
                label={
                  state.marketProduct === loanMarketProductId //MARKET PRODUCTS HARDCODED
                    ? 'Save loan purpose'
                    : 'Save asset details'
                }
                style="search"
              />
            )}
        </Panel>
      </div>
    );
  }
);
