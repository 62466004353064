import { Box, makeStyles } from '@material-ui/core';
import { DialogContentProps } from '@material-ui/core/DialogContent';
import React, { useEffect } from 'react';
import theme from '../constants/theme';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';
import { AppState } from '../state';

const useStyles = makeStyles({
  root: {
    padding: theme.spacing(2),
  },
});

interface Props {
  open: boolean;
  title?: string;
  handleClose: () => void;
  onOk: () => void;
  okCaption?: string;
  errorMessage?: string;
  state: AppState;
}

export default ({
  handleClose,
  open,
  title,
  children,
  onOk,
  okCaption,
  state,
}: Props & DialogContentProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (open) {
      state.setIsLoading(false);
    }
  });

  return (
    <Dialog
      handleClose={handleClose}
      open={open}
      title={title || t('error', 'Error')}
      actions={[{ caption: okCaption || t('retry', 'Retry'), onClick: onOk }]}
    >
      <Box className={useStyles().root}>{children}</Box>
    </Dialog>
  );
};
