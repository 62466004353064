import { makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { clone, getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import { PriceListSelect } from '../../../components/PriceListSelect';
import { CalculatorState } from '../../../model/Calculator.model';
import { ErrorMessage } from '../../../components/ErrorMessage';
import React, { ReactElement } from 'react';
import { CustomerForm } from '../../../model/CustomerForm.model';
import { observer } from 'mobx-react';
import { BusinessPartnerRef } from '../../../tools/SiemensApi';
import Modal from '../../../components/Modal';

const useStyles = makeStyles({
  customerBox: {
    '& .contentBox': {
      alignItems: 'baseline',
      display: 'flex',
    },
    '& .customerName': {
      color: '#000028',
      fontSize: 16,
      fontWeight: 700,
    },
    '& .customerNumber': {
      color: '#009999',
      marginLeft: 20,
    },
    '&:hover': {
      backgroundColor: '#00FFB9',
      color: 'black !important',
      cursor: 'pointer',
    },
    border: '1px solid #D1D5DB',
    borderRadius: 8,
    color: '#374151',
    fontSize: 14,
    marginBottom: 4,
    padding: 8,
  },
  header: {
    '& strong': {
      color: '#009999',
    },
    fontSize: 24,
    marginBottom: 16,
  },
});

interface Props {
  next: () => void;
  state: CustomerForm;
  closeCustomerSearch: () => void;
}

const CustomerSelector = observer(
  ({ next, state, closeCustomerSearch }: Props) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const appState: AppState = getParent<AppState>(state, 2);

    const closeModal = (): void => {
      closeCustomerSearch();
    };

    const handleSelect = async (
      businessPartnerRef: BusinessPartnerRef
    ): Promise<any> => {
      appState.setIsLoading(true);
      await state.setCustomer(businessPartnerRef);
      next();
      closeModal();
    };

    //state.nameQuery && !state.registrationNumberQuery && !state.postcodeQuery;

    let searchQuery = '';
    if (state.nameQuery) {
      searchQuery += state.nameQuery + ', ';
    }
    if (state.registrationNumberQuery) {
      searchQuery += state.registrationNumberQuery + ', ';
    }
    if (state.postcodeQuery) {
      searchQuery += state.postcodeQuery + ', ';
    }
    searchQuery = searchQuery.substring(0, searchQuery.length - 2);

    const content = (): ReactElement => {
      return (
        <div>
          {state.customers && state.customers.length ? (
            <>
              <div className={classes.header}>
                <Trans i18nKey="selectCustomerFrom">
                  {{ customerName: searchQuery }}
                </Trans>
              </div>
              {state.customers.map((customer) => {
                if (customer && customer.businessPartnerRef) {
                  return (
                    <div
                      key={customer.businessPartnerRef.registrationNumber}
                      className={classes.customerBox}
                      onClick={
                        () => handleSelect(clone(customer.businessPartnerRef)) //it doesn't like this
                      }
                    >
                      <div className="contentBox">
                        <div className="customerName">{customer.name}</div>
                        <div className="customerNumber">
                          Reg # {customer.businessPartnerRef.registrationNumber}
                        </div>
                      </div>
                      <div>
                        <div>
                          {[customer.address1, customer.address2]
                            .filter(Boolean)
                            .join(' ')}
                          ,{customer.city},{customer.postalCode}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}

              {getParent<AppState>(state, 2).isBT && (
                <PriceListSelect
                  state={getParent<CalculatorState>(state)}
                  label={'Industry'}
                  translation={{
                    'Local Authority':
                      'Local Authority, Police Authority, Fire Authority',
                    NHS: 'NHS Trust',
                    PLC: 'LSE Quoted PLC',
                    SME: 'SMEs and Other',
                    'Universities & Schools':
                      'University, Local Authority School, Academy School',
                  }}
                />
              )}
            </>
          ) : (
            <ErrorMessage>
              <Typography variant={'body1'}>
                <Trans i18nKey="customerSearchError">
                  {{
                    errorType:
                      state.errorType === 'TooManyResults'
                        ? t('tooMany', 'too many')
                        : t('no', 'no'),
                  }}
                </Trans>
              </Typography>
              <Typography variant={'body1'}>
                <Trans i18nKey="repeatSearch">
                  {{
                    searchType:
                      state.errorType === 'TooManyResults'
                        ? t('narrowQuery', 'narrow your query')
                        : t('searchAgain', 'search again'),
                  }}
                </Trans>
              </Typography>
            </ErrorMessage>
          )}
        </div>
      );
    };

    return <Modal closeModal={closeModal} content={content()} />;
  }
);

export default CustomerSelector;
