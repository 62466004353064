import { makeStyles } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react';
import { clone, getParent, getType, getSnapshot } from 'mobx-state-tree';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import CurrencyInput from '../../components/CurrencyInput';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import Panel from '../../components/Panel';
import { PriceListSelect } from '../../components/PriceListSelect';
import { Select, SelectOption, SelectOptions } from '../../components/Select';
import theme from '../../constants/theme';
import { CalculatorState } from '../../model/Calculator.model';
import { AppState } from '../../state';
import {
  Currency,
  MarketProduct,
  performCalculation,
  PerformCalculationParametersModel,
  PerformCalculationResponseType,
  Period,
  CalculationConfiguration,
  CalculationSheet,
} from '../../tools/SiemensApi';
import marketProductsConfig from '../../tools/constants-config';
import { useTranslation } from 'react-i18next';
import Switch from '../../components/Switch';
import _ from 'lodash';
import Upgrades from './components/Upgrades';
import TermSlider from './components/TermSlider';

const useStyles = makeStyles<{}, { operation: Operation }>({
  root: (props) => ({
    '& .MuiFormHelperText-root': {
      display: 'inherit',
      flexDirection: 'initial',
      marginTop: 8,
      textAlign: 'center',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#FFFFFF',
    },
    '& .MuiFormHelperText-root::before': {
      border: 'None',
      left: 0,
      top: 0,
    },
    '& .calculation': {
      backgroundColor: '#00FFB9',
      borderRadius: 6,
      marginBottom: 24,
      padding: 22,
    },
    '& .formGroup': {
      '& .direction-indicator': {
        backgroundColor: '#000028',
        borderRadius: '50%',
        color: 'white',
        height: 30,
        margin: 'auto',
        paddingLeft: 7,
        paddingTop: 3,
        rotate: '90deg',
        transform: `scaleX(${props.operation === 'CALC_ASSET_VALUE' ? -1 : 1})`,
        transition: 'transform 1s',
        width: 30,
        [theme.breakpoints.up('sm') as string]: {
          margin: theme.spacing(5, 0, 0),
          rotate: '0deg',
        },
      },
      '&.term': {
        flexDirection: 'row',
      },
    },
    '& .input-with-loader': {
      flex: '0 1 40%',
      position: 'inherit',
      [theme.breakpoints.up('sm') as string]: {
        maxWidth: '40%',
      },
    },
    '& .input-wrap': {
      '& .loader-wrap': {
        left: 35,
        [theme.breakpoints.up('sm') as string]: {
          left: '10px !important',
        },
        letterSpacing: 5,
        position: 'absolute',
        top: 20,
      },
      '& .transparentText input': {
        color: 'transparent',
      },
      display: 'flex',
      flexBasis: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      [theme.breakpoints.up('sm') as string]: {
        flexDirection: 'row',
      },
    },
    '& .input-wrap.total': {
      marginTop: 16,
    },
    '& .intro': {
      '& > div': {
        flexBasis: 0, // for ie11
        flexGrow: 1,
        width: 'unset',
      },
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: '10px',
      [theme.breakpoints.up('sm') as string]: {
        flexDirection: 'row',
      },
    },
    '& .rentalInput': {
      marginBottom: 0,
    },
    '& .serviceFeeCheckbox': {
      marginTop: 0,
    },
    '& .term': {
      display: 'flex',
    },
    '& .termInput': {
      width: 100,
    },
    '& .termLabel': {
      marginLeft: 8,
      paddingTop: 45,
    },
  }),
});

const TextfieldOrSelect = ({ options, selectedOption, onChange, label }: any) =>
  options.length === 1 ? (
    <Input
      label={label}
      readOnly={true}
      value={selectedOption === undefined ? '' : selectedOption.label}
    />
  ) : (
    <Select
      options={options}
      label={label}
      onChange={onChange}
      value={selectedOption}
      isSearchable={false}
    />
  );

type Operation = 'CALC_RENTAL' | 'CALC_ASSET_VALUE';

interface Props {
  next: () => void;
  state: CalculatorState;
  setErrorMessage?: any;
  setIsError?: any;
}

export default observer(
  ({ next, setErrorMessage, setIsError, state }: Props) => {
    const { t } = useTranslation();
    const [throttle, setThrottle] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [term, setTerm] = useState<number>(
      (state.calculation &&
        state.calculation.sheet &&
        state.calculation.sheet.term) ||
        12
    );

    const [assetValue, setAssetValue] = useState<number | null>(
      state.assetValue || null
    );
    const [rental, setRental] = useState<number | null>(state.rental || null);

    useEffect(() => {
      if (state.rental) {
        setRental(state.rental);
      }
    }, [state.rental]);

    useEffect(() => {
      if (state.assetValue) {
        setAssetValue(state.assetValue);
      }
    }, [state.assetValue]);

    const prepareSheet = (operation: string): void => {
      if (
        state.calculation &&
        state.calculation.sheet &&
        state.calculation.sheet.payment &&
        state.calculation.sheet.payment.paymentLines //check payment lines exists instead
      ) {
        const upgradeValue = state.financeForm.upgradeValue;
        const sheetAssetValue =
          operation === 'CALC_ASSET_VALUE' ? null : assetValue;

        const amountFinanced =
          operation === 'CALC_ASSET_VALUE'
            ? null
            : (sheetAssetValue || 0) + (upgradeValue || 0);

        const paymentAmount =
          operation === 'CALC_ASSET_VALUE' ? rental || 0 : null;

        //const payment = clone(state.calculation.sheet.payment) as Payment;
        //const paymentLine = payment.paymentLines[0] as PaymentLine;

        const payment = JSON.parse(
          JSON.stringify(state.calculation.sheet.payment)
        );
        payment.paymentLines[0].value = paymentAmount;
        //paymentLine.setValue(paymentAmount);

        const fees = [];
        if (state.financeForm.maintenanceValue && state.marketProduct === 127) {
          //MARKET PRODUCTS HARDCODED
          fees.push({
            amount: state.financeForm.maintenanceValue,
            feeIncluded: false,
            feeType: {
              code: 'HES_MAINTENANCE',
              id: 12,
              name: 'Maintenance',
              readonly: false,
            },
            manualSet: true,
          });
        }

        //the sheet isn't being prepped right because the new model is differet
        //add the correct number of payments to calculate term based on the input value for payment period
        //default should be value returned by monthly
        //if term is

        //set the correct number of payments based on term and months per period to get the correct term and mpp
        const numberOfPayments: number =
          state.calculation.sheet.term /
          state.calculation.sheet.payment.paymentLines[0].period
            .monthsPerPeriod;

        payment.paymentLines[0].numberOfPayments = numberOfPayments;
        //paymentLine.setNumberOfPayments(numberOfPayments);

        state.updateSheet({
          assetValue: sheetAssetValue,
          payment,
          fees,
          amountFinanced,
        });
      }
    };

    const calculate = () => {
      if (throttle) {
        window.clearTimeout(throttle);
      }

      setThrottle(
        window.setTimeout(async () => {
          setThrottle(null);
          if (
            state.calculation &&
            state.calculation.sheet &&
            state.periodsFitInTerm &&
            (assetValue || rental)
          ) {
            if (!assetValue && operation === 'CALC_RENTAL') {
              state.setOperation('CALC_ASSET_VALUE');
            } else if (!rental && operation === 'CALC_ASSET_VALUE') {
              state.setOperation('CALC_RENTAL');
            }

            if (
              state.operation === 'CALC_RENTAL' &&
              assetValue &&
              assetValue <
                state.calculation!.config!.assetValueConfig!.minValue!
            ) {
              return null;
            }

            setLoading(true);

            prepareSheet(state.operation);
            const upgradeValue = state.financeForm.upgradeValue;

            const clonedSheet = (node: any) =>
              getType(node).create(
                Object.assign({}, getSnapshot(node), {
                  assetValue: assetValue,
                })
              );

            const newSheet = clonedSheet(state.calculation.sheet);
            let calculation: AxiosResponse<PerformCalculationResponseType>;
            if (!state.proposal) {
              try {
                calculation = await performCalculation(
                  PerformCalculationParametersModel.create({
                    introducerId: state.introducerId,
                    //proposalId: state.proposal!.id!,
                    officeId: state.officeId,
                  }),
                  {
                    operation: state.operation,
                    //sheet: state.calculation.sheet as CalculationSheet,
                    sheet: newSheet as CalculationSheet,
                  }
                );
              } catch (error) {
                calculation = error.response;
              }
              state.setCalculation(calculation.data);
              if (state.operation === 'CALC_ASSET_VALUE') {
                setAssetValue(calculation.data.sheet!.assetValue || 0);
              }
              if (state.operation === 'CALC_RENTAL') {
                setRental(
                  calculation.data.sheet.payment.avgPaymentPerPeriod || 0
                );
              }
            }
            if (state.proposal) {
              //this is hot fix - because the rental wasn't updating when the calculation was being saved
              await state.saveCalculationToDraft(state.operation);
            }

            setLoading(false);
          }
        }, 1000)
      );
    };

    useEffect(() => {
      if (state.operation === 'CALC_ASSET_VALUE') {
        calculate();
      }
    }, [rental]);

    useEffect(() => {
      if (
        state.operation === 'CALC_RENTAL' &&
        assetValue &&
        assetValue != state.assetValue
        //this might be why the rental cal isn't setting but Asset calc is
      ) {
        calculate();
      }
    }, [assetValue]);

    useEffect(() => {
      if (state.operation === 'CALC_ASSET_VALUE') {
        state.assetForm.setServiceFee(false);
      }
    }, [state.operation]);

    if (
      !state.calculation ||
      !state.calculation.config ||
      !state.calculation.sheet ||
      !state.calculation.errors
    ) {
      return <></>;
    }

    const config: CalculationConfiguration = state.calculation
      .config as CalculationConfiguration;
    const sheet: CalculationSheet = state.calculation.sheet as CalculationSheet;

    const validIds = marketProductsConfig.validValues.map(
      (product) => product.id
    );

    const monthlyPayment =
      sheet.payment &&
      sheet.payment.paymentLines &&
      sheet.payment.paymentLines[0].value
        ? sheet.payment.paymentLines[0].value
        : 0; //TODO: this is not being returned

    const marketProductOptions: SelectOptions = (config.marketProductsConfig
      .validValues as Array<MarketProduct>) //it's doing that annoying error
      .filter(
        (marketProduct) => _.includes(validIds, marketProduct.id) //have numbers changed ???? YES!!! TODO: check all numbers correct, 483 not used
      ) //MARKET PRODUCTS HARDCODED

      .map((marketProduct: MarketProduct) => ({
        label: marketProduct.name!,
        value: marketProduct.id,
      }));

    const marketProductOption = marketProductOptions.find(
      (marketProduct) => marketProduct.value === sheet.marketProduct!.id
    )!;

    const paymentPeriodOptions: SelectOptions = (config.paymentPeriodsConfig
      .validValues! as Array<Period>)
      .filter((period: Period) => period.monthsPerPeriod)
      .map((period: Period) => {
        const label = period
          .label!.toLowerCase()
          .replace(/quarter/i, 'quarterly');
        return {
          label: label[0].toLocaleUpperCase() + label.slice(1),
          value: period.id,
        };
      });

    const paymentPeriodOption = paymentPeriodOptions.find(
      (paymentPeriod) =>
        paymentPeriod.value === sheet.payment.paymentLines[0].period.id //state.paymentPeriod!.id //sheet.payment.paymentLines[0].period.id //TODO: this can now be found in payment lines array
    )!;

    //payment period is not updating in the state

    const handleUpgradeClick = async () => {
      const isUpgrade = !state.financeForm.isUpgrade;
      if (!isUpgrade) {
        await state.deleteUpgrades(state.proposal!.id!);
        state.financeForm.resetUpgrade();
        calculate();
      }
      state.financeForm.setIsUpgrade(isUpgrade);
    };
    //this will need fixing next
    const getAmountFinanced = (): number | null => {
      const upgradeValue = state.financeForm.upgradeValue;
      const amountFinanced = (assetValue || 0) + (upgradeValue || 0);
      if (amountFinanced > 0) {
        return (assetValue || 0) + (upgradeValue || 0);
      }
      return null;
    };
    const operation = state.operation;
    const classes = useStyles({ operation });
    // @ts-ignore
    return (
      <Panel
        classes={classes}
        title={t('chooseFinance', 'Choose financing options')}
        number={1}
      >
        <div className={'intro'}>
          <TextfieldOrSelect
            onChange={({ value }: SelectOption) => {
              const marketProduct: MarketProduct = (config.marketProductsConfig
                .validValues! as Array<MarketProduct>).find(
                (product: MarketProduct) => product.id === value
              )!;
              state.updateSheet({
                marketProduct: clone(marketProduct),
                marketProductId: marketProduct.id,
              });
              calculate();
            }}
            options={marketProductOptions}
            selectedOption={marketProductOption}
            label={t('marketProduct', 'Market product')}
          />
          <Select
            options={paymentPeriodOptions}
            label={t('paymentPeriod', 'Payment period')}
            onChange={({ value }: any) => {
              if (state.calculation) {
                const paymentPeriod = (config.paymentPeriodsConfig
                  .validValues! as Array<Period>).find(
                  (period: Period) => period.id === value
                );
                //Number of payments = term entered / paymentPeriod.months per period
                //when term is changed number of payments should also change

                const payment = JSON.parse(
                  JSON.stringify(state.calculation.sheet.payment)
                );
                payment.paymentLines[0].period = clone(paymentPeriod);
                state.updateSheet({ payment: payment });

                calculate();
              }
            }}
            value={paymentPeriodOption} //I don't think this is beig passed in properly
            isSearchable={false}
            disabled={paymentPeriodOptions.length === 1}
          />
          {config.pricelistsConfig.validValues!.length > 1 && (
            // !getParent<AppState>(state).isBT && (
            <PriceListSelect state={state} onChange={() => calculate()} />
          )}
        </div>
        <div className={'term'}>
          <Input
            className={'termInput'}
            value={term} //term seems to be lagging
            placeholder={t('term', 'Term')}
            onChange={(event: any) => {
              if (state.calculation && config.termConfig) {
                const value: any = event.target.value || 0;
                const min = config.termConfig.minValue || 12;
                const max = config.termConfig.maxValue || 84;

                if (loading || isNaN(value)) {
                  return;
                }

                const newTerm = +value || 0;

                setTerm(newTerm);
                if (newTerm >= min && newTerm <= max) {
                  state.updateSheet({ term: newTerm });
                  calculate();
                }
              }
            }}
            label={t('termLength', 'Term length')}
            validate={(value: string) => {
              let result;
              if (
                state.calculation &&
                config.termConfig &&
                sheet.payment.paymentLines[0].period &&
                sheet.payment.paymentLines[0].period.monthsPerPeriod
              ) {
                const min = config.termConfig.minValue || 12;
                const max = config.termConfig.maxValue || 84;
                result =
                  +value < min || +value > max
                    ? t(
                        'termLengthOutOfBounds',
                        'Term length is out of bounds, please enter between 12 and 84 months'
                      )
                    : '';
                if (!result) {
                  if (
                    +value %
                    sheet.payment.paymentLines[0].period.monthsPerPeriod
                  ) {
                    result = t(
                      'termLengthWholeNumberError',
                      'The term length does not contain a whole number of payment periods'
                    );
                  }
                }
              } else {
                result = '';
              }
              return result;
            }}
          />
          <div className="termLabel">{t('months', 'months')}</div>
        </div>
        <TermSlider
          state={state}
          setTerm={setTerm}
          loading={loading}
          calculate={calculate}
        />

        {state.allowUpgrade && (
          <Switch
            onClick={handleUpgradeClick}
            checked={state.financeForm.isUpgrade}
            label={t('addUpgrade', 'Add Upgrade/ Settlement Details')}
          />
        )}
        {state.financeForm.isUpgrade && (
          <Upgrades
            state={state}
            assetValue={assetValue}
            rental={rental}
            calculate={calculate}
            setErrorMessage={setErrorMessage}
            setIsError={setIsError}
          />
        )}

        <div className={'formGroup calculation'}>
          <div className="input-wrap">
            <div className="input-with-loader">
              <CurrencyInput
                className={
                  loading && state.operation === 'CALC_ASSET_VALUE'
                    ? 'transparentText'
                    : ''
                }
                value={assetValue}
                //value={getAmountFinanced()}
                currency={state.calculation.config.currency as Currency}
                label={
                  state.financeForm.isUpgrade
                    ? t('equipmentCost', 'Equipment Cost')
                    : t('amountFinanced', 'Amount financed')
                }
                onFocus={() => state.setOperation('CALC_RENTAL')}
                onChange={(value: number | null) => {
                  setAssetValue(value);
                }}
                // validate={(total: number | null) =>
                //   state.getTotalValidationMessage(total, operation) || ''
                // }
              />
              {loading && state.operation === 'CALC_ASSET_VALUE' && (
                <div className="loader-wrap">
                  <Loader dots={true} />
                </div>
              )}
            </div>
            <div className="direction-indicator">→</div>
            <div className="input-with-loader">
              <CurrencyInput
                className={
                  loading && state.operation === 'CALC_RENTAL'
                    ? 'rentalInput transparentText'
                    : 'rentalInput'
                }
                value={rental} //set rental value
                currency={state.calculation.config.currency as Currency}
                label={t('rental', 'Rental')}
                onFocus={() => {
                  state.setOperation('CALC_ASSET_VALUE');
                }}
                onChange={(value: number | null) => {
                  setRental(value);
                }}
                validate={(total: number | null) =>
                  state.getRentalValidationMessage(total, state.operation) || ''
                }
              />
              {getParent<AppState>(state).isBT && (
                <Checkbox
                  className={'serviceFeeCheckbox'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    state.assetForm.setServiceFee(event.target.checked);
                    setRental(state.rentalExFee);
                  }}
                  checked={
                    state.operation === 'CALC_ASSET_VALUE'
                      ? false
                      : state.assetForm.serviceFee
                  }
                  disabled={state.operation === 'CALC_ASSET_VALUE'}
                  label={t('addServiceFee', 'Add service fee')}
                />
              )}{' '}
              {loading && state.operation === 'CALC_RENTAL' && (
                <div className="loader-wrap">
                  <Loader dots={true} />
                </div>
              )}
            </div>
          </div>

          <div className="input-wrap total">
            <div
              className="input-with-loader"
              style={{
                display: state.financeForm.isUpgrade ? 'block' : 'none',
              }}
            >
              <CurrencyInput
                className={loading ? 'transparentText' : ''}
                label={
                  t('total', 'Total') +
                  (state.upgradeValue
                    ? t('includingUpgradeValue', ' including upgrade value')
                    : '')
                }
                currency={state.calculation.config.currency as Currency}
                readOnly={true}
                value={getAmountFinanced() || 0} //set amount financed value
                //value={state.calculation.sheet.amountFinanced || 0}
              />
              {loading && (
                <div className="loader-wrap">
                  <Loader dots={true} />
                </div>
              )}
            </div>

            {state.marketProduct === 127 && ( //MARKET PRODUCTS HARDCODED
              <div className="input-with-loader">
                <CurrencyInput
                  value={state.financeForm.maintenanceValue}
                  currency={state.calculation.config.currency as Currency}
                  label={t('maintenanceAmount', 'Maintenance')}
                  onChange={(value: number | null) => {
                    state.financeForm.setMaintenanceValue(value);
                    calculate();
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {!state.proposal && (
          <Button
            disabled={
              !monthlyPayment ||
              !!throttle ||
              loading ||
              marketProductOption === undefined ||
              (assetValue || 0) >
                state.calculation!.config!.assetValueConfig!.maxValue! ||
              (assetValue || 0) <
                state.calculation!.config!.assetValueConfig!.minValue! ||
              (state.marketProduct === 127 && //MARKET PRODUCTS HARDCODED
                !state.financeForm.maintenanceValue) ||
              (state.financeForm.isUpgrade && !state.upgradeValue)
            }
            onClick={() => next()}
            label={t(
              'continueToCustomerSelection',
              'Continue to customer selection'
            )}
            style="search"
          />
        )}
      </Panel>
    );
  }
);
