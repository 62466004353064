import { observer } from 'mobx-react';
import { makeStyles, Slider } from '@material-ui/core';
import React from 'react';
import { CalculationConfiguration } from '../../../tools/SiemensApi';
import theme from '../../../constants/theme';
import { CalculatorState } from '../../../model/Calculator.model';

const useStyles = makeStyles({
  root: {
    '& .MuiSlider-markLabel': {
      color: '#000000',
      fontSize: 14,
      top: 0,
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#f3f4f6',
      height: 4,
      opacity: 1,
    },
    '& .MuiSlider-thumb': {
      backgroundColor: '#fff',
      border: 'solid 2px #00FFB9',
      height: 24,
      marginLeft: 8,
      marginTop: 5,
      width: 24,
    },
    '& .MuiSlider-thumb.MuiSlider-active': {
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 40, 0.16)',
    },
    '& .MuiSlider-track': {
      background: 'linear-gradient(90deg, #00ffb9 0%, #00e6dc 100.25%)',
      border: 'none',
      height: 4,
    },
    margin: theme.spacing(4, 2),
  },
});

interface Props {
  state: CalculatorState;
  setTerm: (newTerm: number) => void;
  loading: boolean;
  calculate: () => void;
}

const TermSlider = observer(({ state, setTerm, loading, calculate }: Props) => {
  const classes = useStyles();
  const config: CalculationConfiguration = state.calculation
    ?.config as CalculationConfiguration;
  /**
   * TODO:The min and max have changed so just use default for now
   */
  const sliderMin = 12; //config.termConfig?.minValue || 12; //min is now 8
  const sliderMax = 84; //config.termConfig?.maxValue || 84; //max is now 84
  const sliderStep = 12;
  const marks = [];
  for (let value = sliderMin; value <= sliderMax; value = value + sliderStep) {
    marks.push({ value, label: '' + value });
  }

  return (
    <Slider
      className={classes.root}
      step={sliderStep}
      min={sliderMin}
      max={sliderMax}
      marks={marks}
      value={state.calculation?.sheet?.term || sliderMin}
      onDragStart={(event: any): void => {
        event.target.focus();
      }}
      onChange={(e: any, value: number | Array<number>): void => {
        if (loading || !state.calculation || typeof value !== 'number') {
          return;
        }
        const newTerm = value;
        setTerm(newTerm);
        state.updateSheet({ term: newTerm });
        calculate();
      }}
    />
  );
});

export default TermSlider;
