import { Container, makeStyles } from '@material-ui/core';

//import spinner from 'media/spinner.gif';
import spinner from 'media/spinner.svg';
import React from 'react';
import { COLORS } from '../constants/style-constants';

// @ts-ignore
const dotStyles = makeStyles({
  '@keyframes blink': {
    '0%': { opacity: '.2' },
    '100%': { opacity: '.2' },
    '20%': { opacity: '1' },
  },
  root: {
    '& span': {
      animationDuration: '1.4s',
      animationFillMode: 'both',
      animationIterationCount: 'infinite',
      animationName: '$blink',
      fontSize: 40,
    },
    '& span:nth-child(2)': {
      animationDelay: '.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '.4s',
    },
  },
});

const useStyles = makeStyles({
  root: {
    '& img': {
      width: 80,
    },
    alignItems: 'center',
    color: '#000028',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 20,
    letterSpacing: 0.77,
    margin: '15px 0',
  },
});

interface Props {
  text?: string;
  dots?: boolean;
}

export default ({ text, dots }: Props = { text: '' }) => {
  if (dots) {
    return (
      <Container classes={dotStyles()}>
        {text && <div>{text}</div>}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Container>
    );
  } else {
    return (
      <Container classes={useStyles()}>
        {text && <div>{text}</div>}
        <img src={spinner} />
      </Container>
    );
  }
};
