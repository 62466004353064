import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';
import { AppState } from '../../state';
import { Authentication } from '../../tools/Auth';
import App from './App';
import { Callback } from './Callback';
import Splash from './Splash';
import TermsAndConditions from './TermsAndConditions';
import Logout from './Logout';
import PrivateRoute from './PrivateRoute';
import ContentLoaderOverlay from '../../components/ContentLoaderOverlay';

interface Props {
  state: AppState;
}

export default observer(({ state }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const agreedToTerms = async () => {
    await state.setHasReadTermsAndConditions();
    await state.init();
    navigate('/app');
  };

  // const isShowModal: boolean = state.showModal;
  //
  // const isLoading: boolean =
  //   state.isLoading && location.pathname.startsWith('/app/myprops');

  // just to make sure this component gets rerendered on authentication timeout
  useEffect(() => void 0, [state.isAuthenticated]);
  return (
    <>
      <ScrollToTop location={location} />
      <Routes>
        <Route path="/" element={<Splash onLine={state.onLine} />} />
        <Route
          path="callback"
          element={
            <Callback
              setAuthentication={async (authentication: Authentication) => {
                await state.init(authentication);
              }}
            />
          }
        />
        <Route
          path="terms-and-conditions"
          element={
            <PrivateRoute isAuthenticated={state.isAuthenticated}>
              <TermsAndConditions handleAgreed={() => agreedToTerms()} />
            </PrivateRoute>
          }
        />
        <Route
          path="app/*"
          element={
            state.user && !state.hasReadTermsAndConditions ? (
              <Navigate replace to={'/terms-and-conditions'} />
            ) : (
              <PrivateRoute isAuthenticated={state.isAuthenticated}>
                <App state={state} />
              </PrivateRoute>
            )
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<Logout />} />
      </Routes>
      {state.loading && <ContentLoaderOverlay text={'Loading'} state={state} />}
    </>
  );
});
