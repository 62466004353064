import { observer } from 'mobx-react';
import { Select } from '../../../components/Select';
import Input from '../../../components/Input';
import CurrencyInput from '../../../components/CurrencyInput';
import {
  Contract,
  ContractUpgradeQuote,
  Currency,
  Lessor,
} from '../../../tools/SiemensApi';
import Button from '../../../components/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { CalculatorState } from '../../../model/Calculator.model';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import { useTranslation } from 'react-i18next';
import theme from '../../../constants/theme';

const useStyles = makeStyles({
  '& .upgrade-inputs': {
    '& .MuiFormControl-root': {
      margin: 0,
    },
    '& > div': {
      boxSizing: 'border-box',
      flex: '0 1 33%',
      padding: '16px 24px',
      width: 'unset',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm') as string]: {
      flexDirection: 'row',
    },
  },
  formGroup: {
    '& .input': {
      display: 'flex',
      flex: 1,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: 25,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  lessorContainer: {
    marginBottom: 25,
  },
  root: {
    '& .upgrade-buttons': {
      '& .MuiButton-label': {
        whiteSpace: 'normal',
      },
      '& button': {
        height: 'auto',
        margin: 0,

        minHeight: 50,
        minWidth: 100,
        width: '100%',
        [theme.breakpoints.up('sm') as string]: {
          maxWidth: '288px',
        },
      },
      '& div': {
        boxSizing: 'border-box',
        display: 'flex',
        flex: '1 1 33%',
        justifyContent: 'center',
        padding: '16px 24px',
        [theme.breakpoints.up('sm') as string]: {
          //maxWidth: '33%',
        },
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm') as string]: {
        flexDirection: 'row',
      },
    },
    '& .upgrade-inputs': {
      '& .MuiFormControl-root': {
        margin: 0,
      },
      '& > div': {
        boxSizing: 'border-box',
        flex: '0 1 33%',
        padding: '16px 24px',
        width: 'unset',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm') as string]: {
        flexDirection: 'row',
      },
    },
    borderBottom: 'solid #EBF0F4 1px',
    padding: 0,
  },
});

interface Props {
  state: CalculatorState;
  assetValue: number | null;
  rental: number | null;
  calculate: () => void;
  setErrorMessage: any;
  setIsError: any;
}

const Upgrades = observer(
  ({
    state,
    assetValue,
    rental,
    calculate,
    setErrorMessage,
    setIsError,
  }: Props) => {
    const appState = getParent<AppState>(state);
    const [stateLessors, setLessors] = useState<Array<Lessor>>([]);
    const [errorEnabled, setErrorEnabled] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>('');
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const getUpgrade = (): void => {
      if (
        state.upgrades &&
        state.upgrades[0] &&
        state.upgrades[0].lessor &&
        state.upgrades[0].quoteTotalValue
      ) {
        // @ts-ignore
        state.financeForm.setLessor(state.upgrades[0].lessor);
        if (state.upgrades[0].contractNumber) {
          state.financeForm.setContractNumber(state.upgrades[0].contractNumber);
        }
        state.financeForm.setUpgradeValue(state.upgrades[0].quoteTotalValue);
        state.financeForm.setIsUpgrade(true);
      }
    };

    useEffect(() => {
      getUpgrade();
    }, []);

    useEffect(() => {
      (async (): Promise<any> => {
        const { data: lessors } = await appState.getLessors();
        setLessors(lessors);
      })();
    }, []);

    // useEffect(() => {
    //   if (state.financeForm.upgradeValue) {
    //     calculate();
    //   }
    // }, [state.financeForm.upgradeValue]);

    const fetchUpgradeQuote = async (): Promise<any> => {
      let contractNumber = state.financeForm.contractNumber;
      state.financeForm.setUpgradeValue(null);

      if (contractNumber === null || contractNumber.length === 0) {
        setErrorEnabled(true);
        setValidationMessage('');
        return;
      }

      if (!contractNumber.startsWith('A')) {
        contractNumber = 'A' + contractNumber;
      }

      const contractObj: Contract = await appState.getContractObj(
        contractNumber
      );
      let upgradeQuoteObj: ContractUpgradeQuote;

      if (contractObj?.id) {
        upgradeQuoteObj = await appState.getUpgradeQuote(contractObj?.id);
      } else {
        setErrorEnabled(true);
        setValidationMessage(t('invalidContract', 'Invalid Contract'));
        return;
      }

      if (
        upgradeQuoteObj != null &&
        upgradeQuoteObj.data && //TODO: Errors no longer returned
        upgradeQuoteObj.data.errors &&
        upgradeQuoteObj.data.errors.length !== 0
      ) {
        setErrorEnabled(true);
        setValidationMessage(
          t('upgradeNotAllowed', upgradeQuoteObj.data.errors[0].errorMessage)
        );
      }

      if (upgradeQuoteObj != null && upgradeQuoteObj.values != null) {
        const upgradeQuoteValueObj = upgradeQuoteObj.values[0]; //TODO:values are no longer being returned
        // @ts-ignore
        state.financeForm.setUpgradeValue(upgradeQuoteValueObj?.quoteValue);
      }
    };

    const handleUpgradeClick = async (): Promise<any> => {
      const isUpgrade = !state.financeForm.isUpgrade;
      if (!isUpgrade && state.proposal && state.proposal.id) {
        await state.deleteUpgrades(state.proposal.id);
        state.financeForm.resetUpgrade();
        calculate();
      }
      state.financeForm.setIsUpgrade(isUpgrade);
    };

    const saveUpgrade = async (): Promise<any> => {
      if (state.financeForm.isUpgrade) {
        appState.setIsLoading(true);
        try {
          await state.saveUpgradeToDraft();
          await state.saveCalculationToDraft(state.operation);
        } catch (error) {
          setErrorMessage(error.response.data.errors[0].errorMessage);
          Sentry.captureException(error.response.data.errors[0].errorMessage);
          setIsError(true);
          appState.setIsLoading(false);
          return;
        }
        //if (assetValue || rental) {
        //  calculate();
        //}
        appState.setIsLoading(false);
        // Log the upgrade action and the upgrade amount in GA
        const upgradeDealLabel = `proposalId:${state.proposal?.id}|\
        amount:${state.financeForm.upgradeValue}|\
        contractNumber:${state.financeForm.contractNumber}|\
         lessorId:${state.financeForm.lessor?.id}`;
        ReactGA.event({
          action: 'Upgrade proposal action',
          category: 'Upgrade Proposal',
          label: upgradeDealLabel,
        });
      }
    };

    const createOption = (lessor: any) => ({
      label: lessor.name,
      value: lessor.id,
    });

    return (
      <Container className={classes.root} ref={ref}>
        <div className={classes.lessorContainer}>
          <Select
            options={stateLessors.map(createOption)}
            label={t('lessor', 'Lessor')}
            placeholder={t('selectLessor', 'Select lessor')}
            onChange={(option: any): void => {
              const selectedLessor =
                stateLessors.find((lessor) => lessor.id === option.value) ||
                null;
              state.financeForm.setLessor(selectedLessor);
              setErrorEnabled(false);
              setValidationMessage('');
            }}
            value={
              state.financeForm.lessor
                ? createOption(state.financeForm.lessor)
                : null
            }
            className="lessor"
          />
        </div>
        <Box className={classes.formGroup}>
          <Input
            label={t('contractNumber', 'Contract number')}
            value={state.financeForm.contractNumber || ''}
            onChange={(event: any): void => {
              state.financeForm.setContractNumber(event.target.value);
              setErrorEnabled(false);
              setValidationMessage('');
            }}
            error={errorEnabled}
            helperText={validationMessage}
            className="input"
          />
          <CurrencyInput
            value={state.financeForm.upgradeValue}
            currency={state.calculation.config.currency as Currency}
            label={t('upgradeValue', 'Upgrade value')}
            disabled={
              state.financeForm.lessor !== null &&
              state.financeForm.lessor.id === 91 &&
              state.financeForm.isUpgrade
            }
            onChange={(value: number | null): void => {
              state.financeForm.setUpgradeValue(value);
            }}
            className="input"
          />
        </Box>
        <div className="upgrade-buttons">
          <div
            style={{
              display:
                state.financeForm.lessor !== null &&
                state.financeForm.lessor.id === 91 &&
                state.financeForm.isUpgrade
                  ? 'block'
                  : 'none',
            }}
          >
            <Button
              onClick={(): Promise<void> => fetchUpgradeQuote()}
              disabled={!state.financeForm.contractNumber}
              label={t('fetchUpgradeQuote', 'Fetch Upgrade Quote')}
              style={'search'}
            />
          </div>
          <div>
            <Button
              disabled={
                !state.financeForm.lessor ||
                !state.financeForm.upgradeValue ||
                !(appState.calculator.upgrades[0] === undefined) ||
                (state.financeForm.lessor.id === 91 &&
                  !state.financeForm.contractNumber)
              }
              onClick={(): Promise<void> => saveUpgrade()}
              label={t('saveUpgrade', 'Save upgrade')}
            />
          </div>
          <div>
            <Button
              disabled={!state.upgradeValue}
              onClick={(): Promise<void> => handleUpgradeClick()}
              label={t('deleteUpgrade', 'Delete upgrade')}
            />
          </div>
        </div>
      </Container>
    );
  }
);

export default Upgrades;
