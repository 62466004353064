import { makeStyles, TextField } from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import DropdownIndicator from './ArrowDropDownIcon';
import React, { HTMLAttributes } from 'react';
import ReactSelect from 'react-select';
import { ControlProps } from 'react-select/src/components/Control';
import { Props as SelectProps } from 'react-select/src/Select';
import { OptionsType } from 'react-select/src/types';
import { COLORS, zIndexes } from '../constants/style-constants';
import theme from '../constants/theme';

export interface SelectOption<T = number> {
  label: string;
  value: T;
}

export type SelectOptions<T = number> = OptionsType<SelectOption<T>>;

const useStyles = makeStyles({
  root: {
    '& .select__indicator-separator': {
      display: 'none',
    },
    '& .select__indicators': {
      '& svg': {
        // transform: 'scale(1.5)'
      },
      // background: COLORS.gradient,
      // color: COLORS.white,
      justifyContent: 'center',
      width: theme.spacing(6),
    },
    '& .select__value-container': {
      padding: 0,
    },
  },
});

const useInputStyles = makeStyles({
  input: {
    display: 'flex',
    height: '100%',
    fontSize: 16,
  },
  root: {
    overflow: 'hidden',
    paddingRight: 0,
  },
});

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

const Control = ({
  children,
  innerProps,
  innerRef,
  selectProps: { TextFieldProps },
}: ControlProps<any>) => {
  return (
    <TextField
      InputProps={{
        classes: useInputStyles(),
        inputComponent,
        inputProps: {
          children,
          ref: innerRef,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

interface OwnProps {
  label: string;
  disabled?: boolean;
}

export type Props<T = number> = OwnProps & SelectProps<SelectOption<T>>;

export const Select = <T extends {}>({
  disabled,
  label,
  ...rest
}: Props<T>): React.ReactElement<Props<T>> => (
  <ReactSelect<SelectOption<T>>
    menuPortalTarget={document.body}
    classNamePrefix="select"
    menuShouldBlockScroll={true}
    menuPlacement="auto"
    styles={{
      menu: (base) => ({
        ...base,
        background: COLORS.lightGrey,
        border: '1px solid #778A9A',
      }),
      menuPortal: (base) => ({ ...base, zIndex: zIndexes.selectDropDown }),
    }}
    isDisabled={disabled || false}
    components={{ DropdownIndicator, Control } as any}
    TextFieldProps={{ label, classes: useStyles() }}
    {...rest}
  />
);
