import React, { ReactElement } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Button from './Button';
import SieLoanHeader1 from '../images/SieLoanHeader1.png';
import SieLoanHeader2 from '../images/SieLoanHeader2.png';
import SieLoanTick from '../images/SieLoanTick.png';
import { formatCurrency, formatDate } from '../tools';
import { Proposal } from '../tools/SiemensApi';

interface Props {
  proposal?: Proposal;
}

const circleBullet = (
  doc: any,
  x: number,
  y: number,
  r: number,
  content: string,
  text: string
): void => {
  doc.setDrawColor('#006288');
  doc.setTextColor('#006288');
  doc.setFontSize(9);
  doc.circle(x, y, r, 'S');
  doc.text(x - 0.7, y + 0.7, content);
  doc.setFontSize(10);
  doc.setTextColor('#000000');
  doc.setFontType('normal');
  const splitText = doc.splitTextToSize(text, 170);
  doc.text(x + 5, y + 1, splitText);
};

const print = (proposal: Proposal): void => {
  const symbol = '£';

  // const today = new Date();
  // const dd = String(today.getDate()).padStart(2, '0');
  // const mm = String(today.getMonth() + 1).padStart(2, '0');
  // const yyyy = today.getFullYear();
  // const todayString = dd + '/' + mm + '/' + yyyy;

  const doc = new jsPDF('p', 'mm', 'a4');

  doc.setFont('helvetica');
  doc.setFontType('bold');

  doc.addImage(SieLoanHeader1, 'PNG', 0, 0, 210, 114);

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(
    15,
    145,
    'Do you want to spread the total cost of your order over time?'
  );

  doc.setFontType('bold');
  doc.setTextColor('#000000');
  doc.setFontSize(10);
  doc.text(
    15,
    153,
    'Siemens Financial Services (SFS) can offer a loan to support you.'
  );

  doc.setFontType('normal');
  const splitText1 = doc.splitTextToSize(
    'Take advantage of our SieLoan offering so when you complete your order with Siemens, SFS will make the full payment for your order to Siemens on your behalf, enabling you to break down your costs into affordable monthly repayments. Your will repay your loan to SFS over 12 months.',
    180
  );
  doc.text(15, 160, splitText1);

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(108, 181, 'Quotation');

  doc.setTextColor('#000000');
  doc.setFontSize(10);
  doc.text(108, 190, 'Please find below a quotation for your order');

  doc.autoTable({
    body: [
      ['Date', formatDate(proposal.created!)],
      ['Customer', proposal!.customer!.businessPartner!.name],
      ['Reference', proposal!.id],
      ['Repayment Term', proposal!.currentCalculation!.term],
      [
        'Repayments Per Year',
        proposal!.currentCalculation!.payment!.paymentLines[0].period
          .periodsPerYear,
      ],
      [
        'Repayment Amount',
        `${symbol}${formatCurrency(
          '###,##0.00',
          proposal!.currentCalculation!.payment!.avgPaymentPerPeriod
        )}`,
      ],
      ['Fee', `${symbol}${formatCurrency('###,##0.00', 200)}`],
    ],
    columnStyles: {
      0: {
        cellWidth: 43,
        fillColor: '#ffffff',
        hAlign: 'left',
      },
      1: { cellWidth: 43, fillColor: '#ffffff' },
    },
    margin: { left: 108 },
    startY: 195,
    styles: { fontStyle: 'bold', lineColor: '#D3EAF0', lineWidth: 0.2 },
  });

  doc.rect(108, 252, 87, 12);
  const splitText2 = doc.splitTextToSize(
    'This loan is approved and is valid for 90 days from the date above',
    80
  );
  doc.text(110, 257, splitText2);
  doc.setFillColor('#D3EAF0');
  doc.rect(15, 180, 86, 55, 'F');

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(18, 187, 'Benefits:');

  doc.setTextColor('#000000');
  doc.setFontSize(10);

  doc.addImage(SieLoanTick, 'PNG', 19, 192, 3, 3);
  const splitText3 = doc.splitTextToSize(
    'Spread the cost of your order over 12 equal monthly repayments to SFS',
    60
  );
  doc.text(25, 195, splitText3);

  doc.addImage(SieLoanTick, 'PNG', 19, 203, 3, 3);
  const splitText4 = doc.splitTextToSize(
    'No need to find initial capital to pay for the order yourself',
    60
  );
  doc.text(25, 206, splitText4);

  doc.addImage(SieLoanTick, 'PNG', 19, 215, 3, 3);
  doc.text(25, 218, 'Easy electronic documentation');
  doc.addImage(SieLoanTick, 'PNG', 19, 222, 3, 3);
  doc.text(25, 225, 'No change to your usual ordering process');

  doc.setFontType('bold');
  doc.text(155, 280, 'Siemens Financial Services');

  // Page 2
  doc.addPage();

  doc.addImage(SieLoanHeader2, 'PNG', 0, 15, 210, 61);

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(15, 89, 'How does it work?');

  circleBullet(
    doc,
    17,
    97,
    2.5,
    '1',
    'Confirm you wish to take out a SieLoan when you confirm your order (VAT and delivery fee to be included)'
  );
  circleBullet(
    doc,
    17,
    105,
    2.5,
    '2',
    'Provide Siemens with a Purchase Order for the order in the usual way'
  );
  circleBullet(
    doc,
    17,
    113,
    2.5,
    '3',
    'Confirm and provide e-mail of authorised signatory for electronic loan documentation for signing'
  );
  circleBullet(
    doc,
    17,
    121,
    2.5,
    '4',
    'Upon completion of the order, Siemens will raise an invoice for you in the usual way addressed to your company – you do not need to pay it, it is issued for your own records and tax requirements'
  );
  circleBullet(
    doc,
    17,
    131,
    2.5,
    '5',
    'SFS will make the payment to Siemens on your behalf for the full amount of the invoice'
  );
  circleBullet(
    doc,
    17,
    139,
    2.5,
    '6',
    'SFS will debit the first repayment and fee from your account, as agreed, once the Direct Debit has been set up and collect repayments monthly thereafter'
  );

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(15, 160, 'Considerations:');

  circleBullet(
    doc,
    17,
    168,
    2.5,
    'a',
    'Loan amount must match full value of the invoice including VAT and any delivery charge'
  );
  circleBullet(
    doc,
    17,
    176,
    2.5,
    'b',
    'Only applicable on full deliveries – not on partial deliveries'
  );
  circleBullet(
    doc,
    17,
    184,
    2.5,
    'c',
    'Repayments must still be made in the event of any returns or exchanges'
  );
  circleBullet(
    doc,
    17,
    192,
    2.5,
    'd',
    'Repayments to be made only by direct debit – payment by invoice is not permitted'
  );

  doc.setTextColor('#006288');
  doc.setFontSize(17);
  doc.text(15, 206, 'Why SFS?');

  doc.setTextColor('#000000');
  doc.setFontSize(10);

  const splitText5 = doc.splitTextToSize(
    'Siemens Financial Services (SFS) – the financing arm of Siemens – provides business-to-business financial solutions. A unique combination of financial expertise, risk management and industry know-how enable SFS to create tailored innovative financial solutions. With these, SFS facilitates growth, creates value, enhances competitiveness, and helps customers access new technologies. www.siemens.co.uk/financing-siemens-products',
    180
  );
  doc.text(15, 212, splitText5);

  doc.setFillColor('#0D2448');
  doc.rect(0, 235, 210, 32, 'F');

  doc.setTextColor('#00A2A3');
  doc.setFontSize(17);
  doc.text(15, 242, 'For more information, contact');

  doc.setTextColor('#FFFFFF');
  doc.setFontSize(12);
  doc.text(15, 249, 'Siemens Digital Industries');
  // doc.text(15, 255, 'Tel:');
  doc.text(15, 261, 'Email: Sales.GBI.industry@siemens.com');

  doc.text(110, 249, 'Siemens Financial Services');
  // doc.text(110, 255, 'Tel:');
  doc.text(110, 261, 'Email: financesolutions.gb@siemens.com');

  doc.setTextColor('#000000');
  doc.setFontSize(6.5);
  doc.text(
    10,
    280,
    'If you would like to understand how SFS will use your data if we receive an enquiry or credit application, please visit our Fair Processing Notice at siemens.co.uk/fair-processing-notice'
  );

  doc.save('SFS Quote ' + proposal!.id);
};

const CreatePDF = ({ proposal }: Props): ReactElement => {
  // @ts-ignore
  return <Button onClick={() => print(proposal)} label="Create PDF" />;
};

export default CreatePDF;
