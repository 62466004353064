import { Box, makeStyles, MenuItem, TextField } from '@material-ui/core';
import {
  createSignatory,
  CreateSignatoryParametersModel,
  CreateSignatoryRequestBodyModel,
  deleteSignatory,
  DeleteSignatoryParametersModel,
  JobTitle,
  Proposal,
  Signatory,
  updateSignatory,
  UpdateSignatoryParametersModel,
  UpdateSignatoryRequestBodyModel,
} from '../../../tools/SiemensApi';
import Button from '../../../components/Button';
import React, { ChangeEvent, useState } from 'react';
import { DashboardState, JobTitlesArray } from '../../../model/Dashboard.model';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import { useTranslation } from 'react-i18next';
import theme from '../../../constants/theme';
import Panel from '../../../components/Panel';
import ContactBox from './ContactBox';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 16,
  },
  root: {
    borderRadius: theme.spacing(1),
    margin: '10px 0',
  },
  signatories: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 20,
  },
  signatoryForm: {
    '& .inputs': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& .title': {
      color: '#000028',
      fontSize: 24,
      fontWeight: 700,
    },
    backgroundColor: '#F3F4F6',
    borderRadius: 6,
    marginTop: 20,
    padding: 30,
  },
});

interface SignatoriesForm {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: JobTitle | null;
}

interface Props {
  state: DashboardState;
  activeProposal: Proposal;
  jobTitles: JobTitlesArray;
  setErrorMessage: (message) => void;
  setActiveProposal: (proposal) => void;
  setIfMatch: (ifMatch) => void;
  ifMatch: string;
  proposalId: number;
  fetchProposalAndDocuments: (hideLoading) => void;
}

const Signatories = ({
  state,
  activeProposal,
  jobTitles,
  setErrorMessage,
  ifMatch,
  proposalId,
  fetchProposalAndDocuments,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const [signatoryValues, setSignatoryValues] = useState<SignatoriesForm>({
    email: '',
    firstName: '',
    id: 0,
    jobTitle: null,
    lastName: '',
  });
  const [editingSignatory, setEditingSignatory] = useState<boolean>(false);
  const [addingSignatory, setAddingSignatory] = useState<boolean>(false);
  const [signatoryFormLabel, setSignatoryFormLabel] = useState<string>('Add');

  const setValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSignatoryValues({
      ...signatoryValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditSignatoryClick = (signatory: Signatory) => {
    setSignatoryFormLabel('Edit');
    // @ts-ignore
    setSignatoryValues({
      email: signatory.email as string,
      firstName: signatory.firstName,
      id: signatory.id as number,
      jobTitle: signatory.jobTitle,
      lastName: signatory.lastName,
    });

    setEditingSignatory(true);
  };

  const handleAddSignatoryClick = () => {
    setSignatoryFormLabel('Add');
    setAddingSignatory(!addingSignatory);
  };

  const removeSignatory = async (signatory: Signatory) => {
    try {
      await deleteSignatory(
        DeleteSignatoryParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
          signatoryId: signatory.id || 0,
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    fetchProposalAndDocuments(true);
  };

  const validateSignatories = () => {
    let isValid = false;
    const toValidate = signatoryValues;

    if (
      toValidate.firstName!.length > 0 &&
      toValidate.firstName!.length <= 50 &&
      toValidate.lastName!.length > 0 &&
      toValidate.lastName!.length <= 50 &&
      toValidate.email!.length > 0 &&
      toValidate.email!.length <= 100 &&
      validateEmail(toValidate.email!)
    ) {
      isValid = true;
    }

    return isValid;
  };

  const editSignatory = async () => {
    try {
      await updateSignatory(
        UpdateSignatoryParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
          signatoryId: signatoryValues.id,
        }),
        // @ts-ignore
        UpdateSignatoryRequestBodyModel.create({
          email: signatoryValues.email,
          firstName: signatoryValues.firstName,
          jobTitle: signatoryValues.jobTitle!,
          lastName: signatoryValues.lastName,
          //phone: signatoryValues.phone,
          type: 'CUSTOMER',
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    resetSignatoryForm();
    setEditingSignatory(false);
    fetchProposalAndDocuments(true);
  };

  const addSignatory = async () => {
    setSignatoryFormLabel('Add');
    try {
      await createSignatory(
        CreateSignatoryParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),
        // @ts-ignore
        CreateSignatoryRequestBodyModel.create({
          bpId: activeProposal.customer.businessPartner.id.toString(),
          email: signatoryValues.email,
          firstName: signatoryValues.firstName,
          jobTitle: signatoryValues.jobTitle!,
          lastName: signatoryValues.lastName,
          type: 'CUSTOMER',
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    resetSignatoryForm();
    setAddingSignatory(false);
    fetchProposalAndDocuments(true);
  };

  const resetSignatoryForm = () => {
    setSignatoryValues({
      email: '',
      firstName: '',
      id: 0,
      jobTitle: null,
      lastName: '',
      //phone: '',
    });
  };

  const setJobRole = (value: string) => {
    const jobTitle =
      (jobTitles && jobTitles.find((item) => '' + item.id === value)) || null;
    setSignatoryValues({ ...signatoryValues, jobTitle });
  };

  const cancelEditSignatory = () => {
    setEditingSignatory(false);
    resetSignatoryForm();
  };

  const validateEmail = (emailValue: string) => {
    if (emailValue.length > 0) {
      return emailValue.match(
        /^[\w!#$%&’*+\/=?`{|}~^-]+(?:\.[\w!#$%&’*+\/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/
      );
    } else {
      return true;
    }
  };

  return (
    <Panel className={classes.root} title={t('signatories', 'Signatories')}>
      {activeProposal.signatories!.length > 0 && (
        <div className={classes.signatories}>
          {activeProposal
            .signatories!.slice()
            .filter((item) => item.type === 'CUSTOMER')
            .map((item, index) => {
              return (
                <ContactBox
                  key={index}
                  contact={item}
                  contactType="signatory"
                  handleEditSignatoryClick={handleEditSignatoryClick}
                  removeSignatory={removeSignatory}
                />
              );
            })}
        </div>
      )}

      <Button
        onClick={handleAddSignatoryClick}
        label={t('addSignatory', 'Add signatory')}
        style="complete"
      />

      {(addingSignatory || editingSignatory) && (
        <>
          <div className={classes.signatoryForm}>
            <div className="title">{signatoryFormLabel} signatory</div>
            <div className="inputs">
              <TextField
                label={t('firstName', 'First Name')}
                id="firstName"
                value={signatoryValues.firstName}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="firstName"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(event);
                }}
                disabled={editingSignatory}
              />
              <TextField
                label={t('lastName', 'Last Name')}
                id="lastName"
                value={signatoryValues.lastName}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="lastName"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(event);
                }}
                disabled={editingSignatory}
              />
            </div>
            <div className="inputs">
              {jobTitles && jobTitles!.length > 0 && (
                <TextField
                  onChange={(event: ChangeEvent<any>) => {
                    setJobRole(event.target.value);
                  }}
                  select={true}
                  label={t('role', 'Role')}
                  value={
                    signatoryValues.jobTitle
                      ? '' + signatoryValues.jobTitle.id
                      : ''
                  }
                >
                  {jobTitles!.map((item, index) => (
                    <MenuItem key={index} value={'' + item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                label={t('email', 'Email')}
                id="email"
                error={!validateEmail(signatoryValues.email)}
                value={signatoryValues.email}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="email"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(event);
                }}
              />
            </div>
            <div className="inputs">
              {/*<TextField*/}
              {/*  label={t('phone', 'Phone')}*/}
              {/*  id="contact-telphone"*/}
              {/*  error={!validatePhone(signatoryValues.phone)}*/}
              {/*  value={signatoryValues.phone}*/}
              {/*  classes={{*/}
              {/*    root: 'customer-details__input-wrapper',*/}
              {/*  }}*/}
              {/*  name="phone"*/}
              {/*  onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
              {/*    setValue(event);*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<TextField*/}
              {/*  label={t('email', 'Email')}*/}
              {/*  id="email"*/}
              {/*  error={!validateEmail(signatoryValues.email)}*/}
              {/*  value={signatoryValues.email}*/}
              {/*  classes={{*/}
              {/*    root: 'customer-details__input-wrapper',*/}
              {/*  }}*/}
              {/*  name="email"*/}
              {/*  onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
              {/*    setValue(event);*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={
                  editingSignatory
                    ? () => editSignatory()
                    : () => addSignatory()
                }
                disabled={!validateSignatories()}
                label={t('save', 'Save')}
              />
              <Button
                onClick={
                  editingSignatory
                    ? () => cancelEditSignatory()
                    : () => setAddingSignatory(false)
                }
                label={t('cancel', 'Cancel')}
              />
            </div>
          </div>
        </>
      )}
    </Panel>
  );
};

export default Signatories;
