import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ErrorDialog from '../../components/ErrorDialog';
import { CalculatorState, StepType } from '../../model/Calculator.model';
import Asset from './Asset';
import Customer from './Customer';
import Finance from './Finance';
import Referred from './Referred';
import Reset from './Reset';
import Success from './Success';
import Expire from './Expire';
import Summary from './Summary';
import { DashboardState } from '../../model/Dashboard.model';
import { useTranslation } from 'react-i18next';
import theme from '../../constants/theme';
import { AppState } from '../../state';
import { getParent } from 'mobx-state-tree';
import marketProductsConfig from '../../tools/constants-config';

const useStyles = makeStyles({
  heading: {
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: '-0.015em',
    marginBottom: 16,
    maxWidth: 780,
    width: '90%',
    [theme.breakpoints.up('sm') as string]: {
      width: 780,
    },
  },
  main: {
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 'auto',
    paddingBottom: 20,
    paddingTop: 20,
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 950,
      width: '80%',
    },
  },
});

interface Props {
  state: CalculatorState;
  dashboardState: DashboardState;
  openProposal: (proposalId?: number) => void;
}

export default observer(({ openProposal, state }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const appState = getParent<AppState>(state);

  const classes = useStyles();
  const customerRef = useRef<HTMLDivElement>(null);
  const assetRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);

  const loanMarketProductId = marketProductsConfig.validValues[1].id;

  const go = (step: StepType) => {
    if (step) {
      navigate(`/app/calculator/${step}`);
    } else {
      navigate(`/app/calculator`);
    }
    getParent<AppState>(state).setShowModal(false);
  };

  const next = (step: StepType) => {
    go(step);
  };

  const reset = async () => {
    go('reset');
  };

  const handleFinanceFormNext = async (): Promise<any> => {
    appState.setIsLoading(true);

    await state.saveCalculationToDraft(state.operation);

    appState.setIsLoading(false);
    if (customerRef.current !== null) {
      customerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCustomerFormNext = async () => {
    appState.setIsLoading(true);
    await state.saveCustomerToDraft();
    appState.setIsLoading(false);
    if (assetRef.current !== null) {
      assetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAssetFormNext = async () => {
    appState.setIsLoading(true);
    if (state.marketProduct === loanMarketProductId) {
      //MARKET PRODUCTS HARDCODED
      await state.saveLoanToDraft();
    } else {
      await state.saveAssetToDraft();
    }
    appState.setIsLoading(false);
    if (summaryRef.current !== null) {
      summaryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ErrorDialog
        open={isError}
        onOk={() => setIsError(false)}
        handleClose={() => setIsError(false)}
        okCaption={t('ok', 'OK')}
        state={appState}
      >
        <p>{errorMessage}</p>
        <p>
          For help, please email{' '}
          <a href="mailto:mysfs.uk@siemens.com">mysfs.uk@siemens.com</a>
        </p>
      </ErrorDialog>

      <div className={classes.main}>
        <Routes>
          <Route
            path="success"
            element={<Success reset={reset} state={state} />}
          />

          <Route
            path="referred"
            element={<Referred reset={reset} state={state} />}
          />

          <Route path="reset">
            <Route
              path=":proposalId"
              element={
                <Reset go={(step: StepType) => go(step)} state={state} />
              }
            />
            <Route
              index
              element={
                <Reset go={(step: StepType) => go(step)} state={state} />
              }
            />
          </Route>

          <Route
            path="/expire/:proposalId"
            element={
              <Expire openProposal={() => openProposal()} state={state} />
            } //
          />

          <Route
            index
            element={
              <>
                {!state.calculation && (
                  <div>
                    There is a problem with the configuration for this office.
                    Please contact your account manager
                  </div>
                )}
                {state.calculation && (
                  <Finance
                    next={handleFinanceFormNext}
                    state={state}
                    setErrorMessage={setErrorMessage}
                    setIsError={setIsError}
                  />
                )}
                {state.proposal && state.amountFinanced && (
                  <Customer
                    next={handleCustomerFormNext}
                    state={state.customerForm}
                    reference={customerRef}
                  />
                )}
                {state.proposal &&
                  state.customerForm.selectedBusinessPartnerRef &&
                  state.customerForm.nameQuery && (
                    <>
                      <Asset
                        next={handleAssetFormNext}
                        state={state}
                        reference={assetRef}
                      />
                    </>
                  )}
                {state.amountFinanced && (
                  <Summary state={state} next={next} reference={summaryRef} />
                )}
              </>
            }
          />
        </Routes>
      </div>
    </>
  );
});
