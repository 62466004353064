import { useTranslation } from 'react-i18next';
import { DashboardState } from '../../../model/Dashboard.model';
import {
  amendProposal,
  AmendProposalParametersModel,
  AmendProposalRequestBodyModel,
  createCustomerInvoiceAddress,
  CreateCustomerInvoiceAddressParametersModel,
  CreateCustomerInvoiceAddressRequestBodyModel,
  getProposal,
  GetProposalParametersModel,
  Proposal,
  requestCreditDecision,
  RequestCreditDecisionParametersModel,
  RequestCreditDecisionRequestBodyModel,
} from '../../../tools/SiemensApi';
import { Box, IconButton, makeStyles, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../../../components/Button';
import React, { ChangeEvent, useState } from 'react';
import Panel from '../../../components/Panel';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';

const useStyles = makeStyles({
  address: {
    '& .name': {
      color: '#000028',
      fontSize: 18,
      fontWeight: 700,
    },
    border: '1px solid #D1D5DB',
    borderRadius: 5,
    color: '#374151',
    flex: 1,
    flexBasis: '50%',
    fontSize: 14,
    maxWidth: 300,
    padding: 20,
  },
  addressForm: {
    '& .inputs': {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
    '& .title': {
      color: '#000028',
      fontSize: 24,
      fontWeight: 700,
    },
    backgroundColor: '#F3F4F6',
    borderRadius: 6,
    marginTop: 20,
    padding: 30,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 16,
  },
  root: {
    padding: 0,
  },
});

interface Props {
  state: DashboardState;
  activeProposal: Proposal;
  setErrorMessage: (message) => void;
  setActiveProposal: (proposal) => void;
  setIfMatch: (ifMatch) => void;
  ifMatch: string;
  proposalId: number;
}

interface InvoiceAddressForm {
  id: number | null | undefined;
  address1: string | null | undefined;
  address2: string | null | undefined;
  city: string | null | undefined;
  postalCode: string | null | undefined;
  phoneNumber: string | null | undefined;
}

const InvoiceAddress = ({
  state,
  activeProposal,
  setErrorMessage,
  setActiveProposal,
  setIfMatch,
  ifMatch,
  proposalId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({});

  const [invoiceAddressValues, setInvoiceAddressValues] = useState<
    InvoiceAddressForm
  >({
    address1: '',
    address2: '',
    city: '',
    id: 0,
    phoneNumber: '',
    postalCode: '',
  });
  const [addingInvoiceAddress, setAddingInvoiceAddress] = useState<boolean>(
    false
  );
  const [editingInvoiceAddress, setEditingInvoiceAddress] = useState<boolean>(
    false
  );

  const handleEditInvoiceAddressClick = () => {
    const invoiceObj = activeProposal!.customer!.invoiceAddress!;
    setInvoiceAddressValues({
      address1: invoiceObj.address1,
      address2: invoiceObj.address2 ? invoiceObj.address2 : '',
      city: invoiceObj.city ? invoiceObj.city : '',
      id: invoiceObj.id,
      phoneNumber: invoiceObj.phoneNumber ? invoiceObj.phoneNumber : '',
      postalCode: invoiceObj.postalCode ? invoiceObj.postalCode : '',
    });
    setEditingInvoiceAddress(true);
  };

  const setInvoiceAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setInvoiceAddressValues({
      ...invoiceAddressValues,
      [event.target.name]: event.target.value,
    });
  };

  const validatePhone = (phoneNumber: string) => {
    if (phoneNumber.length > 0) {
      return phoneNumber.match(/^(?:0|\+?44)(?:\d\s?){9,10}$/);
    } else {
      return true;
    }
  };

  const resetInvoiceAddressForm = () => {
    setInvoiceAddressValues({
      address1: '',
      address2: '',
      city: '',
      id: 0,
      phoneNumber: '',
      postalCode: '',
    });
  };

  const validateInvoiceAddress = () => {
    let isValid = false;
    const toValidate = invoiceAddressValues;

    if (
      toValidate.phoneNumber &&
      toValidate.phoneNumber.length > 0 &&
      toValidate.address1 &&
      toValidate.address1.length > 0 &&
      toValidate.postalCode &&
      toValidate.postalCode.length > 0 &&
      toValidate.city &&
      toValidate.city.length > 0
    ) {
      isValid = true;
    }

    return isValid;
  };

  const cancelEditInvoiceAddress = () => {
    setEditingInvoiceAddress(false);
    resetInvoiceAddressForm();
  };

  const addOrEditInvoiceAddress = async () => {
    let creditDecisionResponse;

    try {
      // Step 1:  Amend the existing proposal from Accepted state to Prospect state for editing the invoice details.
      await amendProposal(
        AmendProposalParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),
        AmendProposalRequestBodyModel
      );

      // Step2: Get the updated proposal details

      const getProposalResponse = await getProposal(
        GetProposalParametersModel.create({
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        })
      );
      // Step 3: Set the new IF match details and proposal data

      setIfMatch(getProposalResponse.headers.etag);
      setActiveProposal(getProposalResponse.data);

      // Step4: Update the customer invoice details
      const invoiceChangeResponse = await createCustomerInvoiceAddress(
        CreateCustomerInvoiceAddressParametersModel.create({
          ifMatch: getProposalResponse!.headers.etag,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),

        // @ts-ignore
        CreateCustomerInvoiceAddressRequestBodyModel.create({
          address1: invoiceAddressValues.address1,
          address2: invoiceAddressValues.address2,
          city: invoiceAddressValues.city,
          phoneNumber: invoiceAddressValues.phoneNumber,
          postalCode: invoiceAddressValues.postalCode,
        })
      );

      setIfMatch(invoiceChangeResponse.headers.etag);
      setActiveProposal(invoiceChangeResponse.data);

      const latestIfMatch = invoiceChangeResponse!.headers!.etag!;

      // Step 5: Submit the proposal details for credit decision
      creditDecisionResponse = await requestCreditDecision(
        RequestCreditDecisionParametersModel.create({
          ifMatch: latestIfMatch ? latestIfMatch : '',
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),
        RequestCreditDecisionRequestBodyModel.create({
          commentToRiskAnalyst: '',
          siemensMayContactCustomer: false,
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    const proposal = creditDecisionResponse.data;
    setActiveProposal(proposal);
    setIfMatch(creditDecisionResponse.headers.etag);
    // @ts-ignore
    setInvoiceAddressValues(proposal.customer!.invoiceAddress!);
    state.setProposalsInfo(proposalId, creditDecisionResponse.data);
    setEditingInvoiceAddress(false);
    setAddingInvoiceAddress(false);
    resetInvoiceAddressForm();
  };

  return (
    <Panel
      className={classes.root}
      title={t('invoiceAddress', 'Invoice Address')}
    >
      {activeProposal.customer!.invoiceAddress && (
        <>
          <div className={classes.address}>
            <div className="name">
              {activeProposal.customer!.businessPartner!.name
                ? `${activeProposal.customer!.businessPartner!.name}`
                : ''}
            </div>
            <div>
              {activeProposal.customer!.invoiceAddress!.address1
                ? `${activeProposal.customer!.invoiceAddress!.address1}`
                : ''}
            </div>
            <div>
              {activeProposal.customer!.invoiceAddress!.address2
                ? `${activeProposal.customer!.invoiceAddress!.address2}`
                : ''}
            </div>
            <div>
              {activeProposal.customer!.invoiceAddress!.city
                ? `${activeProposal.customer!.invoiceAddress!.city}`
                : ''}
            </div>
            <div>
              {activeProposal.customer!.invoiceAddress!.postalCode
                ? `${activeProposal.customer!.invoiceAddress!.postalCode}`
                : ''}
            </div>
            <div>
              {activeProposal.customer!.invoiceAddress!.phoneNumber
                ? `${activeProposal.customer!.invoiceAddress!.phoneNumber}`
                : ''}
            </div>
            <IconButton
              color="primary"
              onClick={() => handleEditInvoiceAddressClick()}
            >
              <EditIcon />
            </IconButton>
          </div>
        </>
      )}
      {!activeProposal.customer!.invoiceAddress && (
        <Button
          onClick={() => setAddingInvoiceAddress(!addingInvoiceAddress)}
          label={t('addInvoiceAddress', 'Add Invoice Address')}
        />
      )}
      {(addingInvoiceAddress || editingInvoiceAddress) && (
        <>
          <div className={classes.addressForm}>
            <div className="inputs">
              <TextField
                label={t('address1', 'Address1')}
                id="address1"
                value={invoiceAddressValues.address1}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="address1"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceAddress(event);
                }}
              />
              <TextField
                label={t('address2', 'Address2')}
                id="address2"
                value={invoiceAddressValues.address2}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="address2"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceAddress(event);
                }}
              />
            </div>
            <div className="inputs">
              <TextField
                label={t('city', 'City')}
                id="city"
                value={invoiceAddressValues.city}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="city"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceAddress(event);
                }}
              />
              <TextField
                label={t('postalCode', 'Postal Code')}
                id="postalCode"
                value={invoiceAddressValues.postalCode}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="postalCode"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceAddress(event);
                }}
              />
            </div>
            <div className="inputs">
              <TextField
                label={t('phone', 'Phone')}
                id="phoneNumber"
                value={invoiceAddressValues.phoneNumber}
                error={!validatePhone(invoiceAddressValues.phoneNumber!)}
                classes={{
                  root: 'customer-details__input-wrapper',
                }}
                name="phoneNumber"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceAddress(event);
                }}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={() => addOrEditInvoiceAddress()}
                disabled={!validateInvoiceAddress()}
                label={t('save', 'Save')}
              />
              {editingInvoiceAddress && (
                <Button
                  onClick={() => cancelEditInvoiceAddress()}
                  label={t('cancel', 'Cancel')}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Panel>
  );
};

export default InvoiceAddress;
