import { makeStyles } from '@material-ui/core';
import baseicon from 'media/baseicon.png';
import React, { Fragment } from 'react';
import { COLORS } from '../../constants/style-constants';
import { authorize } from '../../tools/Auth';
import Reconnecting from './Reconnecting';

const useStyles = makeStyles({
  root: {
    background: `url(${baseicon}) no-repeat`,
    backgroundColor: COLORS.white,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

interface Props {
  onLine: boolean;
}

export default ({ onLine }: Props) => {
  if (onLine) {
    authorize();
  }
  return (
    <Fragment>
      <div className={useStyles().root} onClick={() => authorize} />
      <Reconnecting open={!onLine} />
    </Fragment>
  );
};
