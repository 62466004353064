import React, { useState } from 'react';
import Panel from '../../../components/Panel';
import { KonicaState } from '../../../model/Konica.model';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/Button';

interface Props {
  state: KonicaState;
  setSnackBarOpen: (value) => void;
  setSnackbar: (value) => void;
  resetLocal: () => void;
}

const ProposalSearch = ({
  state,
  setSnackBarOpen,
  setSnackbar,
  resetLocal,
}: Props) => {
  const [proposalNumber, setProposalNumber] = useState(0);
  const [onBaseNumber, setOnBaseNumber] = useState(0);

  const searchProposals = async () => {
    if (!proposalNumber && !onBaseNumber) {
      return false;
    }
    resetLocal();
    state.reset();
    await state.getProposal(proposalNumber, onBaseNumber).then((error) => {
      if (error) {
        setSnackbar({ message: error ? error : 'Error', severity: 'error' });
        setSnackBarOpen(true);
      }
    });
  };

  const reset = async () => {
    await state.reset();
    await resetLocal();
  };

  const handleProposalNumberChange = (event) => {
    if (onBaseNumber) {
      setOnBaseNumber(null);
    }
    setProposalNumber(event.target.value);
  };

  const handleOnBaseNumberChange = (event) => {
    if (proposalNumber) {
      setProposalNumber(null);
    }
    setOnBaseNumber(event.target.value);
  };

  return (
    <Panel title={'Proposal search'}>
      <div className="row">
        <TextField
          type="number"
          label="Proposal number"
          value={proposalNumber ? proposalNumber : ''}
          onChange={handleProposalNumberChange}
        />
        <TextField
          type="number"
          label="OnBase number"
          value={onBaseNumber ? onBaseNumber : ''}
          onChange={handleOnBaseNumberChange}
        />
      </div>
      <div className="row" style={{ justifyContent: 'space-between' }}>
        <Button onClick={() => searchProposals()} label="Search" />
        <Button onClick={() => reset()} label="Reset" />
      </div>
    </Panel>
  );
};

export default ProposalSearch;
