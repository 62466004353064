import { createTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { BREAKPOINTS, COLORS } from './style-constants';
import spinner from 'media/spinner.gif';

const palette: PaletteOptions = {
  primary: {
    contrastText: COLORS.white,
    main: COLORS.deepBlue,
  },
  secondary: {
    contrastText: COLORS.white,
    main: COLORS.aquaBlue,
  },
  text: {
    secondary: COLORS.black,
  },
};

export const font =
  '"Siemens Sans", "Helvetica Neue", "HelveticaNeue", Helvetica, Arial, sans-serif';

export const baseTheme = createTheme({
  breakpoints: {
    values: {
      lg: 65536,
      md: 1180,
      sm: BREAKPOINTS.tablet,
      xl: 65536,
      xs: 256,
    },
  },
  palette,
  props: {
    MuiDialog: {
      scroll: 'body',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: false,
    },
  },
  typography: {
    body1: {
      color: COLORS.black,
      fontSize: '1rem',
    },
    fontFamily: font,
    fontSize: 16,
  },
});

export const theme = createTheme({
  ...baseTheme,
  overrides: {
    MuiButton: {
      containedPrimary: {
        '&:disabled': {
          background: COLORS.gradient,
          color: baseTheme.palette.primary.contrastText,
        },
        background: COLORS.gradient,
      },
      containedSecondary: {
        '&:disabled': {
          background: COLORS.gradient,
          color: baseTheme.palette.secondary.contrastText,
        },
      },
      label: {
        fontSize: '1.5rem',
        whiteSpace: 'nowrap',
      },
      outlined: {
        // boxShadow: 'none',
      },
      outlinedPrimary: {
        '&:disabled': {
          background: COLORS.gradient,
          color: baseTheme.palette.primary.contrastText,
        },
      },
      outlinedSecondary: {
        '&:disabled': {
          background: COLORS.gradient,
          color: baseTheme.palette.secondary.contrastText,
        },
      },
      root: {
        '&.thin': {
          height: 50,
          marginLeft: 0,
          marginRight: 0,
          maxWidth: 400,
          padding: 0,
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:disabled': {
          opacity: 0.5,
        },
        borderRadius: 10,
        boxShadow: 'none',
        display: 'block',
        height: 50,
        margin: '16px auto',
        maxWidth: '100%',
        minWidth: baseTheme.spacing(36),
        textTransform: 'unset' as any,
        width: 'auto',
        [`&.loading, &.loading[class*='disabled'], &.loading:disabled`]: {
          '&>.MuiButton-label': {
            opacity: 0,
          },
          backgroundImage: `url(${spinner}) !important`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 15,
        marginBottom: 10,
        width: '100%',
      },
    },

    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiCardHeader: {
      content: {
        width: '100%',
      },
      root: {
        background: COLORS.gradient,
        padding: baseTheme.spacing(1, 1, 1, 1),
        textAlign: 'center',
      },
      title: {
        color: COLORS.white,
        fontSize: '1.25rem',
        letterSpacing: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        [baseTheme.breakpoints.up('sm') as string]: {
          fontSize: '1.5rem',
        },
      },
    },
    MuiCheckbox: {
      root: {
        [`&.isLoading, &.isLoading[class*='disabled'], &.isLoading:disabled`]: {
          backgroundImage: `url(${spinner}) !important`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 6,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogContentText: {
      root: {
        padding: '0 12px',
      },
    },
    MuiDialogTitle: {
      root: {
        //background: COLORS.gradient,
        color: COLORS.black,
        letterSpacing: 2,
        textTransform: 'uppercase',
        fontSize: 24,
        fontWeight: 700,
        '& h2': {
          fontSize: 24,
          fontWeight: 700,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: COLORS.label,
        fontSize: '1rem',
        fontWeight: 'bold',
        [baseTheme.breakpoints.up('sm') as string]: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&::before': {
          border: '10px solid transparent',
          borderBottomColor: COLORS.tooltip,
          content: "''",
          position: 'absolute',
          top: -20,
        },
        alignItems: 'center',
        background: COLORS.tooltip,
        borderRadius: 6,
        color: COLORS.white,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        letterSpacing: 0.8,
        marginTop: 32,
        maxWidth: 'unset',
        padding: 8,
        paddingLeft: 16,
        position: 'relative',
        textAlign: 'center',
      },
    },
    MuiInput: {
      input: {
        '&[readonly]': {
          color: COLORS.slate,
          cursor: 'inherit',
        },
        flexGrow: 1,
        fontSize: '1em',
        margin: 0,
        padding: 0,
        width: 0,
      },
      root: {
        '&.readOnly': {
          borderColor: COLORS.lightGrey,
          cursor: 'inherit',
        },
        '&.readOnly:focus-within': {
          borderColor: COLORS.lightGrey,
          boxShadow: `none`,
        },
        '&:focus-within': {
          borderColor: COLORS.green,
          boxShadow: `0 0 0 1px ${COLORS.green}`,
          outline: 0,
        },
        background: '#fff',
        border: '1px solid #D1D5DB',
        borderRadius: 6,
        display: 'flex',
        height: 40,
        padding: '0 20px 0 15px',
      },
    },
    MuiInputLabel: {
      formControl: {
        '&$focused': {
          color: COLORS.label,
        },
        color: '#000028',
        display: 'block',
        fontSize: 15,
        fontWeight: 'bold',
        position: 'unset',
        transform: 'unset',
        // [baseTheme.breakpoints.up('sm') as string]: {
        //   fontSize: '1.25rem',
        // },
      },
    },
    MuiMenuItem: {
      root: {
        color: COLORS.green,
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: 1,
      },
    },
    // @ts-ignore
    MuiSlider: {
      mark: {
        display: 'none',
      },
      markLabel: {
        fontWeight: 'bold',
        marginTop: baseTheme.spacing(3),
      },
      rail: {
        backgroundColor: COLORS.slate,
        borderRadius: 5,
        height: 10,
        left: 0,
      },
      root: {
        display: 'block',
        height: 56,
        margin: baseTheme.spacing(7, 4, 0),
        width: 'unset',
      },
      thumb: {
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.slate}`,
        height: 50,
        transform: 'translate(-20px,-16px)',
        width: 50,

        '&, &:hover, &$activated': {
          boxShadow: COLORS.shadow,
        },
      },
      track: {
        background: COLORS.gradient,
        borderRadius: 5,
        height: 10,
      },
    },
    MuiTextField: {
      root: {
        '& .prefix': {
          marginRight: baseTheme.spacing(1),
        },
        '&.readOnly .prefix': {
          color: COLORS.slate,
        },
        display: 'block',
        fontSize: '1.25rem',
        margin: baseTheme.spacing(2, 0, 1),
        width: '100%',
      },
    },
    MuiTouchRipple: {
      root: {
        color: COLORS.black,
      },
    },
    MuiTypography: {
      body1: {
        padding: baseTheme.spacing(2, 4),
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
  },
});

export default theme;
