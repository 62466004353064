interface CurrencySymbol {
  symbol: string;
}

const currencies: { [key: string]: CurrencySymbol } = {
  DKK: { symbol: 'kr' },
  EUR: {
    symbol: '€',
  },
  GBP: {
    symbol: '£',
  },
  NOK: { symbol: 'kr' },
  SEK: { symbol: 'kr' },
};

export default currencies;
