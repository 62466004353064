import React from 'react';

export function UKFlag() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9994 63.9987C49.6715 63.9987 63.9987 49.6703 63.9987 31.9994C63.9987 14.3285 49.6728 0 31.9994 0C14.326 0 0 14.3285 0 31.9994C0 49.6703 14.3285 63.9987 31.9994 63.9987Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.4835 6.61487C48.1685 4.06725 44.3859 2.19484 40.35 1.10373V17.7497L51.4835 6.61487ZM1.10449 23.6633C2.19049 19.6216 4.06331 15.8339 6.61563 12.5173L17.7504 23.6633H1.10449ZM57.3871 12.5173C59.9373 15.8353 61.8099 19.6225 62.8983 23.6633H46.2499L57.3871 12.5173ZM1.10449 40.3555H17.7504L6.61688 51.4802C4.06954 48.1682 2.19671 44.3885 1.10449 40.3555ZM12.5205 57.3864C15.8358 59.9335 19.6183 61.8059 23.6541 62.8975V46.2579L12.5205 57.3864ZM12.5205 6.61487C15.8346 4.0659 19.6175 2.19334 23.6541 1.10373V17.7497L12.5205 6.61487ZM40.3475 62.8975C44.384 61.8079 48.1669 59.9354 51.481 57.3864L40.3475 46.2616V62.8975ZM57.3834 51.484L46.2499 40.3592H62.8945C61.8044 44.3929 59.9318 48.1728 57.3834 51.484Z"
        fill="#0052B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7294 27.8232H36.1749V0.267491C33.4034 -0.0874954 30.5979 -0.0874954 27.8264 0.267491V27.822H0.271906C-0.0893331 30.5956 -0.0893331 33.4044 0.271906 36.178H27.8264V63.7325C30.5979 64.0875 33.4034 64.0875 36.1749 63.7325V36.178H63.7294C64.0907 33.4048 64.0907 30.5964 63.7294 27.8232ZM40.3549 40.3555L54.6246 54.6302L56.5058 52.5677L44.281 40.3555H40.3499H40.3549ZM9.37548 54.6302L23.6539 40.3555V44.2841L11.4292 56.5151L9.37548 54.6302ZM23.6527 23.6445L9.37548 9.37481L7.50051 11.4373L19.7253 23.6683H23.6614L23.6527 23.6445ZM54.6246 9.37481L40.3499 23.6445V19.7159L52.5746 7.48485L54.6246 9.37481Z"
        fill="#D80027"
      />
    </svg>
  );
}

export function FinlandFlag() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 64C49.6725 64 64 49.6712 64 32C64 14.3288 49.6725 0 32 0C14.3275 0 0 14.3288 0 32C0 49.6712 14.3263 64 32 64Z"
        fill="#F0F0F0"
      />
      <path
        d="M63.7288 27.8225H25.045V0.766266C22.128 1.40689 19.317 2.4591 16.6963 3.89127V27.8238H0.270001C-0.0900003 30.5971 -0.0900003 33.4054 0.270001 36.1788H16.6963V60.1075C19.32 61.533 22.1301 62.5848 25.045 63.2325V36.1788H63.7288C64.09 33.4051 64.09 30.5962 63.7288 27.8225Z"
        fill="#0052B4"
      />
    </svg>
  );
}

export function NorwayFlag() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 64C49.6725 64 64 49.6712 64 32C64 14.3288 49.6725 0 32 0C14.3275 0 0 14.3288 0 32C0 49.6712 14.3263 64 32 64Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8978 23.6437C59.2266 10.0262 46.7853 0 32.0003 0C31.0728 0 30.1428 0.035 29.2178 0.125V23.6437H62.8978ZM12.5228 57.3862C6.95516 53.1119 2.94303 47.1295 1.10156 40.3563V40.3375H12.5203L12.5228 57.3862ZM32.0003 64L29.2178 63.875V40.3563H62.8978C59.2241 53.9725 46.7841 64 32.0003 64ZM12.5203 6.61375C6.9488 10.884 2.9364 16.8681 1.10156 23.6437H12.5203V6.61375Z"
        fill="#D80027"
      />
      <path
        d="M63.7287 27.8225H25.045V0.764984C22.128 1.40561 19.317 2.45781 16.6963 3.88998V27.8225H0.270001C-0.0900003 30.5959 -0.0900003 33.4041 0.270001 36.1775H16.6963V60.1062C19.32 61.5317 22.1301 62.5835 25.045 63.2312V36.1775H63.7287C64.09 33.4042 64.09 30.5958 63.7287 27.8225Z"
        fill="#0052B4"
      />
    </svg>
  );
}

export function SwedenFlag() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#FFDA44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7275 27.8258H25.043V0.760889C27.283 0.266667 29.6101 0 31.9995 0C48.259 0 61.683 12.1244 63.7275 27.8258ZM16.6964 3.91111V27.8258H0.271484C1.59948 17.4791 7.9106 8.68444 16.6964 3.91111ZM16.6964 36.1742H0.271484C1.61904 46.5209 7.90883 55.3156 16.6981 60.1102V36.1742H16.6964ZM25.043 63.2391V36.1742H25.0448H63.7292C61.683 51.8756 48.2572 64 31.9995 64C29.6119 64 27.283 63.7369 25.043 63.2391Z"
        fill="#0052B4"
      />
    </svg>
  );
}
