import React from 'react';
import { makeStyles } from '@material-ui/core';
import { COLORS } from '../constants/style-constants';
import theme from '../constants/theme';

const useStyles = makeStyles({
  root: {
    ' & .closebtn': {
      color: 'white',
      cursor: 'pointer',
      fontSize: '28px',
      position: 'absolute',
      right: '15px',
      top: '5px',
    },

    '& .dropdown-container': {
      backgroundClip: 'padding-box',
      backgroundColor: COLORS.white,
      border: '1px solid rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
      display: 'none',
      float: 'right',
      fontSize: '12px',
      height: window.innerHeight - 480 + 'px',
      left: '125px',
      listStyle: 'none',
      margin: '2px 0 0',
      maxWidth: '200px',
      minWidth: '200px',
      overflowY: 'scroll',
      position: 'fixed',
      top: '475px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        height: 'auto',
        maxWidth: '500px',
        minWidth: '200px',
        overflowY: 'auto',
        top: '575px',
      },
      webkitBoxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
      zIndex: 9999,
    },

    '& .dropdown-menu': {
      clear: 'left',
      display: 'block',
      position: 'static',
    },

    '& .dropdown-notifications .dropdown-menu': {
      padding: 0,
    },

    '& .dropdown-toolbar .dropdown-toolbar-title': {
      color: COLORS.white,
      fontSize: '20px',
      lineHeight: 2.5,
      margin: 0,
    },

    '& .dropdown-toolbar': {
      background: COLORS.gradient,
      backgroundColor: '#fff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      borderRadius: '4px 4px 0 0',
      paddingBottom: '5px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '6px',
    },

    '& .notification': {
      backgroundColor: COLORS.white,
      borderColor: '#eeeeee',
      borderStyle: 'solid',
      borderWidth: '0 0 3px 0',
      color: '#778A9A',
      display: 'block',
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '9.6px 12px',
      textDecoration: 'none',
    },

    '& .notifications': {
      listStyle: 'none',
      padding: 0,
    },
  },
});

interface Props {
  hideMessage: boolean;
  showMessages: () => void;
}

export default ({ hideMessage, showMessages }: Props) => {
  const classes = useStyles();

  const callClose = () => {
    showMessages();
  };

  return (
    <div className={classes.root}>
      <div
        className={'dropdown-container'}
        style={{ display: hideMessage ? 'none' : 'block' }}
      >
        <div className="dropdown-toolbar">
          <h1 className="dropdown-toolbar-title">Notifications</h1>
          <span className={'closebtn'} onClick={callClose}>
            ×
          </span>
        </div>
        <ul className="dropdown-menu notifications">
          <li className="notification">
            A new look & feel. An updated look to the application including New
            Proposal" progress bar revamp but keeping the simple and easy use
            features
          </li>

          <li className="notification">
            A new side bar to help you navigate easily between different areas
            of the application. From here you can;
          </li>

          <li className="notification">Create a Loan application</li>
          <li className="notification">
            Create a Fixed term or Minimum term application with maintenance
          </li>
          <li className="notification">
            The ability to see the individual sales person on the proposal and
            the ability to change it if you have a ‘leasedesk’ role
          </li>
        </ul>
      </div>
    </div>
  );
};
