import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { DialogContentProps } from '@material-ui/core/DialogContent';
import React from 'react';
import theme from '../constants/theme';
import Button from './Button';

const useActionsStyles = makeStyles({
  root: {
    '& .button': {
      margin: theme.spacing(2, 2),
    },
    justifyContent: 'center',
  },
});

interface Action {
  onClick: () => void;
  caption: string;
}

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
  actions: Array<Action>;
}

export default ({
  handleClose,
  open,
  children,
  actions,
  title,
  ...props
}: Props & DialogContentProps & DialogProps) => (
  <Dialog onClose={handleClose} open={open} {...props}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions classes={useActionsStyles()}>
      {actions.map(({ onClick, caption }, index) => (
        <Button
          key={index}
          className="button"
          onClick={onClick}
          label={caption}
        />
      ))}
    </DialogActions>
  </Dialog>
);
