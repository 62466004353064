import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import React from 'react';

import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import config from '../../../config';
import theme from '../../../constants/theme';
import {
  AssetArray,
  DashboardState,
  ExtendedProposalSummary,
} from '../../../model/Dashboard.model';
import { AppState } from '../../../state';
import { formatCurrency, isSendDocumentsStatus } from '../../../tools';
import { BusinessPartner, Proposal } from '../../../tools/SiemensApi';
import Address from '../namevalues/Address';
import { Assets } from '../namevalues/Assets';
import CardDetail from '../namevalues/CardDetail';
import { SerialNumbers } from '../namevalues/SerialNumbers';
import { useTranslation } from 'react-i18next';
import CreatePDF from '../../../components/ApprovalPDF';

interface Props {
  proposal: ExtendedProposalSummary; //extended proposal summary, might have an issue with details
  openProposal: () => void;
  openMoreDetails: (proposalId: number) => void;
  amendProposal: (proposalId: number) => void;
  deleteProposal: (proposalId: number) => void;
  state: DashboardState;
  proposalInfo: Proposal;
  assets: AssetArray;
  isUpgradeAction: boolean;
  createProposalWithUpgrade: (proposal: ExtendedProposalSummary) => void;
  canBeContinued: boolean;
  status: string;
  activeFilter: string;
}

const useStyles = makeStyles({
  actions: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  root: {
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& .content-box': {
      backgroundColor: '#00FFB9',
      borderRadius: 6,
    },
    '& .content-inner': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 8,
    },
    '& .heading': {
      color: '#374151 !important',
    },
    '& .row': {
      display: 'flex',
      flexDirection: 'row',
    },
    '&:before': {
      display: 'none',
    },
    borderRadius: theme.spacing(1),
    margin: '10px 0',
    padding: theme.spacing(0, 2, 0, 2),
    width: '100%',
  },
});

export default observer(
  ({
    proposal,
    openProposal,
    deleteProposal,
    openMoreDetails,
    amendProposal,
    state,
    proposalInfo,
    assets,
    isUpgradeAction,
    createProposalWithUpgrade,
    canBeContinued,
    status,
    activeFilter,
  }: Props) => {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
      <>
        <div className={classes.root}>
          <div className="content">
            <div className="content-inner">
              <div className="content-box">
                <div className="row">
                  <CardDetail title={t('term', 'Term')}>
                    {proposalInfo &&
                    proposalInfo.currentCalculation &&
                    proposalInfo.currentCalculation.term
                      ? //!proposal.isActive
                        `${proposalInfo!.currentCalculation!.term || 0} ${t(
                          'months',
                          'Months'
                        )}`
                      : 'T.B.C'}
                  </CardDetail>
                  <CardDetail title={t('rental', 'Rental')}>
                    {proposalInfo &&
                    proposalInfo.currentCalculation &&
                    proposalInfo.currentCalculation.payment &&
                    proposalInfo.currentCalculation.payment.avgPaymentPerPeriod
                      ? formatCurrency(
                          proposal.extendedCurrency.format,
                          proposalInfo.currentCalculation.payment
                            .avgPaymentPerPeriod || 0,
                          proposal.extendedCurrency.symbol
                        )
                      : 'T.B.C'}
                  </CardDetail>
                </div>
                <CardDetail title={t('marketProduct', 'Product')}>
                  {proposalInfo &&
                  proposalInfo.currentCalculation &&
                  proposalInfo.currentCalculation.marketProduct
                    ? proposalInfo.currentCalculation.marketProduct.name
                    : 'T.B.C'}
                </CardDetail>
              </div>
            </div>
            <div className="content-inner">
              <div className="content-box">
                <CardDetail title={t('assetDetails', 'Asset Details')}>
                  <Assets
                    assets={assets as any}
                    getAssetType={state.getAssetType}
                  />
                </CardDetail>
                <CardDetail title={t('serialNumbers', 'Serial Numbers')}>
                  <SerialNumbers assets={assets as any} />
                </CardDetail>
              </div>
              <div className="content-box">
                <div className="row">
                  {proposal.isActive && false && (
                    <CardDetail title={t('residualValue', 'Residual Value')}>
                      {proposalInfo &&
                        proposalInfo.currentCalculation &&
                        proposalInfo.currentCalculation.residualValue &&
                        typeof proposalInfo!.currentCalculation!.residualValue
                          .amount === 'number' && (
                          <>
                            {formatCurrency(
                              proposal.extendedCurrency.format,
                              proposalInfo.currentCalculation.residualValue
                                .amount || 0,
                              proposal.extendedCurrency.symbol
                            )}
                          </>
                        )}
                    </CardDetail>
                  )}
                </div>
                <div className="row">
                  <Address
                    businessPartner={
                      proposalInfo.customer!.businessPartner! as BusinessPartner
                    }
                  />
                  <CardDetail title={t('salesContact', 'Sales Contact')}>
                    {proposalInfo.salesContact != null
                      ? proposalInfo.salesContact.firstName +
                        ' ' +
                        proposalInfo.salesContact.lastName
                      : ''}
                  </CardDetail>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            {isUpgradeAction ? (
              <Loader />
            ) : proposal.isActive && config.isUpgradeAllowed ? (
              <Button
                onClick={() => createProposalWithUpgrade(proposal)}
                label="Upgrade"
                style="accordion"
              />
            ) : (
              ''
            )}
            {isSendDocumentsStatus(status) && config.allowSendDocuments && (
              <Button
                onClick={() => openMoreDetails(proposal!.id!)}
                label={t('sendDocuments', 'Send documents')}
                style="accordion"
              />
            )}
            {proposal.isDraft && (
              <>
                {canBeContinued && (
                  <Button
                    onClick={() => openProposal()} //shouldn't proposal.ID be passed in here too???
                    disabled={!canBeContinued}
                    style="accordion"
                    label={t('continueDraft', 'Continue Draft')}
                  />
                )}
                <Button
                  style="accordion"
                  onClick={() => deleteProposal(proposal!.id!)}
                  label={t('deleteDraft', 'Delete Draft')}
                />
              </>
            )}
            {proposal &&
              proposalInfo.decision &&
              proposalInfo.decision.decisionStatus === 'ACCEPTED' &&
              status === 'Expired' && (
                <Button
                  onClick={() => openProposal()}
                  disabled={!canBeContinued}
                  style="accordion"
                  label={t('reviewAndResubmit', 'Review & Resubmit')}
                />
              )}
            {proposal &&
              proposalInfo.decision &&
              proposalInfo.decision.decisionStatus === 'ACCEPTED' &&
              status !== 'Expired' &&
              activeFilter !== 'active' && (
                <Button
                  onClick={() => amendProposal(proposal!.id!)}
                  label={t('AmendProposal', 'Amend proposal')}
                  style="accordion"
                />
              )}
            {proposalInfo &&
              proposalInfo.decision &&
              proposalInfo.decision.decisionStatus === 'ACCEPTED' &&
              status !== 'Expired' &&
              getParent<AppState>(state).isSiemensDI && (
                <CreatePDF proposal={proposalInfo} />
              )}
          </div>
        </div>
      </>
    );
  }
);
