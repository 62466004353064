import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import ErrorDialog from '../../components/ErrorDialog';
import Loader from '../../components/Loader';
import { DashboardState, JobTitlesArray } from '../../model/Dashboard.model';
import { AppState } from '../../state';

import {
  getJobTitles,
  GetJobTitlesParametersModel,
  getProposal,
  GetProposalParametersModel,
  Proposal,
  AssetType,
  BusinessPartner,
} from '../../tools/SiemensApi';
import { useTranslation } from 'react-i18next';
import CardDetail from './namevalues/CardDetail';
import config from '../../config';
import Panel from '../../components/Panel';
import Signatories from './components/Signatories';
import BoardMembers from './components/BoardMembers';
import Address from './namevalues/Address';
import InvoiceAddress from './components/InvoiceAddress';
import Documents from './components/Documents';
import { useParams } from 'react-router-dom';
import theme from '../../constants/theme';

const useStyles = makeStyles({
  customerDetail: {
    '& .detailBox': {
      margin: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  heading: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    flexDirection: 'column',
    fontSize: 28,
    fontWeight: 700,
    gap: 20,
    marginBottom: 30,
  },
  root: {
    width: '100%',
  },
});

interface Props {
  state: DashboardState;
  backButton: React.ReactNode;
  getAssetType: (assetTypeId: number) => AssetType | undefined;
}

export default observer(({ state, backButton, getAssetType }: Props) => {
  const { t } = useTranslation();
  const { propId } = useParams();
  const proposalId = +propId;
  const introducerId = getParent<AppState>(state).introducer.id;
  const [activeProposal, setActiveProposal] = useState<Proposal | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobTitles, setJobTitles] = useState<JobTitlesArray | null>(null);
  const [ifMatch, setIfMatch] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const appState = getParent<AppState>(state);

  useEffect(() => {
    fetchProposalAndDocuments();
  }, []);

  const fetchProposalAndDocuments = async (hideLoading?: boolean) => {
    if (!hideLoading) {
      setIsLoading(true);
    }

    let response;

    try {
      response = await getProposal(
        GetProposalParametersModel.create({
          introducerId,
          proposalId,
        })
      );
    } catch (error) {
      response = error.response;
    }

    const proposal = response.data;
    setActiveProposal(proposal);

    setIfMatch(response.headers.etag);

    let jobTitlesResponse;

    if (!jobTitles) {
      try {
        jobTitlesResponse = await getJobTitles(
          GetJobTitlesParametersModel.create({
            introducerId,
          })
        );
      } catch (error) {
        jobTitlesResponse = error.response;
      }

      setJobTitles(jobTitlesResponse.data);
    }

    setIsLoading(false);
  };

  const classes = useStyles();

  if (isLoading || !activeProposal) {
    return <Loader text={t('loading', 'Loading')} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>{backButton} Send documents</div>
      <Panel title={t('customerDetail', 'Customer detail')}>
        <div className={classes.customerDetail}>
          <CardDetail title={'Customer name'}>
            {activeProposal.customer!.businessPartner!.name}
          </CardDetail>
          <CardDetail title={'Registration number'}>
            {activeProposal.customer!.businessPartner!.id}
          </CardDetail>
          <Address
            businessPartner={
              activeProposal.customer!.businessPartner! as BusinessPartner
            }
          />
        </div>
      </Panel>

      <BoardMembers
        state={state}
        setErrorMessage={() => setErrorMessage}
        setActiveProposal={() => setActiveProposal}
        ifMatch={ifMatch}
        proposalId={proposalId}
        fetchProposalAndDocuments={fetchProposalAndDocuments}
      />

      <Signatories
        state={state}
        activeProposal={activeProposal}
        jobTitles={jobTitles}
        setErrorMessage={() => setErrorMessage}
        setActiveProposal={() => setActiveProposal}
        setIfMatch={() => setIfMatch}
        ifMatch={ifMatch}
        proposalId={proposalId}
        fetchProposalAndDocuments={fetchProposalAndDocuments}
      />

      {config.isInvoiceAddressEdit && (
        <InvoiceAddress
          state={state}
          activeProposal={activeProposal}
          setErrorMessage={() => setErrorMessage}
          setActiveProposal={() => setActiveProposal}
          setIfMatch={() => setIfMatch}
          ifMatch={ifMatch}
          proposalId={proposalId}
        />
      )}

      <Documents
        state={state}
        activeProposal={activeProposal}
        ifMatch={ifMatch}
        proposalId={proposalId}
        setErrorMessage={() => setErrorMessage}
        fetchProposalAndDocuments={fetchProposalAndDocuments}
        getAssetType={getAssetType}
      />

      <ErrorDialog
        open={!!errorMessage}
        onOk={() => setErrorMessage('')}
        okCaption={'Ok'}
        handleClose={() => setErrorMessage('')}
        state={appState}
      >
        {errorMessage}
        <p>
          For help, please email{' '}
          <a href="mailto:mysfs.uk@siemens.com">mysfs.uk@siemens.com</a>
        </p>
      </ErrorDialog>
    </div>
  );
});
