import { makeStyles, IconButton } from '@material-ui/core';
import React from 'react';
import { Boardmember, Signatory } from '../../../tools/SiemensApi';
import Button from '../../../components/Button';
import { EditIcon } from '../../../images/DashboardIcons';
import { DeleteIcon } from '../../../images/DashboardIcons';

const useStyles = makeStyles({
  buttons: {
    //marginTop: 10,
  },
  root: {
    '& .name': {
      color: '#000028',
      fontSize: 18,
      fontWeight: 700,
    },
    border: '1px solid #D1D5DB',
    borderRadius: 5,
    color: '#374151',
    flex: 1,
    flexBasis: '40%',
    fontSize: 14,
    padding: 16,
    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: 300,
    // },
  },
});

interface Props {
  contact: Boardmember | Signatory;
  contactType: string;
  addBoardmemberAsSignatory?: (contact) => void;
  validateBoardMember?: (contact) => boolean;
  handleEditSignatoryClick?: (contact) => void;
  removeSignatory?: (contact) => void;
}

const ContactBox = ({
  contact,
  contactType,
  addBoardmemberAsSignatory,
  validateBoardMember,
  handleEditSignatoryClick,
  removeSignatory,
}: Props) => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className="name">
        {contact.firstName} {contact.lastName}
      </div>
      {contact.jobTitle && (
        <div>{contact.jobTitle ? `${contact.jobTitle!.name}` : ''}</div>
      )}
      <div style={{ marginTop: 10 }}>E: {contact.email}</div>
      <div className={classes.buttons}>
        {contactType === 'boardMember' && (
          <Button
            onClick={() => addBoardmemberAsSignatory?.(contact as Boardmember)}
            disabled={!validateBoardMember?.(contact as Boardmember)}
            label="Add as signatory"
          />
        )}
        {contactType === 'signatory' && (
          <>
            <IconButton
              color="primary"
              onClick={() => handleEditSignatoryClick?.(contact as Signatory)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => removeSignatory?.(contact as Signatory)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactBox;
