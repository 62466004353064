import { MenuItem, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';
import { SortByType } from '../../../model/Dashboard.model';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: SortByType;
}

interface Props {
  value: SortByType;
  onChange: (value: SortByType) => void;
  options: Array<Option>;
}

const Sort = ({
  onChange,
  options,
  ...props
}: Props & Omit<TextFieldProps, 'onChange' | 'value' | 'options'>) => {
  const { t } = useTranslation();
  return (
    // @ts-ignore
    <TextField
      onChange={(event) => onChange(event.target.value as SortByType)}
      select={true}
      label={t('sortBy', 'Sort by:')}
      {...props}
    >
      {options.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Sort;
