import { Box, Container, makeStyles } from '@material-ui/core';
import icon from 'media/Completed.svg';
import React, { ReactElement } from 'react';
import Button from '../../components/Button';
import { COLORS } from '../../constants/style-constants';
import { CalculatorState } from '../../model/Calculator.model';
import theme from '../../constants/theme';
import Panel from '../../components/Panel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  paragraph: {
    color: COLORS.black,
    fontSize: 15,
  },
  root: {
    '& .MuiButtonBase-root': {
      marginTop: theme.spacing(5),
    },
    '& .title': {
      color: COLORS.black,
      fontSize: 24,
      fontWeight: 700,
    },
    '& img': {
      height: 100,
      margin: theme.spacing(4),
      width: 100,
    },
    textAlign: 'center',
  },

  textDecorator: {
    color: COLORS.green,
  },
});

interface Props {
  reset: () => void;
  state: CalculatorState;
}

const Referred = ({ reset, state }: Props): ReactElement => {
  const { t } = useTranslation();
  if (!state.proposal) {
    return (
      <>
        <Panel title={t('confirmation', 'Confirmation')}>
          t('noProposal', 'No proposal known')
        </Panel>
      </>
    );
  }
  const classes = useStyles();

  return (
    <Panel>
      <Box className={classes.root}>
        <img src={icon} alt="Success Icon" />
        <Container className={'title'}>
          {t('applicationReferred', { propId: state.proposal.id })}
        </Container>
        <Container className={classes.paragraph}>
          <br />
          {t(
            'discussYourApplication',
            'A member of the Siemens Financial Services team will contact you to discuss your application'
          )}
        </Container>
        <div className={classes.navigation}>
          <Button
            onClick={reset}
            label={t('newApplication', 'New application')}
          />
        </div>
      </Box>
    </Panel>
  );
};

export default Referred;
