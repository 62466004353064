import { Box, Container, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button';
import theme from '../../../constants/theme';
import {
  DashboardState,
  SortByModel,
  SortByType,
  FilterModel,
  FilterType,
  statusMap,
} from '../../../model/Dashboard.model';
import WorkflowStatusFilter from './WorkflowStatusFilter';
import Search from './Search';
import Sort from './Sort';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NewProposalIcon } from '../../../images/SidebarIcons';
import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';

const useStyles = makeStyles({
  root: {
    '& .bar': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      margin: theme.spacing(1, 0, 1),
      paddingRight: 0,
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(2, 0, 2),
      },
    },
    '& .dateRange': {
      margin: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    '& .header': {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 28,
      fontWeight: 700,
      justifyContent: 'space-between',
      padding: 0,
    },
    '& .inputBar': {
      '& .search, & .sort, & .workflowStatusFilter': {
        display: 'block',
        margin: theme.spacing(0),
      },
      '& .startNewDeal': {
        '& button': {
          margin: '0 0 10px 0',
        },
        alignItems: 'flex-end',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      marginTop: 16,
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        '& .search, & .sort, & .workflowStatusFilter': {
          flexGrow: 1,
          width: 0,
        },
        '& .startNewDeal': {
          '& .button': {
            margin: 0,
          },
          flexBasis: 0,
          flexGrow: 1,
          order: 3,
        },
        flexDirection: 'row',
      },
    },
    '& .paginationText': { textAlign: 'right', paddingRight: '33px' },
    '& .paginationText p': { marginBottom: '5px', marginTop: '5px' },
    width: '100%',
  },
});

interface Props {
  openProposal: () => void;
  state: DashboardState;
  filters: Array<any>;
  goProposals: () => void;
  getMoreProposals: () => void;
}

const Header = observer(
  ({
    openProposal,
    getMoreProposals,
    state,
    state: { activeFilter, searchQuery, sortBy, workflowStatus, isSearching },
  }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [throttle, setThrottle] = useState<number | null>(null);
    const appState = getParent<AppState>(state);

    const handleChange = (value: string) => {
      state.setSearchQuery(value);

      if (throttle) {
        window.clearTimeout(throttle);
      }

      if (value) {
        setThrottle(
          window.setTimeout(async () => {
            state.getProposals(value);
          }, 1000)
        );
      } else {
        state.getProposals(value);
      }
    };

    const handleFilterChange = (value: FilterType) => {
      state.setWorkflowStatus(value);
      //state.setActiveFilter(value);
    };
    const handleSortChange = (value: SortByType) => {
      state.setLoadingCount(0); //Reset the number of proposals loaded rather than keeping existing
      state.setSortBy(value);
      state.getProposals(searchQuery);
    };
    const sortByOptions = [
      {
        label: t('proposalNumber', 'Proposal Number'),
        value: SortByModel.create('PROPOSAL_NO'),
      },
      {
        label: t('dateCreated', 'Date Created'),
        value: SortByModel.create('CREATED'),
      },
      {
        label: t('dateUpdated', 'Date Updated'),
        value: SortByModel.create('UPDATED'),
      },
    ];

    const activeFilterOptions = [
      {
        label: t('draft', 'Draft'),
        value: FilterModel.create('draft'),
      },
      {
        label: t('pending', 'Pending'),
        value: FilterModel.create('pending'),
      },
      {
        label: t('active', 'Active'),
        value: FilterModel.create('active'),
      },
      {
        label: t('all', 'All'),
        value: FilterModel.create('all'),
      },
    ];

    const title = (activeFilter) => {
      if (activeFilter == 'draft') {
        return 'Drafts';
      }
      if (activeFilter == 'pending') {
        return 'Proposals';
      }
      if (activeFilter == 'active') {
        return 'Activated';
      }
      return activeFilter;
    };

    /*
    const statusDisplayNames = {
      all: 'All',
      draft: 'Draft',
      active: 'Active',
      pending: 'Pending',
    };

    const statusCountKeys = {
      all: 'allTotal',
      draft: 'draftTotal',
      active: 'activeTotal',
      pending: 'pendingTotal',
    };

    const displayName = statusDisplayNames[state.workflowStatus];
    const count =
      state.workflowStatusCounts[statusCountKeys[state.workflowStatus]];*/

    return (
      <div className={classes.root}>
        <Container className={'header'}>
          <div>Proposals</div>
          <Box className={'startNewDeal'}>
            <Button
              onClick={() => openProposal()}
              label={t('newApplication', 'New application')}
              icon={<NewProposalIcon />}
            />
          </Box>
        </Container>
        <Container className={'inputBar'}>
          <Search
            onChange={handleChange}
            value={searchQuery}
            className={'search'}
          />
          <Sort
            value={sortBy}
            onChange={handleSortChange}
            options={sortByOptions}
            className={'sort'}
          />
        </Container>
        <Box className={'bar'}>
          {!isSearching && !appState.loading && (
            <Button
              onClick={() => getMoreProposals()}
              label={t('loadMore', 'Load more')}
            />
          )}
        </Box>
      </div>
    );
  }
);

export default Header;
