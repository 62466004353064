import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { AppState } from '../state';
import {
  AssetModel,
  AssetType,
  Currency as ApiCurrency,
  Proposal,
  ProposalModel,
  ProposalSummaryModel,
  JobTitleModel,
  ProposalDocumentModel,
  ContractModel,
  findProposals,
  findContracts,
  FindProposalsParametersModel,
  FindContractsParametersModel,
  expireProposal,
  ExpireProposalParametersModel,
  BoardmemberModel,
  getProposal,
  GetProposalParametersModel,
  getProposalStatusCounts,
  GetProposalStatusCountsParametersModel,
} from '../tools/SiemensApi';
import { createCurrency, Currency } from './Currency';
import moment from 'moment';
import Reset from '../containers/Dashboard/Reset';

const AssetArrayModel = types.array(AssetModel);
const JobTitlesArrayModel = types.array(JobTitleModel);
const DocuementsArrayModel = types.array(ProposalDocumentModel);
const BoardMembersArrayModel = types.array(BoardmemberModel);
export type AssetArray = Instance<typeof AssetArrayModel>;
export type JobTitlesArray = Instance<typeof JobTitlesArrayModel>;
export type DocumentsArray = Instance<typeof DocuementsArrayModel>;
export type BoardMembersArray = Instance<typeof BoardMembersArrayModel>;

declare global {
  /* tslint:disable-next-line:no-unused-declaration */
  interface Window {
    msCrypto: Crypto;
  }
}
const crypto = window.crypto || window.msCrypto;

type Phase = 'active' | 'pending' | 'draft';

export const statusMap: { [key: string]: Array<string> } = {
  active: ['ACTIVATED', 'CONTRACT_STARTED', 'PAID'],
  draft: ['OFFER', 'PROSPECT'],
  pending: [
    'DEAL_AMENDMENT',
    'MORE_INFORMATION_REQUIRED',
    'WAITING_FOR_DECISION',
    'PENDING',
    'CREDIT_APPROVED',
    'WAITING_FOR_PRINT',
    'WAITING_FOR_E_SIGNATURE',
    'DOCUMENTS_ARRIVED',
    'DOCUMENTS_COMPLETED',
    'WAITING_BACKEND',
    'EXPIRED',
    'DECLINED',
    'WAITING_ACTIVATION',
    'WAITING',
  ],
};

export const FilterModel = types.enumeration([
  'draft',
  'pending',
  'active',
  'all',
]);
export const SortByModel = types.enumeration([
  'ID',
  'PROPOSAL_NO',
  'LABEL',
  'CREATED',
  'UPDATED',
]);

export const WorkflowStatusCountsModel = types.model({
  draftCurrent: types.number,
  activeCurrent: types.number,
  pendingCurrent: types.number,
  allCurrent: types.number,
  draftTotal: types.number,
  activeTotal: types.number,
  pendingTotal: types.number,
  allTotal: types.number,
});

export type WorkflowStatusCounts = Instance<typeof WorkflowStatusCountsModel>;

export type FilterType = Instance<typeof FilterModel>;
export type SortByType = Instance<typeof SortByModel>;

export const getPhase = (status: string): FilterType => {
  //could solve the reset here but might be better after sidebars are clicked
  for (const phase of Object.keys(statusMap)) {
    if (statusMap[phase].includes(status || '')) {
      return phase as Phase;
    }
  }
  return 'active';
  //throw new Error('status is not mapable to phase: ' + status);
};

export const ExtendedProposalSummaryModel = ProposalSummaryModel.views(
  (self) => ({
    get phase(): Phase {
      return getPhase(self.status as any);
    },
  })
).views((self) => ({
  get extendedCurrency(): Currency {
    return createCurrency(self.currency as ApiCurrency);
  },
  get isActive(): boolean {
    return self.phase === 'active';
  },
  get isDraft(): boolean {
    return self.phase === 'draft';
  },
  get isPending(): boolean {
    return self.phase === 'pending';
  },
}));

export type ExtendedProposalSummary = Instance<
  typeof ExtendedProposalSummaryModel
>;

const ProposalSummaryArrayModel = types.array(ExtendedProposalSummaryModel);
const ContractSummaryArrayModel = types.array(ContractModel);

export type ProposalSummaryArray = Instance<typeof ProposalSummaryArrayModel>;
export type ContractSummaryArray = Instance<typeof ContractSummaryArrayModel>;

export const DashboardModel = types
  .model({
    activeFilter: FilterModel,
    assets: types.map(types.array(AssetModel)),
    contracts: types.maybeNull(ContractSummaryArrayModel),
    desktopListView: types.boolean,
    error: types.boolean,
    isLoading: types.boolean,
    lastContractsCallId: types.maybeNull(types.number),
    lastProposalsCallId: types.maybeNull(types.number),
    loadingCount: types.number,
    proposalCount: types.number,
    proposals: ProposalSummaryArrayModel,
    proposalsInfo: types.map(ProposalModel), //could be the reason
    searchDateEnd: types.maybeNull(types.Date),
    searchDateStart: types.maybeNull(types.Date),
    searchQuery: types.string,
    sortBy: SortByModel,
    workflowStatus: types.maybeNull(FilterModel),
    workflowStatusCounts: types.maybeNull(WorkflowStatusCountsModel),
  })
  .views((self) => ({
    getAssetType(id: number): AssetType | undefined {
      const appState = getParent<AppState>(self);
      return appState.calculator.assetForm.assetTypesResult != null
        ? appState.calculator!.assetForm!.assetTypesResult!.assetTypes!.find(
            (asset: AssetType) => asset.id === id
          )
        : undefined;
    },
    getAssetValue(status: string) {
      const proposals = self.proposals ? self.proposals! : [];
      let total = 0;

      switch (status) {
        case 'active':
          proposals.forEach((item) => {
            if (item.isActive) {
              const value = item.assetValue ? +item.assetValue : 0;
              total += value;
            }
          });

          break;
        case 'pending':
          proposals.forEach((item) => {
            if (item.isPending) {
              const value = item.assetValue ? +item.assetValue : 0;
              total += value;
            }
          });
          break;
      }

      return +total.toFixed(2);
    },
    getAssets(id: number): AssetArray | undefined {
      return self.assets.get('' + id);
    },
    getFilteredProposals(filter?: FilterType): Array<ExtendedProposalSummary> {
      const proposals = self.proposals ? self.proposals! : [];

      return proposals; // MT - added this to show all props - the whole
      // filtering needs reworking

      if (filter === undefined) {
        return proposals;
      } else {
        return proposals.filter((proposal: ExtendedProposalSummary) => {
          if (proposal.status && filter) {
            return statusMap[filter!].includes(proposal.status);
          }
        });
      }
    },
    getProposalInfo(id: number): Proposal | undefined {
      return self.proposalsInfo.get('' + id);
    },
    getSelectedPhase(status: string) {
      return getPhase(status);
    },
    get isSearching(): boolean {
      return !!self.searchQuery;
    },
    //TODO:get the totalnumber of proposals for each Workflowstatus - use this to set up paginiation.
  }))
  .actions((self) => ({
    appendProposals(proposals: ProposalSummaryArray) {
      const duplicateExists = proposals.some((newProposal) =>
        self.proposals.some(
          (existingProposal) =>
            JSON.stringify(newProposal) === JSON.stringify(existingProposal)
        )
      );

      if (duplicateExists) {
        console.warn(
          'One or more proposals already exist in the existing proposals.'
        );
        return;
      }

      self.proposals.push(...proposals); //this is where the list of proposals are added to the existing list

      //new version should be to add the new proposals to the existing list
      // the keys will be the same however we want to make sure were not adding duplicate props
    },
    deleteProposal: flow(function* deleteProposalById(
      proposalId: number,
      ifMatch?: string
    ) {
      const appState = getParent<AppState>(self);
      if (ifMatch == null || undefined) {
        const getProposalResponse = yield getProposal(
          GetProposalParametersModel.create({
            introducerId: appState.introducer!.id, //TODO:this could pose an issue
            proposalId,
          })
        );
        ifMatch = getProposalResponse!.headers!.etag;
      }
      yield expireProposal(
        ExpireProposalParametersModel.create({
          ifMatch,
          introducerId: appState.introducer!.id, //TODO:this could pose an issue
          proposalId,
        })
      );
      const deletedProposal = self.proposals.find((item) => {
        return item.proposalNumber === '' + proposalId;
      });

      if (deletedProposal) {
        self.proposals.remove(deletedProposal);
      }
    }),
    incrementLoadingCount() {
      self.loadingCount++;
      //self.proposalsInfo.delete('' + proposalId);
    },
    invalidateCache(proposalId: number) {
      self.assets.delete('' + proposalId);
      self.proposalsInfo.delete('' + proposalId);
    },
    resetLoadingCount() {
      self.loadingCount = 0;
    },
    setActiveFilter(filter: FilterType) {
      self.activeFilter = filter;
    },
    setContracts(contracts: ContractSummaryArray) {
      self.contracts = contracts;
    },
    setDeskTopListView(desktopListView: boolean) {
      self.desktopListView = desktopListView;
    },
    setIsLoading(value: boolean) {
      self.isLoading = value;
    },
    setLastProposalsCallId(value: number | null) {
      self.lastProposalsCallId = value;
    },
    setProposals(proposals: ProposalSummaryArray) {
      self.proposals = proposals;
    },
    setProposalsAssets(id: number, assets: Instance<typeof AssetArrayModel>) {
      self.assets.set('' + id, assets);
    },
    setProposalsInfo(id: number, proposal: Proposal) {
      self.proposalsInfo.set('' + id, proposal);
    },
    setSearchQuery(searchQuery: string) {
      self.searchQuery = searchQuery;
    },
    setSortBy(sortBy: SortByType) {
      self.sortBy = sortBy;
    },
    setWorkflowStatus(workflowStatus: FilterType) {
      self.workflowStatus = workflowStatus;
    },
    setLoadingCount(loadingCount: number) {
      self.loadingCount = loadingCount;
      //let response;
      //let promiseCall: ReturnType<typeof getProposalStatusCounts> | null = null;
      //self.workflowStatusCounts = workflowStatusCounts;
      /**Worth having separate functions for each */
      //call the function and print the results
      //set the reults to the workflowStatusCounts object
      /*try {
        promiseCall = getProposalStatusCounts(
            //getParent<AppState>(self).introducer!.id
            GetProposalStatusCountsParametersModel.create({
                introducerId: getParent<AppState>(self).introducer!.id,
                start: 0,
                end: 0,
            })

        );

        response = yield promiseCall; // Fix: Remove the '*' operator
    } catch (error) {
        response = error.response;
    }*/
    },
  }))
  .actions((self) => ({
    getContracts: flow(function* getContracts() {
      let contracts;
      let promiseCall: ReturnType<typeof findProposals> | null = null;
      const lastContractsCallId =
        crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1);

      try {
        promiseCall = findContracts(
          FindContractsParametersModel.create({
            introducerId: getParent<AppState>(self).introducer!.id,
          })
        );

        self.lastContractsCallId = lastContractsCallId;

        contracts = yield promiseCall;
      } catch (error) {
        contracts = error.response;
      }

      if (lastContractsCallId === self.lastContractsCallId) {
        if (contracts && contracts.data) {
          try {
            self.setContracts(contracts.data.data!);
          } catch (error) {
            self.error = true;
          }
        }

        self.lastContractsCallId = null;
      }
    }),
  }))
  .actions((self) => ({
    getAllProposals: flow(function* getProposals(value = '') {
      const totalProposals: ProposalSummaryArray = [] as any;
      let response;
      let promiseCall: ReturnType<typeof findProposals> | null = null;
      const lastProposalsCallId =
        crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1);
      let start = 0;
      let total;

      do {
        try {
          promiseCall = findProposals(
            FindProposalsParametersModel.create({
              custName: `*${value}*`,
              introducerId: getParent<AppState>(self).introducer!.id,
              sortBy: self.sortBy,
              start,
            })
          );

          self.lastProposalsCallId = lastProposalsCallId;

          response = yield promiseCall;
        } catch (error) {
          response = error.response;
        }
        totalProposals.push(...response.data.data!);
        start = start + 100;
        total = response.data.pagination.total;
      } while (start < total);

      if (lastProposalsCallId === self.lastProposalsCallId) {
        if (response && response.data) {
          self.setProposals(totalProposals);
        }
        self.lastProposalsCallId = null;
        self.getContracts();
      }
    }),
    getProposals: flow(function* getProposals(
      value = '',
      loadMore = false,
      filter?: FilterType
    ) {
      getParent<AppState>(self).setIsLoading(true);
      let response;
      let promiseCall: ReturnType<typeof findProposals> | null = null;
      const lastProposalsCallId =
        crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1);

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 600);
      self.searchDateEnd = new Date();
      self.searchDateStart = startDate;

      let searchDateStartString;
      let searchDateEndString;
      if (!value) {
        searchDateStartString = moment(self.searchDateStart).format(
          'YYYY-MM-DD'
        );
        searchDateEndString = moment(self.searchDateEnd).format('YYYY-MM-DD');
      } else {
        //change if value because if theres no value you shouldn't search righ? - you should still search but also if there is one
        searchDateStartString = moment(self.searchDateStart).format(
          'YYYY-MM-DD'
        );
        searchDateEndString = moment(self.searchDateEnd).format('YYYY-MM-DD');
      }

      let customerName = value;
      let propNumber;

      if (!isNaN(Number(value))) {
        propNumber = +value;
        customerName = '';
      }

      let workflowStatus = '';
      if (filter) {
        workflowStatus = statusMap[filter].toString();
      }
      const size = 10;
      const startValue = self.loadingCount * size;
      //use the stats to get the number of items in total to avoid indexing error
      try {
        promiseCall = findProposals(
          FindProposalsParametersModel.create({
            createdAfter: searchDateStartString + 'T00:00:00.000Z',
            createdBefore: searchDateEndString + 'T00:00:00.000Z',
            custName: `*${customerName}*`,
            introducerId: getParent<AppState>(self).introducer!.id,
            propNo: propNumber,
            sortBy: self.sortBy,
            start: startValue, //if loadmore is clicked then start is the number of times already loaded * number of items per page
            size: size, //TODO: set correctnumber of items per page
            // eslint-disable-next-line sort-keys
            //dateSearchTypeId: 'CREATED',
            //workflowStatus,
          })
        );

        self.lastProposalsCallId = lastProposalsCallId;

        response = yield promiseCall;
      } catch (error) {
        response = error.response;
      }

      if (lastProposalsCallId === self.lastProposalsCallId) {
        if (response && response.data) {
          if (loadMore) {
            self.appendProposals(response.data.data!);
          } else {
            //reset load count here
            self.setProposals(response.data.data!);
          }
        }
        self.lastProposalsCallId = null;
        // self.getContracts(value);
        getParent<AppState>(self).setIsLoading(false);
        self.setIsLoading(false);
      }
    }),
    getStatusCounts: flow(function* getStatusCounts(value = '') {
      //getParent<AppState>(self).setIsLoading(true);

      const response = yield getProposalStatusCounts(
        GetProposalStatusCountsParametersModel.create({
          introducerId: getParent<AppState>(self).introducer!.id,
          start: -600, //see line 463 - is it worth having this a constant long term
          end: 0,
        })
      );
      //self.workflowStatusCounts = response.data;

      const counts = response.data.counts;
      const draftTotal = counts['OFFER'] + counts['PROSPECT'];

      const activeTotal = counts['CONTRACT_STARTED'] + counts['ACTIVATED']; //TODO: ACTIVATED is not included in list
      const pendingTotal = counts['APR'] + counts['CREDIT_APPROVED']; //TODO: APR and CREDIT_APPROVED are not included in list
      const allTotal = draftTotal + activeTotal + pendingTotal;

      self.workflowStatusCounts.draftTotal = draftTotal;
      self.workflowStatusCounts.activeTotal = activeTotal;
      self.workflowStatusCounts.pendingTotal = pendingTotal;
      self.workflowStatusCounts.allTotal = allTotal;
    }),
    reset() {
      Object.assign(self, dashboardDefaults);
    },
    toggleLoadingModal(value: boolean) {
      getParent<AppState>(self).setShowModal(value);
    },
  }));

export type DashboardState = Instance<typeof DashboardModel>;

export const dashboardDefaults = {
  activeFilter: FilterModel.create('draft'),
  desktopListView: true,
  error: false,
  isLoading: false,
  loadingCount: 0,
  proposalCount: 0,
  proposals: [],
  searchQuery: '',
  sortBy: SortByModel.create('UPDATED'),
  workflowStatus: FilterModel.create('all'),
  workflowStatusCounts: WorkflowStatusCountsModel.create({
    activeCurrent: 0,
    allCurrent: 0,
    draftCurrent: 0,
    pendingCurrent: 0,
    activeTotal: 0,
    allTotal: 0,
    draftTotal: 0,
    pendingTotal: 0,
  }),
  //Set Count after get initial proposals
  //Update Count after loadmore
  //lo
};
