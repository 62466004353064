import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& svg': {
      margin: '0 1px',
    },
    display: 'flex',
  },
}));

interface Props {
  colour?: string;
  height?: number;
  width?: number;
}

export const HamburgerIcon = ({ colour }: Props): ReactElement => {
  const classes = useStyles();
  const fillColour = colour ? colour : '#fff';
  return (
    <div className={classes.root}>
      <svg
        width="48"
        height="36"
        viewBox="0 0 48 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.91796 5.83524H45.0827C46.6916 5.83524 48 4.52688 48 2.91797C48 1.30905 46.6916 0 45.0827 0H2.91796C1.30905 0 0 1.30905 0 2.91797C0 4.52688 1.30905 5.83524 2.91796 5.83524Z"
          fill={fillColour}
        />
        <path
          d="M45.0827 15.0586H2.91796C1.30905 15.0586 0 16.3676 0 17.9766C0 19.5855 1.30905 20.8938 2.91796 20.8938H45.0827C46.6916 20.8938 48 19.5855 48 17.9766C48 16.3676 46.6916 15.0586 45.0827 15.0586Z"
          fill={fillColour}
        />
        <path
          d="M45.0827 30.1172H2.91796C1.30905 30.1172 0 31.4269 0 33.0358C0 34.6448 1.30905 35.9531 2.91796 35.9531H45.0827C46.6916 35.9531 48 34.6448 48 33.0358C48 31.4269 46.6916 30.1172 45.0827 30.1172Z"
          fill={fillColour}
        />
      </svg>
    </div>
  );
};
