import { Container, makeStyles, useMediaQuery } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  DashboardState,
  ExtendedProposalSummary,
  statusMap,
} from '../../model/Dashboard.model';
import Accordion from './components/Accordion';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import theme from '../../constants/theme';
import { ActivatedIcon } from '../../images/SidebarIcons';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';
import loading from 'media/spinner.svg';

const useStyles = makeStyles<{}, { activeFilter: any }>({
  root: ({ activeFilter }) => ({
    '& .active': {
      display: activeFilter === 'active' ? 'block' : 'none',
    },
    '& .draft': {
      display: activeFilter === 'draft' ? 'block' : 'none',
    },
    '& .pending': {
      display: activeFilter === 'pending' ? 'block' : 'none',
    },
    '& .search-query-text': {
      fontSize: '1.25rem',
    },
    padding: 0,
  }),
});

interface Props {
  openProposal: (proposalId?: number) => void;
  deleteProposal: (proposalId: number) => void;
  openMoreDetails: (proposalId: number) => void;
  amendProposal: (proposalId: number) => void;
  getMoreProposals: () => void;
  state: DashboardState;
  filters: Array<any>;
}

export default observer(
  ({
    openProposal,
    deleteProposal,
    openMoreDetails,
    getMoreProposals,
    amendProposal,
    state,
    state: {
      activeFilter,
      searchQuery,
      getFilteredProposals,
      isSearching,
      workflowStatus,
    },
    filters,
  }: Props) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<number | null>(null);
    const proposals = getFilteredProposals(activeFilter); //TODO: this will cause problems
    const showDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const appState = getParent<AppState>(state);

    const activeProposals = proposals.filter((proposal: { status: string }) =>
      statusMap['active'].includes(proposal.status!)
    );

    const draftProposals = proposals.filter((proposal: { status: string }) =>
      statusMap['draft'].includes(proposal.status!)
    );

    const pendingProposals = proposals.filter((proposal: { status: string }) =>
      statusMap['pending'].includes(proposal.status!)
    );

    return (
      <Container classes={useStyles({ activeFilter })}>
        {isSearching && (
          <p className="search-query-text">
            {t('showingAll', 'Showing all')}{' '}
            {/*filters.find((filter) => filter.filter === activeFilter)!.label*/}{' '}
            for {searchQuery}
          </p>
        )}
        {proposals.length === 0 ? (
          <>
            {!appState.loading ? (
              <p className="no-results-found">
                {t('noProposals', 'No proposals')}
              </p>
            ) : (
              <p className="no-results-found">
                {t('loadingProposals', 'Loading proposals...')}
              </p>
            )}
            {!isSearching && !appState.loading && (
              <Button
                onClick={() => getMoreProposals()}
                label={t('loadMore', 'Load more')}
              />
            )}
          </>
        ) : (
          <>
            {workflowStatus === 'all' &&
              proposals.map((proposal: ExtendedProposalSummary) => (
                <Accordion
                  //className={state.getSelectedPhase(proposal.status!)}
                  expandedItem={expanded}
                  key={proposal.id!}
                  proposal={proposal}
                  setExpanded={setExpanded}
                  openProposal={() => openProposal(proposal.id!)}
                  state={state}
                  deleteProposal={() => deleteProposal(proposal.id!)}
                  openMoreDetails={() => openMoreDetails(proposal.id!)}
                  amendProposal={() => amendProposal(proposal.id!)}
                  activeFilter={activeFilter}
                  showDesktop={showDesktop}
                />
              ))}
            {/*
            {workflowStatus === 'active' &&
              activeProposals.map((proposal: ExtendedProposalSummary) => (
                <Accordion
                  //className={state.getSelectedPhase(proposal.status!)}
                  expandedItem={expanded}
                  key={proposal.id!}
                  proposal={proposal}
                  setExpanded={setExpanded}
                  openProposal={() => openProposal(proposal.id!)}
                  state={state}
                  deleteProposal={() => deleteProposal(proposal.id!)}
                  openMoreDetails={() => openMoreDetails(proposal.id!)}
                  amendProposal={() => amendProposal(proposal.id!)}
                  activeFilter={activeFilter}
                  showDesktop={showDesktop}
                />
              ))}
            {workflowStatus === 'draft' &&
              draftProposals.map((proposal: ExtendedProposalSummary) => (
                <Accordion
                  //className={state.getSelectedPhase(proposal.status!)}
                  expandedItem={expanded}
                  key={proposal.id!}
                  proposal={proposal}
                  setExpanded={setExpanded}
                  openProposal={() => openProposal(proposal.id!)}
                  state={state}
                  deleteProposal={() => deleteProposal(proposal.id!)}
                  openMoreDetails={() => openMoreDetails(proposal.id!)}
                  amendProposal={() => amendProposal(proposal.id!)}
                  activeFilter={activeFilter}
                  showDesktop={showDesktop}
                />
              ))}
            {workflowStatus === 'pending' &&
              pendingProposals.map((proposal: ExtendedProposalSummary) => (
                <Accordion
                  //className={state.getSelectedPhase(proposal.status!)}
                  expandedItem={expanded}
                  key={proposal.id!}
                  proposal={proposal}
                  setExpanded={setExpanded}
                  openProposal={() => openProposal(proposal.id!)}
                  state={state}
                  deleteProposal={() => deleteProposal(proposal.id!)}
                  openMoreDetails={() => openMoreDetails(proposal.id!)}
                  amendProposal={() => amendProposal(proposal.id!)}
                  activeFilter={activeFilter}
                  showDesktop={showDesktop}
                />
              ))}*/}
            {!isSearching && !appState.loading && (
              <Button
                onClick={() => getMoreProposals()}
                label={t('loadMore', 'Load more')}
              />
            )}
          </>
        )}
      </Container>
    );
  }
);
