import React, { useEffect } from 'react';
//import { RouteComponentProps } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { CalculatorState, StepType } from '../../model/Calculator.model';
import { useNavigate } from 'react-router-dom';

interface Props {
  state: CalculatorState;
  //openProposal: (proposalId: number) => void; //go: (step: StepType) => void;
}

/*
export default ({
  state,
  go,
  match: {
    params: { proposalId },
  },
}: Props & RouteComponentProps<{ proposalId: string }>) => {*/

// eslint-disable-next-line react/display-name
export default ({ state }: Props) => {
  const navigate = useNavigate();
  const { proposalId } = useParams<{ proposalId: string }>(); // Fix: Change the type of proposalId to string
  console.log('proposal ID from Expire.tsx', proposalId);

  const openProposal = async (proposalId?: number) => {
    console.log('proposal ID moved from App.tsx to Expire.tsx', proposalId);
    navigate(
      `/app/calculator/reset/${
        typeof proposalId === 'number' ? proposalId : ''
      }`
    );
  };
  useEffect(() => {
    (async () => {
      //state.reset(); //reset dash
      await state.initialize(
        //proposalId === undefined ? undefined : +proposalId
        proposalId === undefined ? undefined : Number(proposalId) //initialise with current proposal
      );
      console.log(
        'proposal ID from Expire.tsx just before App',
        Number(proposalId)
      );
      openProposal(Number(proposalId)); //Go to the proposal

      //go(state.currentStep);
    })();
  }, []);

  return <Loader text={''} />;
};
