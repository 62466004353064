import React, { ReactElement } from 'react';

export function ToggleIcon(): ReactElement {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5Z"
        fill="#00FFB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81186 9.1138C6.97051 8.95515 7.22773 8.95515 7.38638 9.1138L11.1746 12.902C11.2866 13.014 11.3535 13.0805 11.407 13.1259C11.4397 13.1536 11.4556 13.1631 11.4601 13.1654C11.4862 13.1735 11.5141 13.1735 11.5402 13.1654C11.5447 13.1631 11.5606 13.1536 11.5933 13.1259C11.6468 13.0805 11.7137 13.014 11.8257 12.902L15.6139 9.1138C15.7726 8.95515 16.0298 8.95515 16.1884 9.1138C16.3471 9.27245 16.3471 9.52967 16.1884 9.68832L12.4002 13.4765L12.3902 13.4866C12.2916 13.5852 12.201 13.6758 12.1191 13.7453C12.0308 13.8203 11.9262 13.8943 11.7931 13.9376C11.6027 13.9994 11.3976 13.9994 11.2072 13.9376C11.0741 13.8943 10.9695 13.8203 10.8812 13.7453C10.7993 13.6758 10.7087 13.5852 10.6101 13.4866L10.6001 13.4765L6.81186 9.68832C6.65321 9.52967 6.65321 9.27245 6.81186 9.1138ZM11.5389 13.1661C11.5389 13.166 11.5392 13.1659 11.5397 13.1657C11.5391 13.166 11.5389 13.1661 11.5389 13.1661ZM11.4614 13.1661C11.4614 13.1661 11.4612 13.166 11.4606 13.1657C11.4611 13.1659 11.4614 13.166 11.4614 13.1661Z"
        fill="#000028"
      />
    </svg>
  );
}
