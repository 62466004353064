/* tslint:disable */
/* eslint-disable */
/**
 * Customer Support Service API
 * MySFS Customer Support Services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'body'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Comment
     */
    'uploads'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CustomFieldValue
 */
export interface CustomFieldValue {
    /**
     * 
     * @type {number}
     * @memberof CustomFieldValue
     */
    'id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomFieldValue
     */
    'value'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Requester
 */
export interface Requester {
    /**
     * 
     * @type {string}
     * @memberof Requester
     */
    'name'?: string;
}
/**
 * Represents a ticket
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * The id of the ticket.
     * @type {number}
     * @memberof Ticket
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'subject'?: string;
    /**
     * 
     * @type {Comment}
     * @memberof Ticket
     */
    'comment': Comment;
    /**
     * 
     * @type {Array<CustomFieldValue>}
     * @memberof Ticket
     */
    'customFields'?: Array<CustomFieldValue>;
    /**
     * 
     * @type {Requester}
     * @memberof Ticket
     */
    'requester'?: Requester;
    /**
     * 
     * @type {Array<object>}
     * @memberof Ticket
     */
    'followers'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'ticketFormId'?: number;
}
/**
 * Represents a ticket request
 * @export
 * @interface TicketDetails
 */
export interface TicketDetails {
    /**
     * 
     * @type {Ticket}
     * @memberof TicketDetails
     */
    'ticket'?: Ticket;
}
/**
 * 
 * @export
 * @interface Upload
 */
export interface Upload {
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'token'?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Upload
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface UploadDetails
 */
export interface UploadDetails {
    /**
     * 
     * @type {Upload}
     * @memberof UploadDetails
     */
    'upload'?: Upload;
}

/**
 * TicketApi - axios parameter creator
 * @export
 */
export const TicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this operation to create a new ticket.
         * @summary create a new customer support service ticket.
         * @param {TicketDetails} [ticketDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (ticketDetails?: TicketDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketApi - functional programming interface
 * @export
 */
export const TicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this operation to create a new ticket.
         * @summary create a new customer support service ticket.
         * @param {TicketDetails} [ticketDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(ticketDetails?: TicketDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(ticketDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketApi - factory interface
 * @export
 */
export const TicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketApiFp(configuration)
    return {
        /**
         * Use this operation to create a new ticket.
         * @summary create a new customer support service ticket.
         * @param {TicketDetails} [ticketDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(ticketDetails?: TicketDetails, options?: any): AxiosPromise<void> {
            return localVarFp.createTicket(ticketDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
export class TicketApi extends BaseAPI {
    /**
     * Use this operation to create a new ticket.
     * @summary create a new customer support service ticket.
     * @param {TicketDetails} [ticketDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public createTicket(ticketDetails?: TicketDetails, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).createTicket(ticketDetails, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this operation to upload a file
         * @summary Upload a support document
         * @param {any} file The document
         * @param {string} name name of the document with file extension.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (file: any, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocument', 'file', file)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('uploadDocument', 'name', name)
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this operation to upload a file
         * @summary Upload a support document
         * @param {any} file The document
         * @param {string} name name of the document with file extension.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(file: any, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(file, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * Use this operation to upload a file
         * @summary Upload a support document
         * @param {any} file The document
         * @param {string} name name of the document with file extension.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(file: any, name: string, options?: any): AxiosPromise<UploadDetails> {
            return localVarFp.uploadDocument(file, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * Use this operation to upload a file
     * @summary Upload a support document
     * @param {any} file The document
     * @param {string} name name of the document with file extension.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadDocument(file: any, name: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadDocument(file, name, options).then((request) => request(this.axios, this.basePath));
    }
}


