import { Box } from '@material-ui/core';
import React from 'react';
import { AssetArray } from '../../../model/Dashboard.model';
import { Asset, AssetType } from '../../../tools/SiemensApi';

interface Props {
  assets: AssetArray | null;
  getAssetType: (assetTypeId: number) => AssetType | undefined;
}

export const Assets = ({ assets, getAssetType }: Props) => (
  <>
    {assets && assets.length > 0 ? (
      assets.map((asset: Asset) => (
        <Box key={asset.id!}>
          {asset.assetSubType &&
            asset.assetSubType.assetTypeId &&
            getAssetType(asset.assetSubType.assetTypeId) && (
              <Box>{getAssetType(asset.assetSubType.assetTypeId)!.name}</Box>
            )}
          {asset.assetSubType && asset.assetSubType.name && (
            <Box>{asset.assetSubType.name}</Box>
          )}
          {asset.description && (
            <Box>
              {asset.quantity || 0} x {asset.description}
            </Box>
          )}
        </Box>
      ))
    ) : (
      <Box>T.B.C.</Box>
    )}
  </>
);
