import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import theme from '../../constants/theme';
import { AppState } from '../../state';
import { Introducer, Office } from '../../tools/SiemensApi';
import Settings from './Settings';
import { SiemensLogoSmall } from '../../images/SiemensLogo';
import { HamburgerIcon } from '../../images/HamburgerIcon';
import { ToggleIcon } from '../../images/ToggleIcon';

const useStyles = makeStyles<
  {},
  {
    isMyPropsPage: boolean;
    isHealthineers: boolean;
    settingsDialogOpen: boolean;
  }
>({
  background: ({ isHealthineers }) => ({
    '& .logo': {
      '& img': {
        width: 165,
      },
    },
    '& .menuIcon': {
      display: isHealthineers ? 'none' : 'block',
      height: 25,
      left: 10,
      width: 40,
    },
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      '& .menuIcon': {
        display: 'none',
      },
      justifyContent: 'flex-end',
      minWidth: 300,
    },
  }),
  icons: {
    display: 'flex',
    gap: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  introducer: ({ settingsDialogOpen }) => ({
    '& .introducerName': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& svg': {
      transform: settingsDialogOpen ? 'rotate(180deg)' : '',
    },
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 700,
    gap: 10,
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'unset',
    },
    textAlign: 'right',
  }),
  root: {
    '& > *': {
      flex: 1,
    },
    backgroundColor: '#000028',
    fontSize: '1.5rem',
    height: 70,
    margin: '0 auto',
    padding: '0 24px',
    position: 'relative',
    width: '100%',
  },
  settings: {
    position: 'fixed',
    right: 0,
    top: 70,
    width: '100%',
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
    },
  },
});

interface Props {
  resetApp: (introducer?: Introducer, office?: Office) => void;
  state: AppState;
  handleDrawerToggle: () => void;
  isHealthineers: boolean;
}

export default observer(
  ({ state, resetApp, handleDrawerToggle, isHealthineers }: Props) => {
    const location = useLocation();
    const isMyProps = location.pathname.startsWith('/app/myprops');
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const classes = useStyles({
      isHealthineers,
      isMyPropsPage: isMyProps,
      settingsDialogOpen,
    });

    const closeSettings = () => {
      setSettingsDialogOpen(false);
    };

    return (
      <div className={classes.root}>
        <div className={classes.background}>
          <div className={classes.icons}>
            <div className={'menuIcon'} onClick={handleDrawerToggle}>
              <HamburgerIcon />
            </div>
            <SiemensLogoSmall />
          </div>
          {state.introducer && state.office && (
            <div>
              <div
                className={classes.introducer}
                onClick={() => {
                  setSettingsDialogOpen(!settingsDialogOpen);
                }}
              >
                <span className="introducerName">{state.introducer.name}</span>
                <ToggleIcon />
              </div>
              {settingsDialogOpen && (
                <div className={classes.settings}>
                  <Settings
                    introducer={state.introducer}
                    office={state.office}
                    reset={resetApp}
                    state={state.calculator}
                    close={closeSettings}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
