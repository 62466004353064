import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_EN from './translation_EN.json';
import translation_NO from './translation_NO.json';
import translation_SE from './translation_SE.json';
import translation_FI from './translation_FI.json';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: { translations: translation_EN },
    fi: { translations: translation_FI },
    nb: { translations: translation_NO },
    sv: { translations: translation_SE },
  },
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
