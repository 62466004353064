import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import React, { useState } from 'react';
import theme from '../constants/theme';

const useStyles = makeStyles({
  root: {
    '& .MuiFormControlLabel-label': { padding: theme.spacing(0), fontSize: 16 },
    margin: '16px 0 16px 0',
  },
});

interface Props {
  label?: React.ReactNode;
}

export default ({
  className,
  label,
  disabled,
  onClick,
  ...props
}: CheckboxProps & Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormControlLabel
      classes={useStyles()}
      className={classNames(className)}
      control={
        <Switch
          className={classNames({ isLoading })}
          disabled={disabled || isLoading}
          color={'primary'}
          onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
            if (onClick) {
              setIsLoading(true);
              await new Promise((resolve) => resolve(onClick(event)));
              setIsLoading(false);
            }
          }}
          {...props}
        />
      }
      label={label || ''}
    />
  );
};
