import auth0, { Auth0DecodedHash, WebAuth } from 'auth0-js';
import config, { Auth0Config } from '../config';

export interface Authentication {
  accessToken: string;
  expiryTime: number;
}

const createWebAuth = (options: Auth0Config): WebAuth =>
  new auth0.WebAuth({
    audience: options.audience,
    clientID: options.clientId,
    domain: options.domain,
    redirectUri: options.callbackUri,
    responseType: options.responseType,
    scope: options.scope,
  });

export const authorize = (): void => {
  const webAuth = createWebAuth(config.auth0);
  webAuth.authorize({ connection: config.auth0.connection });
};

export const auth0LogOut = (url: string): void => {
  const webAuth = createWebAuth(config.auth0);
  webAuth.logout({
    clientID: config.auth0.clientId,
    federated: true,
    returnTo: url + '/logout',
  });
};

const parseHash = (hash: string): Promise<Auth0DecodedHash | null> => {
  const auth = createWebAuth(config.auth0);
  return new Promise((resolve, reject) => {
    auth.parseHash({ hash }, (err, tokenPayload) => {
      if (err) {
        reject(err);
      } else {
        resolve(tokenPayload);
      }
    });
  });
};

export const handleAuthentication = async (
  hash: string
): Promise<Authentication> => {
  if (/access_token|error/.test(hash)) {
    const authResult = await parseHash(hash);
    if (
      authResult &&
      typeof authResult.accessToken === 'string' &&
      authResult.expiresIn
    ) {
      return {
        accessToken: authResult.accessToken,
        expiryTime: authResult.expiresIn * 1000 + Date.now(),
      };
    } else {
      throw new Error('wrong authResult');
    }
  } else {
    throw new Error('wrong hash');
  }
};
