import { Card, Collapse, IconButton } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles<{}, { number?: number }>({
  content: {
    marginBottom: 16,
  },
  header: {
    background: '#fff',
    display: 'flex',
    marginBottom: 20,
    marginTop: 20,
  },
  numberBadge: {
    background: '#000028',
    borderRadius: 37,
    color: '#fff',
    fontSize: 20,
    fontWeight: 700,
    height: 39,
    paddingTop: 5,
    textAlign: 'center',
    width: 39,
  },
  root: {
    '& .MuiInput-root': {
      backgroundColor: '#fff',
      border: '1px solid #D1D5DB',
      borderRadius: 6,
    },
    '& .MuiInputLabel-formControl': {
      color: '#000028',
      fontSize: 15,
    },
    '& .MuiTextField-root': {
      margin: 0,
      marginBottom: 10,
    },
    borderRadius: 5,
    margin: '8px auto',
    padding: '0 25px',
    width: '100%',
  },
  title: ({ number }) => ({
    alignItems: 'center',
    color: '#000',
    display: 'flex',
    fontSize: 24,
    fontWeight: 700,
    marginLeft: number ? 15 : 0,
  }),
});

interface Props {
  title?: string;
  number?: number;
  style?: string;
  allowExpand?: boolean;
  expanded?: boolean;
}

const Panel = ({
  title,
  number,
  allowExpand,
  expanded,
  children,
  ...rest
}: Props & CardProps) => {
  const classes = useStyles({ number });
  const [expand, setExpand] = useState(true);
  useEffect(() => {
    if (allowExpand) {
      setExpand(expanded);
    }
  }, [expanded]);

  return (
    <Card {...rest} className={classes.root}>
      <div className={classes.header}>
        {number && <div className={classes.numberBadge}>{number}</div>}
        {title && <div className={classes.title}>{title}</div>}
        {allowExpand && (
          <IconButton onClick={() => setExpand(!expand)}>
            <ArrowDropDown />
          </IconButton>
        )}
      </div>
      <Collapse in={expand}>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </Card>
  );
};

export default Panel;
