import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CalculatorState, StepType } from '../../model/Calculator.model';
import ReactGA from 'react-ga';
import { AppState } from '../../state';
import { getParent } from 'mobx-state-tree';

interface Props {
  state: CalculatorState;
  go: (step?: StepType) => void;
}

export default ({ state, go }: Props) => {
  const { proposalId } = useParams();
  const appState = getParent<AppState>(state);
  useEffect(() => {
    (async () => {
      appState.setIsLoading(true);
      state.reset();
      await state.initialize(
        proposalId === undefined ? undefined : +proposalId
      );
      appState.setIsLoading(false);
      // Log "start new deal" click event in GA and not on opening an existing proposal
      if (!proposalId) {
        const newDealLabel = `gid:${
          getParent<AppState>(state).user!.gid
        }|introducerId:${getParent<AppState>(state).introducer!.bpId}`;
        ReactGA.event({
          action: 'Start a new deal',
          category: 'New deal',
          label: newDealLabel,
        });
      }
      // state.setCurrentStep('asset');
      go();
    })();
  }, []);

  return <></>;
};
