import { Navigate } from 'react-router-dom';
import React from 'react';

interface Props {
  isAuthenticated: boolean;
  children: React.ReactElement;
}

export default ({ isAuthenticated, children }: Props) => {
  if (!isAuthenticated) {
    return <Navigate to={'/'} />;
  } else {
    return children;
  }
};
