import React, { ReactElement } from 'react';
import currencies from '../constants/currencies';
import { Currency } from '../tools/SiemensApi';
import NumberInput, { Props as NumberInputProps } from './NumberInput';

export interface Props extends NumberInputProps {
  currency: Currency;
}
const CurrencyInput = ({ currency, ...rest }: Props): ReactElement => {
  const currencyFormat = currency.format!;
  const symbol = currencies[currency.currencyCode!].symbol;
  return <NumberInput prefix={symbol} format={currencyFormat} {...rest} />;
};
export default CurrencyInput;
