import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (value: string) => void;
}

const Search = ({
  onChange,
  ...props
}: Props & Omit<TextFieldProps, 'onChange'>) => {
  const { t } = useTranslation();
  return (
    // @ts-ignore
    <TextField
      label={t('search', 'Search')}
      placeholder={t('enterCustomerName', 'Enter customer name')}
      onChange={(event) => onChange(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="search"
      {...props}
    />
  );
};
export default Search;
