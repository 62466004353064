import { Box, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';
import React, { useState } from 'react';

interface OwnProps {
  prefix?: string;
  suffix?: string;
  validate?: (value: string) => string;
  deferValidation?: boolean;
  readOnly?: boolean;
}

export type Props = OwnProps & Omit<TextFieldProps, 'variant'>;

const Input = ({
  className,
  prefix,
  suffix,
  validate,
  deferValidation,
  value,
  readOnly,
  onChange,
  onClick,
  onBlur,
  ...props
}: Props): React.ReactElement<Props> => {
  const [hasChanged, setHasChanged] = useState(false);
  const [hasBlurred, setHasBlurred] = useState(false);
  const validationMessage =
    validate && (!deferValidation || hasChanged || hasBlurred)
      ? validate(value as string)
      : '';
  return (
    <TextField
      className={classNames(className, { readOnly })}
      value={value}
      helperText={validationMessage}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        className: classNames({ readOnly }),
        endAdornment: suffix ? (
          <span
            style={{
              left: `calc(${
                ((value as number) || 0).toString().length / 1.618
              }em + 19px)`,
              pointerEvents: 'none',
              position: 'absolute',
              top: 13,
            }}
          >
            {suffix}
          </span>
        ) : (
          ''
        ),
        onBlur: (event) => {
          if (!hasBlurred) {
            setHasBlurred(true);
          }
          if (onBlur) {
            onBlur(event as any);
          }
        },
        onClick: (event: React.MouseEvent<HTMLInputElement>) => {
          if (readOnly) {
            event.currentTarget.blur();
          }
          if (onClick) {
            onClick(event);
          }
        },
        readOnly,
        startAdornment: prefix ? <Box className={'prefix'}>{prefix}</Box> : '',
      }}
      inputProps={{
        tabIndex: readOnly ? -1 : 0,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const changedValue = event.target.value;
        if (!hasChanged && changedValue !== value) {
          setHasChanged(true);
        }
        if (onChange) {
          onChange(event);
        }
      }}
      {...props}
    />
  );
};
export default Input;
