import Ajv from 'ajv';
import draft04 from 'ajv/lib/refs/json-schema-draft-04.json';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { get } from '../tools';
import {
  OpenAPIObject,
  OperationObject,
  ParameterLocation,
  ParameterObject,
  ReferenceObject,
  SchemaObject,
  ServerObject,
} from 'openapi3-ts';
import { AppState } from '../state';

import json from '../../install/openapi.json';

const openapi = JSON.parse(JSON.stringify(json)) as OpenAPIObject;

const isRef = (o: any): boolean => {
  return typeof o === 'object' && o !== null && o.$ref;
};

const resolveRef = (o: ReferenceObject): SchemaObject => {
  const path = o.$ref.slice(2).replace(/\//g, '.');
  return get(openapi, path);
};

// replaces all referenceObjects with what they refer to.
const resolve = (o: any) => {
  if (Array.isArray(o)) {
    o.forEach((item, index) => {
      if (isRef(item)) {
        o[index] = resolveRef(item);
      }
      resolve(item);
    });
  } else if (typeof o === 'object' && o !== null) {
    for (const [key, value] of Object.entries(o)) {
      if (isRef(value)) {
        o[key] = resolveRef(value as ReferenceObject);
      }
      resolve(value);
    }
  }
};

resolve(openapi);

const ajv = new Ajv({ meta: draft04, schemaId: 'id' });
ajv.addFormat(
  'int32',
  (value) =>
    typeof value === 'number' &&
    value === Math.round(value) &&
    value >= 0 &&
    value <= 2 ** 32 - 1
);
ajv.addFormat(
  'double',
  (value) => typeof value === 'number' && Number.isFinite(value)
);

export const validate = (testObject: any, objectName: string): boolean => {
  //return true;
  return ajv.validate(
    {
      ...((openapi.components!.schemas as any)![objectName] as SchemaObject),
      components: openapi.components,
    },
    testObject
  ) as boolean;
};

const toKebabCase = (x: string): string =>
  x.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
export const toCamelCase = (x: string): string =>
  x
    .replace(/^(.)/g, (match, letter) => letter.toLowerCase())
    .replace(/-(.)/g, (match, letter) => letter.toUpperCase());

interface Parameters {
  [key: string]: number | string;
}

const parameterNameMapping: { [key: string]: string } = {
  ifMatch: 'If-Match',
};

export const createRequestParameters = <P extends ApiParameters>(
  parameters: P
): Parameters => {
  const result: Parameters = {};
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key) && parameters[key] !== undefined) {
      const value = parameters[key];
      if (typeof value === 'number' || typeof value === 'string') {
        const newKey = parameterNameMapping[key] || toKebabCase(key);
        result[newKey] = value;
      }
    }
  }
  return result;
};

export type Method = 'get' | 'put' | 'post' | 'delete' | 'patch';

export interface ApiParameters {
  // needed to define an empty object,
  // using an empty interface would define the type as {[key:any]:any}
  never?: never;
}

export const formatPath = (
  pathFormat: string,
  parameters: ApiParameters
): string => {
  let formattedPath: string = pathFormat;
  for (const [key, parameter] of Object.entries(parameters)) {
    const encodedValue = encodeURIComponent('' + parameter);
    formattedPath = formattedPath.replace(
      new RegExp(`\{${key}\}`, 'g'),
      encodedValue
    );
  }
  return formattedPath;
};

let state: AppState;
export const setState = (newState: AppState) => (state = newState);

export class Api {
  public openApiObject: OpenAPIObject;
  public server: ServerObject;
  public state: AppState;

  public constructor(url: string) {
    if (!openapi || !openapi.servers || !state) {
      throw new Error();
    }
    const server = { url };
    //   openapi.servers!.find(
    //   (serverObject: ServerObject) => serverObject.url === url
    // );
    // if (!server) {
    //   throw new Error();
    // }
    this.openApiObject = openapi as OpenAPIObject;
    this.server = server;
    this.state = state;
  }

  public async request<P extends ApiParameters, B, R>(
    operationId: string,
    parameters: P,
    requestBody: B
  ): Promise<AxiosResponse<R>> {
    let operation: OperationObject | null = null;
    let path: string | null = null;
    let method: Method | null = null;
    for (const [pathKey] of Object.entries(openapi.paths)) {
      const pathItem = this.openApiObject.paths[pathKey];
      for (const [methodKey] of Object.entries(pathItem)) {
        const operationItem = pathItem[methodKey];
        if (operationItem.operationId === operationId) {
          path = pathKey;
          method = methodKey as Method;
          operation = operationItem;
        }
      }
    }
    if (!path || !method || !operation) {
      throw new Error();
    }

    const requestParameters: Parameters = createRequestParameters<P>(
      parameters
    );
    const parametersIn: { [key in ParameterLocation]: Parameters } = {
      cookie: {},
      header: {},
      path: {},
      query: {},
    };

    if (operation.parameters) {
      for (const p of operation.parameters) {
        const parameterObject: ParameterObject = p as ParameterObject;
        parametersIn[parameterObject.in][parameterObject.name] =
          requestParameters[parameterObject.name];
      }
    }
    // if (operation.parameters) {
    //   for (const p of operation.parameters) {
    //     const parameter = p as ParameterObject;
    //     if (parameter.name in requestParameters && parameter.in === 'query') {
    //       queryParameters[parameter.name] = requestParameters[parameter.name];
    //     }
    //   }
    // }
    const url = this.server.url + formatPath(path, parametersIn.path).slice(1);
    if (!this.state.authentication || !this.state.isAuthenticated) {
      throw new Error('no authentication');
    }
    const headers = {
      Authorization: 'Bearer ' + this.state.authentication.accessToken,
      accept: 'application/json',
      ...parametersIn.header,
    };
    const config: AxiosRequestConfig = {
      data: requestBody,
      headers,
      method,
      url,
    };
    config.params = parametersIn.query;
    return axios.request<R>(config);
  }
}
