import { clone, flow, getParent, Instance, types } from 'mobx-state-tree';
import { AppState } from '../state';
import {
  ProposalModel,
  ProposalCreateModel,
  CalculationRequestModel,
  uploadDocument,
  DocumentUploadEntity,
  DocumentUploadEntityModel,
  createProposal,
  findCustomersOfIntroducer,
  FindCustomersOfIntroducerParametersModel,
  CustomerSearchResultItem,
  CustomerSearchResultItemModel,
  MarketProduct,
  createLoan,
  LoanModel,
  ProposalDocumentModel,
  getUploadedDocuments,
  getCalculationConfiguration,
  GetCalculationConfigurationParametersModel,
} from '../tools/SiemensApi';
import { BlockDataInterface } from '../containers/SpecialPages/Block/Block';
import config from '../config';
import {
  Configuration,
  ConfigurationParameters,
} from '../../../generated-sources/dms-api';
import {
  TicketApi,
  UploadApi as ZendeskUploadApi,
} from '../../../generated-sources/support-api';
import data from './../containers/SpecialPages/Block/data.json';

export const zendeskTicketAPI = (token: string): TicketApi => {
  const apiConfigParams: ConfigurationParameters = {
    accessToken: token,
    basePath: config.backendApiUrl + '/support/v1',
  };

  return new TicketApi(new Configuration(apiConfigParams));
};

export const zendeskUploadAPI = (token: string): ZendeskUploadApi => {
  const apiConfigParams: ConfigurationParameters = {
    accessToken: token,
    basePath: config.backendApiUrl + '/support/v1',
  };

  return new ZendeskUploadApi(new Configuration(apiConfigParams));
};

const ProposalSummaryArrayModel = types.array(ProposalModel);

const BlockDataModel = types.model('BlockDataModel', {
  amountFinanced: types.optional(
    types.union(types.string, types.undefined, types.null), //it doesn't like amount finance is a string even though it returns a string
    undefined
  ),
  numberOfAgreements: types.optional(
    types.union(types.string, types.undefined, types.null),
    undefined
  ),
  replacementPaperLabel: types.optional(
    types.union(types.string, types.undefined, types.null),
    undefined
  ), //added replacement paper label as that was coming out of the API.
  term: types.optional(
    types.union(types.string, types.undefined, types.null),
    undefined
  ),
});

export type ProposalSummaryArray = Instance<typeof ProposalSummaryArrayModel>;

export const BlockModel = types
  .model({
    blockData: types.maybeNull(BlockDataModel),
    customer: types.maybeNull(CustomerSearchResultItemModel),
    documents: types.maybeNull(types.array(ProposalDocumentModel)),
    hasAdditionalDocuments: types.maybeNull(types.boolean),
    listingSchedule: types.maybeNull(DocumentUploadEntityModel),
    proposal: types.maybeNull(ProposalModel),
    ticketCreated: types.maybeNull(types.boolean),
    zendeskUploadId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get hasFinanceAgreement(): boolean {
      if (self.documents) {
        return !!self.documents.find((o) => o.documentType.id === 458);
      } else {
        return false;
      }
    },
  }))
  .actions((self) => ({
    reset() {
      Object.assign(self, blockDefaults);
    },
    setCustomer(customer: CustomerSearchResultItem): void {
      self.customer = customer;
    },
    setHasAdditionalDocuments(value: boolean): void {
      self.hasAdditionalDocuments = value;
    },
  }))
  .actions((self) => ({
    initializeProposal: flow(function* initializeProposal(
      data: BlockDataInterface
    ) {
      const parentState = getParent<AppState>(self, 1);

      if (!parentState.calculator.calculation) {
        const { data: response } = yield getCalculationConfiguration(
          GetCalculationConfigurationParametersModel.create({
            introducerId: parentState.introducer.id,
            marketproductId: 1,
          })
        );
        parentState.calculator.setCalculationConfigResponse(response);
      }

      const calculatorState = parentState.calculator;
      const calculation = parentState.calculator.calculation;

      const paymentJSON = JSON.parse(JSON.stringify(calculation.sheet.payment));

      paymentJSON.paymentLines[0].numberOfPayments = +data.term;

      self.ticketCreated = false;

      if (data) {
        self.blockData = data;

        calculatorState.updateSheet({
          assetValue: +data.amountFinanced!,
          payment: paymentJSON,
          term: +data.term!,
        });
      }

      const blockDiscounting = (calculation!.config!.marketProductsConfig
        .validValues! as Array<MarketProduct>).find(
        (product) => product.name === 'Block Discounting'
      );
      if (
        blockDiscounting &&
        blockDiscounting.id !== calculation!.sheet!.marketProductId
      ) {
        calculatorState.updateSheet({
          marketProduct: clone(blockDiscounting),
          marketProductId: blockDiscounting.id,
        });
      }

      const requestBody = ProposalCreateModel.create({
        calculation: CalculationRequestModel.create({
          operation: 'CALC_RENTAL',
          sheet: clone(parentState.calculator.calculation!.sheet!) as any,
        }),
        customerBpRef: clone(self.customer!.businessPartnerRef) as any,
        officeId: parentState.office.id,
      });

      const proposal: any = yield createProposal(
        {
          introducerId: parentState.introducer.id,
        },
        requestBody
      );

      if (proposal) {
        self.proposal = proposal.data;
        const ifMatch = proposal.headers.etag;

        yield createLoan(
          {
            ifMatch,
            introducerId: parentState.introducer.id,
            proposalId: self.proposal!.id,
          },
          LoanModel.create({
            loanPurpose: {
              id: 12,
              name: 'Other',
            },
            value: +data.amountFinanced! || 0,
          })
        );
      }
    }),

    getCustomer: flow(function* uploadDoc() {
      const introducer = data.introducers.find(
        (x) => x['bpId'] === getParent<AppState>(self).introducer!.bpId
      );
      if (introducer) {
        const customer = CustomerSearchResultItemModel.create({
          businessPartnerRef: {
            companyType: 'LIMITED',
            registrationNumber: introducer.regNumber,
          },
        });
        self.setCustomer(customer);
        return;
      }

      let response;
      const introducerName = getParent<AppState>(self).introducer!.name;
      let promiseCall: ReturnType<
        typeof CustomerSearchResultItem
      > | null = null;

      try {
        promiseCall = findCustomersOfIntroducer(
          FindCustomersOfIntroducerParametersModel.create({
            companyType: 'LIMITED',
            introducerId: getParent<AppState>(self).introducer!.id,
            name: introducerName,
          })
        );

        response = yield promiseCall;
      } catch (error) {
        response = error.response;
      }

      if (response && response.data) {
        if (response.data.length > 1) {
          for (let i = 0; i < response.data.length; i++) {
            if (
              response.data[i].name
                .toLowerCase()
                .replace('limited', '')
                .replace('ltd', '')
                .replace('plc', '')
                .trim() ==
              introducerName
                .toLowerCase()
                .replace('limited', '')
                .replace('ltd', '')
                .replace('plc', '')
                .trim()
            ) {
              self.setCustomer(response.data[i]);
            }
          }
        } else {
          self.setCustomer(response.data[0]);
        }
      }
    }),
  }))
  .actions((self) => ({
    uploadDocSiesmart: flow(function* uploadDoc(payload: DocumentUploadEntity) {
      let document;
      try {
        document = yield uploadDocument(
          {
            introducerId: getParent<AppState>(self).introducer.id,
            proposalId: self.proposal.id,
          },
          payload
        );
      } catch (error) {
        //return false;
        return Promise.reject(new Error('Failed with ' + error));
      }

      if (document) {
        self.listingSchedule = payload;
        const uploadedDocuments: any = yield getUploadedDocuments({
          introducerId: getParent<AppState>(self).introducer.id,
          proposalId: self.proposal.id,
        });

        if (uploadedDocuments && uploadedDocuments.data) {
          self.documents = uploadedDocuments.data;
        }
      }
      return true;
    }),
    uploadDocZendesk: flow(function* uploadDoc(file: string, name: string) {
      if (!self.listingSchedule) {
        return;
      }
      let document;

      try {
        document = yield zendeskUploadAPI(
          getParent<AppState>(self).authentication.accessToken
        ).uploadDocument(file, name);
      } catch (error) {
        return false;
      }
      if (document) {
        self.zendeskUploadId = document.data.upload.token;
        return true;
      }
    }),
    createTicket: flow(function* createTicket(payload: any) {
      const ticket = yield zendeskTicketAPI(
        getParent<AppState>(self).authentication.accessToken
      ).createTicket(payload);
      if (ticket) {
        self.ticketCreated = true;
      }
    }),
  }));

export type BlockState = Instance<typeof BlockModel>;

export const blockDefaults = {
  blockData: null,
  customer: null,
  documents: null,
  hasAdditionalDocuments: null,
  listingSchedule: null,
  proposal: null,
  ticketCreated: null,
  zendeskUploadId: null,
};
