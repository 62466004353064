import { getParent, Instance, types } from 'mobx-state-tree';
import { isEqual } from '../tools';
import {
  BusinessPartnerRef,
  BusinessPartnerRefModel,
  CustomerSearchResultItem,
  FindCustomersOfIntroducerResponseModel,
  FindCustomersOfIntroducerResponseType,
} from '../tools/SiemensApi';
import { CalculatorState } from './Calculator.model';
import { AppState } from '../state';

export const ErrorTypeModel = types.maybeNull(
  types.enumeration(['TooManyResults', 'NoHits', 'InvalidParameter_name'])
);

export type ErrorType = Instance<typeof ErrorTypeModel>;

const CompanyTypeQueryModel = types.enumeration(['LIMITED', 'NONLIMITED']);
export type CompanyTypeQuery = Instance<typeof CompanyTypeQueryModel>;

export const CustomerFormModel = types
  .model({
    companyTypeQuery: CompanyTypeQueryModel,
    customers: types.maybeNull(FindCustomersOfIntroducerResponseModel),
    errorType: ErrorTypeModel,
    nameQuery: types.string,
    postcodeQuery: types.string,
    registrationNumberQuery: types.string,
    salesContactPersonId: types.maybeNull(types.number),
    selectedBusinessPartnerRef: types.maybeNull(BusinessPartnerRefModel),
    sentCompanyTypeQuery: types.string,
    sentNameQuery: types.string,
    sentPostcodeQuery: types.string,
    sentRegistrationNumberQuery: types.string,
  })
  .views((self) => ({
    get customer(): CustomerSearchResultItem | null {
      return (
        (self.customers &&
          self.customers.find(
            (customer) =>
              !!customer.businessPartnerRef &&
              !!self.selectedBusinessPartnerRef &&
              isEqual(
                customer.businessPartnerRef,
                self.selectedBusinessPartnerRef
              )
          )) ||
        null
      );
    },
  }))
  .actions((self) => ({
    setCompanyType(companyTypeQuery: CompanyTypeQuery) {
      self.companyTypeQuery = companyTypeQuery;
    },
    setCustomer(customer: BusinessPartnerRef | null) {
      self.selectedBusinessPartnerRef = customer;
    },
    setCustomers(customers: FindCustomersOfIntroducerResponseType) {
      self.customers = customers;
    },
    setErrorType(errorType: ErrorType) {
      self.errorType = errorType;
    },
    setNameQuery(nameQuery: string) {
      self.nameQuery = nameQuery;
    },
    setPostcodeQuery(postcodeQuery: string) {
      self.postcodeQuery = postcodeQuery;
    },
    setRegistrationNumberQuery(registrationNumberQuery: string) {
      self.registrationNumberQuery = registrationNumberQuery;
    },
    setSalesPersonContactId(salesPersonContactId: number) {
      if (salesPersonContactId === 0) {
        const proposalObj = getParent<CalculatorState>(self).proposal!;

        const parentState = getParent<AppState>(self, 2);
        const salesPersonObj = parentState.salesContacts!.find(
          (introducerContact) =>
            introducerContact.firstName + ' ' + introducerContact.lastName ===
            parentState.user!.firstName! + ' ' + parentState.user!.lastName!
        );
        if (salesPersonObj != null) {
          salesPersonContactId = salesPersonObj.id!;
        }
        if (proposalObj != null && proposalObj.salesContact != null) {
          salesPersonContactId = proposalObj.salesContact.id!;
        }
      }
      self.salesContactPersonId = salesPersonContactId;
    },
    setSentCompanyTypeQuery(sentCompanyTypeQuery: CompanyTypeQuery) {
      self.sentCompanyTypeQuery = sentCompanyTypeQuery;
    },
    setSentNameQuery(sentNameQuery: string) {
      self.sentNameQuery = sentNameQuery;
    },
    setSentPostcodeQuery(sentPostcodeQuery: string) {
      self.sentPostcodeQuery = sentPostcodeQuery;
    },

    setSentRegistrationNumberQuery(sentRegistrationNumberQuery: string) {
      self.sentRegistrationNumberQuery = sentRegistrationNumberQuery;
    },
  }));

export type CustomerForm = Instance<typeof CustomerFormModel>;
