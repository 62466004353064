type Environment = 'development' | 'uat' | 'production' | 'digilabs';

export interface Auth0Config {
  connection?: string;
  domain: string;
  clientId: string;
  callbackUri: string;
  responseType: string;
  scope: string;
  audience: string;
}

export interface Config {
  allowSendDocuments: boolean;
  includeActiveDeals: boolean;
  includeDashboard: boolean;
  includeNotification: boolean;
  isUpgradeAllowed: boolean;
  isInvoiceAddressEdit: boolean;
  siesmartApiUrl: string;
  backendApiUrl: string;
  auth0: Auth0Config;
  env: Environment;
  analyticsId: string;
  routePath: string;
  btIntroducerId: number;
  appVersion: number;
  diIntroducerId: number;
  zendesk?: Zendesk;
}

interface Zendesk {
  agreementField: string;
  amountFinancedField: string;
  bpcRequestField: string;
  groupId: string;
  proposalIdField: string;
  requestField: string;
  salesChannelField: string;
  ticketFormId: string;
  konicaGroup: string;
}

interface EnvironmentConfig {
  allowSendDocuments: boolean;
  siesmartApiUrl: string;
  backendApiUrl: string;
  connection?: string;
  env: Environment;
  domain: string;
  authDomain: string;
  clientId: string;
  includeDashboard: boolean;
  includeNotification: boolean;
  isUpgradeAllowed: boolean;
  isInvoiceAddressEdit: boolean;
  analyticsId: string;
  routePath?: string;
  includeActiveDeals: boolean;
  btIntroducerId: number;
  diIntroducerId: number;
  zendesk?: Zendesk;
}

const developmentConfig: EnvironmentConfig = {
  allowSendDocuments: true,
  analyticsId: 'UA-133600798-1',
  authDomain: 'sfs-q-001.siemens-sfs.auth0app.com',
  backendApiUrl: 'https://api-test.sfs.siemens.cloud/mysfs',
  btIntroducerId: 2214894,
  clientId: 'mFXmrAs6S8SOPwGe3dfhv868X0Tv0mrH',
  connection: 'main-tenant-oidc',
  diIntroducerId: 3768969,
  domain: '',
  env: 'development',
  includeActiveDeals: true,
  includeDashboard: true,
  includeNotification: false,
  isInvoiceAddressEdit: false,
  isUpgradeAllowed: true,
  siesmartApiUrl: 'https://eu-pre-api.siesmart.sfs.siemens.com/api/',
  zendesk: {
    agreementField: '360028637397',
    amountFinancedField: '360028514458',
    bpcRequestField: '360029633337',
    groupId: '360010573717',
    proposalIdField: '1900004049853',
    requestField: '1900004049753',
    salesChannelField: '360028637437',
    ticketFormId: '360003936677',
    konicaGroup: '360010573597',
  },
};

const environmentData: Array<EnvironmentConfig> = [
  developmentConfig,
  {
    allowSendDocuments: true,
    analyticsId: 'UA-140199142-1',
    authDomain: 'sfs-p-001.siemens-sfs.auth0app.com',
    backendApiUrl: 'https://api.sfs.siemens.cloud/mysfs',
    btIntroducerId: 2214894,
    clientId: 'Lslt0nyGDfFL2HxyUFLJe6xXB9A3XRBJ',
    connection: 'main-tenant-oidc',
    diIntroducerId: 3768969,
    domain: 'mysfs.siemens.com',
    env: 'production',
    includeActiveDeals: true,
    includeDashboard: true,
    includeNotification: false,
    isInvoiceAddressEdit: false,
    isUpgradeAllowed: false,
    siesmartApiUrl: 'https://api-eu.sfs.siemens.com/api/',
    zendesk: {
      agreementField: '114103540393',
      amountFinancedField: '360020670933',
      bpcRequestField: '360029936717',
      groupId: '360002389853',
      proposalIdField: '360005888357',
      requestField: '360013856553',
      salesChannelField: '114103770934',
      ticketFormId: '360000019274',
      konicaGroup: '360001116713',
    },
  },
  {
    allowSendDocuments: true,
    analyticsId: 'UA-133600798-1',
    authDomain: 'sfs-q-001.siemens-sfs.auth0app.com',
    backendApiUrl: 'https://api-test.sfs.siemens.cloud/mysfs',
    btIntroducerId: 2214894,
    clientId: 'mFXmrAs6S8SOPwGe3dfhv868X0Tv0mrH',
    connection: 'main-tenant-oidc',
    diIntroducerId: 3768969,
    domain: 'uat.siesmart.info',
    env: 'uat',
    includeActiveDeals: true,
    includeDashboard: true,
    includeNotification: false,
    isInvoiceAddressEdit: false,
    isUpgradeAllowed: false,
    siesmartApiUrl: 'https://eu-pre-api.siesmart.sfs.siemens.com/api/',
    zendesk: {
      agreementField: '360028637397',
      amountFinancedField: '360028514458',
      bpcRequestField: '360029633337',
      groupId: '360010573717',
      proposalIdField: '1900004049853',
      requestField: '1900004049753',
      salesChannelField: '360028637437',
      ticketFormId: '360003936677',
      konicaGroup: '360010573597',
    },
  },
  {
    allowSendDocuments: true,
    analyticsId: 'UA-133600798-1',
    authDomain: 'sfs-q-001.siemens-sfs.auth0app.com',
    backendApiUrl: 'https://api-test.sfs.siemens.cloud/mysfs',
    btIntroducerId: 2214894,
    clientId: 'mFXmrAs6S8SOPwGe3dfhv868X0Tv0mrH',
    connection: 'main-tenant-oidc',
    diIntroducerId: 3768969,
    domain: 'mysfs-dev.siemens.com',
    env: 'digilabs',
    includeActiveDeals: true,
    includeDashboard: true,
    includeNotification: false,
    isInvoiceAddressEdit: false,
    isUpgradeAllowed: false,
    siesmartApiUrl: 'https://eu-pre-api.siesmart.sfs.siemens.com/api/',
    zendesk: {
      agreementField: '360028637397',
      amountFinancedField: '360028514458',
      bpcRequestField: '360029633337',
      groupId: '360010573717',
      proposalIdField: '1900004049853',
      requestField: '1900004049753',
      salesChannelField: '360028637437',
      ticketFormId: '360003936677',
      konicaGroup: '360010573597',
    },
  },
];

const detectEnv = (givenEnv?: string): EnvironmentConfig => {
  let environmentConfig: EnvironmentConfig | null | undefined;
  environmentConfig = environmentData.find((item) => item.env === givenEnv);
  if (!environmentConfig) {
    try {
      const hostname = location.hostname;
      environmentConfig = environmentData.find(
        (item) => item.domain === hostname
      );
    } catch (error) {
      environmentConfig = null;
    }
  }
  return environmentConfig || developmentConfig;
};

const env: EnvironmentConfig = detectEnv(process.env.ENV);

let origin;
try {
  origin = location.origin;
} catch (error) {
  origin = '';
}

const auth0: Auth0Config = {
  audience: 'https://siesmart.info/ev',
  callbackUri: origin + (env.routePath || '') + '/callback',
  clientId: env.clientId,
  connection: env.connection,
  domain: env.authDomain,
  responseType: 'token id_token',
  scope: 'openid',
};

const config: Config = {
  allowSendDocuments: env.allowSendDocuments,
  analyticsId: env.analyticsId,
  appVersion: 1.1,
  auth0,
  backendApiUrl: env.backendApiUrl,
  btIntroducerId: env.btIntroducerId,
  diIntroducerId: env.diIntroducerId,
  env: env.env,
  includeActiveDeals: env.includeActiveDeals,
  includeDashboard: env.includeDashboard,
  includeNotification: env.includeNotification,
  isInvoiceAddressEdit: env.isInvoiceAddressEdit,
  isUpgradeAllowed: env.isUpgradeAllowed,
  routePath: env.routePath || '',
  siesmartApiUrl: env.siesmartApiUrl,
  zendesk: env.zendesk,
};

export default config;
