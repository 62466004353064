export const COLORS = {
  aquaBlue: '#0acbec',
  black: '#000000',
  blueBayoux: '#48616C',
  brownGrey: '#9b9b9b',
  cerulean: '#049cd4',
  darkBlue: '#005B81',
  dashboardGradient: 'linear-gradient(to bottom right, #0acbec, #049cd4)',
  gradient: 'linear-gradient(to bottom right, #46BBC4, #009AD4)',
  green: '#40AAAA',
  greyishBrown: '#4a4a4a',
  label: '#384348',
  lightGrey: '#EBF0F4',
  mist: '#F2F5F7',
  orange: '#EB8C18',
  pressed: '#288989',
  scarlet: '#B40056',
  selected: '#a8d1ff',
  shadow: '0 2px 4px 0 rgba(119, 138, 154, 0.5)',
  slate: '#778A9A',
  tooltip: '#DB6A73',
  veryLightPink: '#eee',
  white: '#FFFFFF',
  wineBerry: '#5D1535',
  yellow: '#F2B318',
  deepBlue: '#000028',
  petrol: '#009999',
};

export const BREAKPOINTS = {
  tablet: 768,
};
export const MAX_WIDTH = 1024;

export const zIndexes = {
  selectDropDown: 9999,
};
