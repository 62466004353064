import { makeStyles } from '@material-ui/core';
import React, { HTMLProps } from 'react';

const useStyles = makeStyles({
  root: {
    '&, & *': {
      boxSizing: 'border-box',
    },
    alignItems: 'center',
    backgroundColor: '#F3F4F6',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
    //height: '100vh',
  },
});

export default ({ children }: HTMLProps<HTMLDivElement>) => {
  return <div className={useStyles().root}>{children}</div>;
};
