import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem, makeStyles } from '@material-ui/core';
import data from './data.json';
import theme from '../../../constants/theme';
import { MAX_WIDTH } from '../../../constants/style-constants';
import Panel from '../../../components/Panel';
import Button from '../../../components/Button';
import CreateQuotePDF from './CreateQuotePDF';
import { KonicaState } from '../../../model/Konica.model';
import ProposalSearch from './ProposalSearch';
import UploadDocs from './UploadDocs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';

interface Props {
  state: KonicaState;
}

interface lineItem {
  price: number;
  rate: number;
  rental: number;
  baseRate: number;
  subTotal: number;
  commission: number;
}

export interface StateInterface {
  customer: string;
  paymentsPerYear: number | null;
  term: number | null;
  dealType: string;
  commercialDealType: string;
  profile1: number | null;
  profile2: number | null;
  newEquipment: lineItem;
  software: lineItem;
  upgrade: lineItem;
  nonRvEquipment: lineItem;
  total: lineItem;
  onBaseNumber: number | null;
  framework: string;
  URN: string;
  equipment: string;
  lastSignatureDate: string;
  officeConfig: officeConfigItem;
}

export interface officeIdItem {
  environment: string;
  id: number;
}

export interface officeConfigItem {
  officeId: Array<officeIdItem>;
  name: string;
  commission: boolean;
  letter: boolean;
}

const newEquipment = {} as lineItem;
const software = {} as lineItem;
const upgrade = {} as lineItem;
const nonRvEquipment = {} as lineItem;
const total = {} as lineItem;
const officeConfig = {} as officeConfigItem;

const initialState = {
  URN: '',
  commercialDealType: '',
  customer: '',
  dealType: 'Commercial',
  equipment: '',
  framework: '',
  lastSignatureDate: '',
  newEquipment: newEquipment,
  nonRvEquipment: nonRvEquipment,
  officeConfig: officeConfig,
  onBaseNumber: null,
  paymentsPerYear: null,
  profile1: null,
  profile2: null,
  software: software,
  term: null,
  total: total,
  upgrade: upgrade,
};

const useStyles = makeStyles({
  calculator: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '10px',
  },
  content: {
    '& .framework': {
      marginTop: 16,
    },
    '& .officeName': {
      fontWeight: '600',
      margin: 10,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    maxWidth: MAX_WIDTH,
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      padding: '10px 25px',
    },
    position: 'relative',
    width: '100%',
  },
  dealType: {
    '& div': {
      '&.selected': {
        background: 'linear-gradient(90deg, #00ffb9 0%, #00e6dc 100.25%)',
      },
      alignItems: 'center',
      background: 'white',
      border: 'unset',
      borderRadius: '4px',
      boxSizing: 'border-box',
      color: '#000028',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '16px',
      fontWeight: 'bold',
      height: 40,
      justifyContent: 'center',
      lineHeight: '18px',
      maxWidth: '200px',
      padding: '12px',
      textAlign: 'center',
      userSelect: 'none',
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  main: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'inherit',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .row': {
      '& >div': {
        flex: 1,
      },
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    paddingBottom: '40vh',
    position: 'relative',
    width: '100%',
  },
  pdfButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rental: {
    backgroundColor: 'aliceblue',
    height: 90,
    margin: '16px 24px',
    padding: 8,
  },
  rentalHeader: {
    textAlign: 'left',
  },
  rentalValue: {
    fontSize: 24,
  },
  table: {
    marginBottom: 16,
    tableLayout: 'fixed',
    width: '100%',
  },
  summaryTable: {
    marginBottom: 16,
    tableLayout: 'fixed',
    '& td:nth-child(1)': {
      width: 150,
    },
  },
});

const Konica = ({ state }: Props) => {
  const { proposalId } = useParams();
  const parentState = getParent<AppState>(state);
  const [localState, setLocalState] = useState<StateInterface>(initialState);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    message: 'Error',
    severity: 'success',
  });

  const environment = config.env;

  const getOfficeConfig = () => {
    if (parentState.office && parentState.office.id && environment) {
      const result = data.officeConfig.filter(function (v) {
        return v.officeId.find((x) => x.id === parentState.office.id);
      });
      if (result) {
        return result[0];
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (proposalId) {
        await state.getProposal(proposalId);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.proposal) {
      setLocalState({
        ...localState,
        dealType: state.dealType,
        onBaseNumber: state.proposal.label ? state.proposal.label : '',
        paymentsPerYear:
          state.proposal.currentCalculation.payment.paymentLines[0].period
            .periodsPerYear,
        profile1: state.profile.profile1,
        profile2: state.profile.profile2,
        term: state.proposal.currentCalculation.term,
      });
    }
  }, [state.proposal && state.proposal.id]);

  const resetLocal = () => {
    setLocalState(initialState);
  };

  const Alert = (props: any): ReactElement => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event: any, reason: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const PV = (rate, periods, payment, future, type) => {
    // Initialize type
    payment = +payment.toFixed(2);
    future = +future.toFixed(2);
    rate = eval(rate);
    periods = eval(periods);

    // Return present value
    if (rate === 0) {
      return -payment * periods - future;
    } else {
      return (
        (((1 - Math.pow(1 + rate, periods)) / rate) *
          payment *
          (1 + rate * type) -
          future) /
        Math.pow(1 + rate, periods)
      );
    }
  };

  const calculate = async () => {
    let result = { ...localState };
    let priceTotal = 0;
    let rentalTotal = 0;
    let total = 0;

    for (let i = 0; i < data.lineItemType.length; i++) {
      const itemType = data.lineItemType[i].value;

      const item = localState[data.lineItemType[i].value];
      let baseRate;

      if (itemType === 'newEquipment') {
        baseRate = state.baseRateRV;
      } else {
        baseRate = state.baseRateNonRV;
      }

      if (item.price && item.rate) {
        priceTotal = priceTotal + +item.price;
        const pmt = (item.price * item.rate) / 1000;
        rentalTotal = rentalTotal + pmt;
        let subTotal;
        if (getOfficeConfig()['commission']) {
          subTotal = Math.max(
            -PV(
              0.085 / localState.paymentsPerYear,
              localState.term / (12 / localState.paymentsPerYear),
              pmt,
              itemType === 'newEquipment' ? item.price * 0.08 : 0,
              1
            ),
            item.price
          );
        } else {
          subTotal = (pmt / baseRate) * 1000;
        }
        total = total + subTotal;
        if (!isNaN(pmt)) {
          result = {
            ...result,
            [itemType]: {
              ...result[itemType],
              ['rental']: pmt.toFixed(2),
              ['subTotal']: subTotal.toFixed(2),
            },
            ['total']: {
              ...result['total'],
              ['price']: +priceTotal.toString(),
              ['rental']: +rentalTotal.toFixed(2),
              ['subTotal']: +total.toFixed(2),
              ['commission']: +(total - priceTotal).toFixed(2),
            },
          };
        }
      }
    }

    if (result) {
      await new Promise((resolve) =>
        resolve(
          setLocalState({
            ...localState,
            ...result,
          })
        )
      );
      await state
        .saveCalculation(
          state.proposal.id,
          +rentalTotal.toFixed(2),
          +total.toFixed(2)
        )
        .then((error) => {
          if (error) {
            setSnackbar({
              message: error ? error : 'Error',
              severity: 'error',
            });
            setSnackbarOpen(true);
          }
        });
    }
  };

  const updateState = async (newValue: any) => {
    if (newValue.hasOwnProperty('target')) {
      const nestedObject = newValue.target.name.indexOf('.') > 0;
      if (nestedObject) {
        const outer = newValue.target.name.split('.')[0];
        const inner = newValue.target.name.split('.')[1];
        await new Promise((resolve) =>
          resolve(
            setLocalState({
              ...localState,
              [outer]: {
                ...localState[outer],
                [inner]: newValue.target.value,
              },
            })
          )
        );
        //calculate();
      } else {
        await new Promise((resolve) =>
          resolve(
            setLocalState({
              ...localState,
              [newValue.target.name]: newValue.target.value,
            })
          )
        );
      }
    } else {
      await new Promise((resolve) =>
        resolve(setLocalState({ ...localState, ...newValue }))
      );
    }
  };

  const baseRate = (item: string) => {
    if (item === 'newEquipment') {
      if (state.baseRateRV) {
        return state.baseRateRV.toFixed(2);
      } else {
        return '';
      }
    } else {
      if (state.baseRateNonRV) {
        return state.baseRateNonRV.toFixed(2);
      } else {
        return '';
      }
    }
  };

  const validateRateGrid = () => {
    let itemsEnteredCount = 0;
    let lineItemValid = true;
    const salesRateValid = true;
    data.lineItemType.forEach(function (item) {
      if (localState[item.value].price) {
        itemsEnteredCount++;
        if (!localState[item.value].rate) {
          lineItemValid = false;
        }
      }
    });
    return itemsEnteredCount === 0 || !lineItemValid || !salesRateValid;
  };

  const validateQuickQuote = () => {
    if (
      validateRateGrid() ||
      !localState.onBaseNumber ||
      !localState.equipment ||
      !localState.lastSignatureDate
    ) {
      return false;
    }
    return true;
  };

  const classes = useStyles();
  if (!getOfficeConfig()) {
    return (
      <>
        There is a problem with the configuration for this office - please try
        reloading the page
      </>
    );
  }

  if (!getOfficeConfig()) {
    return (
      <>
        There is a problem with the configuration for this office - please try
        reloading the page
      </>
    );
  }

  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <div className="officeName">{parentState.office.name}</div>
        <ProposalSearch
          state={state}
          setSnackBarOpen={setSnackbarOpen}
          setSnackbar={setSnackbar}
          resetLocal={resetLocal}
        />

        <Panel
          title={'Quick quote'}
          allowExpand={true}
          expanded={!!state.proposal}
        >
          {state.proposal && !state.proposal.customer.businessPartner && (
            <div>This proposal is missing a customer - please contact SFS</div>
          )}

          {state.proposal && state.proposal.customer.businessPartner && (
            <>
              <div className="row">
                <TextField
                  name="proposalNumber"
                  label="Proposal number"
                  value={
                    state.proposal && state.proposal.id ? state.proposal.id : ''
                  }
                />
                <TextField
                  name="onBaseNumber"
                  label="OnBase number"
                  value={localState.onBaseNumber ? localState.onBaseNumber : ''}
                />
              </div>
              <div className="row">
                <TextField
                  name="dealType"
                  label="Deal type"
                  value={localState.dealType}
                />
                <TextField
                  name="Customer"
                  label="Customer"
                  value={
                    state.proposal &&
                    state.proposal.customer.businessPartner.name
                      ? state.proposal.customer.businessPartner.name
                      : ''
                  }
                />
              </div>

              <div className="row">
                <TextField
                  name="paymentsPerYear"
                  label="Payment period"
                  value={
                    localState.paymentsPerYear
                      ? data.paymentsPerYear.find(
                          (x) => +x.value === localState.paymentsPerYear
                        ).name
                      : ''
                  }
                />

                <TextField
                  name="term"
                  label="Term in months"
                  value={localState.term ? localState.term : ''}
                />
              </div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Price</th>
                    <th>Sales rate</th>
                    <th>Rental</th>
                    <th>Base rate</th>
                    <th>Sub total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.lineItemType.map((item) => (
                    <tr key={item.name}>
                      <td>{item.name}</td>
                      <td>
                        <TextField
                          name={`${item.value}.price`}
                          margin="normal"
                          type="number"
                          value={
                            localState[item.value].price
                              ? localState[item.value].price
                              : ''
                          }
                          onChange={updateState}
                        />
                      </td>
                      <td>
                        <TextField
                          name={`${item.value}.rate`}
                          margin="normal"
                          type="number"
                          value={
                            localState[item.value].rate
                              ? localState[item.value].rate
                              : ''
                          }
                          onChange={updateState}
                        />
                      </td>
                      <td>
                        <TextField
                          name="rental"
                          value={
                            localState[item.value].rental
                              ? localState[item.value].rental
                              : ''
                          }
                        />
                      </td>
                      <td>
                        <TextField
                          name="baseRate"
                          value={baseRate(item.value)}
                        />
                      </td>
                      <td>
                        <TextField
                          name="subTotal"
                          value={
                            localState[item.value].subTotal
                              ? localState[item.value].subTotal
                              : ''
                          }
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <TextField
                        value={
                          localState['total'].price
                            ? localState['total'].price
                            : ''
                        }
                      />
                    </td>
                    <td></td>
                    <td>
                      <TextField
                        name="rental"
                        value={
                          localState['total'].rental
                            ? localState['total'].rental
                            : ''
                        }
                      />
                    </td>
                    <td></td>
                    <td>
                      <TextField
                        name="subTotal"
                        value={
                          localState['total'].subTotal
                            ? localState['total'].subTotal
                            : ''
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={classes.summaryTable}>
                <tbody>
                  <tr>
                    <td>Equipment price</td>
                    <td>
                      {localState['total'].price
                        ? localState['total'].price
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>RV (8%)</td>
                    <td>
                      {localState['newEquipment'].price
                        ? (localState['newEquipment'].price * 0.08).toFixed(2)
                        : ''}
                    </td>
                  </tr>
                  {getOfficeConfig()['commission'] && (
                    <tr>
                      <td>Commission</td>
                      <td>
                        {localState['total'].commission
                          ? localState['total'].commission
                          : ''}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <strong>Invoice total</strong>
                    </td>
                    <td>
                      {localState['total'].subTotal
                        ? localState['total'].subTotal
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ marginBottom: 15 }}>
                <Button
                  onClick={() => calculate()}
                  disabled={validateRateGrid()}
                  label="Calculate"
                />
              </div>

              {localState.dealType === 'Framework' && (
                <TextField
                  name="framework"
                  id="outlined-select-currency"
                  select={true}
                  label="Framework"
                  value={localState.framework ? localState.framework : ''}
                  onChange={updateState}
                  className="framework"
                >
                  {data.frameworks.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {parentState.office.id === 153952 && (
                <TextField
                  name="commercialDealType"
                  id="outlined-select-currency"
                  select={true}
                  label="Commercial deal type"
                  value={
                    localState.commercialDealType
                      ? localState.commercialDealType
                      : ''
                  }
                  onChange={updateState}
                  className="framework"
                >
                  {data.commercialDealType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <div className="row">
                <TextField
                  name="onBaseNumber"
                  margin="normal"
                  type="number"
                  label="OnBase number"
                  value={localState.onBaseNumber ? localState.onBaseNumber : ''}
                  onChange={updateState}
                />
                <TextField
                  name="URN"
                  margin="normal"
                  label="URN/PO number"
                  value={localState.URN ? localState.URN : ''}
                  onChange={updateState}
                />
                <TextField
                  label="Last signature date"
                  type="date"
                  name="lastSignatureDate"
                  value={
                    localState.lastSignatureDate
                      ? localState.lastSignatureDate
                      : '' //Hack to make date appear as dd/mm/yyyy at first
                  }
                  onChange={updateState}
                />
              </div>
              <div>
                <TextField
                  name="equipment"
                  margin="normal"
                  label="Equipment details"
                  value={localState.equipment ? localState.equipment : ''}
                  onChange={updateState}
                />
              </div>
              <div className={classes.pdfButtons}>
                <CreateQuotePDF
                  localState={localState}
                  officeConfig={getOfficeConfig()}
                  state={state}
                  valid={validateQuickQuote()}
                />
              </div>
            </>
          )}
        </Panel>

        {state.proposal && state.proposal.customer.businessPartner && (
          <UploadDocs
            localState={localState}
            state={state}
            setSnackBarOpen={setSnackbarOpen}
            setSnackbar={setSnackbar}
          />
        )}
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Konica;
