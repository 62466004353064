import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import theme from '../constants/theme';
import { Unselected, Selected } from '../images/Checkbox';

const useStyles = makeStyles({
  root: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      padding: theme.spacing(0),
    },
  },
});

const checkboxStyles = makeStyles({
  root: {
    '&.MuiCheckbox-colorPrimary': {
      '&.Mui-checked': {
        color: '#46bbca',
      },
    },
  },
});

interface Props {
  label?: React.ReactNode;
}

const CheckboxStyled = ({
  className,
  label,
  disabled,
  onClick,
  ...props
}: CheckboxProps & Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormControlLabel
      classes={useStyles()}
      className={classNames(className)}
      control={
        <Checkbox
          className={classNames({ isLoading })}
          disabled={disabled || isLoading}
          color={'primary'}
          icon={<Unselected />}
          checkedIcon={<Selected />}
          onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
            if (onClick) {
              setIsLoading(true);
              await new Promise((resolve) => resolve(onClick(event)));
              setIsLoading(false);
            }
          }}
          classes={checkboxStyles()}
          {...props}
        />
      }
      label={label || ''}
    />
  );
};
export default CheckboxStyled;
