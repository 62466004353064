// This file is auto generated so do not change!
// Run the script from the directory the script is in as follows: ts-node createApi.ts

import { Instance, types } from 'mobx-state-tree';
import { AxiosResponse } from 'axios';
import config from '../config';
import { Api } from './Api';

// tslint:disable:object-literal-sort-keys

const request = <P, B, R>(
  operationId: string,
  parameters: P,
  requestBody: B
): Promise<AxiosResponse<R>> =>
  new Api(config.siesmartApiUrl).request<P, B, R>(
    operationId,
    parameters,
    requestBody
  );

export const AssetSummaryModel = types.model('AssetSummaryModel', {
numberOfAssets: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
totalAssetValue: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type AssetSummary = Instance<typeof AssetSummaryModel>;

export const CommissionTypeModel = types.model('CommissionTypeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type CommissionType = Instance<typeof CommissionTypeModel>;

export const CountryModel = types.model('CountryModel', {
code: types.string,
name: types.string,
hasRegions: types.boolean});
export type Country = Instance<typeof CountryModel>;

export const CurrencyModel = types.model('CurrencyModel', {
currencyCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
format: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type Currency = Instance<typeof CurrencyModel>;

export const CustomerInvoiceContactModel = types.model('CustomerInvoiceContactModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type CustomerInvoiceContact = Instance<typeof CustomerInvoiceContactModel>;

export const EquipmentSupplierSummaryEntityModel = types.model('EquipmentSupplierSummaryEntityModel', {
numberOfEquipmentSuppliers: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type EquipmentSupplierSummaryEntity = Instance<typeof EquipmentSupplierSummaryEntityModel>;

export const FeeTypeModel = types.model('FeeTypeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
code: types.optional(types.union(types.string, types.undefined, types.null),undefined),
hideIncluded: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FeeType = Instance<typeof FeeTypeModel>;

export const FieldDefBooleanModel = types.model('FieldDefBooleanModel', {
defaultValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FieldDefBoolean = Instance<typeof FieldDefBooleanModel>;

export const IntroducerContactModel = types.model('IntroducerContactModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
firstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
lastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
role: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type IntroducerContact = Instance<typeof IntroducerContactModel>;

export const JobTitleModel = types.model('JobTitleModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
isCustom: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type JobTitle = Instance<typeof JobTitleModel>;

export const LegalFormModel = types.model('LegalFormModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
code: types.optional(types.union(types.string, types.undefined, types.null),undefined),
partnership: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type LegalForm = Instance<typeof LegalFormModel>;

export const LoanSummaryModel = types.model('LoanSummaryModel', {
numberOfLoans: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
totalLoanValue: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type LoanSummary = Instance<typeof LoanSummaryModel>;

export const MarketProductModel = types.model('MarketProductModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type MarketProduct = Instance<typeof MarketProductModel>;

export const NumberOfPartnersConfigEntityModel = types.model('NumberOfPartnersConfigEntityModel', {
defaultValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
maxValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type NumberOfPartnersConfigEntity = Instance<typeof NumberOfPartnersConfigEntityModel>;

export const PaymentMethodModel = types.model('PaymentMethodModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type PaymentMethod = Instance<typeof PaymentMethodModel>;

export const PeriodModel = types.model('PeriodModel', {
id: types.integer,
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
periodsPerYear: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
monthsPerPeriod: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type Period = Instance<typeof PeriodModel>;

export const PostponedStartAffectedPartyModel = types.model('PostponedStartAffectedPartyModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type PostponedStartAffectedParty = Instance<typeof PostponedStartAffectedPartyModel>;

export const PriceListModel = types.model('PriceListModel', {
id: types.integer,
name: types.string});
export type PriceList = Instance<typeof PriceListModel>;

export const ProposalStatusModel = types.model('ProposalStatusModel', {
status: types.optional(types.union(types.enumeration(['OFFER', 'PROSPECT', 'DEAL_AMENDMENT', 'MORE_INFORMATION_REQUIRED', 'WAITING_FOR_DECISION', 'PENDING', 'CREDIT_APPROVED', 'WAITING_FOR_PRINT', 'WAITING', 'WAITING_FOR_E_SIGNATURE', 'DOCUMENTS_ARRIVED', 'DOCUMENTS_COMPLETED', 'CONTRACT_STARTED', 'WAITING_BACKEND', 'ACTIVATED', 'PAID', 'EXPIRED', 'DECLINED', 'WAITING_ACTIVATION']), types.undefined, types.null),undefined),
officeReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
salesContactReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
customerReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
calculationsReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
assetsReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
loanReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
upgradeReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
signatoryReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
proposalReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
equipmentSupplierReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
equipmentSupplierAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
editPermissionType: types.optional(types.union(types.enumeration(['NO_CHANGES_ALLOWED', 'EDITS_ALLOWED', 'AMENDMENTS_ALLOWED']), types.undefined, types.null),undefined)});
export type ProposalStatus = Instance<typeof ProposalStatusModel>;

export const PutOptionTypeModel = types.model('PutOptionTypeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
inputType: types.optional(types.union(types.enumeration(['PUT_OPTION_PERCENTAGE', 'PUT_OPTION_VALUE', 'CAPPED_PERCENTAGE', 'RENTALS_VALUE']), types.undefined, types.null),undefined),
fixedValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type PutOptionType = Instance<typeof PutOptionTypeModel>;

export const RegionModel = types.model('RegionModel', {
id: types.integer,
name: types.string});
export type Region = Instance<typeof RegionModel>;

export const ResidualValueModel = types.model('ResidualValueModel', {
amount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
percentage: types.optional(types.union(types.number, types.undefined, types.null),undefined),
usePercentage: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
manualSet: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ResidualValue = Instance<typeof ResidualValueModel>;

export const SignatoryModel = types.model('SignatoryModel', {
id: types.optional(types.union(types.string, types.undefined, types.null),undefined),
firstName: types.string,
lastName: types.string,
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
jobTitle: types.optional(types.union(JobTitleModel, types.undefined, types.null),undefined),
jobTitleCustom: types.optional(types.union(types.string, types.undefined, types.null),undefined),
boardmemberId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
boardmember: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
type: types.optional(types.union(types.enumeration(['CUSTOMER', 'CUSTOMER_CURRENT', 'INTRODUCER', 'GUARANTOR', 'EQUIPMENT_SUPPLIER', 'SIEMENS', 'SIEMENS_MANAGER', 'CC']), types.undefined, types.null),undefined),
bpId: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type Signatory = Instance<typeof SignatoryModel>;

export const SpecialPaymentTypeModel = types.model('SpecialPaymentTypeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
downPaymentType: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
code: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type SpecialPaymentType = Instance<typeof SpecialPaymentTypeModel>;

export const UpgradeSummaryModel = types.model('UpgradeSummaryModel', {
numberOfUpgrades: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
totalUpgradeValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
validUntil: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type UpgradeSummary = Instance<typeof UpgradeSummaryModel>;

export const ApiErrorModel = types.model('ApiErrorModel', {
errorType: types.string,
errorCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
errorMessage: types.string});
export type ApiError = Instance<typeof ApiErrorModel>;

export const ErrorsModel = types.model('ErrorsModel', {
errors: types.optional(types.union(types.array(ApiErrorModel), types.undefined, types.null),undefined)});
export type Errors = Instance<typeof ErrorsModel>;

export const AssetTypeModel = types.model('AssetTypeModel', {
id: types.integer,
name: types.string});
export type AssetType = Instance<typeof AssetTypeModel>;

export const PricePerUnitModel = types.model('PricePerUnitModel', {
id: types.integer,
name: types.string});
export type PricePerUnit = Instance<typeof PricePerUnitModel>;

export const AmountFinancedConfigEntityModel = types.model('AmountFinancedConfigEntityModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
maxValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
writeable: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type AmountFinancedConfigEntity = Instance<typeof AmountFinancedConfigEntityModel>;

export const AssetValueConfigEntityModel = types.model('AssetValueConfigEntityModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
maxValue: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type AssetValueConfigEntity = Instance<typeof AssetValueConfigEntityModel>;

export const CustomerPtoConfigEntityModel = types.model('CustomerPtoConfigEntityModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type CustomerPtoConfigEntity = Instance<typeof CustomerPtoConfigEntityModel>;

export const FactoredPaymentsConfigModel = types.model('FactoredPaymentsConfigModel', {
factoredPaymentsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
factorMaximum: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type FactoredPaymentsConfig = Instance<typeof FactoredPaymentsConfigModel>;

export const FinalPaymentsConfigModel = types.model('FinalPaymentsConfigModel', {
finalPaymentsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FinalPaymentsConfig = Instance<typeof FinalPaymentsConfigModel>;

export const InterestRateConfigModel = types.model('InterestRateConfigModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
maxValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type InterestRateConfig = Instance<typeof InterestRateConfigModel>;

export const MarketProductsConfigEntityModel = types.model('MarketProductsConfigEntityModel', {
defaultValue: types.optional(types.union(MarketProductModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(MarketProductModel), types.undefined, types.null),undefined)});
export type MarketProductsConfigEntity = Instance<typeof MarketProductsConfigEntityModel>;

export const PaymentMethodsConfigEntityModel = types.model('PaymentMethodsConfigEntityModel', {
defaultValue: types.optional(types.union(PaymentMethodModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(PaymentMethodModel), types.undefined, types.null),undefined)});
export type PaymentMethodsConfigEntity = Instance<typeof PaymentMethodsConfigEntityModel>;

export const PaymentModeConfigEntityModel = types.model('PaymentModeConfigEntityModel', {
defaultValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type PaymentModeConfigEntity = Instance<typeof PaymentModeConfigEntityModel>;

export const PaymentPeriodsConfigEntityModel = types.model('PaymentPeriodsConfigEntityModel', {
defaultValue: types.optional(types.union(PeriodModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(PeriodModel), types.undefined, types.null),undefined)});
export type PaymentPeriodsConfigEntity = Instance<typeof PaymentPeriodsConfigEntityModel>;

export const PricelistsConfigEntityModel = types.model('PricelistsConfigEntityModel', {
defaultValue: types.optional(types.union(PriceListModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(PriceListModel), types.undefined, types.null),undefined)});
export type PricelistsConfigEntity = Instance<typeof PricelistsConfigEntityModel>;

export const RentalSkipsConfigModel = types.model('RentalSkipsConfigModel', {
rentalSkipsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rentalSkipsNumberMaximum: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
minTermsForRentalSkips: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
maxTermsForRentalSkips: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type RentalSkipsConfig = Instance<typeof RentalSkipsConfigModel>;

export const ResidualValueConfigModel = types.model('ResidualValueConfigModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
percentageMin: types.optional(types.union(types.number, types.undefined, types.null),undefined),
percentageMax: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type ResidualValueConfig = Instance<typeof ResidualValueConfigModel>;

export const TariffRateConfigEntityModel = types.model('TariffRateConfigEntityModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type TariffRateConfigEntity = Instance<typeof TariffRateConfigEntityModel>;

export const TermConfigEntityModel = types.model('TermConfigEntityModel', {
defaultValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
maxValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type TermConfigEntity = Instance<typeof TermConfigEntityModel>;

export const LessorModel = types.model('LessorModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
siemensLessor: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type Lessor = Instance<typeof LessorModel>;

export const LoanPurposeModel = types.model('LoanPurposeModel', {
id: types.integer,
name: types.string});
export type LoanPurpose = Instance<typeof LoanPurposeModel>;

export const NationalityModel = types.model('NationalityModel', {
id: types.string,
name: types.string});
export type Nationality = Instance<typeof NationalityModel>;

export const ProfessionTypeModel = types.model('ProfessionTypeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ProfessionType = Instance<typeof ProfessionTypeModel>;

export const TitleModel = types.model('TitleModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type Title = Instance<typeof TitleModel>;

export const ContentFeedItemModel = types.model('ContentFeedItemModel', {
title: types.optional(types.union(types.string, types.undefined, types.null),undefined),
text: types.optional(types.union(types.string, types.undefined, types.null),undefined),
imageLink: types.optional(types.union(types.string, types.undefined, types.null),undefined),
targetLink: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ContentFeedItem = Instance<typeof ContentFeedItemModel>;

export const ContractConfigModel = types.model('ContractConfigModel', {
showAmountFinanced: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showUpgradeValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showResidualValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showFetchQuote: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showSecurities: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ContractConfig = Instance<typeof ContractConfigModel>;

export const ProposalReferenceModel = types.model('ProposalReferenceModel', {
proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type ProposalReference = Instance<typeof ProposalReferenceModel>;

export const ContractUpgradeQuoteValueModel = types.model('ContractUpgradeQuoteValueModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
type: types.optional(types.union(types.enumeration(['QUOTE_TECH_REFRESH', 'QUOTE_WITH_SALE', 'QUOTE_WITH_RETURN']), types.undefined, types.null),undefined),
value: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type ContractUpgradeQuoteValue = Instance<typeof ContractUpgradeQuoteValueModel>;

export const EarlyCancellationOfferModel = types.model('EarlyCancellationOfferModel', {
quoteTypes: types.array(types.enumeration(['QUOTE_TECH_REFRESH', 'QUOTE_WITH_SALE', 'QUOTE_WITH_RETURN'])),
recipient: types.string,
id: types.integer,
sentDate: types.string,
sentBy: types.string,
offerType: types.enumeration(['TERMINATION', 'UPGRADE', 'BOTH'])});
export type EarlyCancellationOffer = Instance<typeof EarlyCancellationOfferModel>;

export const EarlyCancellationOfferRequestModel = types.model('EarlyCancellationOfferRequestModel', {
quoteTypes: types.array(types.enumeration(['QUOTE_TECH_REFRESH', 'QUOTE_WITH_SALE', 'QUOTE_WITH_RETURN'])),
recipient: types.string,
contractUpgradeId: types.integer});
export type EarlyCancellationOfferRequest = Instance<typeof EarlyCancellationOfferRequestModel>;

export const PaginationInfoModel = types.model('PaginationInfoModel', {
size: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
total: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
sortBy: types.optional(types.union(types.string, types.undefined, types.null),undefined),
order: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)});
export type PaginationInfo = Instance<typeof PaginationInfoModel>;

export const FileDataModel = types.model('FileDataModel', {
data: types.optional(types.union(types.string, types.undefined, types.null),undefined),
contentType: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fileName: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type FileData = Instance<typeof FileDataModel>;

export const DateSearchTypeModel = types.model('DateSearchTypeModel', {
id: types.string,
label: types.string});
export type DateSearchType = Instance<typeof DateSearchTypeModel>;

export const ContractExpiringCountModel = types.model('ContractExpiringCountModel', {
offsetStart: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
offsetEnd: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
count: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type ContractExpiringCount = Instance<typeof ContractExpiringCountModel>;

export const CustomerPartnerSearchFilterModel = types.model('CustomerPartnerSearchFilterModel', {
ssn: types.string});
export type CustomerPartnerSearchFilter = Instance<typeof CustomerPartnerSearchFilterModel>;

export const BusinessPartnerRefModel = types.model('BusinessPartnerRefModel', {
registrationNumber: types.string,
companyType: types.optional(types.union(types.enumeration(['LIMITED', 'NONLIMITED']), types.undefined, types.null),undefined),
sourceCountryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type BusinessPartnerRef = Instance<typeof BusinessPartnerRefModel>;

export const CustomerSearchResultItemModel = types.model('CustomerSearchResultItemModel', {
businessPartnerRef: types.optional(types.union(BusinessPartnerRefModel, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address1: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address2: types.optional(types.union(types.string, types.undefined, types.null),undefined),
city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
businessBlocked: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
legalFormSelectionRequired: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type CustomerSearchResultItem = Instance<typeof CustomerSearchResultItemModel>;

export const EquipmentSupplierSearchResultItemModel = types.model('EquipmentSupplierSearchResultItemModel', {
businessPartnerRef: types.optional(types.union(BusinessPartnerRefModel, types.undefined, types.null),undefined),
bpId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address1: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address2: types.optional(types.union(types.string, types.undefined, types.null),undefined),
city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
businessBlocked: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
appliedToIntroducer: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type EquipmentSupplierSearchResultItem = Instance<typeof EquipmentSupplierSearchResultItemModel>;

export const ExternalLinkModel = types.model('ExternalLinkModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
url: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ExternalLink = Instance<typeof ExternalLinkModel>;

export const ExternalLinkFolderModel = types.model('ExternalLinkFolderModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
links: types.optional(types.union(types.array(ExternalLinkModel), types.undefined, types.null),undefined)});
export type ExternalLinkFolder = Instance<typeof ExternalLinkFolderModel>;

export const FieldDefCountryModel = types.model('FieldDefCountryModel', {
defaultValue: types.optional(types.union(CountryModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(CountryModel), types.undefined, types.null),undefined)});
export type FieldDefCountry = Instance<typeof FieldDefCountryModel>;

export const FieldDefRegionModel = types.model('FieldDefRegionModel', {
defaultValue: types.optional(types.union(RegionModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FieldDefRegion = Instance<typeof FieldDefRegionModel>;

export const FieldDefStringModel = types.model('FieldDefStringModel', {
defaultValue: types.optional(types.union(types.string, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FieldDefString = Instance<typeof FieldDefStringModel>;

export const MinMaxLengthFieldDefStringModel = types.model('MinMaxLengthFieldDefStringModel', {
defaultValue: types.optional(types.union(types.string, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
minLength: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
maxLength: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type MinMaxLengthFieldDefString = Instance<typeof MinMaxLengthFieldDefStringModel>;

export const ReportValueDoubleModel = types.model('ReportValueDoubleModel', {
value: types.optional(types.union(types.number, types.undefined, types.null),undefined),
unit: types.optional(types.union(types.string, types.undefined, types.null),undefined),
format: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
metricType: types.optional(types.union(types.enumeration(['COUNT', 'CURRENCY', 'PERCENTAGE']), types.undefined, types.null),undefined)});
export type ReportValueDouble = Instance<typeof ReportValueDoubleModel>;

export const ReportInvoiceModel = types.model('ReportInvoiceModel', {
invoiceNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
amount: types.optional(types.union(ReportValueDoubleModel, types.undefined, types.null),undefined),
dueDate: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ReportInvoice = Instance<typeof ReportInvoiceModel>;

export const ReportInvoicesResponseModel = types.model('ReportInvoicesResponseModel', {
data: types.optional(types.union(types.array(ReportInvoiceModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined),
key: types.optional(types.union(types.enumeration(['SUMMARY_INFORMATION', 'CUSTOMER_ARREARS', 'INTRODUCER_ARREARS', 'THIRTY_DAY_ARREARS', 'BAD_DEBT_WRITE_OFFS', 'FIRST_YEAR_BAD_DEBT']), types.undefined, types.null),undefined),
contractId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ReportInvoicesResponse = Instance<typeof ReportInvoicesResponseModel>;

export const ReportMetricNumberModel = types.model('ReportMetricNumberModel', {
value: types.optional(types.union(types.number, types.undefined, types.null),undefined),
unit: types.optional(types.union(types.string, types.undefined, types.null),undefined),
format: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
metricType: types.optional(types.union(types.enumeration(['COUNT', 'CURRENCY', 'PERCENTAGE']), types.undefined, types.null),undefined),
key: types.optional(types.union(types.enumeration(['NO_OF_PROPOSALS', 'NO_OF_DECISIONS', 'NO_OF_ACCEPTANCES', 'NO_OF_DEALS_CURRENTLY_AMENDED', 'PROPOSAL_PERCENT_OF_ACCEPTANCES', 'PROPOSAL_AMOUNT_FINANCED', 'PERCENT_CONVERSION', 'PROPOSAL_AVERAGE_AMOUNT_FINANCED', 'NO_OF_AGREEMENTS', 'AGREEMENT_PERCENT_OF_ACCEPTANCES', 'PERCENT_OF_DIRECT_DEBITS', 'AGREEMENT_AVERAGE_AMOUNT_FINANCED', 'AGREEMENT_AMOUNT_FINANCED', 'PERCENT_OF_AMOUNT_FINANCED_CONVERSION']), types.undefined, types.null),undefined)});
export type ReportMetricNumber = Instance<typeof ReportMetricNumberModel>;

export const TimespanModel = types.model('TimespanModel', {
start: types.optional(types.union(types.string, types.undefined, types.null),undefined),
end: types.optional(types.union(types.string, types.undefined, types.null),undefined),
type: types.optional(types.union(types.string, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type Timespan = Instance<typeof TimespanModel>;

export const ReportValueLongModel = types.model('ReportValueLongModel', {
value: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
unit: types.optional(types.union(types.string, types.undefined, types.null),undefined),
format: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fractionDigits: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
metricType: types.optional(types.union(types.enumeration(['COUNT', 'CURRENCY', 'PERCENTAGE']), types.undefined, types.null),undefined)});
export type ReportValueLong = Instance<typeof ReportValueLongModel>;

export const SalesContactsModel = types.model('SalesContactsModel', {
salesContacts: types.optional(types.union(types.array(IntroducerContactModel), types.undefined, types.null),undefined),
defaultSalesContact: types.optional(types.union(IntroducerContactModel, types.undefined, types.null),undefined)});
export type SalesContacts = Instance<typeof SalesContactsModel>;

export const IntroducerSalesContactDataModel = types.model('IntroducerSalesContactDataModel', {
name: types.string,
mail: types.string,
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type IntroducerSalesContactData = Instance<typeof IntroducerSalesContactDataModel>;

export const NotificationModel = types.model('NotificationModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
message: types.optional(types.union(types.string, types.undefined, types.null),undefined),
priority: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type Notification = Instance<typeof NotificationModel>;

export const ProposalCopyRequestModel = types.model('ProposalCopyRequestModel', {
officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
salesPersonId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
copyAssets: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
copyCustomer: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ProposalCopyRequest = Instance<typeof ProposalCopyRequestModel>;

export const PricePerUnitValueModel = types.model('PricePerUnitValueModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
pricePerUnit: types.optional(types.union(PricePerUnitModel, types.undefined, types.null),undefined),
value: types.optional(types.union(types.number, types.undefined, types.null),undefined),
valueIncluded: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type PricePerUnitValue = Instance<typeof PricePerUnitValueModel>;

export const ServiceAgreementModel = types.model('ServiceAgreementModel', {
serviceType: types.enumeration(['LIMITED', 'FULL']),
pricePerUnitValues: types.array(PricePerUnitValueModel)});
export type ServiceAgreement = Instance<typeof ServiceAgreementModel>;

export const FieldDefAssetConditionEnumModel = types.model('FieldDefAssetConditionEnumModel', {
defaultValue: types.optional(types.union(types.enumeration(['NEW', 'REFURBISHED', 'USED', 'REMANUFACTORED', 'RETAINED', 'EX_DEMO']), types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(types.enumeration(['NEW', 'REFURBISHED', 'USED', 'REMANUFACTORED', 'RETAINED', 'EX_DEMO'])), types.undefined, types.null),undefined)});
export type FieldDefAssetConditionEnum = Instance<typeof FieldDefAssetConditionEnumModel>;

export const FieldDefBigDecimalModel = types.model('FieldDefBigDecimalModel', {
defaultValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FieldDefBigDecimal = Instance<typeof FieldDefBigDecimalModel>;

export const FieldDefIntegerModel = types.model('FieldDefIntegerModel', {
defaultValue: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type FieldDefInteger = Instance<typeof FieldDefIntegerModel>;

export const BoardmemberModel = types.model('BoardmemberModel', {
id: types.integer,
title: types.optional(types.union(TitleModel, types.undefined, types.null),undefined),
firstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
lastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
jobTitle: types.optional(types.union(JobTitleModel, types.undefined, types.null),undefined),
jobTitleCustom: types.optional(types.union(types.string, types.undefined, types.null),undefined),
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phoneCountryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
signatoryType: types.enumeration(['CUSTOMER', 'CUSTOMER_CURRENT', 'INTRODUCER', 'GUARANTOR', 'EQUIPMENT_SUPPLIER', 'SIEMENS', 'SIEMENS_MANAGER', 'CC']),
bpId: types.string});
export type Boardmember = Instance<typeof BoardmemberModel>;

export const DecisionPrecheckViolationModel = types.model('DecisionPrecheckViolationModel', {
code: types.optional(types.union(types.string, types.undefined, types.null),undefined),
userMessage: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type DecisionPrecheckViolation = Instance<typeof DecisionPrecheckViolationModel>;

export const DecisionRequestModel = types.model('DecisionRequestModel', {
commentToRiskAnalyst: types.optional(types.union(types.string, types.undefined, types.null),undefined),
siemensMayContactCustomer: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
siemensMayRequestCreditData: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
prelease: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type DecisionRequest = Instance<typeof DecisionRequestModel>;

export const DocumentIdentifierModel = types.model('DocumentIdentifierModel', {
value: types.integer,
type: types.enumeration(['CREDIT_DOCUMENT_ID', 'DOCUMENT_ID', 'DOCUMENT_TYPE'])});
export type DocumentIdentifier = Instance<typeof DocumentIdentifierModel>;

export const PrintOptionModel = types.model('PrintOptionModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
selected: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type PrintOption = Instance<typeof PrintOptionModel>;

export const DocumentSignatoryModel = types.model('DocumentSignatoryModel', {
firstName: types.string,
lastName: types.string,
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
jobTitle: JobTitleModel,
type: types.enumeration(['CUSTOMER', 'CUSTOMER_CURRENT', 'INTRODUCER', 'GUARANTOR', 'EQUIPMENT_SUPPLIER', 'SIEMENS', 'SIEMENS_MANAGER', 'CC'])});
export type DocumentSignatory = Instance<typeof DocumentSignatoryModel>;

export const DocumentTypeModel = types.model('DocumentTypeModel', {
id: types.integer,
code: types.optional(types.union(types.string, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type DocumentType = Instance<typeof DocumentTypeModel>;

export const SigningRequestModel = types.model('SigningRequestModel', {
status: types.optional(types.union(types.enumeration(['FINALIZED', 'WAITING_FOR_SIGNATURE', 'SIGNED', 'EXPIRED', 'ABORTED', 'AUTHORING', 'NOT_VALID']), types.undefined, types.null),undefined),
documentVersion: types.optional(types.union(types.string, types.undefined, types.null),undefined),
password: types.optional(types.union(types.string, types.undefined, types.null),undefined),
refreshAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
deleteAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
downloadAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
sendReminderAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
senderFirstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
senderLastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
senderGid: types.optional(types.union(types.string, types.undefined, types.null),undefined),
sendDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
signatories: types.optional(types.union(types.array(DocumentSignatoryModel), types.undefined, types.null),undefined)});
export type SigningRequest = Instance<typeof SigningRequestModel>;

export const SignatureTypeConfigModel = types.model('SignatureTypeConfigModel', {
type: types.enumeration(['CUSTOMER', 'CUSTOMER_CURRENT', 'INTRODUCER', 'GUARANTOR', 'EQUIPMENT_SUPPLIER', 'SIEMENS', 'SIEMENS_MANAGER', 'CC']),
min: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
max: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type SignatureTypeConfig = Instance<typeof SignatureTypeConfigModel>;

export const DocumentUploadConfigEntityModel = types.model('DocumentUploadConfigEntityModel', {
documentTypes: types.array(DocumentTypeModel),
uploadAllowed: types.boolean});
export type DocumentUploadConfigEntity = Instance<typeof DocumentUploadConfigEntityModel>;

export const SendSignatureRequestModel = types.model('SendSignatureRequestModel', {
documentIds: types.array(DocumentIdentifierModel),
customerSignatoryIds: types.array(types.string),
vendorSignatoryIds: types.array(types.string),
equipmentSupplierSignatoryIds: types.array(types.string),
emailsInCC: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type SendSignatureRequest = Instance<typeof SendSignatureRequestModel>;

export const DocumentUploadEntityModel = types.model('DocumentUploadEntityModel', {
alias: types.string,
note: types.optional(types.union(types.string, types.undefined, types.null),undefined),
filename: types.string,
fileContent: types.string,
type: DocumentTypeModel});
export type DocumentUploadEntity = Instance<typeof DocumentUploadEntityModel>;

export const EsignHistoryProcessSigneeEntityModel = types.model('EsignHistoryProcessSigneeEntityModel', {
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
role: types.optional(types.union(types.string, types.undefined, types.null),undefined),
type: types.optional(types.union(types.string, types.undefined, types.null),undefined),
regNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
jobTitle: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
action: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type EsignHistoryProcessSigneeEntity = Instance<typeof EsignHistoryProcessSigneeEntityModel>;

export const HistoryProcessEntityModel = types.model('HistoryProcessEntityModel', {
esignProcessName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
sendDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
sender: types.optional(types.union(types.string, types.undefined, types.null),undefined),
esignId: types.optional(types.union(types.string, types.undefined, types.null),undefined),
documents: types.optional(types.union(types.array(types.string), types.undefined, types.null),undefined),
status: types.optional(types.union(types.enumeration(['FINALIZED', 'WAITING_FOR_SIGNATURE', 'SIGNED', 'EXPIRED', 'ABORTED', 'AUTHORING', 'NOT_VALID']), types.undefined, types.null),undefined),
signees: types.optional(types.union(types.array(EsignHistoryProcessSigneeEntityModel), types.undefined, types.null),undefined)});
export type HistoryProcessEntity = Instance<typeof HistoryProcessEntityModel>;

export const GuaranteeTypeModel = types.model('GuaranteeTypeModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
alias: types.string,
personalGuarantee: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type GuaranteeType = Instance<typeof GuaranteeTypeModel>;

export const CorporateGuarantorSearchResultSelectionModel = types.model('CorporateGuarantorSearchResultSelectionModel', {
businessPartnerRef: BusinessPartnerRefModel,
guaranteeType: GuaranteeTypeModel});
export type CorporateGuarantorSearchResultSelection = Instance<typeof CorporateGuarantorSearchResultSelectionModel>;

export const CorporateGuarantorSearchResultItemModel = types.model('CorporateGuarantorSearchResultItemModel', {
businessPartnerRef: types.optional(types.union(BusinessPartnerRefModel, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address1: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address2: types.optional(types.union(types.string, types.undefined, types.null),undefined),
city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type CorporateGuarantorSearchResultItem = Instance<typeof CorporateGuarantorSearchResultItemModel>;

export const GuarantorsConfigModel = types.model('GuarantorsConfigModel', {
personalGuarantorManagementAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
maxPersonalGuarantors: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
personalGuarantorCreationMethod: types.optional(types.union(types.enumeration(['SOCIAL_SECURITY_NUMBER', 'SELECT_FROM_AVAILABLE']), types.undefined, types.null),undefined),
corporateGuarantorManagementAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
maxCorporateGuarantors: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
availableCorporateGuaranteeTypes: types.optional(types.union(types.array(GuaranteeTypeModel), types.undefined, types.null),undefined)});
export type GuarantorsConfig = Instance<typeof GuarantorsConfigModel>;

export const PersonalGuarantorSearchFilterModel = types.model('PersonalGuarantorSearchFilterModel', {
ssn: types.string});
export type PersonalGuarantorSearchFilter = Instance<typeof PersonalGuarantorSearchFilterModel>;

export const GuarantorFicoScoreModel = types.model('GuarantorFicoScoreModel', {
fico: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type GuarantorFicoScore = Instance<typeof GuarantorFicoScoreModel>;

export const LoanModel = types.model('LoanModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
value: types.number,
loanPurpose: types.optional(types.union(LoanPurposeModel, types.undefined, types.null),undefined)});
export type Loan = Instance<typeof LoanModel>;

export const DecisionConfigEntityModel = types.model('DecisionConfigEntityModel', {
siemensMayContactCustomer: types.optional(types.union(FieldDefBooleanModel, types.undefined, types.null),undefined),
siemensMayRequestCreditData: types.optional(types.union(FieldDefBooleanModel, types.undefined, types.null),undefined)});
export type DecisionConfigEntity = Instance<typeof DecisionConfigEntityModel>;

export const ProposalConfigModel = types.model('ProposalConfigModel', {
loansAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
loanProposalEditsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
assetsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
multipleAssetsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showUpgradeValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showAssetValue: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
signatoryJobTitleDefault: types.optional(types.union(JobTitleModel, types.undefined, types.null),undefined),
upgradesAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
equipmentSupplierAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showAssetsDescription: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
multipleEquipmentSupplierAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
guarantorsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
deliveryAddressAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
assetLocationAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
customerPartnerAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
customerPartnerCreationMethod: types.optional(types.union(types.enumeration(['NONE', 'SOCIAL_SECURITY_NUMBER', 'PERSONAL_DATA']), types.undefined, types.null),undefined),
showDetailedCustomerPartnerData: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showDetailedBoardmemberData: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
decisionConfig: types.optional(types.union(DecisionConfigEntityModel, types.undefined, types.null),undefined),
esignDocPackAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
showPrelease: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ProposalConfig = Instance<typeof ProposalConfigModel>;

export const ProposalStatusCountsModel = types.model('ProposalStatusCountsModel', {
offsetStart: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
offsetEnd: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
counts: types.optional(types.union(types.map(types.integer), types.undefined, types.null),undefined)});
export type ProposalStatusCounts = Instance<typeof ProposalStatusCountsModel>;

export const DocumentStatusModel = types.model('DocumentStatusModel', {
id: types.optional(types.union(types.string, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type DocumentStatus = Instance<typeof DocumentStatusModel>;

export const WorkflowStatusModel = types.model('WorkflowStatusModel', {
id: types.optional(types.union(types.string, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
order: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type WorkflowStatus = Instance<typeof WorkflowStatusModel>;

export const SignatoryConfigModel = types.model('SignatoryConfigModel', {
availableTypes: types.optional(types.union(types.array(types.enumeration(['CUSTOMER', 'CUSTOMER_CURRENT', 'INTRODUCER', 'GUARANTOR', 'EQUIPMENT_SUPPLIER', 'SIEMENS', 'SIEMENS_MANAGER', 'CC'])), types.undefined, types.null),undefined),
signeePhoneNumberMandatory: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
signeeEmailMandatory: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type SignatoryConfig = Instance<typeof SignatoryConfigModel>;

export const UpgradeModel = types.model('UpgradeModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
lessor: types.optional(types.union(LessorModel, types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
quoteTotalValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
rentalDate: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type Upgrade = Instance<typeof UpgradeModel>;

export const ProposalUpdateModel = types.model('ProposalUpdateModel', {
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
salesContactId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
specialConditionText: types.optional(types.union(types.string, types.undefined, types.null),undefined),
scheduleAssetsDescription: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ProposalUpdate = Instance<typeof ProposalUpdateModel>;

export const RateCardModel = types.model('RateCardModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
priceListName: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type RateCard = Instance<typeof RateCardModel>;

export const SalesPersonRoleModel = types.model('SalesPersonRoleModel', {
id: types.string,
name: types.string});
export type SalesPersonRole = Instance<typeof SalesPersonRoleModel>;

export const BlockOptionModel = types.model('BlockOptionModel', {
id: types.string,
label: types.string});
export type BlockOption = Instance<typeof BlockOptionModel>;

export const BlockRequestModel = types.model('BlockRequestModel', {
blockReason: types.string,
blockOptions: types.optional(types.union(types.array(BlockOptionModel), types.undefined, types.null),undefined),
blockOption: types.optional(types.union(BlockOptionModel, types.undefined, types.null),undefined)});
export type BlockRequest = Instance<typeof BlockRequestModel>;

export const UserPermissionsModel = types.model('UserPermissionsModel', {
permissions: types.optional(types.union(types.array(types.enumeration(['VIEW_EXTERNAL_LINKS', 'VIEW_RATE_CARDS', 'VIEW_SALES_PERSONS', 'CREATE_SALES_PERSON', 'EDIT_SALES_PERSON', 'CREATE_CUSTOMER_MANUAL', 'VIEW_PROPOSALS', 'CREATE_PROPOSAL', 'VIEW_CONTRACTS', 'CREATE_CALCULATION', 'CREATE_UPGRADE', 'VIEW_UPGRADE', 'VIEW_INTRODUCER_EQUIPMENT_SUPPLIERS', 'CREATE_PROPOSAL_EXCEL_EXPORT', 'CREATE_PORTFOLIO_EXCEL_EXPORT', 'CREATE_INTRODUCER_EQUIPMENT_SUPPLIER', 'VIEW_RATE_CALCULATOR', 'VIEW_REPORT'])), types.undefined, types.null),undefined)});
export type UserPermissions = Instance<typeof UserPermissionsModel>;

export const UserProfileModel = types.model('UserProfileModel', {
gid: types.optional(types.union(types.string, types.undefined, types.null),undefined),
firstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
lastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
accountLocked: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
consentToMarketingCommunications: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type UserProfile = Instance<typeof UserProfileModel>;

export const AddressModel = types.model('AddressModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
address1: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address2: types.optional(types.union(types.string, types.undefined, types.null),undefined),
houseNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
houseName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
flatName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
phoneNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
mobilePhoneNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
email: types.optional(types.union(types.string, types.undefined, types.null),undefined),
country: types.optional(types.union(CountryModel, types.undefined, types.null),undefined),
region: types.optional(types.union(RegionModel, types.undefined, types.null),undefined)});
export type Address = Instance<typeof AddressModel>;

export const AppliedPutOptionEntityModel = types.model('AppliedPutOptionEntityModel', {
putOptionType: types.optional(types.union(PutOptionTypeModel, types.undefined, types.null),undefined),
value: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type AppliedPutOptionEntity = Instance<typeof AppliedPutOptionEntityModel>;

export const BusinessPartnerModel = types.model('BusinessPartnerModel', {
id: types.integer,
name: types.string,
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
officialAddress: AddressModel,
rulesForSigning: types.optional(types.union(types.string, types.undefined, types.null),undefined),
legalForm: types.optional(types.union(LegalFormModel, types.undefined, types.null),undefined),
businessBlocked: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type BusinessPartner = Instance<typeof BusinessPartnerModel>;

export const CommissionModel = types.model('CommissionModel', {
type: types.optional(types.union(CommissionTypeModel, types.undefined, types.null),undefined),
amount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
percentage: types.optional(types.union(types.number, types.undefined, types.null),undefined),
usePercentage: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
manualSet: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type Commission = Instance<typeof CommissionModel>;

export const DecisionModel = types.model('DecisionModel', {
decisionStatus: types.optional(types.union(types.enumeration(['NOT_AVAILABLE', 'PENDING', 'ACCEPTED', 'DECLINED', 'MORE_INFOMATION_REQUIRED', 'CANCELED', 'UNKNOWN', 'AMEND', 'ACCEPTED_WITH_CONDITIONS', 'EXPIRED']), types.undefined, types.null),undefined),
decisionRequestedDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
decisionDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
decisionValidUntilDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
comment: types.optional(types.union(types.string, types.undefined, types.null),undefined),
condition: types.optional(types.union(types.string, types.undefined, types.null),undefined),
reason: types.optional(types.union(types.string, types.undefined, types.null),undefined),
commentToRiskAnalyst: types.optional(types.union(types.string, types.undefined, types.null),undefined),
siemensMayContactCustomer: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
decisionDocumentAvailable: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
prelease: types.optional(types.union(FieldDefBooleanModel, types.undefined, types.null),undefined)});
export type Decision = Instance<typeof DecisionModel>;

export const FeeModel = types.model('FeeModel', {
feeType: types.optional(types.union(FeeTypeModel, types.undefined, types.null),undefined),
amount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
manualSet: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
feeIncluded: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type Fee = Instance<typeof FeeModel>;

export const LegalFormConfigModel = types.model('LegalFormConfigModel', {
legalForm: types.optional(types.union(LegalFormModel, types.undefined, types.null),undefined),
numberOfPartnersFieldDef: types.optional(types.union(NumberOfPartnersConfigEntityModel, types.undefined, types.null),undefined),
professionTypeRequired: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type LegalFormConfig = Instance<typeof LegalFormConfigModel>;

export const OfficeModel = types.model('OfficeModel', {
id: types.integer,
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
postalAddress: types.optional(types.union(AddressModel, types.undefined, types.null),undefined)});
export type Office = Instance<typeof OfficeModel>;

export const PaymentLineModel = types.model('PaymentLineModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
startDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
factor: types.optional(types.union(types.number, types.undefined, types.null),undefined),
factorType: types.optional(types.union(types.enumeration(['PLAIN', 'FACTORED_FOLLOWING', 'FACTORED_PREVIOUS']), types.undefined, types.null),undefined),
periodMultiplier: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
numberOfPayments: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
firstDueDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
value: types.optional(types.union(types.number, types.undefined, types.null),undefined),
maintenance: types.optional(types.union(types.number, types.undefined, types.null),undefined),
period: types.optional(types.union(PeriodModel, types.undefined, types.null),undefined),
valueType: types.optional(types.union(types.enumeration(['AMORTIZATION', 'PAYMENT', 'SKIP', 'FINAL']), types.undefined, types.null),undefined)});
export type PaymentLine = Instance<typeof PaymentLineModel>;

export const PostponedStartModel = types.model('PostponedStartModel', {
month: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
affectedParty: types.optional(types.union(PostponedStartAffectedPartyModel, types.undefined, types.null),undefined)});
export type PostponedStart = Instance<typeof PostponedStartModel>;

export const ProposalCustomerModel = types.model('ProposalCustomerModel', {
businessPartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
introducerCustomerLabel: types.optional(types.union(types.string, types.undefined, types.null),undefined),
customerPartnerLimit: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
invoiceAddress: types.optional(types.union(AddressModel, types.undefined, types.null),undefined),
deliveryAddress: types.optional(types.union(AddressModel, types.undefined, types.null),undefined),
invoiceContact: types.optional(types.union(CustomerInvoiceContactModel, types.undefined, types.null),undefined),
legalFormConfig: types.optional(types.union(LegalFormConfigModel, types.undefined, types.null),undefined)});
export type ProposalCustomer = Instance<typeof ProposalCustomerModel>;

export const SpecialPaymentModel = types.model('SpecialPaymentModel', {
type: SpecialPaymentTypeModel,
amount: types.number,
equipmentDetails: types.optional(types.union(types.string, types.undefined, types.null),undefined),
serialNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type SpecialPayment = Instance<typeof SpecialPaymentModel>;

export const AddressSearchResultItemModel = types.model('AddressSearchResultItemModel', {
ref: types.optional(types.union(types.string, types.undefined, types.null),undefined),
displayText: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address: types.optional(types.union(AddressModel, types.undefined, types.null),undefined)});
export type AddressSearchResultItem = Instance<typeof AddressSearchResultItemModel>;

export const AssetSubTypeModel = types.model('AssetSubTypeModel', {
id: types.integer,
assetTypeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
name: types.string,
pricePerUnits: types.optional(types.union(types.array(PricePerUnitModel), types.undefined, types.null),undefined)});
export type AssetSubType = Instance<typeof AssetSubTypeModel>;

export const AssetTypesModel = types.model('AssetTypesModel', {
assetTypes: types.optional(types.union(types.array(AssetTypeModel), types.undefined, types.null),undefined),
assetSubTypes: types.optional(types.union(types.map(types.array(AssetSubTypeModel)), types.undefined, types.null),undefined),
defaultAssetType: types.optional(types.union(AssetTypeModel, types.undefined, types.null),undefined),
defaultAssetSubType: types.optional(types.union(AssetSubTypeModel, types.undefined, types.null),undefined)});
export type AssetTypes = Instance<typeof AssetTypesModel>;

export const CalculationConfigurationModel = types.model('CalculationConfigurationModel', {
officeId: types.integer,
office: types.optional(types.union(OfficeModel, types.undefined, types.null),undefined),
marketProductsConfig: types.optional(types.union(MarketProductsConfigEntityModel, types.undefined, types.null),undefined),
pricelistsConfig: types.optional(types.union(PricelistsConfigEntityModel, types.undefined, types.null),undefined),
paymentPeriodsConfig: types.optional(types.union(PaymentPeriodsConfigEntityModel, types.undefined, types.null),undefined),
paymentMethodsConfig: types.optional(types.union(PaymentMethodsConfigEntityModel, types.undefined, types.null),undefined),
paymentModeConfig: types.optional(types.union(PaymentModeConfigEntityModel, types.undefined, types.null),undefined),
onlyRentalsCalculationMode: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
currency: CurrencyModel,
interestRateConfig: types.optional(types.union(InterestRateConfigModel, types.undefined, types.null),undefined),
customerPtoConfig: types.optional(types.union(CustomerPtoConfigEntityModel, types.undefined, types.null),undefined),
tariffRateConfig: types.optional(types.union(TariffRateConfigEntityModel, types.undefined, types.null),undefined),
assetValueConfig: types.optional(types.union(AssetValueConfigEntityModel, types.undefined, types.null),undefined),
residualValueConfig: types.optional(types.union(ResidualValueConfigModel, types.undefined, types.null),undefined),
termConfig: types.optional(types.union(TermConfigEntityModel, types.undefined, types.null),undefined),
amountFinancedConfig: types.optional(types.union(AmountFinancedConfigEntityModel, types.undefined, types.null),undefined),
rentalSkipsConfig: types.optional(types.union(RentalSkipsConfigModel, types.undefined, types.null),undefined),
finalPaymentsConfig: types.optional(types.union(FinalPaymentsConfigModel, types.undefined, types.null),undefined),
factoredPaymentsConfig: types.optional(types.union(FactoredPaymentsConfigModel, types.undefined, types.null),undefined),
postponedStartAffectedParties: types.optional(types.union(types.array(PostponedStartAffectedPartyModel), types.undefined, types.null),undefined),
postponedStartReadonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
commissionTypes: types.optional(types.union(types.array(CommissionTypeModel), types.undefined, types.null),undefined),
feeTypes: types.optional(types.union(types.array(FeeTypeModel), types.undefined, types.null),undefined),
specialPaymentTypes: types.optional(types.union(types.array(SpecialPaymentTypeModel), types.undefined, types.null),undefined),
putOptionTypes: types.optional(types.union(types.array(PutOptionTypeModel), types.undefined, types.null),undefined),
allowedOperations: types.optional(types.union(types.array(types.enumeration(['CALC_RENTAL', 'CALC_RESIDUAL_VALUE', 'CALC_INTEREST_RATE', 'CALC_COMMISSIONS', 'CALC_ASSET_VALUE', 'CALC_TERM', 'CALC_KEEPING_CUSTOMER_PTO'])), types.undefined, types.null),undefined),
showRentalTotalValueInclMaintenance: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type CalculationConfiguration = Instance<typeof CalculationConfigurationModel>;

export const ContractUpgradeQuotePeriodModel = types.model('ContractUpgradeQuotePeriodModel', {
period: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
rentalDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
validTo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
values: types.optional(types.union(types.array(ContractUpgradeQuoteValueModel), types.undefined, types.null),undefined),
allowedForUpgrade: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ContractUpgradeQuotePeriod = Instance<typeof ContractUpgradeQuotePeriodModel>;

export const ContractModel = types.model('ContractModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
amountFinanced: types.optional(types.union(types.number, types.undefined, types.null),undefined),
currency: types.optional(types.union(CurrencyModel, types.undefined, types.null),undefined),
customer: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
customerAddress: types.optional(types.union(AddressModel, types.undefined, types.null),undefined),
marketProduct: types.optional(types.union(MarketProductModel, types.undefined, types.null),undefined),
latestFetchedUpgradeQuoteValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
salesPerson: types.optional(types.union(IntroducerContactModel, types.undefined, types.null),undefined),
salesOffice: types.optional(types.union(OfficeModel, types.undefined, types.null),undefined),
startDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
endDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
term: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
terminationNoticeDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
completedTerm: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
remainingTerm: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
remainingRentals: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
paymentMethodCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
nextInvoiceDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
paymentDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
primaryArrearsValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
residualValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
fetchUpgradeQuoteAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
createUpgradeAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
createCoTermAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
guaranteeTypeRepurchaserForSecurities: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
contractSecurities: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
terminationReceivedNotice: types.optional(types.union(types.string, types.undefined, types.null),undefined),
contractStatus: types.optional(types.union(types.enumeration(['LIVE', 'EXTENSION', 'MATURED', 'TERMINATED']), types.undefined, types.null),undefined)});
export type Contract = Instance<typeof ContractModel>;

export const ContractAssetModel = types.model('ContractAssetModel', {
description: types.optional(types.union(types.string, types.undefined, types.null),undefined),
quantity: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
serialNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
equipmentSupplier: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
location: types.optional(types.union(types.string, types.undefined, types.null),undefined),
presentValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
paymentDate: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ContractAsset = Instance<typeof ContractAssetModel>;

export const ContractBusinesspartnerModel = types.model('ContractBusinesspartnerModel', {
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
roleAlias: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address: types.optional(types.union(AddressModel, types.undefined, types.null),undefined)});
export type ContractBusinesspartner = Instance<typeof ContractBusinesspartnerModel>;

export const ContractChargeModel = types.model('ContractChargeModel', {
description: types.optional(types.union(types.string, types.undefined, types.null),undefined),
startDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
amount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
frequency: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
number: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businesspartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined)});
export type ContractCharge = Instance<typeof ContractChargeModel>;

export const ContractRentalModel = types.model('ContractRentalModel', {
startDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
amount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
frequency: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
number: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businesspartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined)});
export type ContractRental = Instance<typeof ContractRentalModel>;

export const ContractSecurityModel = types.model('ContractSecurityModel', {
guarantee: types.optional(types.union(types.string, types.undefined, types.null),undefined),
limit: types.optional(types.union(types.number, types.undefined, types.null),undefined),
startDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
endDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
businesspartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined)});
export type ContractSecurity = Instance<typeof ContractSecurityModel>;

export const ContractSearchResultsModel = types.model('ContractSearchResultsModel', {
data: types.optional(types.union(types.array(ContractModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type ContractSearchResults = Instance<typeof ContractSearchResultsModel>;

export const FileBasedPaginatedResponseModel = types.model('FileBasedPaginatedResponseModel', {
data: types.optional(types.union(FileDataModel, types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type FileBasedPaginatedResponse = Instance<typeof FileBasedPaginatedResponseModel>;

export const ContractSearchConfigModel = types.model('ContractSearchConfigModel', {
searchByProposalNumberAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
searchByTerminationNoticeAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
searchByAssetsAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
searchByCustomerLabelAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
searchByPortfolioStatusAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
marketproducts: types.optional(types.union(types.array(MarketProductModel), types.undefined, types.null),undefined),
salesContacts: types.optional(types.union(types.array(IntroducerContactModel), types.undefined, types.null),undefined),
offices: types.optional(types.union(types.array(OfficeModel), types.undefined, types.null),undefined),
portfolioStatusFilterCriteria: types.optional(types.union(types.array(types.enumeration(['ACTIVE', 'LIVE', 'EXTENSION', 'MATURED', 'TERMINATED', 'ALL'])), types.undefined, types.null),undefined),
defaultPortfolioStatusFilterCriterium: types.optional(types.union(types.enumeration(['ACTIVE', 'LIVE', 'EXTENSION', 'MATURED', 'TERMINATED', 'ALL']), types.undefined, types.null),undefined),
dateSearchTypes: types.optional(types.union(types.array(DateSearchTypeModel), types.undefined, types.null),undefined),
contractConfig: types.optional(types.union(ContractConfigModel, types.undefined, types.null),undefined)});
export type ContractSearchConfig = Instance<typeof ContractSearchConfigModel>;

export const PartnerModel = types.model('PartnerModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businesspartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
title: types.optional(types.union(TitleModel, types.undefined, types.null),undefined),
firstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
lastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
secondLastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
ssn: types.optional(types.union(types.string, types.undefined, types.null),undefined),
dateOfBirth: types.optional(types.union(types.string, types.undefined, types.null),undefined),
addressResidencyLength: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
liable: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type Partner = Instance<typeof PartnerModel>;

export const BusinessPartnerSearchConfigModel = types.model('BusinessPartnerSearchConfigModel', {
country: types.optional(types.union(FieldDefCountryModel, types.undefined, types.null),undefined),
region: types.optional(types.union(FieldDefRegionModel, types.undefined, types.null),undefined),
postalCode: types.optional(types.union(MinMaxLengthFieldDefStringModel, types.undefined, types.null),undefined),
street: types.optional(types.union(FieldDefStringModel, types.undefined, types.null),undefined),
website: types.optional(types.union(FieldDefStringModel, types.undefined, types.null),undefined),
customPostalCodeCityValidation: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type BusinessPartnerSearchConfig = Instance<typeof BusinessPartnerSearchConfigModel>;

export const IntroducerConfigModel = types.model('IntroducerConfigModel', {
addressLookupAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
phoneNumberOnAddressLevel: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
businesspartnerSearchConfig: types.optional(types.union(BusinessPartnerSearchConfigModel, types.undefined, types.null),undefined),
proposalSearchAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
eulaAccepted: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
addressesWithDetailedHouseNumbers: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
sendUpgradeEmailAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
broker: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type IntroducerConfig = Instance<typeof IntroducerConfigModel>;

export const IntroducerEquipmentSupplierListPageResultModel = types.model('IntroducerEquipmentSupplierListPageResultModel', {
data: types.optional(types.union(types.array(BusinessPartnerModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type IntroducerEquipmentSupplierListPageResult = Instance<typeof IntroducerEquipmentSupplierListPageResultModel>;

export const ReportContractModel = types.model('ReportContractModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
introducerName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
introducerBpId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
customerName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
customerBpId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
equipmentSupplierName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
equipmentSupplierBpId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
amountFinanced: types.optional(types.union(ReportValueDoubleModel, types.undefined, types.null),undefined),
arrears: types.optional(types.union(ReportValueDoubleModel, types.undefined, types.null),undefined),
badDebt: types.optional(types.union(ReportValueDoubleModel, types.undefined, types.null),undefined)});
export type ReportContract = Instance<typeof ReportContractModel>;

export const ReportContractsResponseModel = types.model('ReportContractsResponseModel', {
data: types.optional(types.union(types.array(ReportContractModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined),
key: types.optional(types.union(types.enumeration(['SUMMARY_INFORMATION', 'CUSTOMER_ARREARS', 'INTRODUCER_ARREARS', 'THIRTY_DAY_ARREARS', 'BAD_DEBT_WRITE_OFFS', 'FIRST_YEAR_BAD_DEBT']), types.undefined, types.null),undefined)});
export type ReportContractsResponse = Instance<typeof ReportContractsResponseModel>;

export const TemporalReportModel = types.model('TemporalReportModel', {
timespan: types.optional(types.union(TimespanModel, types.undefined, types.null),undefined),
metrics: types.optional(types.union(types.array(ReportMetricNumberModel), types.undefined, types.null),undefined)});
export type TemporalReport = Instance<typeof TemporalReportModel>;

export const ReportContractsMetricModel = types.model('ReportContractsMetricModel', {
key: types.optional(types.union(types.enumeration(['SUMMARY_INFORMATION', 'CUSTOMER_ARREARS', 'INTRODUCER_ARREARS', 'THIRTY_DAY_ARREARS', 'BAD_DEBT_WRITE_OFFS', 'FIRST_YEAR_BAD_DEBT']), types.undefined, types.null),undefined),
count: types.optional(types.union(ReportValueLongModel, types.undefined, types.null),undefined),
amount: types.optional(types.union(ReportValueDoubleModel, types.undefined, types.null),undefined)});
export type ReportContractsMetric = Instance<typeof ReportContractsMetricModel>;

export const StaticReportModel = types.model('StaticReportModel', {
metrics: types.optional(types.union(types.array(ReportContractsMetricModel), types.undefined, types.null),undefined)});
export type StaticReport = Instance<typeof StaticReportModel>;

export const IntroducerModel = types.model('IntroducerModel', {
id: types.integer,
countryCode: types.string,
bpId: types.integer,
name: types.string,
contactDetails: IntroducerSalesContactDataModel});
export type Introducer = Instance<typeof IntroducerModel>;

export const AssetEquipmentSupplierModel = types.model('AssetEquipmentSupplierModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businessPartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined)});
export type AssetEquipmentSupplier = Instance<typeof AssetEquipmentSupplierModel>;

export const AssetModelModel = types.model('AssetModelModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
description: types.optional(types.union(types.string, types.undefined, types.null),undefined),
assetType: types.optional(types.union(AssetTypeModel, types.undefined, types.null),undefined),
assetSubType: types.optional(types.union(AssetSubTypeModel, types.undefined, types.null),undefined),
fixPrice: types.optional(types.union(types.number, types.undefined, types.null),undefined)});
export type AssetModel = Instance<typeof AssetModelModel>;

export const FieldDefAssetModelModel = types.model('FieldDefAssetModelModel', {
defaultValue: types.optional(types.union(AssetModelModel, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
disabled: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
rendered: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validValues: types.optional(types.union(types.array(AssetModelModel), types.undefined, types.null),undefined)});
export type FieldDefAssetModel = Instance<typeof FieldDefAssetModelModel>;

export const ProposalEquipmentSupplierModel = types.model('ProposalEquipmentSupplierModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businessPartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
isSupplierForProposalAssets: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
supplierForProposalAssets: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type ProposalEquipmentSupplier = Instance<typeof ProposalEquipmentSupplierModel>;

export const AssetAddressResultModel = types.model('AssetAddressResultModel', {
data: types.optional(types.union(types.array(AddressModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type AssetAddressResult = Instance<typeof AssetAddressResultModel>;

export const CustomerSearchResultSelectionModel = types.model('CustomerSearchResultSelectionModel', {
customer: BusinessPartnerRefModel,
legalForm: types.optional(types.union(LegalFormModel, types.undefined, types.null),undefined),
professionType: types.optional(types.union(ProfessionTypeModel, types.undefined, types.null),undefined),
numberOfPartners: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
partners: types.optional(types.union(types.array(PartnerModel), types.undefined, types.null),undefined)});
export type CustomerSearchResultSelection = Instance<typeof CustomerSearchResultSelectionModel>;

export const ManualCustomerCreateModel = types.model('ManualCustomerCreateModel', {
legalForm: LegalFormModel,
professionType: types.optional(types.union(ProfessionTypeModel, types.undefined, types.null),undefined),
numberOfPartners: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
companyName: types.string,
dateOfIncorporation: types.optional(types.union(types.string, types.undefined, types.null),undefined),
registrationNumber: types.string,
address: types.optional(types.union(AddressModel, types.undefined, types.null),undefined),
partners: types.optional(types.union(types.array(PartnerModel), types.undefined, types.null),undefined)});
export type ManualCustomerCreate = Instance<typeof ManualCustomerCreateModel>;

export const ManualCustomerConfigModel = types.model('ManualCustomerConfigModel', {
allowedLegalForms: types.optional(types.union(types.array(LegalFormConfigModel), types.undefined, types.null),undefined),
numberFromPartnerDetailsNotRequired: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type ManualCustomerConfig = Instance<typeof ManualCustomerConfigModel>;

export const DecisionPrecheckResultModel = types.model('DecisionPrecheckResultModel', {
requestAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
cancelAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
preconditionViolations: types.optional(types.union(types.array(DecisionPrecheckViolationModel), types.undefined, types.null),undefined)});
export type DecisionPrecheckResult = Instance<typeof DecisionPrecheckResultModel>;

export const PrintDocumentIdentifierModel = types.model('PrintDocumentIdentifierModel', {
id: types.optional(types.union(DocumentIdentifierModel, types.undefined, types.null),undefined),
printOptions: types.optional(types.union(types.array(PrintOptionModel), types.undefined, types.null),undefined)});
export type PrintDocumentIdentifier = Instance<typeof PrintDocumentIdentifierModel>;

export const ProposalDocumentModel = types.model('ProposalDocumentModel', {
id: types.optional(types.union(DocumentIdentifierModel, types.undefined, types.null),undefined),
documentType: types.optional(types.union(DocumentTypeModel, types.undefined, types.null),undefined),
printOptions: types.optional(types.union(types.array(PrintOptionModel), types.undefined, types.null),undefined),
downloadAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
downloadUploadedAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
sendByEmailAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
required: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
received: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
receivedDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
valid: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
validationDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
reason: types.optional(types.union(types.string, types.undefined, types.null),undefined),
reasonNote: types.optional(types.union(types.string, types.undefined, types.null),undefined),
eligibleForSigning: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
sendSigningRequestAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
canBeSignedCombined: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
signingRequest: types.optional(types.union(SigningRequestModel, types.undefined, types.null),undefined)});
export type ProposalDocument = Instance<typeof ProposalDocumentModel>;

export const SignaturePreConditionModel = types.model('SignaturePreConditionModel', {
signatureTypes: types.array(SignatureTypeConfigModel)});
export type SignaturePreCondition = Instance<typeof SignaturePreConditionModel>;

export const SendEmailRequestModel = types.model('SendEmailRequestModel', {
documentIds: types.array(PrintDocumentIdentifierModel),
emailTo: types.string});
export type SendEmailRequest = Instance<typeof SendEmailRequestModel>;

export const GuarantorModel = types.model('GuarantorModel', {
id: types.integer,
name: types.string,
registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
officialAddress: AddressModel,
guaranteeType: GuaranteeTypeModel,
hasFico: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type Guarantor = Instance<typeof GuarantorModel>;

export const PersonalGuarantorCreationDataModel = types.model('PersonalGuarantorCreationDataModel', {
fico: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
firstName: types.string,
lastName: types.string,
secondLastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
address: AddressModel,
titleId: types.integer,
dateOfBirth: types.optional(types.union(types.string, types.undefined, types.null),undefined),
ssn: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type PersonalGuarantorCreationData = Instance<typeof PersonalGuarantorCreationDataModel>;

export const PersonalGuarantorCandidateModel = types.model('PersonalGuarantorCandidateModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
businesspartner: types.optional(types.union(BusinessPartnerModel, types.undefined, types.null),undefined),
firstName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
lastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
secondLastName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
title: types.optional(types.union(TitleModel, types.undefined, types.null),undefined),
ssn: types.optional(types.union(types.string, types.undefined, types.null),undefined),
dateOfBirth: types.optional(types.union(types.string, types.undefined, types.null),undefined),
addressResidencyLength: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
appliedToProposal: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)});
export type PersonalGuarantorCandidate = Instance<typeof PersonalGuarantorCandidateModel>;

export const ProposalSummaryModel = types.model('ProposalSummaryModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
proposalNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
created: types.optional(types.union(types.string, types.undefined, types.null),undefined),
updated: types.optional(types.union(types.string, types.undefined, types.null),undefined),
office: types.optional(types.union(OfficeModel, types.undefined, types.null),undefined),
salesContact: types.optional(types.union(IntroducerContactModel, types.undefined, types.null),undefined),
customer: types.optional(types.union(ProposalCustomerModel, types.undefined, types.null),undefined),
assetValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
currency: types.optional(types.union(CurrencyModel, types.undefined, types.null),undefined),
status: types.optional(types.union(types.enumeration(['OFFER', 'PROSPECT', 'DEAL_AMENDMENT', 'MORE_INFORMATION_REQUIRED', 'WAITING_FOR_DECISION', 'PENDING', 'CREDIT_APPROVED', 'WAITING_FOR_PRINT', 'WAITING', 'WAITING_FOR_E_SIGNATURE', 'DOCUMENTS_ARRIVED', 'DOCUMENTS_COMPLETED', 'CONTRACT_STARTED', 'WAITING_BACKEND', 'ACTIVATED', 'PAID', 'EXPIRED', 'DECLINED', 'WAITING_ACTIVATION']), types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type ProposalSummary = Instance<typeof ProposalSummaryModel>;

export const ProposalSearchConfigModel = types.model('ProposalSearchConfigModel', {
customerLabelAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
proposalLabelAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
marketproducts: types.optional(types.union(types.array(MarketProductModel), types.undefined, types.null),undefined),
assetDescriptionAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
assetSerialNumberAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
workflowStatus: types.optional(types.union(types.array(WorkflowStatusModel), types.undefined, types.null),undefined),
documentStatus: types.optional(types.union(types.array(DocumentStatusModel), types.undefined, types.null),undefined),
salesContacts: types.optional(types.union(types.array(IntroducerContactModel), types.undefined, types.null),undefined),
offices: types.optional(types.union(types.array(OfficeModel), types.undefined, types.null),undefined),
signatureStatus: types.optional(types.union(types.array(types.enumeration(['FINALIZED', 'WAITING_FOR_SIGNATURE', 'SIGNED', 'EXPIRED', 'ABORTED', 'AUTHORING', 'NOT_VALID'])), types.undefined, types.null),undefined),
signatureDocumentTypes: types.optional(types.union(types.array(DocumentTypeModel), types.undefined, types.null),undefined),
maxSize: types.optional(types.union(types.integer, types.undefined, types.null),undefined)});
export type ProposalSearchConfig = Instance<typeof ProposalSearchConfigModel>;

export const SalesPersonModel = types.model('SalesPersonModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
firstName: types.string,
lastName: types.string,
title: types.optional(types.union(TitleModel, types.undefined, types.null),undefined),
email: types.string,
phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
cellPhone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
fax: types.optional(types.union(types.string, types.undefined, types.null),undefined),
role: SalesPersonRoleModel,
loginPresent: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
officeIds: types.optional(types.union(types.array(types.integer), types.undefined, types.null),undefined)});
export type SalesPerson = Instance<typeof SalesPersonModel>;

export const SalesPersonCreateModel = types.model('SalesPersonCreateModel', {
salesPerson: SalesPersonModel,
officeIds: types.array(types.integer)});
export type SalesPersonCreate = Instance<typeof SalesPersonCreateModel>;

export const PaymentModel = types.model('PaymentModel', {
paymentMode: types.optional(types.union(types.enumeration(['IN_ADVANCE', 'ARREARS']), types.undefined, types.null),undefined),
avgPaymentPerMonth: types.optional(types.union(types.number, types.undefined, types.null),undefined),
avgPaymentPerPeriod: types.optional(types.union(types.number, types.undefined, types.null),undefined),
paymentLines: types.optional(types.union(types.array(PaymentLineModel), types.undefined, types.null),undefined)});
export type Payment = Instance<typeof PaymentModel>;

export const ContractUpgradeQuoteModel = types.model('ContractUpgradeQuoteModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
created: types.optional(types.union(types.string, types.undefined, types.null),undefined),
offers: types.optional(types.union(types.array(EarlyCancellationOfferModel), types.undefined, types.null),undefined),
periods: types.optional(types.union(types.array(ContractUpgradeQuotePeriodModel), types.undefined, types.null),undefined)});
export type ContractUpgradeQuote = Instance<typeof ContractUpgradeQuoteModel>;

export const AssetModel = types.model('AssetModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
quantity: types.integer,
description: types.string,
valuePerAsset: types.optional(types.union(types.number, types.undefined, types.null),undefined),
totalValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
assetModel: types.optional(types.union(AssetModelModel, types.undefined, types.null),undefined),
assetModelId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
assetSubType: types.optional(types.union(AssetSubTypeModel, types.undefined, types.null),undefined),
assetSubTypeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
serviceAgreement: types.optional(types.union(ServiceAgreementModel, types.undefined, types.null),undefined),
address: types.optional(types.union(AddressModel, types.undefined, types.null),undefined),
serialNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
deliveryDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
condition: types.optional(types.union(types.enumeration(['NEW', 'REFURBISHED', 'USED', 'REMANUFACTORED', 'RETAINED', 'EX_DEMO']), types.undefined, types.null),undefined),
productionYear: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
productionMonth: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
saleAndLeaseBack: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
subHired: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
equipmentSupplier: types.optional(types.union(AssetEquipmentSupplierModel, types.undefined, types.null),undefined),
equipmentSupplierId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
readonly: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
deletionAllowed: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
shareQuoteNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined)});
export type Asset = Instance<typeof AssetModel>;

export const AssetConfigModel = types.model('AssetConfigModel', {
introducerId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
assetTypes: types.optional(types.union(AssetTypesModel, types.undefined, types.null),undefined),
defaultAssetEquipmentSupplier: types.optional(types.union(ProposalEquipmentSupplierModel, types.undefined, types.null),undefined),
assetModel: types.optional(types.union(FieldDefAssetModelModel, types.undefined, types.null),undefined),
assetCondition: types.optional(types.union(FieldDefAssetConditionEnumModel, types.undefined, types.null),undefined),
productionDate: types.optional(types.union(FieldDefIntegerModel, types.undefined, types.null),undefined),
valuePerAsset: types.optional(types.union(FieldDefBigDecimalModel, types.undefined, types.null),undefined),
totalValue: types.optional(types.union(FieldDefBigDecimalModel, types.undefined, types.null),undefined),
saleAndLeaseBack: types.optional(types.union(FieldDefBooleanModel, types.undefined, types.null),undefined),
subHired: types.optional(types.union(FieldDefBooleanModel, types.undefined, types.null),undefined)});
export type AssetConfig = Instance<typeof AssetConfigModel>;

export const AssetListResponseModel = types.model('AssetListResponseModel', {
data: types.optional(types.union(types.array(AssetModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type AssetListResponse = Instance<typeof AssetListResponseModel>;

export const DownloadRequestModel = types.model('DownloadRequestModel', {
documentIds: types.array(PrintDocumentIdentifierModel)});
export type DownloadRequest = Instance<typeof DownloadRequestModel>;

export const AvailablePersonalGuarantorsListPageResultModel = types.model('AvailablePersonalGuarantorsListPageResultModel', {
data: types.optional(types.union(types.array(PersonalGuarantorCandidateModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type AvailablePersonalGuarantorsListPageResult = Instance<typeof AvailablePersonalGuarantorsListPageResultModel>;

export const ProposalSearchResultModel = types.model('ProposalSearchResultModel', {
data: types.optional(types.union(types.array(ProposalSummaryModel), types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined),
pagination: types.optional(types.union(PaginationInfoModel, types.undefined, types.null),undefined)});
export type ProposalSearchResult = Instance<typeof ProposalSearchResultModel>;

export const CalculationSheetModel = types.model('CalculationSheetModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
created: types.optional(types.union(types.string, types.undefined, types.null),undefined),
marketProductId: types.integer,
marketProduct: types.optional(types.union(MarketProductModel, types.undefined, types.null),undefined),
introducerPricelistId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
introducerPricelist: types.optional(types.union(PriceListModel, types.undefined, types.null),undefined),
paymentMethod: types.optional(types.union(PaymentMethodModel, types.undefined, types.null),undefined),
payment: types.optional(types.union(PaymentModel, types.undefined, types.null),undefined),
interestRate: types.optional(types.union(types.number, types.undefined, types.null),undefined),
customerPto: types.optional(types.union(types.number, types.undefined, types.null),undefined),
tariffRate: types.optional(types.union(types.number, types.undefined, types.null),undefined),
selloutTariffRate: types.optional(types.union(types.number, types.undefined, types.null),undefined),
upgradeValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
assetValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
amountFinanced: types.optional(types.union(types.number, types.undefined, types.null),undefined),
vendorInvoice: types.optional(types.union(types.number, types.undefined, types.null),undefined),
vatAmount: types.optional(types.union(types.number, types.undefined, types.null),undefined),
residualValue: types.optional(types.union(ResidualValueModel, types.undefined, types.null),undefined),
term: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
postponedStart: types.optional(types.union(PostponedStartModel, types.undefined, types.null),undefined),
commissions: types.optional(types.union(types.array(CommissionModel), types.undefined, types.null),undefined),
fees: types.optional(types.union(types.array(FeeModel), types.undefined, types.null),undefined),
optionFee: types.optional(types.union(types.number, types.undefined, types.null),undefined),
specialPayments: types.optional(types.union(types.array(SpecialPaymentModel), types.undefined, types.null),undefined),
specialPaymentsSum: types.optional(types.union(types.number, types.undefined, types.null),undefined),
appliedPutOption: types.optional(types.union(AppliedPutOptionEntityModel, types.undefined, types.null),undefined)});
export type CalculationSheet = Instance<typeof CalculationSheetModel>;

export const ProposalModel = types.model('ProposalModel', {
id: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
proposalNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
contractNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
specialConditionText: types.optional(types.union(types.string, types.undefined, types.null),undefined),
scheduleAssetsDescription: types.optional(types.union(types.string, types.undefined, types.null),undefined),
created: types.optional(types.union(types.string, types.undefined, types.null),undefined),
updated: types.optional(types.union(types.string, types.undefined, types.null),undefined),
currency: types.optional(types.union(CurrencyModel, types.undefined, types.null),undefined),
office: OfficeModel,
salesContact: types.optional(types.union(IntroducerContactModel, types.undefined, types.null),undefined),
customer: types.optional(types.union(ProposalCustomerModel, types.undefined, types.null),undefined),
signatories: types.optional(types.union(types.array(SignatoryModel), types.undefined, types.null),undefined),
currentCalculation: types.optional(types.union(CalculationSheetModel, types.undefined, types.null),undefined),
decision: types.optional(types.union(DecisionModel, types.undefined, types.null),undefined),
status: types.optional(types.union(ProposalStatusModel, types.undefined, types.null),undefined),
equipmentSupplierSummary: types.optional(types.union(EquipmentSupplierSummaryEntityModel, types.undefined, types.null),undefined),
assetSummary: types.optional(types.union(AssetSummaryModel, types.undefined, types.null),undefined),
loanSummary: types.optional(types.union(LoanSummaryModel, types.undefined, types.null),undefined),
upgradeSummary: types.optional(types.union(UpgradeSummaryModel, types.undefined, types.null),undefined)});
export type Proposal = Instance<typeof ProposalModel>;

export const CalculationConfigResponseModel = types.model('CalculationConfigResponseModel', {
config: CalculationConfigurationModel,
calculationSheet: CalculationSheetModel});
export type CalculationConfigResponse = Instance<typeof CalculationConfigResponseModel>;

export const CalculationResponseModel = types.model('CalculationResponseModel', {
config: types.optional(types.union(CalculationConfigurationModel, types.undefined, types.null),undefined),
sheet: types.optional(types.union(CalculationSheetModel, types.undefined, types.null),undefined),
errors: types.optional(types.union(types.array(types.string), types.undefined, types.null),undefined)});
export type CalculationResponse = Instance<typeof CalculationResponseModel>;

export const ProposalCalculationResponseModel = types.model('ProposalCalculationResponseModel', {
config: types.optional(types.union(CalculationConfigurationModel, types.undefined, types.null),undefined),
sheet: types.optional(types.union(CalculationSheetModel, types.undefined, types.null),undefined),
errors: types.optional(types.union(types.array(types.string), types.undefined, types.null),undefined),
proposal: types.optional(types.union(ProposalModel, types.undefined, types.null),undefined)});
export type ProposalCalculationResponse = Instance<typeof ProposalCalculationResponseModel>;

export const CalculationRequestModel = types.model('CalculationRequestModel', {
operation: types.optional(types.union(types.enumeration(['CALC_RENTAL', 'CALC_RESIDUAL_VALUE', 'CALC_INTEREST_RATE', 'CALC_COMMISSIONS', 'CALC_ASSET_VALUE', 'CALC_TERM', 'CALC_KEEPING_CUSTOMER_PTO']), types.undefined, types.null),undefined),
sheet: CalculationSheetModel});
export type CalculationRequest = Instance<typeof CalculationRequestModel>;

export const ProposalResponseModel = types.model('ProposalResponseModel', {
data: types.optional(types.union(ProposalModel, types.undefined, types.null),undefined),
errors: types.optional(types.union(ErrorsModel, types.undefined, types.null),undefined)});
export type ProposalResponse = Instance<typeof ProposalResponseModel>;

export const ProposalCreateModel = types.model('ProposalCreateModel', {
officeId: types.integer,
salesContactId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
label: types.optional(types.union(types.string, types.undefined, types.null),undefined),
specialConditionText: types.optional(types.union(types.string, types.undefined, types.null),undefined),
scheduleAssetsDescription: types.optional(types.union(types.string, types.undefined, types.null),undefined),
customerBpRef: types.optional(types.union(BusinessPartnerRefModel, types.undefined, types.null),undefined),
assets: types.optional(types.union(types.array(AssetModel), types.undefined, types.null),undefined),
calculation: types.optional(types.union(CalculationRequestModel, types.undefined, types.null),undefined)});
export type ProposalCreate = Instance<typeof ProposalCreateModel>;

        const ProposalSuccessModel = ProposalModel;
        export type ProposalSuccess = Instance<typeof ProposalSuccessModel>;

        const ProposalModificationNotAllowedModel = ErrorsModel;
        export type ProposalModificationNotAllowed = Instance<typeof ProposalModificationNotAllowedModel>;

        const ProposalPreconditionFailedModel = ErrorsModel;
        export type ProposalPreconditionFailed = Instance<typeof ProposalPreconditionFailedModel>;



export const GetCountriesParametersModel = types.model('GetCountriesParametersModel', {

});
type GetCountriesParametersType = Instance<typeof GetCountriesParametersModel>;

type GetCountriesRequestBody = null;
export const GetCountriesResponseModel = types.array(CountryModel);
export type GetCountriesResponseType = Instance<typeof GetCountriesResponseModel>
export const getCountries = async (
  parameters: GetCountriesParametersType = {},
  requestBody: GetCountriesRequestBody = null
): Promise<AxiosResponse<GetCountriesResponseType>> =>
  request<GetCountriesParametersType, GetCountriesRequestBody, GetCountriesResponseType>('getCountries', parameters, requestBody);

export const GetRegionsParametersModel = types.model('GetRegionsParametersModel', {
  countryCode: types.string
});
type GetRegionsParametersType = Instance<typeof GetRegionsParametersModel>;

type GetRegionsRequestBody = null;
export const GetRegionsResponseModel = types.array(RegionModel);
export type GetRegionsResponseType = Instance<typeof GetRegionsResponseModel>
export const getRegions = async (
  parameters: GetRegionsParametersType,
  requestBody: GetRegionsRequestBody = null
): Promise<AxiosResponse<GetRegionsResponseType>> =>
  request<GetRegionsParametersType, GetRegionsRequestBody, GetRegionsResponseType>('getRegions', parameters, requestBody);

export const RefineAddressParametersModel = types.model('RefineAddressParametersModel', {
  introducerId: types.integer,
  ref: types.string
});
type RefineAddressParametersType = Instance<typeof RefineAddressParametersModel>;

type RefineAddressRequestBody = null;
export const RefineAddressResponseModel = types.array(AddressSearchResultItemModel);
export type RefineAddressResponseType = Instance<typeof RefineAddressResponseModel>
export const refineAddress = async (
  parameters: RefineAddressParametersType,
  requestBody: RefineAddressRequestBody = null
): Promise<AxiosResponse<RefineAddressResponseType>> =>
  request<RefineAddressParametersType, RefineAddressRequestBody, RefineAddressResponseType>('refineAddress', parameters, requestBody);

export const SearchAddressParametersModel = types.model('SearchAddressParametersModel', {
  introducerId: types.integer,
  postcode: types.string
});
type SearchAddressParametersType = Instance<typeof SearchAddressParametersModel>;

type SearchAddressRequestBody = null;
export const SearchAddressResponseModel = types.array(AddressSearchResultItemModel);
export type SearchAddressResponseType = Instance<typeof SearchAddressResponseModel>
export const searchAddress = async (
  parameters: SearchAddressParametersType,
  requestBody: SearchAddressRequestBody = null
): Promise<AxiosResponse<SearchAddressResponseType>> =>
  request<SearchAddressParametersType, SearchAddressRequestBody, SearchAddressResponseType>('searchAddress', parameters, requestBody);

export const GetAssetTypesOfOfficeParametersModel = types.model('GetAssetTypesOfOfficeParametersModel', {
  introducerId: types.integer,
  officeId: types.integer
});
type GetAssetTypesOfOfficeParametersType = Instance<typeof GetAssetTypesOfOfficeParametersModel>;

type GetAssetTypesOfOfficeRequestBody = null;
export const GetAssetTypesOfOfficeResponseModel = AssetTypesModel;
export type GetAssetTypesOfOfficeResponseType = Instance<typeof GetAssetTypesOfOfficeResponseModel>
export const getAssetTypesOfOffice = async (
  parameters: GetAssetTypesOfOfficeParametersType,
  requestBody: GetAssetTypesOfOfficeRequestBody = null
): Promise<AxiosResponse<GetAssetTypesOfOfficeResponseType>> =>
  request<GetAssetTypesOfOfficeParametersType, GetAssetTypesOfOfficeRequestBody, GetAssetTypesOfOfficeResponseType>('getAssetTypesOfOffice', parameters, requestBody);

export const GetCalculationConfigurationParametersModel = types.model('GetCalculationConfigurationParametersModel', {
  introducerId: types.integer,
  calculationId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  marketproductId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  pricelistId: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type GetCalculationConfigurationParametersType = Instance<typeof GetCalculationConfigurationParametersModel>;

type GetCalculationConfigurationRequestBody = null;
export const GetCalculationConfigurationResponseModel = CalculationConfigResponseModel;
export type GetCalculationConfigurationResponseType = Instance<typeof GetCalculationConfigurationResponseModel>
export const getCalculationConfiguration = async (
  parameters: GetCalculationConfigurationParametersType,
  requestBody: GetCalculationConfigurationRequestBody = null
): Promise<AxiosResponse<GetCalculationConfigurationResponseType>> =>
  request<GetCalculationConfigurationParametersType, GetCalculationConfigurationRequestBody, GetCalculationConfigurationResponseType>('getCalculationConfiguration', parameters, requestBody);

export const UpdateCalculationConfigurationParametersModel = types.model('UpdateCalculationConfigurationParametersModel', {
  introducerId: types.integer,
  calculationId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type UpdateCalculationConfigurationParametersType = Instance<typeof UpdateCalculationConfigurationParametersModel>;
export const UpdateCalculationConfigurationRequestBodyModel = CalculationSheetModel;
type UpdateCalculationConfigurationRequestBody = Instance<typeof UpdateCalculationConfigurationRequestBodyModel>;
export const UpdateCalculationConfigurationResponseModel = CalculationConfigResponseModel;
export type UpdateCalculationConfigurationResponseType = Instance<typeof UpdateCalculationConfigurationResponseModel>
export const updateCalculationConfiguration = async (
  parameters: UpdateCalculationConfigurationParametersType,
  requestBody: UpdateCalculationConfigurationRequestBody
): Promise<AxiosResponse<UpdateCalculationConfigurationResponseType>> =>
  request<UpdateCalculationConfigurationParametersType, UpdateCalculationConfigurationRequestBody, UpdateCalculationConfigurationResponseType>('updateCalculationConfiguration', parameters, requestBody);

export const PerformCalculationParametersModel = types.model('PerformCalculationParametersModel', {
  introducerId: types.integer,
  calculationId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type PerformCalculationParametersType = Instance<typeof PerformCalculationParametersModel>;
export const PerformCalculationRequestBodyModel = CalculationRequestModel;
type PerformCalculationRequestBody = Instance<typeof PerformCalculationRequestBodyModel>;
export const PerformCalculationResponseModel = CalculationResponseModel;
export type PerformCalculationResponseType = Instance<typeof PerformCalculationResponseModel>
export const performCalculation = async (
  parameters: PerformCalculationParametersType,
  requestBody: PerformCalculationRequestBody
): Promise<AxiosResponse<PerformCalculationResponseType>> =>
  request<PerformCalculationParametersType, PerformCalculationRequestBody, PerformCalculationResponseType>('performCalculation', parameters, requestBody);

export const GetJobTitlesParametersModel = types.model('GetJobTitlesParametersModel', {
  introducerId: types.integer
});
type GetJobTitlesParametersType = Instance<typeof GetJobTitlesParametersModel>;

type GetJobTitlesRequestBody = null;
export const GetJobTitlesResponseModel = types.array(JobTitleModel);
export type GetJobTitlesResponseType = Instance<typeof GetJobTitlesResponseModel>
export const getJobTitles = async (
  parameters: GetJobTitlesParametersType,
  requestBody: GetJobTitlesRequestBody = null
): Promise<AxiosResponse<GetJobTitlesResponseType>> =>
  request<GetJobTitlesParametersType, GetJobTitlesRequestBody, GetJobTitlesResponseType>('getJobTitles', parameters, requestBody);

export const GetLessorsParametersModel = types.model('GetLessorsParametersModel', {
  introducerId: types.integer
});
type GetLessorsParametersType = Instance<typeof GetLessorsParametersModel>;

type GetLessorsRequestBody = null;
export const GetLessorsResponseModel = types.array(LessorModel);
export type GetLessorsResponseType = Instance<typeof GetLessorsResponseModel>
export const getLessors = async (
  parameters: GetLessorsParametersType,
  requestBody: GetLessorsRequestBody = null
): Promise<AxiosResponse<GetLessorsResponseType>> =>
  request<GetLessorsParametersType, GetLessorsRequestBody, GetLessorsResponseType>('getLessors', parameters, requestBody);

export const GetLoanPurposesParametersModel = types.model('GetLoanPurposesParametersModel', {
  introducerId: types.integer
});
type GetLoanPurposesParametersType = Instance<typeof GetLoanPurposesParametersModel>;

type GetLoanPurposesRequestBody = null;
export const GetLoanPurposesResponseModel = types.array(LoanPurposeModel);
export type GetLoanPurposesResponseType = Instance<typeof GetLoanPurposesResponseModel>
export const getLoanPurposes = async (
  parameters: GetLoanPurposesParametersType,
  requestBody: GetLoanPurposesRequestBody = null
): Promise<AxiosResponse<GetLoanPurposesResponseType>> =>
  request<GetLoanPurposesParametersType, GetLoanPurposesRequestBody, GetLoanPurposesResponseType>('getLoanPurposes', parameters, requestBody);

export const GetNationalitiesParametersModel = types.model('GetNationalitiesParametersModel', {
  introducerId: types.integer
});
type GetNationalitiesParametersType = Instance<typeof GetNationalitiesParametersModel>;

type GetNationalitiesRequestBody = null;
export const GetNationalitiesResponseModel = types.array(NationalityModel);
export type GetNationalitiesResponseType = Instance<typeof GetNationalitiesResponseModel>
export const getNationalities = async (
  parameters: GetNationalitiesParametersType,
  requestBody: GetNationalitiesRequestBody = null
): Promise<AxiosResponse<GetNationalitiesResponseType>> =>
  request<GetNationalitiesParametersType, GetNationalitiesRequestBody, GetNationalitiesResponseType>('getNationalities', parameters, requestBody);

export const GetProfessionsParametersModel = types.model('GetProfessionsParametersModel', {
  introducerId: types.integer
});
type GetProfessionsParametersType = Instance<typeof GetProfessionsParametersModel>;

type GetProfessionsRequestBody = null;
export const GetProfessionsResponseModel = types.array(ProfessionTypeModel);
export type GetProfessionsResponseType = Instance<typeof GetProfessionsResponseModel>
export const getProfessions = async (
  parameters: GetProfessionsParametersType,
  requestBody: GetProfessionsRequestBody = null
): Promise<AxiosResponse<GetProfessionsResponseType>> =>
  request<GetProfessionsParametersType, GetProfessionsRequestBody, GetProfessionsResponseType>('getProfessions', parameters, requestBody);

export const GetTitlesParametersModel = types.model('GetTitlesParametersModel', {
  introducerId: types.integer
});
type GetTitlesParametersType = Instance<typeof GetTitlesParametersModel>;

type GetTitlesRequestBody = null;
export const GetTitlesResponseModel = types.array(TitleModel);
export type GetTitlesResponseType = Instance<typeof GetTitlesResponseModel>
export const getTitles = async (
  parameters: GetTitlesParametersType,
  requestBody: GetTitlesRequestBody = null
): Promise<AxiosResponse<GetTitlesResponseType>> =>
  request<GetTitlesParametersType, GetTitlesRequestBody, GetTitlesResponseType>('getTitles', parameters, requestBody);

export const GetContentFeedItemsParametersModel = types.model('GetContentFeedItemsParametersModel', {
  introducerId: types.integer
});
type GetContentFeedItemsParametersType = Instance<typeof GetContentFeedItemsParametersModel>;

type GetContentFeedItemsRequestBody = null;
export const GetContentFeedItemsResponseModel = types.array(ContentFeedItemModel);
export type GetContentFeedItemsResponseType = Instance<typeof GetContentFeedItemsResponseModel>
export const getContentFeedItems = async (
  parameters: GetContentFeedItemsParametersType,
  requestBody: GetContentFeedItemsRequestBody = null
): Promise<AxiosResponse<GetContentFeedItemsResponseType>> =>
  request<GetContentFeedItemsParametersType, GetContentFeedItemsRequestBody, GetContentFeedItemsResponseType>('getContentFeedItems', parameters, requestBody);

export const GetContractConfigParametersModel = types.model('GetContractConfigParametersModel', {
  introducerId: types.integer
});
type GetContractConfigParametersType = Instance<typeof GetContractConfigParametersModel>;

type GetContractConfigRequestBody = null;
export const GetContractConfigResponseModel = ContractConfigModel;
export type GetContractConfigResponseType = Instance<typeof GetContractConfigResponseModel>
export const getContractConfig = async (
  parameters: GetContractConfigParametersType,
  requestBody: GetContractConfigRequestBody = null
): Promise<AxiosResponse<GetContractConfigResponseType>> =>
  request<GetContractConfigParametersType, GetContractConfigRequestBody, GetContractConfigResponseType>('getContractConfig', parameters, requestBody);

export const CreateCoTermProposalParametersModel = types.model('CreateCoTermProposalParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type CreateCoTermProposalParametersType = Instance<typeof CreateCoTermProposalParametersModel>;
export const CreateCoTermProposalRequestBodyModel = {};
type CreateCoTermProposalRequestBody = Instance<typeof CreateCoTermProposalRequestBodyModel>;
export const CreateCoTermProposalResponseModel = ProposalReferenceModel;
export type CreateCoTermProposalResponseType = Instance<typeof CreateCoTermProposalResponseModel>
export const createCoTermProposal = async (
  parameters: CreateCoTermProposalParametersType,
  requestBody: CreateCoTermProposalRequestBody
): Promise<AxiosResponse<CreateCoTermProposalResponseType>> =>
  request<CreateCoTermProposalParametersType, CreateCoTermProposalRequestBody, CreateCoTermProposalResponseType>('createCoTermProposal', parameters, requestBody);

export const CreateUpgradeProposalParametersModel = types.model('CreateUpgradeProposalParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type CreateUpgradeProposalParametersType = Instance<typeof CreateUpgradeProposalParametersModel>;
export const CreateUpgradeProposalRequestBodyModel = types.integer;
type CreateUpgradeProposalRequestBody = Instance<typeof CreateUpgradeProposalRequestBodyModel>;
export const CreateUpgradeProposalResponseModel = ProposalReferenceModel;
export type CreateUpgradeProposalResponseType = Instance<typeof CreateUpgradeProposalResponseModel>
export const createUpgradeProposal = async (
  parameters: CreateUpgradeProposalParametersType,
  requestBody: CreateUpgradeProposalRequestBody
): Promise<AxiosResponse<CreateUpgradeProposalResponseType>> =>
  request<CreateUpgradeProposalParametersType, CreateUpgradeProposalRequestBody, CreateUpgradeProposalResponseType>('createUpgradeProposal', parameters, requestBody);

export const GetUpgradeQuotesParametersModel = types.model('GetUpgradeQuotesParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetUpgradeQuotesParametersType = Instance<typeof GetUpgradeQuotesParametersModel>;

type GetUpgradeQuotesRequestBody = null;
export const GetUpgradeQuotesResponseModel = types.array(ContractUpgradeQuoteModel);
export type GetUpgradeQuotesResponseType = Instance<typeof GetUpgradeQuotesResponseModel>
export const getUpgradeQuotes = async (
  parameters: GetUpgradeQuotesParametersType,
  requestBody: GetUpgradeQuotesRequestBody = null
): Promise<AxiosResponse<GetUpgradeQuotesResponseType>> =>
  request<GetUpgradeQuotesParametersType, GetUpgradeQuotesRequestBody, GetUpgradeQuotesResponseType>('getUpgradeQuotes', parameters, requestBody);

export const FetchUpgradeQuoteParametersModel = types.model('FetchUpgradeQuoteParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type FetchUpgradeQuoteParametersType = Instance<typeof FetchUpgradeQuoteParametersModel>;
export const FetchUpgradeQuoteRequestBodyModel = {};
type FetchUpgradeQuoteRequestBody = Instance<typeof FetchUpgradeQuoteRequestBodyModel>;
export const FetchUpgradeQuoteResponseModel = types.array(ContractUpgradeQuoteModel);
export type FetchUpgradeQuoteResponseType = Instance<typeof FetchUpgradeQuoteResponseModel>
export const fetchUpgradeQuote = async (
  parameters: FetchUpgradeQuoteParametersType,
  requestBody: FetchUpgradeQuoteRequestBody
): Promise<AxiosResponse<FetchUpgradeQuoteResponseType>> =>
  request<FetchUpgradeQuoteParametersType, FetchUpgradeQuoteRequestBody, FetchUpgradeQuoteResponseType>('fetchUpgradeQuote', parameters, requestBody);

export const GetContractParametersModel = types.model('GetContractParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractParametersType = Instance<typeof GetContractParametersModel>;

type GetContractRequestBody = null;
export const GetContractResponseModel = ContractModel;
export type GetContractResponseType = Instance<typeof GetContractResponseModel>
export const getContract = async (
  parameters: GetContractParametersType,
  requestBody: GetContractRequestBody = null
): Promise<AxiosResponse<GetContractResponseType>> =>
  request<GetContractParametersType, GetContractRequestBody, GetContractResponseType>('getContract', parameters, requestBody);

export const GetContractAssetsParametersModel = types.model('GetContractAssetsParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractAssetsParametersType = Instance<typeof GetContractAssetsParametersModel>;

type GetContractAssetsRequestBody = null;
export const GetContractAssetsResponseModel = types.array(ContractAssetModel);
export type GetContractAssetsResponseType = Instance<typeof GetContractAssetsResponseModel>
export const getContractAssets = async (
  parameters: GetContractAssetsParametersType,
  requestBody: GetContractAssetsRequestBody = null
): Promise<AxiosResponse<GetContractAssetsResponseType>> =>
  request<GetContractAssetsParametersType, GetContractAssetsRequestBody, GetContractAssetsResponseType>('getContractAssets', parameters, requestBody);

export const GetContractBusinesspartnersParametersModel = types.model('GetContractBusinesspartnersParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractBusinesspartnersParametersType = Instance<typeof GetContractBusinesspartnersParametersModel>;

type GetContractBusinesspartnersRequestBody = null;
export const GetContractBusinesspartnersResponseModel = types.array(ContractBusinesspartnerModel);
export type GetContractBusinesspartnersResponseType = Instance<typeof GetContractBusinesspartnersResponseModel>
export const getContractBusinesspartners = async (
  parameters: GetContractBusinesspartnersParametersType,
  requestBody: GetContractBusinesspartnersRequestBody = null
): Promise<AxiosResponse<GetContractBusinesspartnersResponseType>> =>
  request<GetContractBusinesspartnersParametersType, GetContractBusinesspartnersRequestBody, GetContractBusinesspartnersResponseType>('getContractBusinesspartners', parameters, requestBody);

export const GetContractChargesParametersModel = types.model('GetContractChargesParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractChargesParametersType = Instance<typeof GetContractChargesParametersModel>;

type GetContractChargesRequestBody = null;
export const GetContractChargesResponseModel = types.array(ContractChargeModel);
export type GetContractChargesResponseType = Instance<typeof GetContractChargesResponseModel>
export const getContractCharges = async (
  parameters: GetContractChargesParametersType,
  requestBody: GetContractChargesRequestBody = null
): Promise<AxiosResponse<GetContractChargesResponseType>> =>
  request<GetContractChargesParametersType, GetContractChargesRequestBody, GetContractChargesResponseType>('getContractCharges', parameters, requestBody);

export const GetContractRentalsParametersModel = types.model('GetContractRentalsParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractRentalsParametersType = Instance<typeof GetContractRentalsParametersModel>;

type GetContractRentalsRequestBody = null;
export const GetContractRentalsResponseModel = types.array(ContractRentalModel);
export type GetContractRentalsResponseType = Instance<typeof GetContractRentalsResponseModel>
export const getContractRentals = async (
  parameters: GetContractRentalsParametersType,
  requestBody: GetContractRentalsRequestBody = null
): Promise<AxiosResponse<GetContractRentalsResponseType>> =>
  request<GetContractRentalsParametersType, GetContractRentalsRequestBody, GetContractRentalsResponseType>('getContractRentals', parameters, requestBody);

export const GetContractSecuritiesParametersModel = types.model('GetContractSecuritiesParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type GetContractSecuritiesParametersType = Instance<typeof GetContractSecuritiesParametersModel>;

type GetContractSecuritiesRequestBody = null;
export const GetContractSecuritiesResponseModel = types.array(ContractSecurityModel);
export type GetContractSecuritiesResponseType = Instance<typeof GetContractSecuritiesResponseModel>
export const getContractSecurities = async (
  parameters: GetContractSecuritiesParametersType,
  requestBody: GetContractSecuritiesRequestBody = null
): Promise<AxiosResponse<GetContractSecuritiesResponseType>> =>
  request<GetContractSecuritiesParametersType, GetContractSecuritiesRequestBody, GetContractSecuritiesResponseType>('getContractSecurities', parameters, requestBody);

export const SendEarlyTerminationOfferParametersModel = types.model('SendEarlyTerminationOfferParametersModel', {
  introducerId: types.integer,
  contractId: types.integer
});
type SendEarlyTerminationOfferParametersType = Instance<typeof SendEarlyTerminationOfferParametersModel>;
export const SendEarlyTerminationOfferRequestBodyModel = EarlyCancellationOfferRequestModel;
type SendEarlyTerminationOfferRequestBody = Instance<typeof SendEarlyTerminationOfferRequestBodyModel>;
export const SendEarlyTerminationOfferResponseModel = ContractUpgradeQuoteModel;
export type SendEarlyTerminationOfferResponseType = Instance<typeof SendEarlyTerminationOfferResponseModel>
export const sendEarlyTerminationOffer = async (
  parameters: SendEarlyTerminationOfferParametersType,
  requestBody: SendEarlyTerminationOfferRequestBody
): Promise<AxiosResponse<SendEarlyTerminationOfferResponseType>> =>
  request<SendEarlyTerminationOfferParametersType, SendEarlyTerminationOfferRequestBody, SendEarlyTerminationOfferResponseType>('sendEarlyTerminationOffer', parameters, requestBody);

export const FindContractsParametersModel = types.model('FindContractsParametersModel', {
  introducerId: types.integer,
  contrNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  propNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custRegNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custLabel: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  equipName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  marketproductId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  salesPersonId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  offId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  portfolioStatus: types.optional(types.union(types.enumeration(['ACTIVE', 'LIVE', 'EXTENSION', 'MATURED', 'TERMINATED', 'ALL']), types.undefined, types.null),undefined),
  filterInExtension: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
  filterTermNotice: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
  dateSearchTypeId: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  afterDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  beforeDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  afterOffsetMonth: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  beforeOffsetMonth: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  minDealValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
  maxDealValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
  assetDescription: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  assetSerial: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  assetLocation: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  sortBy: types.optional(types.union(types.enumeration(['CONTRACT_NO', 'CUSTOMER_NAME']), types.undefined, types.null),undefined),
  sortOrder: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)
});
type FindContractsParametersType = Instance<typeof FindContractsParametersModel>;

type FindContractsRequestBody = null;
export const FindContractsResponseModel = types.union(ContractSearchResultsModel, FileBasedPaginatedResponseModel);
export type FindContractsResponseType = Instance<typeof FindContractsResponseModel>
export const findContracts = async (
  parameters: FindContractsParametersType,
  requestBody: FindContractsRequestBody = null
): Promise<AxiosResponse<FindContractsResponseType>> =>
  request<FindContractsParametersType, FindContractsRequestBody, FindContractsResponseType>('findContracts', parameters, requestBody);

export const FindContractsAsExcelExtendedParametersModel = types.model('FindContractsAsExcelExtendedParametersModel', {
  introducerId: types.integer,
  contrNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  propNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custRegNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custLabel: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  equipName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  marketproductId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  salesPersonId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  offId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  portfolioStatus: types.optional(types.union(types.enumeration(['ACTIVE', 'LIVE', 'EXTENSION', 'MATURED', 'TERMINATED', 'ALL']), types.undefined, types.null),undefined),
  filterInExtension: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
  filterTermNotice: types.optional(types.union(types.boolean, types.undefined, types.null),undefined),
  dateSearchTypeId: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  afterDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  beforeDate: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  afterOffsetMonth: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  beforeOffsetMonth: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  minDealValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
  maxDealValue: types.optional(types.union(types.number, types.undefined, types.null),undefined),
  assetDescription: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  assetSerial: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  assetLocation: types.optional(types.union(types.string, types.undefined, types.null),undefined)
});
type FindContractsAsExcelExtendedParametersType = Instance<typeof FindContractsAsExcelExtendedParametersModel>;

type FindContractsAsExcelExtendedRequestBody = null;
export const FindContractsAsExcelExtendedResponseModel = FileDataModel;
export type FindContractsAsExcelExtendedResponseType = Instance<typeof FindContractsAsExcelExtendedResponseModel>
export const findContractsAsExcelExtended = async (
  parameters: FindContractsAsExcelExtendedParametersType,
  requestBody: FindContractsAsExcelExtendedRequestBody = null
): Promise<AxiosResponse<FindContractsAsExcelExtendedResponseType>> =>
  request<FindContractsAsExcelExtendedParametersType, FindContractsAsExcelExtendedRequestBody, FindContractsAsExcelExtendedResponseType>('findContractsAsExcelExtended', parameters, requestBody);

export const GetContractSearchConfigParametersModel = types.model('GetContractSearchConfigParametersModel', {
  introducerId: types.integer
});
type GetContractSearchConfigParametersType = Instance<typeof GetContractSearchConfigParametersModel>;

type GetContractSearchConfigRequestBody = null;
export const GetContractSearchConfigResponseModel = ContractSearchConfigModel;
export type GetContractSearchConfigResponseType = Instance<typeof GetContractSearchConfigResponseModel>
export const getContractSearchConfig = async (
  parameters: GetContractSearchConfigParametersType,
  requestBody: GetContractSearchConfigRequestBody = null
): Promise<AxiosResponse<GetContractSearchConfigResponseType>> =>
  request<GetContractSearchConfigParametersType, GetContractSearchConfigRequestBody, GetContractSearchConfigResponseType>('getContractSearchConfig', parameters, requestBody);

export const GetContractExpiringCountsParametersModel = types.model('GetContractExpiringCountsParametersModel', {
  introducerId: types.integer,
  start: types.integer,
  end: types.integer
});
type GetContractExpiringCountsParametersType = Instance<typeof GetContractExpiringCountsParametersModel>;

type GetContractExpiringCountsRequestBody = null;
export const GetContractExpiringCountsResponseModel = ContractExpiringCountModel;
export type GetContractExpiringCountsResponseType = Instance<typeof GetContractExpiringCountsResponseModel>
export const getContractExpiringCounts = async (
  parameters: GetContractExpiringCountsParametersType,
  requestBody: GetContractExpiringCountsRequestBody = null
): Promise<AxiosResponse<GetContractExpiringCountsResponseType>> =>
  request<GetContractExpiringCountsParametersType, GetContractExpiringCountsRequestBody, GetContractExpiringCountsResponseType>('getContractExpiringCounts', parameters, requestBody);

export const SearchCustomerPartnerParametersModel = types.model('SearchCustomerPartnerParametersModel', {
  introducerId: types.integer
});
type SearchCustomerPartnerParametersType = Instance<typeof SearchCustomerPartnerParametersModel>;
export const SearchCustomerPartnerRequestBodyModel = CustomerPartnerSearchFilterModel;
type SearchCustomerPartnerRequestBody = Instance<typeof SearchCustomerPartnerRequestBodyModel>;
export const SearchCustomerPartnerResponseModel = types.array(PartnerModel);
export type SearchCustomerPartnerResponseType = Instance<typeof SearchCustomerPartnerResponseModel>
export const searchCustomerPartner = async (
  parameters: SearchCustomerPartnerParametersType,
  requestBody: SearchCustomerPartnerRequestBody
): Promise<AxiosResponse<SearchCustomerPartnerResponseType>> =>
  request<SearchCustomerPartnerParametersType, SearchCustomerPartnerRequestBody, SearchCustomerPartnerResponseType>('searchCustomerPartner', parameters, requestBody);

export const FindCustomersOfIntroducerParametersModel = types.model('FindCustomersOfIntroducerParametersModel', {
  introducerId: types.integer,
  registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  regionId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  street: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  website: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  companyType: types.optional(types.union(types.enumeration(['LIMITED', 'NONLIMITED']), types.undefined, types.null),undefined),
  countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined)
});
type FindCustomersOfIntroducerParametersType = Instance<typeof FindCustomersOfIntroducerParametersModel>;

type FindCustomersOfIntroducerRequestBody = null;
export const FindCustomersOfIntroducerResponseModel = types.array(CustomerSearchResultItemModel);
export type FindCustomersOfIntroducerResponseType = Instance<typeof FindCustomersOfIntroducerResponseModel>
export const findCustomersOfIntroducer = async (
  parameters: FindCustomersOfIntroducerParametersType,
  requestBody: FindCustomersOfIntroducerRequestBody = null
): Promise<AxiosResponse<FindCustomersOfIntroducerResponseType>> =>
  request<FindCustomersOfIntroducerParametersType, FindCustomersOfIntroducerRequestBody, FindCustomersOfIntroducerResponseType>('findCustomersOfIntroducer', parameters, requestBody);

export const FindEquipmentSuppliersParametersModel = types.model('FindEquipmentSuppliersParametersModel', {
  introducerId: types.integer,
  registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  regionId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  street: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  website: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined)
});
type FindEquipmentSuppliersParametersType = Instance<typeof FindEquipmentSuppliersParametersModel>;

type FindEquipmentSuppliersRequestBody = null;
export const FindEquipmentSuppliersResponseModel = types.array(EquipmentSupplierSearchResultItemModel);
export type FindEquipmentSuppliersResponseType = Instance<typeof FindEquipmentSuppliersResponseModel>
export const findEquipmentSuppliers = async (
  parameters: FindEquipmentSuppliersParametersType,
  requestBody: FindEquipmentSuppliersRequestBody = null
): Promise<AxiosResponse<FindEquipmentSuppliersResponseType>> =>
  request<FindEquipmentSuppliersParametersType, FindEquipmentSuppliersRequestBody, FindEquipmentSuppliersResponseType>('findEquipmentSuppliers', parameters, requestBody);

export const GetExternalLinksParametersModel = types.model('GetExternalLinksParametersModel', {
  introducerId: types.integer
});
type GetExternalLinksParametersType = Instance<typeof GetExternalLinksParametersModel>;

type GetExternalLinksRequestBody = null;
export const GetExternalLinksResponseModel = types.array(ExternalLinkFolderModel);
export type GetExternalLinksResponseType = Instance<typeof GetExternalLinksResponseModel>
export const getExternalLinks = async (
  parameters: GetExternalLinksParametersType,
  requestBody: GetExternalLinksRequestBody = null
): Promise<AxiosResponse<GetExternalLinksResponseType>> =>
  request<GetExternalLinksParametersType, GetExternalLinksRequestBody, GetExternalLinksResponseType>('getExternalLinks', parameters, requestBody);

export const GetIntroducerConfigParametersModel = types.model('GetIntroducerConfigParametersModel', {
  introducerId: types.integer
});
type GetIntroducerConfigParametersType = Instance<typeof GetIntroducerConfigParametersModel>;

type GetIntroducerConfigRequestBody = null;
export const GetIntroducerConfigResponseModel = IntroducerConfigModel;
export type GetIntroducerConfigResponseType = Instance<typeof GetIntroducerConfigResponseModel>
export const getIntroducerConfig = async (
  parameters: GetIntroducerConfigParametersType,
  requestBody: GetIntroducerConfigRequestBody = null
): Promise<AxiosResponse<GetIntroducerConfigResponseType>> =>
  request<GetIntroducerConfigParametersType, GetIntroducerConfigRequestBody, GetIntroducerConfigResponseType>('getIntroducerConfig', parameters, requestBody);

export const SetEulaAcceptedParametersModel = types.model('SetEulaAcceptedParametersModel', {
  introducerId: types.integer
});
type SetEulaAcceptedParametersType = Instance<typeof SetEulaAcceptedParametersModel>;
export const SetEulaAcceptedRequestBodyModel = {};
type SetEulaAcceptedRequestBody = Instance<typeof SetEulaAcceptedRequestBodyModel>;
export const SetEulaAcceptedResponseModel = IntroducerConfigModel;
export type SetEulaAcceptedResponseType = Instance<typeof SetEulaAcceptedResponseModel>
export const setEulaAccepted = async (
  parameters: SetEulaAcceptedParametersType,
  requestBody: SetEulaAcceptedRequestBody
): Promise<AxiosResponse<SetEulaAcceptedResponseType>> =>
  request<SetEulaAcceptedParametersType, SetEulaAcceptedRequestBody, SetEulaAcceptedResponseType>('setEulaAccepted', parameters, requestBody);

export const GetEquipmentSuppliersParametersModel = types.model('GetEquipmentSuppliersParametersModel', {
  introducerId: types.integer,
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type GetEquipmentSuppliersParametersType = Instance<typeof GetEquipmentSuppliersParametersModel>;

type GetEquipmentSuppliersRequestBody = null;
export const GetEquipmentSuppliersResponseModel = IntroducerEquipmentSupplierListPageResultModel;
export type GetEquipmentSuppliersResponseType = Instance<typeof GetEquipmentSuppliersResponseModel>
export const getEquipmentSuppliers = async (
  parameters: GetEquipmentSuppliersParametersType,
  requestBody: GetEquipmentSuppliersRequestBody = null
): Promise<AxiosResponse<GetEquipmentSuppliersResponseType>> =>
  request<GetEquipmentSuppliersParametersType, GetEquipmentSuppliersRequestBody, GetEquipmentSuppliersResponseType>('getEquipmentSuppliers', parameters, requestBody);

export const AddEquipmentSupplierParametersModel = types.model('AddEquipmentSupplierParametersModel', {
  introducerId: types.integer
});
type AddEquipmentSupplierParametersType = Instance<typeof AddEquipmentSupplierParametersModel>;
export const AddEquipmentSupplierRequestBodyModel = BusinessPartnerRefModel;
type AddEquipmentSupplierRequestBody = Instance<typeof AddEquipmentSupplierRequestBodyModel>;
export const AddEquipmentSupplierResponseModel = BusinessPartnerModel;
export type AddEquipmentSupplierResponseType = Instance<typeof AddEquipmentSupplierResponseModel>
export const addEquipmentSupplier = async (
  parameters: AddEquipmentSupplierParametersType,
  requestBody: AddEquipmentSupplierRequestBody
): Promise<AxiosResponse<AddEquipmentSupplierResponseType>> =>
  request<AddEquipmentSupplierParametersType, AddEquipmentSupplierRequestBody, AddEquipmentSupplierResponseType>('addEquipmentSupplier', parameters, requestBody);

export const GetIntroducerOfficesOfCurrentUserParametersModel = types.model('GetIntroducerOfficesOfCurrentUserParametersModel', {
  introducerId: types.integer
});
type GetIntroducerOfficesOfCurrentUserParametersType = Instance<typeof GetIntroducerOfficesOfCurrentUserParametersModel>;

type GetIntroducerOfficesOfCurrentUserRequestBody = null;
export const GetIntroducerOfficesOfCurrentUserResponseModel = types.array(OfficeModel);
export type GetIntroducerOfficesOfCurrentUserResponseType = Instance<typeof GetIntroducerOfficesOfCurrentUserResponseModel>
export const getIntroducerOfficesOfCurrentUser = async (
  parameters: GetIntroducerOfficesOfCurrentUserParametersType,
  requestBody: GetIntroducerOfficesOfCurrentUserRequestBody = null
): Promise<AxiosResponse<GetIntroducerOfficesOfCurrentUserResponseType>> =>
  request<GetIntroducerOfficesOfCurrentUserParametersType, GetIntroducerOfficesOfCurrentUserRequestBody, GetIntroducerOfficesOfCurrentUserResponseType>('getIntroducerOfficesOfCurrentUser', parameters, requestBody);

export const GetContractsParametersModel = types.model('GetContractsParametersModel', {
  introducerId: types.integer,
  metricKey: types.enumeration(['SUMMARY_INFORMATION', 'CUSTOMER_ARREARS', 'INTRODUCER_ARREARS', 'THIRTY_DAY_ARREARS', 'BAD_DEBT_WRITE_OFFS', 'FIRST_YEAR_BAD_DEBT']),
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  sortBy: types.optional(types.union(types.enumeration(['CONTRACT_NO', 'CUSTOMER_NAME', 'AMOUNT_FINANCED', 'DEALER_ARREARS', 'CUSTOMER_ARREARS', 'BAD_DEBTS']), types.undefined, types.null),undefined),
  sortOrder: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)
});
type GetContractsParametersType = Instance<typeof GetContractsParametersModel>;

type GetContractsRequestBody = null;
export const GetContractsResponseModel = types.union(ReportContractsResponseModel, FileDataModel);
export type GetContractsResponseType = Instance<typeof GetContractsResponseModel>
export const getContracts = async (
  parameters: GetContractsParametersType,
  requestBody: GetContractsRequestBody = null
): Promise<AxiosResponse<GetContractsResponseType>> =>
  request<GetContractsParametersType, GetContractsRequestBody, GetContractsResponseType>('getContracts', parameters, requestBody);

export const GetInvoicesParametersModel = types.model('GetInvoicesParametersModel', {
  introducerId: types.integer,
  metricKey: types.enumeration(['SUMMARY_INFORMATION', 'CUSTOMER_ARREARS', 'INTRODUCER_ARREARS', 'THIRTY_DAY_ARREARS', 'BAD_DEBT_WRITE_OFFS', 'FIRST_YEAR_BAD_DEBT']),
  contractId: types.integer,
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  sortBy: types.optional(types.union(types.enumeration(['INVOICE_NO', 'AMOUNT', 'DUE_DATE']), types.undefined, types.null),undefined),
  sortOrder: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)
});
type GetInvoicesParametersType = Instance<typeof GetInvoicesParametersModel>;

type GetInvoicesRequestBody = null;
export const GetInvoicesResponseModel = types.union(ReportInvoicesResponseModel, FileDataModel);
export type GetInvoicesResponseType = Instance<typeof GetInvoicesResponseModel>
export const getInvoices = async (
  parameters: GetInvoicesParametersType,
  requestBody: GetInvoicesRequestBody = null
): Promise<AxiosResponse<GetInvoicesResponseType>> =>
  request<GetInvoicesParametersType, GetInvoicesRequestBody, GetInvoicesResponseType>('getInvoices', parameters, requestBody);

export const GetReportParametersModel = types.model('GetReportParametersModel', {
  introducerId: types.integer,
  year: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  month: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  calculateLastTwelveMonth: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)
});
type GetReportParametersType = Instance<typeof GetReportParametersModel>;

type GetReportRequestBody = null;
export const GetReportResponseModel = TemporalReportModel;
export type GetReportResponseType = Instance<typeof GetReportResponseModel>
export const getReport = async (
  parameters: GetReportParametersType,
  requestBody: GetReportRequestBody = null
): Promise<AxiosResponse<GetReportResponseType>> =>
  request<GetReportParametersType, GetReportRequestBody, GetReportResponseType>('getReport', parameters, requestBody);

export const GetStaticReportParametersModel = types.model('GetStaticReportParametersModel', {
  introducerId: types.integer
});
type GetStaticReportParametersType = Instance<typeof GetStaticReportParametersModel>;

type GetStaticReportRequestBody = null;
export const GetStaticReportResponseModel = StaticReportModel;
export type GetStaticReportResponseType = Instance<typeof GetStaticReportResponseModel>
export const getStaticReport = async (
  parameters: GetStaticReportParametersType,
  requestBody: GetStaticReportRequestBody = null
): Promise<AxiosResponse<GetStaticReportResponseType>> =>
  request<GetStaticReportParametersType, GetStaticReportRequestBody, GetStaticReportResponseType>('getStaticReport', parameters, requestBody);

export const GetIntroducerSalesContactsOfOfficeParametersModel = types.model('GetIntroducerSalesContactsOfOfficeParametersModel', {
  introducerId: types.integer,
  officeId: types.integer
});
type GetIntroducerSalesContactsOfOfficeParametersType = Instance<typeof GetIntroducerSalesContactsOfOfficeParametersModel>;

type GetIntroducerSalesContactsOfOfficeRequestBody = null;
export const GetIntroducerSalesContactsOfOfficeResponseModel = SalesContactsModel;
export type GetIntroducerSalesContactsOfOfficeResponseType = Instance<typeof GetIntroducerSalesContactsOfOfficeResponseModel>
export const getIntroducerSalesContactsOfOffice = async (
  parameters: GetIntroducerSalesContactsOfOfficeParametersType,
  requestBody: GetIntroducerSalesContactsOfOfficeRequestBody = null
): Promise<AxiosResponse<GetIntroducerSalesContactsOfOfficeResponseType>> =>
  request<GetIntroducerSalesContactsOfOfficeParametersType, GetIntroducerSalesContactsOfOfficeRequestBody, GetIntroducerSalesContactsOfOfficeResponseType>('getIntroducerSalesContactsOfOffice', parameters, requestBody);

export const GetIntroducersOfCurrentUserParametersModel = types.model('GetIntroducersOfCurrentUserParametersModel', {

});
type GetIntroducersOfCurrentUserParametersType = Instance<typeof GetIntroducersOfCurrentUserParametersModel>;

type GetIntroducersOfCurrentUserRequestBody = null;
export const GetIntroducersOfCurrentUserResponseModel = types.array(IntroducerModel);
export type GetIntroducersOfCurrentUserResponseType = Instance<typeof GetIntroducersOfCurrentUserResponseModel>
export const getIntroducersOfCurrentUser = async (
  parameters: GetIntroducersOfCurrentUserParametersType = {},
  requestBody: GetIntroducersOfCurrentUserRequestBody = null
): Promise<AxiosResponse<GetIntroducersOfCurrentUserResponseType>> =>
  request<GetIntroducersOfCurrentUserParametersType, GetIntroducersOfCurrentUserRequestBody, GetIntroducersOfCurrentUserResponseType>('getIntroducersOfCurrentUser', parameters, requestBody);

export const GetNotificationsParametersModel = types.model('GetNotificationsParametersModel', {
  introducerId: types.integer
});
type GetNotificationsParametersType = Instance<typeof GetNotificationsParametersModel>;

type GetNotificationsRequestBody = null;
export const GetNotificationsResponseModel = types.array(NotificationModel);
export type GetNotificationsResponseType = Instance<typeof GetNotificationsResponseModel>
export const getNotifications = async (
  parameters: GetNotificationsParametersType,
  requestBody: GetNotificationsRequestBody = null
): Promise<AxiosResponse<GetNotificationsResponseType>> =>
  request<GetNotificationsParametersType, GetNotificationsRequestBody, GetNotificationsResponseType>('getNotifications', parameters, requestBody);

export const AmendProposalParametersModel = types.model('AmendProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type AmendProposalParametersType = Instance<typeof AmendProposalParametersModel>;
export const AmendProposalRequestBodyModel = {};
type AmendProposalRequestBody = Instance<typeof AmendProposalRequestBodyModel>;

export type AmendProposalResponseType = void
export const amendProposal = async (
  parameters: AmendProposalParametersType,
  requestBody: AmendProposalRequestBody
): Promise<AxiosResponse<AmendProposalResponseType>> =>
  request<AmendProposalParametersType, AmendProposalRequestBody, AmendProposalResponseType>('amendProposal', parameters, requestBody);

export const CopyProposalParametersModel = types.model('CopyProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CopyProposalParametersType = Instance<typeof CopyProposalParametersModel>;
export const CopyProposalRequestBodyModel = ProposalCopyRequestModel;
type CopyProposalRequestBody = Instance<typeof CopyProposalRequestBodyModel>;
export const CopyProposalResponseModel = ProposalResponseModel;
export type CopyProposalResponseType = Instance<typeof CopyProposalResponseModel>
export const copyProposal = async (
  parameters: CopyProposalParametersType,
  requestBody: CopyProposalRequestBody
): Promise<AxiosResponse<CopyProposalResponseType>> =>
  request<CopyProposalParametersType, CopyProposalRequestBody, CopyProposalResponseType>('copyProposal', parameters, requestBody);

export const CreateProposalParametersModel = types.model('CreateProposalParametersModel', {
  introducerId: types.integer
});
type CreateProposalParametersType = Instance<typeof CreateProposalParametersModel>;
export const CreateProposalRequestBodyModel = ProposalCreateModel;
type CreateProposalRequestBody = Instance<typeof CreateProposalRequestBodyModel>;
export const CreateProposalResponseModel = ProposalSuccessModel;
export type CreateProposalResponseType = Instance<typeof CreateProposalResponseModel>
export const createProposal = async (
  parameters: CreateProposalParametersType,
  requestBody: CreateProposalRequestBody
): Promise<AxiosResponse<CreateProposalResponseType>> =>
  request<CreateProposalParametersType, CreateProposalRequestBody, CreateProposalResponseType>('createProposal', parameters, requestBody);

export const GetProposalParametersModel = types.model('GetProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetProposalParametersType = Instance<typeof GetProposalParametersModel>;

type GetProposalRequestBody = null;
export const GetProposalResponseModel = ProposalSuccessModel;
export type GetProposalResponseType = Instance<typeof GetProposalResponseModel>
export const getProposal = async (
  parameters: GetProposalParametersType,
  requestBody: GetProposalRequestBody = null
): Promise<AxiosResponse<GetProposalResponseType>> =>
  request<GetProposalParametersType, GetProposalRequestBody, GetProposalResponseType>('getProposal', parameters, requestBody);

export const ExpireProposalParametersModel = types.model('ExpireProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.optional(types.union(types.string, types.undefined, types.null),undefined)
});
type ExpireProposalParametersType = Instance<typeof ExpireProposalParametersModel>;

type ExpireProposalRequestBody = null;
export const ExpireProposalResponseModel = ProposalSuccessModel;
export type ExpireProposalResponseType = Instance<typeof ExpireProposalResponseModel>
export const expireProposal = async (
  parameters: ExpireProposalParametersType,
  requestBody: ExpireProposalRequestBody = null
): Promise<AxiosResponse<ExpireProposalResponseType>> =>
  request<ExpireProposalParametersType, ExpireProposalRequestBody, ExpireProposalResponseType>('expireProposal', parameters, requestBody);

export const UpdateProposalParametersModel = types.model('UpdateProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type UpdateProposalParametersType = Instance<typeof UpdateProposalParametersModel>;
export const UpdateProposalRequestBodyModel = ProposalUpdateModel;
type UpdateProposalRequestBody = Instance<typeof UpdateProposalRequestBodyModel>;
export const UpdateProposalResponseModel = ProposalSuccessModel;
export type UpdateProposalResponseType = Instance<typeof UpdateProposalResponseModel>
export const updateProposal = async (
  parameters: UpdateProposalParametersType,
  requestBody: UpdateProposalRequestBody
): Promise<AxiosResponse<UpdateProposalResponseType>> =>
  request<UpdateProposalParametersType, UpdateProposalRequestBody, UpdateProposalResponseType>('updateProposal', parameters, requestBody);

export const GetAssetConfigParametersModel = types.model('GetAssetConfigParametersModel', {
  introducerId: types.integer,
  proposalId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  officeId: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type GetAssetConfigParametersType = Instance<typeof GetAssetConfigParametersModel>;

type GetAssetConfigRequestBody = null;
export const GetAssetConfigResponseModel = AssetConfigModel;
export type GetAssetConfigResponseType = Instance<typeof GetAssetConfigResponseModel>
export const getAssetConfig = async (
  parameters: GetAssetConfigParametersType,
  requestBody: GetAssetConfigRequestBody = null
): Promise<AxiosResponse<GetAssetConfigResponseType>> =>
  request<GetAssetConfigParametersType, GetAssetConfigRequestBody, GetAssetConfigResponseType>('getAssetConfig', parameters, requestBody);

export const GetAssetsParametersModel = types.model('GetAssetsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetAssetsParametersType = Instance<typeof GetAssetsParametersModel>;

type GetAssetsRequestBody = null;
export const GetAssetsResponseModel = AssetListResponseModel;
export type GetAssetsResponseType = Instance<typeof GetAssetsResponseModel>
export const getAssets = async (
  parameters: GetAssetsParametersType,
  requestBody: GetAssetsRequestBody = null
): Promise<AxiosResponse<GetAssetsResponseType>> =>
  request<GetAssetsParametersType, GetAssetsRequestBody, GetAssetsResponseType>('getAssets', parameters, requestBody);

export const CreateAssetParametersModel = types.model('CreateAssetParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateAssetParametersType = Instance<typeof CreateAssetParametersModel>;
export const CreateAssetRequestBodyModel = AssetModel;
type CreateAssetRequestBody = Instance<typeof CreateAssetRequestBodyModel>;
export const CreateAssetResponseModel = AssetModel;
export type CreateAssetResponseType = Instance<typeof CreateAssetResponseModel>
export const createAsset = async (
  parameters: CreateAssetParametersType,
  requestBody: CreateAssetRequestBody
): Promise<AxiosResponse<CreateAssetResponseType>> =>
  request<CreateAssetParametersType, CreateAssetRequestBody, CreateAssetResponseType>('createAsset', parameters, requestBody);

export const UpdateAssetParametersModel = types.model('UpdateAssetParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  assetId: types.integer,
  ifMatch: types.string
});
type UpdateAssetParametersType = Instance<typeof UpdateAssetParametersModel>;
export const UpdateAssetRequestBodyModel = AssetModel;
type UpdateAssetRequestBody = Instance<typeof UpdateAssetRequestBodyModel>;
export const UpdateAssetResponseModel = AssetModel;
export type UpdateAssetResponseType = Instance<typeof UpdateAssetResponseModel>
export const updateAsset = async (
  parameters: UpdateAssetParametersType,
  requestBody: UpdateAssetRequestBody
): Promise<AxiosResponse<UpdateAssetResponseType>> =>
  request<UpdateAssetParametersType, UpdateAssetRequestBody, UpdateAssetResponseType>('updateAsset', parameters, requestBody);

export const DeleteAssetParametersModel = types.model('DeleteAssetParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  assetId: types.integer,
  ifMatch: types.string
});
type DeleteAssetParametersType = Instance<typeof DeleteAssetParametersModel>;

type DeleteAssetRequestBody = null;

export type DeleteAssetResponseType = void
export const deleteAsset = async (
  parameters: DeleteAssetParametersType,
  requestBody: DeleteAssetRequestBody = null
): Promise<AxiosResponse<DeleteAssetResponseType>> =>
  request<DeleteAssetParametersType, DeleteAssetRequestBody, DeleteAssetResponseType>('deleteAsset', parameters, requestBody);

export const GetAssetAddressesParametersModel = types.model('GetAssetAddressesParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type GetAssetAddressesParametersType = Instance<typeof GetAssetAddressesParametersModel>;

type GetAssetAddressesRequestBody = null;
export const GetAssetAddressesResponseModel = AssetAddressResultModel;
export type GetAssetAddressesResponseType = Instance<typeof GetAssetAddressesResponseModel>
export const getAssetAddresses = async (
  parameters: GetAssetAddressesParametersType,
  requestBody: GetAssetAddressesRequestBody = null
): Promise<AxiosResponse<GetAssetAddressesResponseType>> =>
  request<GetAssetAddressesParametersType, GetAssetAddressesRequestBody, GetAssetAddressesResponseType>('getAssetAddresses', parameters, requestBody);

export const SplitAssetParametersModel = types.model('SplitAssetParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  assetId: types.integer,
  ifMatch: types.string
});
type SplitAssetParametersType = Instance<typeof SplitAssetParametersModel>;
export const SplitAssetRequestBodyModel = {};
type SplitAssetRequestBody = Instance<typeof SplitAssetRequestBodyModel>;

export type SplitAssetResponseType = void
export const splitAsset = async (
  parameters: SplitAssetParametersType,
  requestBody: SplitAssetRequestBody
): Promise<AxiosResponse<SplitAssetResponseType>> =>
  request<SplitAssetParametersType, SplitAssetRequestBody, SplitAssetResponseType>('splitAsset', parameters, requestBody);

export const GetCalculationsParametersModel = types.model('GetCalculationsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCalculationsParametersType = Instance<typeof GetCalculationsParametersModel>;

type GetCalculationsRequestBody = null;
export const GetCalculationsResponseModel = types.array(CalculationSheetModel);
export type GetCalculationsResponseType = Instance<typeof GetCalculationsResponseModel>
export const getCalculations = async (
  parameters: GetCalculationsParametersType,
  requestBody: GetCalculationsRequestBody = null
): Promise<AxiosResponse<GetCalculationsResponseType>> =>
  request<GetCalculationsParametersType, GetCalculationsRequestBody, GetCalculationsResponseType>('getCalculations', parameters, requestBody);

export const AddCalculationParametersModel = types.model('AddCalculationParametersModel', {
  introducerId: types.integer,
  calculationId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  proposalId: types.integer,
  ifMatch: types.string
});
type AddCalculationParametersType = Instance<typeof AddCalculationParametersModel>;
export const AddCalculationRequestBodyModel = CalculationRequestModel;
type AddCalculationRequestBody = Instance<typeof AddCalculationRequestBodyModel>;
export const AddCalculationResponseModel = ProposalCalculationResponseModel;
export type AddCalculationResponseType = Instance<typeof AddCalculationResponseModel>
export const addCalculation = async (
  parameters: AddCalculationParametersType,
  requestBody: AddCalculationRequestBody
): Promise<AxiosResponse<AddCalculationResponseType>> =>
  request<AddCalculationParametersType, AddCalculationRequestBody, AddCalculationResponseType>('addCalculation', parameters, requestBody);

export const DeleteCalculationParametersModel = types.model('DeleteCalculationParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  calculationId: types.integer,
  ifMatch: types.string
});
type DeleteCalculationParametersType = Instance<typeof DeleteCalculationParametersModel>;

type DeleteCalculationRequestBody = null;
export const DeleteCalculationResponseModel = ProposalSuccessModel;
export type DeleteCalculationResponseType = Instance<typeof DeleteCalculationResponseModel>
export const deleteCalculation = async (
  parameters: DeleteCalculationParametersType,
  requestBody: DeleteCalculationRequestBody = null
): Promise<AxiosResponse<DeleteCalculationResponseType>> =>
  request<DeleteCalculationParametersType, DeleteCalculationRequestBody, DeleteCalculationResponseType>('deleteCalculation', parameters, requestBody);

export const GetCustomerPartnersParametersModel = types.model('GetCustomerPartnersParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCustomerPartnersParametersType = Instance<typeof GetCustomerPartnersParametersModel>;

type GetCustomerPartnersRequestBody = null;
export const GetCustomerPartnersResponseModel = types.array(PartnerModel);
export type GetCustomerPartnersResponseType = Instance<typeof GetCustomerPartnersResponseModel>
export const getCustomerPartners = async (
  parameters: GetCustomerPartnersParametersType,
  requestBody: GetCustomerPartnersRequestBody = null
): Promise<AxiosResponse<GetCustomerPartnersResponseType>> =>
  request<GetCustomerPartnersParametersType, GetCustomerPartnersRequestBody, GetCustomerPartnersResponseType>('getCustomerPartners', parameters, requestBody);

export const AddCustomerPartnerParametersModel = types.model('AddCustomerPartnerParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type AddCustomerPartnerParametersType = Instance<typeof AddCustomerPartnerParametersModel>;
export const AddCustomerPartnerRequestBodyModel = PartnerModel;
type AddCustomerPartnerRequestBody = Instance<typeof AddCustomerPartnerRequestBodyModel>;
export const AddCustomerPartnerResponseModel = PartnerModel;
export type AddCustomerPartnerResponseType = Instance<typeof AddCustomerPartnerResponseModel>
export const addCustomerPartner = async (
  parameters: AddCustomerPartnerParametersType,
  requestBody: AddCustomerPartnerRequestBody
): Promise<AxiosResponse<AddCustomerPartnerResponseType>> =>
  request<AddCustomerPartnerParametersType, AddCustomerPartnerRequestBody, AddCustomerPartnerResponseType>('addCustomerPartner', parameters, requestBody);

export const ApplyCustomerParametersModel = types.model('ApplyCustomerParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type ApplyCustomerParametersType = Instance<typeof ApplyCustomerParametersModel>;
export const ApplyCustomerRequestBodyModel = CustomerSearchResultSelectionModel;
type ApplyCustomerRequestBody = Instance<typeof ApplyCustomerRequestBodyModel>;

export type ApplyCustomerResponseType = void
export const applyCustomer = async (
  parameters: ApplyCustomerParametersType,
  requestBody: ApplyCustomerRequestBody
): Promise<AxiosResponse<ApplyCustomerResponseType>> =>
  request<ApplyCustomerParametersType, ApplyCustomerRequestBody, ApplyCustomerResponseType>('applyCustomer', parameters, requestBody);

export const UpdateCustomerParametersModel = types.model('UpdateCustomerParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type UpdateCustomerParametersType = Instance<typeof UpdateCustomerParametersModel>;
export const UpdateCustomerRequestBodyModel = ProposalCustomerModel;
type UpdateCustomerRequestBody = Instance<typeof UpdateCustomerRequestBodyModel>;
export const UpdateCustomerResponseModel = ProposalSuccessModel;
export type UpdateCustomerResponseType = Instance<typeof UpdateCustomerResponseModel>
export const updateCustomer = async (
  parameters: UpdateCustomerParametersType,
  requestBody: UpdateCustomerRequestBody
): Promise<AxiosResponse<UpdateCustomerResponseType>> =>
  request<UpdateCustomerParametersType, UpdateCustomerRequestBody, UpdateCustomerResponseType>('updateCustomer', parameters, requestBody);

export const SetCustomerDeliveryAddressParametersModel = types.model('SetCustomerDeliveryAddressParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type SetCustomerDeliveryAddressParametersType = Instance<typeof SetCustomerDeliveryAddressParametersModel>;
export const SetCustomerDeliveryAddressRequestBodyModel = types.integer;
type SetCustomerDeliveryAddressRequestBody = Instance<typeof SetCustomerDeliveryAddressRequestBodyModel>;
export const SetCustomerDeliveryAddressResponseModel = ProposalSuccessModel;
export type SetCustomerDeliveryAddressResponseType = Instance<typeof SetCustomerDeliveryAddressResponseModel>
export const setCustomerDeliveryAddress = async (
  parameters: SetCustomerDeliveryAddressParametersType,
  requestBody: SetCustomerDeliveryAddressRequestBody
): Promise<AxiosResponse<SetCustomerDeliveryAddressResponseType>> =>
  request<SetCustomerDeliveryAddressParametersType, SetCustomerDeliveryAddressRequestBody, SetCustomerDeliveryAddressResponseType>('setCustomerDeliveryAddress', parameters, requestBody);

export const CreateCustomerDeliveryAddressParametersModel = types.model('CreateCustomerDeliveryAddressParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateCustomerDeliveryAddressParametersType = Instance<typeof CreateCustomerDeliveryAddressParametersModel>;
export const CreateCustomerDeliveryAddressRequestBodyModel = AddressModel;
type CreateCustomerDeliveryAddressRequestBody = Instance<typeof CreateCustomerDeliveryAddressRequestBodyModel>;
export const CreateCustomerDeliveryAddressResponseModel = ProposalSuccessModel;
export type CreateCustomerDeliveryAddressResponseType = Instance<typeof CreateCustomerDeliveryAddressResponseModel>
export const createCustomerDeliveryAddress = async (
  parameters: CreateCustomerDeliveryAddressParametersType,
  requestBody: CreateCustomerDeliveryAddressRequestBody
): Promise<AxiosResponse<CreateCustomerDeliveryAddressResponseType>> =>
  request<CreateCustomerDeliveryAddressParametersType, CreateCustomerDeliveryAddressRequestBody, CreateCustomerDeliveryAddressResponseType>('createCustomerDeliveryAddress', parameters, requestBody);

export const SetCustomerInvoiceAddressParametersModel = types.model('SetCustomerInvoiceAddressParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type SetCustomerInvoiceAddressParametersType = Instance<typeof SetCustomerInvoiceAddressParametersModel>;
export const SetCustomerInvoiceAddressRequestBodyModel = types.integer;
type SetCustomerInvoiceAddressRequestBody = Instance<typeof SetCustomerInvoiceAddressRequestBodyModel>;
export const SetCustomerInvoiceAddressResponseModel = ProposalSuccessModel;
export type SetCustomerInvoiceAddressResponseType = Instance<typeof SetCustomerInvoiceAddressResponseModel>
export const setCustomerInvoiceAddress = async (
  parameters: SetCustomerInvoiceAddressParametersType,
  requestBody: SetCustomerInvoiceAddressRequestBody
): Promise<AxiosResponse<SetCustomerInvoiceAddressResponseType>> =>
  request<SetCustomerInvoiceAddressParametersType, SetCustomerInvoiceAddressRequestBody, SetCustomerInvoiceAddressResponseType>('setCustomerInvoiceAddress', parameters, requestBody);

export const CreateCustomerInvoiceAddressParametersModel = types.model('CreateCustomerInvoiceAddressParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateCustomerInvoiceAddressParametersType = Instance<typeof CreateCustomerInvoiceAddressParametersModel>;
export const CreateCustomerInvoiceAddressRequestBodyModel = AddressModel;
type CreateCustomerInvoiceAddressRequestBody = Instance<typeof CreateCustomerInvoiceAddressRequestBodyModel>;
export const CreateCustomerInvoiceAddressResponseModel = ProposalSuccessModel;
export type CreateCustomerInvoiceAddressResponseType = Instance<typeof CreateCustomerInvoiceAddressResponseModel>
export const createCustomerInvoiceAddress = async (
  parameters: CreateCustomerInvoiceAddressParametersType,
  requestBody: CreateCustomerInvoiceAddressRequestBody
): Promise<AxiosResponse<CreateCustomerInvoiceAddressResponseType>> =>
  request<CreateCustomerInvoiceAddressParametersType, CreateCustomerInvoiceAddressRequestBody, CreateCustomerInvoiceAddressResponseType>('createCustomerInvoiceAddress', parameters, requestBody);

export const GetCustomerAddressesParametersModel = types.model('GetCustomerAddressesParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCustomerAddressesParametersType = Instance<typeof GetCustomerAddressesParametersModel>;

type GetCustomerAddressesRequestBody = null;
export const GetCustomerAddressesResponseModel = types.array(AddressModel);
export type GetCustomerAddressesResponseType = Instance<typeof GetCustomerAddressesResponseModel>
export const getCustomerAddresses = async (
  parameters: GetCustomerAddressesParametersType,
  requestBody: GetCustomerAddressesRequestBody = null
): Promise<AxiosResponse<GetCustomerAddressesResponseType>> =>
  request<GetCustomerAddressesParametersType, GetCustomerAddressesRequestBody, GetCustomerAddressesResponseType>('getCustomerAddresses', parameters, requestBody);

export const GetCustomerAndEquipmentSupplierBoardMembersParametersModel = types.model('GetCustomerAndEquipmentSupplierBoardMembersParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCustomerAndEquipmentSupplierBoardMembersParametersType = Instance<typeof GetCustomerAndEquipmentSupplierBoardMembersParametersModel>;

type GetCustomerAndEquipmentSupplierBoardMembersRequestBody = null;
export const GetCustomerAndEquipmentSupplierBoardMembersResponseModel = types.array(BoardmemberModel);
export type GetCustomerAndEquipmentSupplierBoardMembersResponseType = Instance<typeof GetCustomerAndEquipmentSupplierBoardMembersResponseModel>
export const getCustomerAndEquipmentSupplierBoardMembers = async (
  parameters: GetCustomerAndEquipmentSupplierBoardMembersParametersType,
  requestBody: GetCustomerAndEquipmentSupplierBoardMembersRequestBody = null
): Promise<AxiosResponse<GetCustomerAndEquipmentSupplierBoardMembersResponseType>> =>
  request<GetCustomerAndEquipmentSupplierBoardMembersParametersType, GetCustomerAndEquipmentSupplierBoardMembersRequestBody, GetCustomerAndEquipmentSupplierBoardMembersResponseType>('getCustomerAndEquipmentSupplierBoardMembers', parameters, requestBody);

export const GetCustomerBoardmembersParametersModel = types.model('GetCustomerBoardmembersParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCustomerBoardmembersParametersType = Instance<typeof GetCustomerBoardmembersParametersModel>;

type GetCustomerBoardmembersRequestBody = null;
export const GetCustomerBoardmembersResponseModel = types.array(BoardmemberModel);
export type GetCustomerBoardmembersResponseType = Instance<typeof GetCustomerBoardmembersResponseModel>
export const getCustomerBoardmembers = async (
  parameters: GetCustomerBoardmembersParametersType,
  requestBody: GetCustomerBoardmembersRequestBody = null
): Promise<AxiosResponse<GetCustomerBoardmembersResponseType>> =>
  request<GetCustomerBoardmembersParametersType, GetCustomerBoardmembersRequestBody, GetCustomerBoardmembersResponseType>('getCustomerBoardmembers', parameters, requestBody);

export const CreateCustomerManualParametersModel = types.model('CreateCustomerManualParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateCustomerManualParametersType = Instance<typeof CreateCustomerManualParametersModel>;
export const CreateCustomerManualRequestBodyModel = ManualCustomerCreateModel;
type CreateCustomerManualRequestBody = Instance<typeof CreateCustomerManualRequestBodyModel>;

export type CreateCustomerManualResponseType = void
export const createCustomerManual = async (
  parameters: CreateCustomerManualParametersType,
  requestBody: CreateCustomerManualRequestBody
): Promise<AxiosResponse<CreateCustomerManualResponseType>> =>
  request<CreateCustomerManualParametersType, CreateCustomerManualRequestBody, CreateCustomerManualResponseType>('createCustomerManual', parameters, requestBody);

export const GetCustomerManualCreationConfigParametersModel = types.model('GetCustomerManualCreationConfigParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetCustomerManualCreationConfigParametersType = Instance<typeof GetCustomerManualCreationConfigParametersModel>;

type GetCustomerManualCreationConfigRequestBody = null;
export const GetCustomerManualCreationConfigResponseModel = ManualCustomerConfigModel;
export type GetCustomerManualCreationConfigResponseType = Instance<typeof GetCustomerManualCreationConfigResponseModel>
export const getCustomerManualCreationConfig = async (
  parameters: GetCustomerManualCreationConfigParametersType,
  requestBody: GetCustomerManualCreationConfigRequestBody = null
): Promise<AxiosResponse<GetCustomerManualCreationConfigResponseType>> =>
  request<GetCustomerManualCreationConfigParametersType, GetCustomerManualCreationConfigRequestBody, GetCustomerManualCreationConfigResponseType>('getCustomerManualCreationConfig', parameters, requestBody);

export const SelectLiablePartnersParametersModel = types.model('SelectLiablePartnersParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type SelectLiablePartnersParametersType = Instance<typeof SelectLiablePartnersParametersModel>;
export const SelectLiablePartnersRequestBodyModel = types.array(types.integer);
type SelectLiablePartnersRequestBody = Instance<typeof SelectLiablePartnersRequestBodyModel>;
export const SelectLiablePartnersResponseModel = types.array(PartnerModel);
export type SelectLiablePartnersResponseType = Instance<typeof SelectLiablePartnersResponseModel>
export const selectLiablePartners = async (
  parameters: SelectLiablePartnersParametersType,
  requestBody: SelectLiablePartnersRequestBody
): Promise<AxiosResponse<SelectLiablePartnersResponseType>> =>
  request<SelectLiablePartnersParametersType, SelectLiablePartnersRequestBody, SelectLiablePartnersResponseType>('selectLiablePartners', parameters, requestBody);

export const UpdateCustomerInvoiceContactParametersModel = types.model('UpdateCustomerInvoiceContactParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type UpdateCustomerInvoiceContactParametersType = Instance<typeof UpdateCustomerInvoiceContactParametersModel>;
export const UpdateCustomerInvoiceContactRequestBodyModel = CustomerInvoiceContactModel;
type UpdateCustomerInvoiceContactRequestBody = Instance<typeof UpdateCustomerInvoiceContactRequestBodyModel>;
export const UpdateCustomerInvoiceContactResponseModel = ProposalSuccessModel;
export type UpdateCustomerInvoiceContactResponseType = Instance<typeof UpdateCustomerInvoiceContactResponseModel>
export const updateCustomerInvoiceContact = async (
  parameters: UpdateCustomerInvoiceContactParametersType,
  requestBody: UpdateCustomerInvoiceContactRequestBody
): Promise<AxiosResponse<UpdateCustomerInvoiceContactResponseType>> =>
  request<UpdateCustomerInvoiceContactParametersType, UpdateCustomerInvoiceContactRequestBody, UpdateCustomerInvoiceContactResponseType>('updateCustomerInvoiceContact', parameters, requestBody);

export const RequestCreditDecisionParametersModel = types.model('RequestCreditDecisionParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type RequestCreditDecisionParametersType = Instance<typeof RequestCreditDecisionParametersModel>;
export const RequestCreditDecisionRequestBodyModel = DecisionRequestModel;
type RequestCreditDecisionRequestBody = Instance<typeof RequestCreditDecisionRequestBodyModel>;
export const RequestCreditDecisionResponseModel = ProposalSuccessModel;
export type RequestCreditDecisionResponseType = Instance<typeof RequestCreditDecisionResponseModel>
export const requestCreditDecision = async (
  parameters: RequestCreditDecisionParametersType,
  requestBody: RequestCreditDecisionRequestBody
): Promise<AxiosResponse<RequestCreditDecisionResponseType>> =>
  request<RequestCreditDecisionParametersType, RequestCreditDecisionRequestBody, RequestCreditDecisionResponseType>('requestCreditDecision', parameters, requestBody);

export const CancelDecisionRequestParametersModel = types.model('CancelDecisionRequestParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CancelDecisionRequestParametersType = Instance<typeof CancelDecisionRequestParametersModel>;

type CancelDecisionRequestRequestBody = null;
export const CancelDecisionRequestResponseModel = ProposalSuccessModel;
export type CancelDecisionRequestResponseType = Instance<typeof CancelDecisionRequestResponseModel>
export const cancelDecisionRequest = async (
  parameters: CancelDecisionRequestParametersType,
  requestBody: CancelDecisionRequestRequestBody = null
): Promise<AxiosResponse<CancelDecisionRequestResponseType>> =>
  request<CancelDecisionRequestParametersType, CancelDecisionRequestRequestBody, CancelDecisionRequestResponseType>('cancelDecisionRequest', parameters, requestBody);

export const CheckPreconditionsForDecisionParametersModel = types.model('CheckPreconditionsForDecisionParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type CheckPreconditionsForDecisionParametersType = Instance<typeof CheckPreconditionsForDecisionParametersModel>;

type CheckPreconditionsForDecisionRequestBody = null;
export const CheckPreconditionsForDecisionResponseModel = DecisionPrecheckResultModel;
export type CheckPreconditionsForDecisionResponseType = Instance<typeof CheckPreconditionsForDecisionResponseModel>
export const checkPreconditionsForDecision = async (
  parameters: CheckPreconditionsForDecisionParametersType,
  requestBody: CheckPreconditionsForDecisionRequestBody = null
): Promise<AxiosResponse<CheckPreconditionsForDecisionResponseType>> =>
  request<CheckPreconditionsForDecisionParametersType, CheckPreconditionsForDecisionRequestBody, CheckPreconditionsForDecisionResponseType>('checkPreconditionsForDecision', parameters, requestBody);

export const DownloadDecisionDocumentParametersModel = types.model('DownloadDecisionDocumentParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type DownloadDecisionDocumentParametersType = Instance<typeof DownloadDecisionDocumentParametersModel>;
export const DownloadDecisionDocumentRequestBodyModel = {};
type DownloadDecisionDocumentRequestBody = Instance<typeof DownloadDecisionDocumentRequestBodyModel>;
export const DownloadDecisionDocumentResponseModel = types.string;
export type DownloadDecisionDocumentResponseType = Instance<typeof DownloadDecisionDocumentResponseModel>
export const downloadDecisionDocument = async (
  parameters: DownloadDecisionDocumentParametersType,
  requestBody: DownloadDecisionDocumentRequestBody
): Promise<AxiosResponse<DownloadDecisionDocumentResponseType>> =>
  request<DownloadDecisionDocumentParametersType, DownloadDecisionDocumentRequestBody, DownloadDecisionDocumentResponseType>('downloadDecisionDocument', parameters, requestBody);

export const SendDecisionDocumentEmailParametersModel = types.model('SendDecisionDocumentEmailParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type SendDecisionDocumentEmailParametersType = Instance<typeof SendDecisionDocumentEmailParametersModel>;
export const SendDecisionDocumentEmailRequestBodyModel = {};
type SendDecisionDocumentEmailRequestBody = Instance<typeof SendDecisionDocumentEmailRequestBodyModel>;

export type SendDecisionDocumentEmailResponseType = void
export const sendDecisionDocumentEmail = async (
  parameters: SendDecisionDocumentEmailParametersType,
  requestBody: SendDecisionDocumentEmailRequestBody
): Promise<AxiosResponse<SendDecisionDocumentEmailResponseType>> =>
  request<SendDecisionDocumentEmailParametersType, SendDecisionDocumentEmailRequestBody, SendDecisionDocumentEmailResponseType>('sendDecisionDocumentEmail', parameters, requestBody);

export const DeleteSigningRequestParametersModel = types.model('DeleteSigningRequestParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type DeleteSigningRequestParametersType = Instance<typeof DeleteSigningRequestParametersModel>;
export const DeleteSigningRequestRequestBodyModel = DocumentIdentifierModel;
type DeleteSigningRequestRequestBody = Instance<typeof DeleteSigningRequestRequestBodyModel>;

export type DeleteSigningRequestResponseType = void
export const deleteSigningRequest = async (
  parameters: DeleteSigningRequestParametersType,
  requestBody: DeleteSigningRequestRequestBody
): Promise<AxiosResponse<DeleteSigningRequestResponseType>> =>
  request<DeleteSigningRequestParametersType, DeleteSigningRequestRequestBody, DeleteSigningRequestResponseType>('deleteSigningRequest', parameters, requestBody);

export const DownloadDocumentParametersModel = types.model('DownloadDocumentParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type DownloadDocumentParametersType = Instance<typeof DownloadDocumentParametersModel>;
export const DownloadDocumentRequestBodyModel = DownloadRequestModel;
type DownloadDocumentRequestBody = Instance<typeof DownloadDocumentRequestBodyModel>;
export const DownloadDocumentResponseModel = types.string;
export type DownloadDocumentResponseType = Instance<typeof DownloadDocumentResponseModel>
export const downloadDocument = async (
  parameters: DownloadDocumentParametersType,
  requestBody: DownloadDocumentRequestBody
): Promise<AxiosResponse<DownloadDocumentResponseType>> =>
  request<DownloadDocumentParametersType, DownloadDocumentRequestBody, DownloadDocumentResponseType>('downloadDocument', parameters, requestBody);

export const DownloadSignedDocumentParametersModel = types.model('DownloadSignedDocumentParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type DownloadSignedDocumentParametersType = Instance<typeof DownloadSignedDocumentParametersModel>;
export const DownloadSignedDocumentRequestBodyModel = DocumentIdentifierModel;
type DownloadSignedDocumentRequestBody = Instance<typeof DownloadSignedDocumentRequestBodyModel>;
export const DownloadSignedDocumentResponseModel = types.string;
export type DownloadSignedDocumentResponseType = Instance<typeof DownloadSignedDocumentResponseModel>
export const downloadSignedDocument = async (
  parameters: DownloadSignedDocumentParametersType,
  requestBody: DownloadSignedDocumentRequestBody
): Promise<AxiosResponse<DownloadSignedDocumentResponseType>> =>
  request<DownloadSignedDocumentParametersType, DownloadSignedDocumentRequestBody, DownloadSignedDocumentResponseType>('downloadSignedDocument', parameters, requestBody);

export const DownloadUploadedDocumentParametersModel = types.model('DownloadUploadedDocumentParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type DownloadUploadedDocumentParametersType = Instance<typeof DownloadUploadedDocumentParametersModel>;
export const DownloadUploadedDocumentRequestBodyModel = DocumentIdentifierModel;
type DownloadUploadedDocumentRequestBody = Instance<typeof DownloadUploadedDocumentRequestBodyModel>;
export const DownloadUploadedDocumentResponseModel = types.string;
export type DownloadUploadedDocumentResponseType = Instance<typeof DownloadUploadedDocumentResponseModel>
export const downloadUploadedDocument = async (
  parameters: DownloadUploadedDocumentParametersType,
  requestBody: DownloadUploadedDocumentRequestBody
): Promise<AxiosResponse<DownloadUploadedDocumentResponseType>> =>
  request<DownloadUploadedDocumentParametersType, DownloadUploadedDocumentRequestBody, DownloadUploadedDocumentResponseType>('downloadUploadedDocument', parameters, requestBody);

export const GetConditionsForSigningParametersModel = types.model('GetConditionsForSigningParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetConditionsForSigningParametersType = Instance<typeof GetConditionsForSigningParametersModel>;
export const GetConditionsForSigningRequestBodyModel = types.array(DocumentIdentifierModel);
type GetConditionsForSigningRequestBody = Instance<typeof GetConditionsForSigningRequestBodyModel>;
export const GetConditionsForSigningResponseModel = SignaturePreConditionModel;
export type GetConditionsForSigningResponseType = Instance<typeof GetConditionsForSigningResponseModel>
export const getConditionsForSigning = async (
  parameters: GetConditionsForSigningParametersType,
  requestBody: GetConditionsForSigningRequestBody
): Promise<AxiosResponse<GetConditionsForSigningResponseType>> =>
  request<GetConditionsForSigningParametersType, GetConditionsForSigningRequestBody, GetConditionsForSigningResponseType>('getConditionsForSigning', parameters, requestBody);

export const GetDocumentsParametersModel = types.model('GetDocumentsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetDocumentsParametersType = Instance<typeof GetDocumentsParametersModel>;

type GetDocumentsRequestBody = null;
export const GetDocumentsResponseModel = types.array(ProposalDocumentModel);
export type GetDocumentsResponseType = Instance<typeof GetDocumentsResponseModel>
export const getDocuments = async (
  parameters: GetDocumentsParametersType,
  requestBody: GetDocumentsRequestBody = null
): Promise<AxiosResponse<GetDocumentsResponseType>> =>
  request<GetDocumentsParametersType, GetDocumentsRequestBody, GetDocumentsResponseType>('getDocuments', parameters, requestBody);

export const GetUploadConfigParametersModel = types.model('GetUploadConfigParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetUploadConfigParametersType = Instance<typeof GetUploadConfigParametersModel>;

type GetUploadConfigRequestBody = null;
export const GetUploadConfigResponseModel = DocumentUploadConfigEntityModel;
export type GetUploadConfigResponseType = Instance<typeof GetUploadConfigResponseModel>
export const getUploadConfig = async (
  parameters: GetUploadConfigParametersType,
  requestBody: GetUploadConfigRequestBody = null
): Promise<AxiosResponse<GetUploadConfigResponseType>> =>
  request<GetUploadConfigParametersType, GetUploadConfigRequestBody, GetUploadConfigResponseType>('getUploadConfig', parameters, requestBody);

export const GetUploadedDocumentsParametersModel = types.model('GetUploadedDocumentsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetUploadedDocumentsParametersType = Instance<typeof GetUploadedDocumentsParametersModel>;

type GetUploadedDocumentsRequestBody = null;
export const GetUploadedDocumentsResponseModel = types.array(ProposalDocumentModel);
export type GetUploadedDocumentsResponseType = Instance<typeof GetUploadedDocumentsResponseModel>
export const getUploadedDocuments = async (
  parameters: GetUploadedDocumentsParametersType,
  requestBody: GetUploadedDocumentsRequestBody = null
): Promise<AxiosResponse<GetUploadedDocumentsResponseType>> =>
  request<GetUploadedDocumentsParametersType, GetUploadedDocumentsRequestBody, GetUploadedDocumentsResponseType>('getUploadedDocuments', parameters, requestBody);

export const UploadDocumentParametersModel = types.model('UploadDocumentParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type UploadDocumentParametersType = Instance<typeof UploadDocumentParametersModel>;
export const UploadDocumentRequestBodyModel = DocumentUploadEntityModel;
type UploadDocumentRequestBody = Instance<typeof UploadDocumentRequestBodyModel>;
export const UploadDocumentResponseModel = ProposalSuccessModel;
export type UploadDocumentResponseType = Instance<typeof UploadDocumentResponseModel>
export const uploadDocument = async (
  parameters: UploadDocumentParametersType,
  requestBody: UploadDocumentRequestBody
): Promise<AxiosResponse<UploadDocumentResponseType>> =>
  request<UploadDocumentParametersType, UploadDocumentRequestBody, UploadDocumentResponseType>('uploadDocument', parameters, requestBody);

export const RefreshSigningRequestParametersModel = types.model('RefreshSigningRequestParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type RefreshSigningRequestParametersType = Instance<typeof RefreshSigningRequestParametersModel>;
export const RefreshSigningRequestRequestBodyModel = DocumentIdentifierModel;
type RefreshSigningRequestRequestBody = Instance<typeof RefreshSigningRequestRequestBodyModel>;
export const RefreshSigningRequestResponseModel = ProposalDocumentModel;
export type RefreshSigningRequestResponseType = Instance<typeof RefreshSigningRequestResponseModel>
export const refreshSigningRequest = async (
  parameters: RefreshSigningRequestParametersType,
  requestBody: RefreshSigningRequestRequestBody
): Promise<AxiosResponse<RefreshSigningRequestResponseType>> =>
  request<RefreshSigningRequestParametersType, RefreshSigningRequestRequestBody, RefreshSigningRequestResponseType>('refreshSigningRequest', parameters, requestBody);

export const RequestSignatureParametersModel = types.model('RequestSignatureParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type RequestSignatureParametersType = Instance<typeof RequestSignatureParametersModel>;
export const RequestSignatureRequestBodyModel = SendSignatureRequestModel;
type RequestSignatureRequestBody = Instance<typeof RequestSignatureRequestBodyModel>;
export const RequestSignatureResponseModel = ProposalDocumentModel;
export type RequestSignatureResponseType = Instance<typeof RequestSignatureResponseModel>
export const requestSignature = async (
  parameters: RequestSignatureParametersType,
  requestBody: RequestSignatureRequestBody
): Promise<AxiosResponse<RequestSignatureResponseType>> =>
  request<RequestSignatureParametersType, RequestSignatureRequestBody, RequestSignatureResponseType>('requestSignature', parameters, requestBody);

export const SendOfferDocumentByMailParametersModel = types.model('SendOfferDocumentByMailParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type SendOfferDocumentByMailParametersType = Instance<typeof SendOfferDocumentByMailParametersModel>;
export const SendOfferDocumentByMailRequestBodyModel = SendEmailRequestModel;
type SendOfferDocumentByMailRequestBody = Instance<typeof SendOfferDocumentByMailRequestBodyModel>;

export type SendOfferDocumentByMailResponseType = void
export const sendOfferDocumentByMail = async (
  parameters: SendOfferDocumentByMailParametersType,
  requestBody: SendOfferDocumentByMailRequestBody
): Promise<AxiosResponse<SendOfferDocumentByMailResponseType>> =>
  request<SendOfferDocumentByMailParametersType, SendOfferDocumentByMailRequestBody, SendOfferDocumentByMailResponseType>('sendOfferDocumentByMail', parameters, requestBody);

export const SendReminderParametersModel = types.model('SendReminderParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type SendReminderParametersType = Instance<typeof SendReminderParametersModel>;
export const SendReminderRequestBodyModel = DocumentIdentifierModel;
type SendReminderRequestBody = Instance<typeof SendReminderRequestBodyModel>;

export type SendReminderResponseType = void
export const sendReminder = async (
  parameters: SendReminderParametersType,
  requestBody: SendReminderRequestBody
): Promise<AxiosResponse<SendReminderResponseType>> =>
  request<SendReminderParametersType, SendReminderRequestBody, SendReminderResponseType>('sendReminder', parameters, requestBody);

export const GetEquipmentSuppliersFromProposalParametersModel = types.model('GetEquipmentSuppliersFromProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetEquipmentSuppliersFromProposalParametersType = Instance<typeof GetEquipmentSuppliersFromProposalParametersModel>;

type GetEquipmentSuppliersFromProposalRequestBody = null;
export const GetEquipmentSuppliersFromProposalResponseModel = types.array(ProposalEquipmentSupplierModel);
export type GetEquipmentSuppliersFromProposalResponseType = Instance<typeof GetEquipmentSuppliersFromProposalResponseModel>
export const getEquipmentSuppliersFromProposal = async (
  parameters: GetEquipmentSuppliersFromProposalParametersType,
  requestBody: GetEquipmentSuppliersFromProposalRequestBody = null
): Promise<AxiosResponse<GetEquipmentSuppliersFromProposalResponseType>> =>
  request<GetEquipmentSuppliersFromProposalParametersType, GetEquipmentSuppliersFromProposalRequestBody, GetEquipmentSuppliersFromProposalResponseType>('getEquipmentSuppliersFromProposal', parameters, requestBody);

export const AddEquipmentSupplierToProposalParametersModel = types.model('AddEquipmentSupplierToProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type AddEquipmentSupplierToProposalParametersType = Instance<typeof AddEquipmentSupplierToProposalParametersModel>;
export const AddEquipmentSupplierToProposalRequestBodyModel = types.integer;
type AddEquipmentSupplierToProposalRequestBody = Instance<typeof AddEquipmentSupplierToProposalRequestBodyModel>;
export const AddEquipmentSupplierToProposalResponseModel = ProposalEquipmentSupplierModel;
export type AddEquipmentSupplierToProposalResponseType = Instance<typeof AddEquipmentSupplierToProposalResponseModel>
export const addEquipmentSupplierToProposal = async (
  parameters: AddEquipmentSupplierToProposalParametersType,
  requestBody: AddEquipmentSupplierToProposalRequestBody
): Promise<AxiosResponse<AddEquipmentSupplierToProposalResponseType>> =>
  request<AddEquipmentSupplierToProposalParametersType, AddEquipmentSupplierToProposalRequestBody, AddEquipmentSupplierToProposalResponseType>('addEquipmentSupplierToProposal', parameters, requestBody);

export const RemoveEquipmentSupplierFromProposalParametersModel = types.model('RemoveEquipmentSupplierFromProposalParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  equipmentSupplierId: types.integer,
  ifMatch: types.string
});
type RemoveEquipmentSupplierFromProposalParametersType = Instance<typeof RemoveEquipmentSupplierFromProposalParametersModel>;

type RemoveEquipmentSupplierFromProposalRequestBody = null;

export type RemoveEquipmentSupplierFromProposalResponseType = void
export const removeEquipmentSupplierFromProposal = async (
  parameters: RemoveEquipmentSupplierFromProposalParametersType,
  requestBody: RemoveEquipmentSupplierFromProposalRequestBody = null
): Promise<AxiosResponse<RemoveEquipmentSupplierFromProposalResponseType>> =>
  request<RemoveEquipmentSupplierFromProposalParametersType, RemoveEquipmentSupplierFromProposalRequestBody, RemoveEquipmentSupplierFromProposalResponseType>('removeEquipmentSupplierFromProposal', parameters, requestBody);

export const GetHistoryParametersModel = types.model('GetHistoryParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetHistoryParametersType = Instance<typeof GetHistoryParametersModel>;

type GetHistoryRequestBody = null;
export const GetHistoryResponseModel = types.array(HistoryProcessEntityModel);
export type GetHistoryResponseType = Instance<typeof GetHistoryResponseModel>
export const getHistory = async (
  parameters: GetHistoryParametersType,
  requestBody: GetHistoryRequestBody = null
): Promise<AxiosResponse<GetHistoryResponseType>> =>
  request<GetHistoryParametersType, GetHistoryRequestBody, GetHistoryResponseType>('getHistory', parameters, requestBody);

export const AddCorporateGuarantorFromSearchResultParametersModel = types.model('AddCorporateGuarantorFromSearchResultParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type AddCorporateGuarantorFromSearchResultParametersType = Instance<typeof AddCorporateGuarantorFromSearchResultParametersModel>;
export const AddCorporateGuarantorFromSearchResultRequestBodyModel = CorporateGuarantorSearchResultSelectionModel;
type AddCorporateGuarantorFromSearchResultRequestBody = Instance<typeof AddCorporateGuarantorFromSearchResultRequestBodyModel>;
export const AddCorporateGuarantorFromSearchResultResponseModel = GuarantorModel;
export type AddCorporateGuarantorFromSearchResultResponseType = Instance<typeof AddCorporateGuarantorFromSearchResultResponseModel>
export const addCorporateGuarantorFromSearchResult = async (
  parameters: AddCorporateGuarantorFromSearchResultParametersType,
  requestBody: AddCorporateGuarantorFromSearchResultRequestBody
): Promise<AxiosResponse<AddCorporateGuarantorFromSearchResultResponseType>> =>
  request<AddCorporateGuarantorFromSearchResultParametersType, AddCorporateGuarantorFromSearchResultRequestBody, AddCorporateGuarantorFromSearchResultResponseType>('addCorporateGuarantorFromSearchResult', parameters, requestBody);

export const RemoveCorporateGuarantorParametersModel = types.model('RemoveCorporateGuarantorParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  guarantorId: types.integer
});
type RemoveCorporateGuarantorParametersType = Instance<typeof RemoveCorporateGuarantorParametersModel>;

type RemoveCorporateGuarantorRequestBody = null;

export type RemoveCorporateGuarantorResponseType = void
export const removeCorporateGuarantor = async (
  parameters: RemoveCorporateGuarantorParametersType,
  requestBody: RemoveCorporateGuarantorRequestBody = null
): Promise<AxiosResponse<RemoveCorporateGuarantorResponseType>> =>
  request<RemoveCorporateGuarantorParametersType, RemoveCorporateGuarantorRequestBody, RemoveCorporateGuarantorResponseType>('removeCorporateGuarantor', parameters, requestBody);

export const SearchCorporateGuarantorsParametersModel = types.model('SearchCorporateGuarantorsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  registrationNumber: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  name: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  regionId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  street: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  city: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  postalCode: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  phone: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  website: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  companyType: types.optional(types.union(types.enumeration(['LIMITED', 'NONLIMITED']), types.undefined, types.null),undefined),
  countryCode: types.optional(types.union(types.string, types.undefined, types.null),undefined)
});
type SearchCorporateGuarantorsParametersType = Instance<typeof SearchCorporateGuarantorsParametersModel>;

type SearchCorporateGuarantorsRequestBody = null;
export const SearchCorporateGuarantorsResponseModel = types.array(CorporateGuarantorSearchResultItemModel);
export type SearchCorporateGuarantorsResponseType = Instance<typeof SearchCorporateGuarantorsResponseModel>
export const searchCorporateGuarantors = async (
  parameters: SearchCorporateGuarantorsParametersType,
  requestBody: SearchCorporateGuarantorsRequestBody = null
): Promise<AxiosResponse<SearchCorporateGuarantorsResponseType>> =>
  request<SearchCorporateGuarantorsParametersType, SearchCorporateGuarantorsRequestBody, SearchCorporateGuarantorsResponseType>('searchCorporateGuarantors', parameters, requestBody);

export const GetGuarantorsParametersModel = types.model('GetGuarantorsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetGuarantorsParametersType = Instance<typeof GetGuarantorsParametersModel>;

type GetGuarantorsRequestBody = null;
export const GetGuarantorsResponseModel = types.array(GuarantorModel);
export type GetGuarantorsResponseType = Instance<typeof GetGuarantorsResponseModel>
export const getGuarantors = async (
  parameters: GetGuarantorsParametersType,
  requestBody: GetGuarantorsRequestBody = null
): Promise<AxiosResponse<GetGuarantorsResponseType>> =>
  request<GetGuarantorsParametersType, GetGuarantorsRequestBody, GetGuarantorsResponseType>('getGuarantors', parameters, requestBody);

export const GetGuarantorsConfigParametersModel = types.model('GetGuarantorsConfigParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetGuarantorsConfigParametersType = Instance<typeof GetGuarantorsConfigParametersModel>;

type GetGuarantorsConfigRequestBody = null;
export const GetGuarantorsConfigResponseModel = GuarantorsConfigModel;
export type GetGuarantorsConfigResponseType = Instance<typeof GetGuarantorsConfigResponseModel>
export const getGuarantorsConfig = async (
  parameters: GetGuarantorsConfigParametersType,
  requestBody: GetGuarantorsConfigRequestBody = null
): Promise<AxiosResponse<GetGuarantorsConfigResponseType>> =>
  request<GetGuarantorsConfigParametersType, GetGuarantorsConfigRequestBody, GetGuarantorsConfigResponseType>('getGuarantorsConfig', parameters, requestBody);

export const ApplyAvailablePersonalGuarantorParametersModel = types.model('ApplyAvailablePersonalGuarantorParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  guarantorId: types.integer
});
type ApplyAvailablePersonalGuarantorParametersType = Instance<typeof ApplyAvailablePersonalGuarantorParametersModel>;
export const ApplyAvailablePersonalGuarantorRequestBodyModel = {};
type ApplyAvailablePersonalGuarantorRequestBody = Instance<typeof ApplyAvailablePersonalGuarantorRequestBodyModel>;
export const ApplyAvailablePersonalGuarantorResponseModel = GuarantorModel;
export type ApplyAvailablePersonalGuarantorResponseType = Instance<typeof ApplyAvailablePersonalGuarantorResponseModel>
export const applyAvailablePersonalGuarantor = async (
  parameters: ApplyAvailablePersonalGuarantorParametersType,
  requestBody: ApplyAvailablePersonalGuarantorRequestBody
): Promise<AxiosResponse<ApplyAvailablePersonalGuarantorResponseType>> =>
  request<ApplyAvailablePersonalGuarantorParametersType, ApplyAvailablePersonalGuarantorRequestBody, ApplyAvailablePersonalGuarantorResponseType>('applyAvailablePersonalGuarantor', parameters, requestBody);

export const CreatePersonalGuarantorManuallyParametersModel = types.model('CreatePersonalGuarantorManuallyParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type CreatePersonalGuarantorManuallyParametersType = Instance<typeof CreatePersonalGuarantorManuallyParametersModel>;
export const CreatePersonalGuarantorManuallyRequestBodyModel = PersonalGuarantorCreationDataModel;
type CreatePersonalGuarantorManuallyRequestBody = Instance<typeof CreatePersonalGuarantorManuallyRequestBodyModel>;
export const CreatePersonalGuarantorManuallyResponseModel = GuarantorModel;
export type CreatePersonalGuarantorManuallyResponseType = Instance<typeof CreatePersonalGuarantorManuallyResponseModel>
export const createPersonalGuarantorManually = async (
  parameters: CreatePersonalGuarantorManuallyParametersType,
  requestBody: CreatePersonalGuarantorManuallyRequestBody
): Promise<AxiosResponse<CreatePersonalGuarantorManuallyResponseType>> =>
  request<CreatePersonalGuarantorManuallyParametersType, CreatePersonalGuarantorManuallyRequestBody, CreatePersonalGuarantorManuallyResponseType>('createPersonalGuarantorManually', parameters, requestBody);

export const GetAvailablePersonalGuarantorsParametersModel = types.model('GetAvailablePersonalGuarantorsParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined)
});
type GetAvailablePersonalGuarantorsParametersType = Instance<typeof GetAvailablePersonalGuarantorsParametersModel>;

type GetAvailablePersonalGuarantorsRequestBody = null;
export const GetAvailablePersonalGuarantorsResponseModel = AvailablePersonalGuarantorsListPageResultModel;
export type GetAvailablePersonalGuarantorsResponseType = Instance<typeof GetAvailablePersonalGuarantorsResponseModel>
export const getAvailablePersonalGuarantors = async (
  parameters: GetAvailablePersonalGuarantorsParametersType,
  requestBody: GetAvailablePersonalGuarantorsRequestBody = null
): Promise<AxiosResponse<GetAvailablePersonalGuarantorsResponseType>> =>
  request<GetAvailablePersonalGuarantorsParametersType, GetAvailablePersonalGuarantorsRequestBody, GetAvailablePersonalGuarantorsResponseType>('getAvailablePersonalGuarantors', parameters, requestBody);

export const RemovePersonalGuarantorParametersModel = types.model('RemovePersonalGuarantorParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  guarantorId: types.integer
});
type RemovePersonalGuarantorParametersType = Instance<typeof RemovePersonalGuarantorParametersModel>;

type RemovePersonalGuarantorRequestBody = null;

export type RemovePersonalGuarantorResponseType = void
export const removePersonalGuarantor = async (
  parameters: RemovePersonalGuarantorParametersType,
  requestBody: RemovePersonalGuarantorRequestBody = null
): Promise<AxiosResponse<RemovePersonalGuarantorResponseType>> =>
  request<RemovePersonalGuarantorParametersType, RemovePersonalGuarantorRequestBody, RemovePersonalGuarantorResponseType>('removePersonalGuarantor', parameters, requestBody);

export const UpdatePersonalGuarantorFicoParametersModel = types.model('UpdatePersonalGuarantorFicoParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  guarantorId: types.integer
});
type UpdatePersonalGuarantorFicoParametersType = Instance<typeof UpdatePersonalGuarantorFicoParametersModel>;
export const UpdatePersonalGuarantorFicoRequestBodyModel = GuarantorFicoScoreModel;
type UpdatePersonalGuarantorFicoRequestBody = Instance<typeof UpdatePersonalGuarantorFicoRequestBodyModel>;
export const UpdatePersonalGuarantorFicoResponseModel = GuarantorModel;
export type UpdatePersonalGuarantorFicoResponseType = Instance<typeof UpdatePersonalGuarantorFicoResponseModel>
export const updatePersonalGuarantorFico = async (
  parameters: UpdatePersonalGuarantorFicoParametersType,
  requestBody: UpdatePersonalGuarantorFicoRequestBody
): Promise<AxiosResponse<UpdatePersonalGuarantorFicoResponseType>> =>
  request<UpdatePersonalGuarantorFicoParametersType, UpdatePersonalGuarantorFicoRequestBody, UpdatePersonalGuarantorFicoResponseType>('updatePersonalGuarantorFico', parameters, requestBody);

export const SearchPersonalGuarantorParametersModel = types.model('SearchPersonalGuarantorParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type SearchPersonalGuarantorParametersType = Instance<typeof SearchPersonalGuarantorParametersModel>;
export const SearchPersonalGuarantorRequestBodyModel = PersonalGuarantorSearchFilterModel;
type SearchPersonalGuarantorRequestBody = Instance<typeof SearchPersonalGuarantorRequestBodyModel>;
export const SearchPersonalGuarantorResponseModel = types.array(PersonalGuarantorCandidateModel);
export type SearchPersonalGuarantorResponseType = Instance<typeof SearchPersonalGuarantorResponseModel>
export const searchPersonalGuarantor = async (
  parameters: SearchPersonalGuarantorParametersType,
  requestBody: SearchPersonalGuarantorRequestBody
): Promise<AxiosResponse<SearchPersonalGuarantorResponseType>> =>
  request<SearchPersonalGuarantorParametersType, SearchPersonalGuarantorRequestBody, SearchPersonalGuarantorResponseType>('searchPersonalGuarantor', parameters, requestBody);

export const GetIntroducerSignatoriesParametersModel = types.model('GetIntroducerSignatoriesParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetIntroducerSignatoriesParametersType = Instance<typeof GetIntroducerSignatoriesParametersModel>;

type GetIntroducerSignatoriesRequestBody = null;
export const GetIntroducerSignatoriesResponseModel = types.array(SignatoryModel);
export type GetIntroducerSignatoriesResponseType = Instance<typeof GetIntroducerSignatoriesResponseModel>
export const getIntroducerSignatories = async (
  parameters: GetIntroducerSignatoriesParametersType,
  requestBody: GetIntroducerSignatoriesRequestBody = null
): Promise<AxiosResponse<GetIntroducerSignatoriesResponseType>> =>
  request<GetIntroducerSignatoriesParametersType, GetIntroducerSignatoriesRequestBody, GetIntroducerSignatoriesResponseType>('getIntroducerSignatories', parameters, requestBody);

export const GetIntroducerSignatoriesAsEquipmentSupplierParametersModel = types.model('GetIntroducerSignatoriesAsEquipmentSupplierParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetIntroducerSignatoriesAsEquipmentSupplierParametersType = Instance<typeof GetIntroducerSignatoriesAsEquipmentSupplierParametersModel>;

type GetIntroducerSignatoriesAsEquipmentSupplierRequestBody = null;
export const GetIntroducerSignatoriesAsEquipmentSupplierResponseModel = types.array(SignatoryModel);
export type GetIntroducerSignatoriesAsEquipmentSupplierResponseType = Instance<typeof GetIntroducerSignatoriesAsEquipmentSupplierResponseModel>
export const getIntroducerSignatoriesAsEquipmentSupplier = async (
  parameters: GetIntroducerSignatoriesAsEquipmentSupplierParametersType,
  requestBody: GetIntroducerSignatoriesAsEquipmentSupplierRequestBody = null
): Promise<AxiosResponse<GetIntroducerSignatoriesAsEquipmentSupplierResponseType>> =>
  request<GetIntroducerSignatoriesAsEquipmentSupplierParametersType, GetIntroducerSignatoriesAsEquipmentSupplierRequestBody, GetIntroducerSignatoriesAsEquipmentSupplierResponseType>('getIntroducerSignatoriesAsEquipmentSupplier', parameters, requestBody);

export const GetLoansParametersModel = types.model('GetLoansParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetLoansParametersType = Instance<typeof GetLoansParametersModel>;

type GetLoansRequestBody = null;
export const GetLoansResponseModel = types.array(LoanModel);
export type GetLoansResponseType = Instance<typeof GetLoansResponseModel>
export const getLoans = async (
  parameters: GetLoansParametersType,
  requestBody: GetLoansRequestBody = null
): Promise<AxiosResponse<GetLoansResponseType>> =>
  request<GetLoansParametersType, GetLoansRequestBody, GetLoansResponseType>('getLoans', parameters, requestBody);

export const CreateLoanParametersModel = types.model('CreateLoanParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateLoanParametersType = Instance<typeof CreateLoanParametersModel>;
export const CreateLoanRequestBodyModel = LoanModel;
type CreateLoanRequestBody = Instance<typeof CreateLoanRequestBodyModel>;
export const CreateLoanResponseModel = LoanModel;
export type CreateLoanResponseType = Instance<typeof CreateLoanResponseModel>
export const createLoan = async (
  parameters: CreateLoanParametersType,
  requestBody: CreateLoanRequestBody
): Promise<AxiosResponse<CreateLoanResponseType>> =>
  request<CreateLoanParametersType, CreateLoanRequestBody, CreateLoanResponseType>('createLoan', parameters, requestBody);

export const UpdateLoanParametersModel = types.model('UpdateLoanParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  loanId: types.integer,
  ifMatch: types.string
});
type UpdateLoanParametersType = Instance<typeof UpdateLoanParametersModel>;
export const UpdateLoanRequestBodyModel = LoanModel;
type UpdateLoanRequestBody = Instance<typeof UpdateLoanRequestBodyModel>;
export const UpdateLoanResponseModel = LoanModel;
export type UpdateLoanResponseType = Instance<typeof UpdateLoanResponseModel>
export const updateLoan = async (
  parameters: UpdateLoanParametersType,
  requestBody: UpdateLoanRequestBody
): Promise<AxiosResponse<UpdateLoanResponseType>> =>
  request<UpdateLoanParametersType, UpdateLoanRequestBody, UpdateLoanResponseType>('updateLoan', parameters, requestBody);

export const DeleteLoanParametersModel = types.model('DeleteLoanParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  loanId: types.integer,
  ifMatch: types.string
});
type DeleteLoanParametersType = Instance<typeof DeleteLoanParametersModel>;

type DeleteLoanRequestBody = null;

export type DeleteLoanResponseType = void
export const deleteLoan = async (
  parameters: DeleteLoanParametersType,
  requestBody: DeleteLoanRequestBody = null
): Promise<AxiosResponse<DeleteLoanResponseType>> =>
  request<DeleteLoanParametersType, DeleteLoanRequestBody, DeleteLoanResponseType>('deleteLoan', parameters, requestBody);

export const GetProposalConfigParametersModel = types.model('GetProposalConfigParametersModel', {
  introducerId: types.integer
});
type GetProposalConfigParametersType = Instance<typeof GetProposalConfigParametersModel>;

type GetProposalConfigRequestBody = null;
export const GetProposalConfigResponseModel = ProposalConfigModel;
export type GetProposalConfigResponseType = Instance<typeof GetProposalConfigResponseModel>
export const getProposalConfig = async (
  parameters: GetProposalConfigParametersType,
  requestBody: GetProposalConfigRequestBody = null
): Promise<AxiosResponse<GetProposalConfigResponseType>> =>
  request<GetProposalConfigParametersType, GetProposalConfigRequestBody, GetProposalConfigResponseType>('getProposalConfig', parameters, requestBody);

export const GetProposalStatusCountsParametersModel = types.model('GetProposalStatusCountsParametersModel', {
  introducerId: types.integer,
  start: types.integer,
  end: types.integer
});
type GetProposalStatusCountsParametersType = Instance<typeof GetProposalStatusCountsParametersModel>;

type GetProposalStatusCountsRequestBody = null;
export const GetProposalStatusCountsResponseModel = ProposalStatusCountsModel;
export type GetProposalStatusCountsResponseType = Instance<typeof GetProposalStatusCountsResponseModel>
export const getProposalStatusCounts = async (
  parameters: GetProposalStatusCountsParametersType,
  requestBody: GetProposalStatusCountsRequestBody = null
): Promise<AxiosResponse<GetProposalStatusCountsResponseType>> =>
  request<GetProposalStatusCountsParametersType, GetProposalStatusCountsRequestBody, GetProposalStatusCountsResponseType>('getProposalStatusCounts', parameters, requestBody);

export const FindProposalsParametersModel = types.model('FindProposalsParametersModel', {
  introducerId: types.integer,
  createdAfter: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  createdBefore: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  propNo: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  contrNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  originalContrNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custRegNo: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  custLabel: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  equipName: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  propLabel: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  marketproductId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  assetDesc: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  assetSerial: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  salesPersonId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  offId: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  workflowStatus: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  docStatus: types.optional(types.union(types.string, types.undefined, types.null),undefined),
  signatureStatus: types.optional(types.union(types.array(types.string), types.undefined, types.null),undefined),
  signatureDocumentTypes: types.optional(types.union(types.array(types.integer), types.undefined, types.null),undefined),
  start: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  size: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  sortBy: types.optional(types.union(types.enumeration(['ID', 'PROPOSAL_NO', 'LABEL', 'CREATED', 'UPDATED']), types.undefined, types.null),undefined),
  sortOrder: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)
});
type FindProposalsParametersType = Instance<typeof FindProposalsParametersModel>;

type FindProposalsRequestBody = null;
export const FindProposalsResponseModel = types.union(ProposalSearchResultModel, FileBasedPaginatedResponseModel);
export type FindProposalsResponseType = Instance<typeof FindProposalsResponseModel>
export const findProposals = async (
  parameters: FindProposalsParametersType,
  requestBody: FindProposalsRequestBody = null
): Promise<AxiosResponse<FindProposalsResponseType>> =>
  request<FindProposalsParametersType, FindProposalsRequestBody, FindProposalsResponseType>('findProposals', parameters, requestBody);

export const FindProposalsWithStatisticsConstraintsParametersModel = types.model('FindProposalsWithStatisticsConstraintsParametersModel', {
  introducerId: types.integer,
  status: types.enumeration(['PROSPECT', 'OFFER', 'PENDING', 'MORE_INFORMATION_REQUIRED', 'APPROVED', 'DECLINED', 'DOCUMENTS_ARRIVED', 'DOCUMENTS_COMPLETED', 'CONTRACT_STARTED']),
  intervalStart: types.integer,
  intervalEnd: types.integer,
  paginationStart: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  paginationSize: types.optional(types.union(types.integer, types.undefined, types.null),undefined),
  sortBy: types.optional(types.union(types.enumeration(['ID', 'PROPOSAL_NO', 'LABEL', 'CREATED', 'UPDATED']), types.undefined, types.null),undefined),
  sortOrder: types.optional(types.union(types.enumeration(['ASC', 'DESC']), types.undefined, types.null),undefined)
});
type FindProposalsWithStatisticsConstraintsParametersType = Instance<typeof FindProposalsWithStatisticsConstraintsParametersModel>;

type FindProposalsWithStatisticsConstraintsRequestBody = null;
export const FindProposalsWithStatisticsConstraintsResponseModel = ProposalSearchResultModel;
export type FindProposalsWithStatisticsConstraintsResponseType = Instance<typeof FindProposalsWithStatisticsConstraintsResponseModel>
export const findProposalsWithStatisticsConstraints = async (
  parameters: FindProposalsWithStatisticsConstraintsParametersType,
  requestBody: FindProposalsWithStatisticsConstraintsRequestBody = null
): Promise<AxiosResponse<FindProposalsWithStatisticsConstraintsResponseType>> =>
  request<FindProposalsWithStatisticsConstraintsParametersType, FindProposalsWithStatisticsConstraintsRequestBody, FindProposalsWithStatisticsConstraintsResponseType>('findProposalsWithStatisticsConstraints', parameters, requestBody);

export const GetProposalSearchConfigParametersModel = types.model('GetProposalSearchConfigParametersModel', {
  introducerId: types.integer
});
type GetProposalSearchConfigParametersType = Instance<typeof GetProposalSearchConfigParametersModel>;

type GetProposalSearchConfigRequestBody = null;
export const GetProposalSearchConfigResponseModel = ProposalSearchConfigModel;
export type GetProposalSearchConfigResponseType = Instance<typeof GetProposalSearchConfigResponseModel>
export const getProposalSearchConfig = async (
  parameters: GetProposalSearchConfigParametersType,
  requestBody: GetProposalSearchConfigRequestBody = null
): Promise<AxiosResponse<GetProposalSearchConfigResponseType>> =>
  request<GetProposalSearchConfigParametersType, GetProposalSearchConfigRequestBody, GetProposalSearchConfigResponseType>('getProposalSearchConfig', parameters, requestBody);

export const CreateSignatoryParametersModel = types.model('CreateSignatoryParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateSignatoryParametersType = Instance<typeof CreateSignatoryParametersModel>;
export const CreateSignatoryRequestBodyModel = SignatoryModel;
type CreateSignatoryRequestBody = Instance<typeof CreateSignatoryRequestBodyModel>;
export const CreateSignatoryResponseModel = ProposalSuccessModel;
export type CreateSignatoryResponseType = Instance<typeof CreateSignatoryResponseModel>
export const createSignatory = async (
  parameters: CreateSignatoryParametersType,
  requestBody: CreateSignatoryRequestBody
): Promise<AxiosResponse<CreateSignatoryResponseType>> =>
  request<CreateSignatoryParametersType, CreateSignatoryRequestBody, CreateSignatoryResponseType>('createSignatory', parameters, requestBody);

export const UpdateSignatoryParametersModel = types.model('UpdateSignatoryParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  signatoryId: types.string,
  ifMatch: types.string
});
type UpdateSignatoryParametersType = Instance<typeof UpdateSignatoryParametersModel>;
export const UpdateSignatoryRequestBodyModel = SignatoryModel;
type UpdateSignatoryRequestBody = Instance<typeof UpdateSignatoryRequestBodyModel>;
export const UpdateSignatoryResponseModel = ProposalSuccessModel;
export type UpdateSignatoryResponseType = Instance<typeof UpdateSignatoryResponseModel>
export const updateSignatory = async (
  parameters: UpdateSignatoryParametersType,
  requestBody: UpdateSignatoryRequestBody
): Promise<AxiosResponse<UpdateSignatoryResponseType>> =>
  request<UpdateSignatoryParametersType, UpdateSignatoryRequestBody, UpdateSignatoryResponseType>('updateSignatory', parameters, requestBody);

export const DeleteSignatoryParametersModel = types.model('DeleteSignatoryParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  signatoryId: types.string,
  ifMatch: types.string
});
type DeleteSignatoryParametersType = Instance<typeof DeleteSignatoryParametersModel>;

type DeleteSignatoryRequestBody = null;
export const DeleteSignatoryResponseModel = ProposalSuccessModel;
export type DeleteSignatoryResponseType = Instance<typeof DeleteSignatoryResponseModel>
export const deleteSignatory = async (
  parameters: DeleteSignatoryParametersType,
  requestBody: DeleteSignatoryRequestBody = null
): Promise<AxiosResponse<DeleteSignatoryResponseType>> =>
  request<DeleteSignatoryParametersType, DeleteSignatoryRequestBody, DeleteSignatoryResponseType>('deleteSignatory', parameters, requestBody);

export const GetSignatoryConfigParametersModel = types.model('GetSignatoryConfigParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetSignatoryConfigParametersType = Instance<typeof GetSignatoryConfigParametersModel>;

type GetSignatoryConfigRequestBody = null;
export const GetSignatoryConfigResponseModel = SignatoryConfigModel;
export type GetSignatoryConfigResponseType = Instance<typeof GetSignatoryConfigResponseModel>
export const getSignatoryConfig = async (
  parameters: GetSignatoryConfigParametersType,
  requestBody: GetSignatoryConfigRequestBody = null
): Promise<AxiosResponse<GetSignatoryConfigResponseType>> =>
  request<GetSignatoryConfigParametersType, GetSignatoryConfigRequestBody, GetSignatoryConfigResponseType>('getSignatoryConfig', parameters, requestBody);

export const GetUpgradesParametersModel = types.model('GetUpgradesParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer
});
type GetUpgradesParametersType = Instance<typeof GetUpgradesParametersModel>;

type GetUpgradesRequestBody = null;
export const GetUpgradesResponseModel = types.array(UpgradeModel);
export type GetUpgradesResponseType = Instance<typeof GetUpgradesResponseModel>
export const getUpgrades = async (
  parameters: GetUpgradesParametersType,
  requestBody: GetUpgradesRequestBody = null
): Promise<AxiosResponse<GetUpgradesResponseType>> =>
  request<GetUpgradesParametersType, GetUpgradesRequestBody, GetUpgradesResponseType>('getUpgrades', parameters, requestBody);

export const CreateUpgradeParametersModel = types.model('CreateUpgradeParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  ifMatch: types.string
});
type CreateUpgradeParametersType = Instance<typeof CreateUpgradeParametersModel>;
export const CreateUpgradeRequestBodyModel = UpgradeModel;
type CreateUpgradeRequestBody = Instance<typeof CreateUpgradeRequestBodyModel>;
export const CreateUpgradeResponseModel = UpgradeModel;
export type CreateUpgradeResponseType = Instance<typeof CreateUpgradeResponseModel>
export const createUpgrade = async (
  parameters: CreateUpgradeParametersType,
  requestBody: CreateUpgradeRequestBody
): Promise<AxiosResponse<CreateUpgradeResponseType>> =>
  request<CreateUpgradeParametersType, CreateUpgradeRequestBody, CreateUpgradeResponseType>('createUpgrade', parameters, requestBody);

export const DeleteUpgradeParametersModel = types.model('DeleteUpgradeParametersModel', {
  introducerId: types.integer,
  proposalId: types.integer,
  upgradeId: types.integer,
  ifMatch: types.string
});
type DeleteUpgradeParametersType = Instance<typeof DeleteUpgradeParametersModel>;

type DeleteUpgradeRequestBody = null;

export type DeleteUpgradeResponseType = void
export const deleteUpgrade = async (
  parameters: DeleteUpgradeParametersType,
  requestBody: DeleteUpgradeRequestBody = null
): Promise<AxiosResponse<DeleteUpgradeResponseType>> =>
  request<DeleteUpgradeParametersType, DeleteUpgradeRequestBody, DeleteUpgradeResponseType>('deleteUpgrade', parameters, requestBody);

export const GetRateCardParametersModel = types.model('GetRateCardParametersModel', {
  introducerId: types.integer,
  rateCardId: types.integer
});
type GetRateCardParametersType = Instance<typeof GetRateCardParametersModel>;

type GetRateCardRequestBody = null;
export const GetRateCardResponseModel = types.string;
export type GetRateCardResponseType = Instance<typeof GetRateCardResponseModel>
export const getRateCard = async (
  parameters: GetRateCardParametersType,
  requestBody: GetRateCardRequestBody = null
): Promise<AxiosResponse<GetRateCardResponseType>> =>
  request<GetRateCardParametersType, GetRateCardRequestBody, GetRateCardResponseType>('getRateCard', parameters, requestBody);

export const GetRateCardsParametersModel = types.model('GetRateCardsParametersModel', {
  introducerId: types.integer
});
type GetRateCardsParametersType = Instance<typeof GetRateCardsParametersModel>;

type GetRateCardsRequestBody = null;
export const GetRateCardsResponseModel = types.array(RateCardModel);
export type GetRateCardsResponseType = Instance<typeof GetRateCardsResponseModel>
export const getRateCards = async (
  parameters: GetRateCardsParametersType,
  requestBody: GetRateCardsRequestBody = null
): Promise<AxiosResponse<GetRateCardsResponseType>> =>
  request<GetRateCardsParametersType, GetRateCardsRequestBody, GetRateCardsResponseType>('getRateCards', parameters, requestBody);

export const AddSalesPersonOfficeParametersModel = types.model('AddSalesPersonOfficeParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer,
  officeId: types.integer
});
type AddSalesPersonOfficeParametersType = Instance<typeof AddSalesPersonOfficeParametersModel>;
export const AddSalesPersonOfficeRequestBodyModel = {};
type AddSalesPersonOfficeRequestBody = Instance<typeof AddSalesPersonOfficeRequestBodyModel>;

export type AddSalesPersonOfficeResponseType = void
export const addSalesPersonOffice = async (
  parameters: AddSalesPersonOfficeParametersType,
  requestBody: AddSalesPersonOfficeRequestBody
): Promise<AxiosResponse<AddSalesPersonOfficeResponseType>> =>
  request<AddSalesPersonOfficeParametersType, AddSalesPersonOfficeRequestBody, AddSalesPersonOfficeResponseType>('addSalesPersonOffice', parameters, requestBody);

export const RemoveSalesPersonOfficeParametersModel = types.model('RemoveSalesPersonOfficeParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer,
  officeId: types.integer
});
type RemoveSalesPersonOfficeParametersType = Instance<typeof RemoveSalesPersonOfficeParametersModel>;

type RemoveSalesPersonOfficeRequestBody = null;

export type RemoveSalesPersonOfficeResponseType = void
export const removeSalesPersonOffice = async (
  parameters: RemoveSalesPersonOfficeParametersType,
  requestBody: RemoveSalesPersonOfficeRequestBody = null
): Promise<AxiosResponse<RemoveSalesPersonOfficeResponseType>> =>
  request<RemoveSalesPersonOfficeParametersType, RemoveSalesPersonOfficeRequestBody, RemoveSalesPersonOfficeResponseType>('removeSalesPersonOffice', parameters, requestBody);

export const BlockSalesPersonParametersModel = types.model('BlockSalesPersonParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer
});
type BlockSalesPersonParametersType = Instance<typeof BlockSalesPersonParametersModel>;
export const BlockSalesPersonRequestBodyModel = BlockRequestModel;
type BlockSalesPersonRequestBody = Instance<typeof BlockSalesPersonRequestBodyModel>;
export const BlockSalesPersonResponseModel = SalesPersonModel;
export type BlockSalesPersonResponseType = Instance<typeof BlockSalesPersonResponseModel>
export const blockSalesPerson = async (
  parameters: BlockSalesPersonParametersType,
  requestBody: BlockSalesPersonRequestBody
): Promise<AxiosResponse<BlockSalesPersonResponseType>> =>
  request<BlockSalesPersonParametersType, BlockSalesPersonRequestBody, BlockSalesPersonResponseType>('blockSalesPerson', parameters, requestBody);

export const GetSalesPersonsParametersModel = types.model('GetSalesPersonsParametersModel', {
  introducerId: types.integer
});
type GetSalesPersonsParametersType = Instance<typeof GetSalesPersonsParametersModel>;

type GetSalesPersonsRequestBody = null;
export const GetSalesPersonsResponseModel = types.array(SalesPersonModel);
export type GetSalesPersonsResponseType = Instance<typeof GetSalesPersonsResponseModel>
export const getSalesPersons = async (
  parameters: GetSalesPersonsParametersType,
  requestBody: GetSalesPersonsRequestBody = null
): Promise<AxiosResponse<GetSalesPersonsResponseType>> =>
  request<GetSalesPersonsParametersType, GetSalesPersonsRequestBody, GetSalesPersonsResponseType>('getSalesPersons', parameters, requestBody);

export const CreateSalesPersonParametersModel = types.model('CreateSalesPersonParametersModel', {
  introducerId: types.integer
});
type CreateSalesPersonParametersType = Instance<typeof CreateSalesPersonParametersModel>;
export const CreateSalesPersonRequestBodyModel = SalesPersonCreateModel;
type CreateSalesPersonRequestBody = Instance<typeof CreateSalesPersonRequestBodyModel>;
export const CreateSalesPersonResponseModel = SalesPersonModel;
export type CreateSalesPersonResponseType = Instance<typeof CreateSalesPersonResponseModel>
export const createSalesPerson = async (
  parameters: CreateSalesPersonParametersType,
  requestBody: CreateSalesPersonRequestBody
): Promise<AxiosResponse<CreateSalesPersonResponseType>> =>
  request<CreateSalesPersonParametersType, CreateSalesPersonRequestBody, CreateSalesPersonResponseType>('createSalesPerson', parameters, requestBody);

export const GetBlockStatusParametersModel = types.model('GetBlockStatusParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer
});
type GetBlockStatusParametersType = Instance<typeof GetBlockStatusParametersModel>;

type GetBlockStatusRequestBody = null;
export const GetBlockStatusResponseModel = BlockRequestModel;
export type GetBlockStatusResponseType = Instance<typeof GetBlockStatusResponseModel>
export const getBlockStatus = async (
  parameters: GetBlockStatusParametersType,
  requestBody: GetBlockStatusRequestBody = null
): Promise<AxiosResponse<GetBlockStatusResponseType>> =>
  request<GetBlockStatusParametersType, GetBlockStatusRequestBody, GetBlockStatusResponseType>('getBlockStatus', parameters, requestBody);

export const GetOfficesParametersModel = types.model('GetOfficesParametersModel', {
  introducerId: types.integer,
  manageable: types.optional(types.union(types.boolean, types.undefined, types.null),undefined)
});
type GetOfficesParametersType = Instance<typeof GetOfficesParametersModel>;

type GetOfficesRequestBody = null;
export const GetOfficesResponseModel = types.array(OfficeModel);
export type GetOfficesResponseType = Instance<typeof GetOfficesResponseModel>
export const getOffices = async (
  parameters: GetOfficesParametersType,
  requestBody: GetOfficesRequestBody = null
): Promise<AxiosResponse<GetOfficesResponseType>> =>
  request<GetOfficesParametersType, GetOfficesRequestBody, GetOfficesResponseType>('getOffices', parameters, requestBody);

export const GetPossibleRolesParametersModel = types.model('GetPossibleRolesParametersModel', {
  introducerId: types.integer
});
type GetPossibleRolesParametersType = Instance<typeof GetPossibleRolesParametersModel>;

type GetPossibleRolesRequestBody = null;
export const GetPossibleRolesResponseModel = types.array(SalesPersonRoleModel);
export type GetPossibleRolesResponseType = Instance<typeof GetPossibleRolesResponseModel>
export const getPossibleRoles = async (
  parameters: GetPossibleRolesParametersType,
  requestBody: GetPossibleRolesRequestBody = null
): Promise<AxiosResponse<GetPossibleRolesResponseType>> =>
  request<GetPossibleRolesParametersType, GetPossibleRolesRequestBody, GetPossibleRolesResponseType>('getPossibleRoles', parameters, requestBody);

export const GetSalesPersonOfficesParametersModel = types.model('GetSalesPersonOfficesParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer
});
type GetSalesPersonOfficesParametersType = Instance<typeof GetSalesPersonOfficesParametersModel>;

type GetSalesPersonOfficesRequestBody = null;
export const GetSalesPersonOfficesResponseModel = types.array(OfficeModel);
export type GetSalesPersonOfficesResponseType = Instance<typeof GetSalesPersonOfficesResponseModel>
export const getSalesPersonOffices = async (
  parameters: GetSalesPersonOfficesParametersType,
  requestBody: GetSalesPersonOfficesRequestBody = null
): Promise<AxiosResponse<GetSalesPersonOfficesResponseType>> =>
  request<GetSalesPersonOfficesParametersType, GetSalesPersonOfficesRequestBody, GetSalesPersonOfficesResponseType>('getSalesPersonOffices', parameters, requestBody);

export const UpdateSalesPersonParametersModel = types.model('UpdateSalesPersonParametersModel', {
  introducerId: types.integer,
  salesPersonId: types.integer
});
type UpdateSalesPersonParametersType = Instance<typeof UpdateSalesPersonParametersModel>;
export const UpdateSalesPersonRequestBodyModel = SalesPersonModel;
type UpdateSalesPersonRequestBody = Instance<typeof UpdateSalesPersonRequestBodyModel>;
export const UpdateSalesPersonResponseModel = SalesPersonModel;
export type UpdateSalesPersonResponseType = Instance<typeof UpdateSalesPersonResponseModel>
export const updateSalesPerson = async (
  parameters: UpdateSalesPersonParametersType,
  requestBody: UpdateSalesPersonRequestBody
): Promise<AxiosResponse<UpdateSalesPersonResponseType>> =>
  request<UpdateSalesPersonParametersType, UpdateSalesPersonRequestBody, UpdateSalesPersonResponseType>('updateSalesPerson', parameters, requestBody);

export const GetUserPermissionParametersModel = types.model('GetUserPermissionParametersModel', {
  introducerId: types.integer
});
type GetUserPermissionParametersType = Instance<typeof GetUserPermissionParametersModel>;

type GetUserPermissionRequestBody = null;
export const GetUserPermissionResponseModel = UserPermissionsModel;
export type GetUserPermissionResponseType = Instance<typeof GetUserPermissionResponseModel>
export const getUserPermission = async (
  parameters: GetUserPermissionParametersType,
  requestBody: GetUserPermissionRequestBody = null
): Promise<AxiosResponse<GetUserPermissionResponseType>> =>
  request<GetUserPermissionParametersType, GetUserPermissionRequestBody, GetUserPermissionResponseType>('getUserPermission', parameters, requestBody);

export const GetUserProfileParametersModel = types.model('GetUserProfileParametersModel', {

});
type GetUserProfileParametersType = Instance<typeof GetUserProfileParametersModel>;

type GetUserProfileRequestBody = null;
export const GetUserProfileResponseModel = UserProfileModel;
export type GetUserProfileResponseType = Instance<typeof GetUserProfileResponseModel>
export const getUserProfile = async (
  parameters: GetUserProfileParametersType = {},
  requestBody: GetUserProfileRequestBody = null
): Promise<AxiosResponse<GetUserProfileResponseType>> =>
  request<GetUserProfileParametersType, GetUserProfileRequestBody, GetUserProfileResponseType>('getUserProfile', parameters, requestBody);

export const UpdateUserProfileParametersModel = types.model('UpdateUserProfileParametersModel', {

});
type UpdateUserProfileParametersType = Instance<typeof UpdateUserProfileParametersModel>;
export const UpdateUserProfileRequestBodyModel = UserProfileModel;
type UpdateUserProfileRequestBody = Instance<typeof UpdateUserProfileRequestBodyModel>;
export const UpdateUserProfileResponseModel = UserProfileModel;
export type UpdateUserProfileResponseType = Instance<typeof UpdateUserProfileResponseModel>
export const updateUserProfile = async (
  parameters: UpdateUserProfileParametersType = {},
  requestBody: UpdateUserProfileRequestBody
): Promise<AxiosResponse<UpdateUserProfileResponseType>> =>
  request<UpdateUserProfileParametersType, UpdateUserProfileRequestBody, UpdateUserProfileResponseType>('updateUserProfile', parameters, requestBody);
