import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import Loader from '../../components/Loader';
import { AppState } from '../../state';
import { Introducer, Office } from '../../tools/SiemensApi';
import Calculator from '../Calculator/Calculator';
import Dashboard from '../Dashboard/Dashboard';
import Healthineers from '../SpecialPages/Healthineers/Healthineers';
import Block from '../SpecialPages/Block/Block';

import Header from './Header';
import Sidebar from './Sidebar';
import Reconnecting from './Reconnecting';
import { makeStyles } from '@material-ui/core';
import theme from '../../constants/theme';
import ErrorDialog from '../../components/ErrorDialog';
import Konica from '../SpecialPages/Konica/Konica';
import _ from 'lodash';

interface Props {
  state: AppState;
}

const useStyles = makeStyles<{}, { standAlonePage: boolean }>({
  background: {
    height: 612,
    opacity: 0.1,
    position: 'fixed',
    transform: 'rotate(45deg)',
    width: 434,
  },
  mainContent: () => ({
    marginLeft: 0,
    width: '100%',
    //height: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginLeft: 255,
      width: 'calc(100vw - 255px)',
    },
  }),
});

export default observer(({ state }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isStateValid, setIsStateValid] = useState<boolean>(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const reset = async (
    introducer?: Introducer,
    office?: Office,
    introducerCount?: number,
    officeCount?: number
  ) => {
    setIsStateValid(false);
    const keepArray = ['user', 'authentication', 'introducers', 'loading'];
    if (introducer.id === state.introducer.id) {
      keepArray.push('introducer');
    }
    await state.reset(keepArray);
    await state.initialize(introducer, office, introducerCount, officeCount);
    setIsStateValid(true);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const hideErrorDialog = () => {
    state.setErrorDialog(false);
    state.dashboard.setLastProposalsCallId(null);
  };

  const openProposal = async (proposalId?: number) => {
    console.log('proposal ID  from App.tsx', proposalId);
    navigate(
      `/app/calculator/reset/${
        typeof proposalId === 'number' ? proposalId : ''
      }`
    );
  };

  let isHealthineers = false;
  if (state.introducer && state.introducer.bpId === 2214139) {
    isHealthineers = true;
  }
  const isSettings = location.pathname.startsWith('/app/settings');

  useEffect(() => {
    if (isHealthineers && !isSettings) {
      navigate('/app/healthineers');
    }
    if (state.landingPage === '/app/block') {
      navigate('/app/block');
    }
  }, []);
  const onlyBlock = () => {
    if (
      state.calculator &&
      state.calculator.marketProducts &&
      state.calculator.marketProducts.length == 1 &&
      _.some(state.calculator.marketProducts, { id: 1 })
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isHealthineers && !isSettings) {
      navigate('/app/healthineers');
    }
    if (state.landingPage === '/app/block' || onlyBlock()) {
      navigate('/app/block');
    }
  }, []);

  const isCalculator = location.pathname.startsWith('/app/calculator');
  const isBlock =
    location.pathname.startsWith('/app/block') ||
    state.landingPage === '/app/block';
  const standAlonePage = isHealthineers || isBlock;

  const classes = useStyles({ standAlonePage });
  return (
    <>
      {!isHealthineers && (
        <Sidebar
          state={state}
          resetCalculator={() => openProposal()}
          isCalculator={isCalculator}
          handleDrawerToggle={handleDrawerToggle}
          closeDrawer={closeDrawer}
          drawerState={mobileOpen}
        />
      )}
      <Header
        state={state}
        resetApp={reset}
        handleDrawerToggle={handleDrawerToggle}
        isHealthineers={isHealthineers}
      />
      <div className={classes.mainContent}>
        <Routes>
          <Route path="" element={<Navigate to="/app/calculator" replace />} />
          <Route
            path="calculator/*"
            element={
              <Calculator
                state={state.calculator}
                dashboardState={state.dashboard}
                openProposal={openProposal}
              />
            }
          />
          <Route path="loading" element={Loader} />
          <Route
            path="myprops/*"
            element={
              <Dashboard openProposal={openProposal} state={state.dashboard} />
            }
          />
          <Route path="block" element={<Block state={state.block} />} />
          <Route path="healthineers" element={<Healthineers />} />
          {/*<Route path="konica" element={<Konica state={state.konica} />} />*/}
          <Route path="konica">
            <Route
              path=":proposalId"
              element={<Konica state={state.konica} />}
            />
            <Route index element={<Konica state={state.konica} />} />
          </Route>
        </Routes>
      </div>

      <Reconnecting open={!state.onLine} />
      <ErrorDialog
        open={!!state.errorDialog}
        onOk={() => hideErrorDialog()}
        okCaption={'OK'}
        handleClose={() => hideErrorDialog()}
        errorMessage={state.errorMessage}
        state={state}
      >
        Sorry, there has been an error
        <p>
          For help, please email{' '}
          <a href="mailto:mysfs.uk@siemens.com">mysfs.uk@siemens.com</a>
        </p>
      </ErrorDialog>
    </>
  );
});
