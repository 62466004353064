import {
  Configuration,
  ConfigurationParameters,
  DmsApi,
  TokenResponse,
} from '../../../../../generated-sources/dms-api';
import config from '../../../config';
import { AxiosResponse } from 'axios';
import { BlobServiceClient } from '@azure/storage-blob';
import { BlockState } from '../../../model/Block.model';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import { v4 as uuidv4 } from 'uuid';

const dmsAPI = (token: string): DmsApi => {
  const apiConfigParams: ConfigurationParameters = {
    accessToken: token,
    basePath: config.backendApiUrl + '/dms/v1',
  };

  return new DmsApi(new Configuration(apiConfigParams));
};

export const getBase64 = (file: File | Blob): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const upload = async (
  origin: string,
  containerName: string,
  sas: string,
  file: any,
  fileName: string,
  setProgress: (state: number) => void
): Promise<any> => {
  // Initialise blobServiceClient
  const blobServiceClient = new BlobServiceClient(`${origin}?${sas}`);

  const uploadOptions = {
    blockSize: 20 * 1024 * 1024,
    concurrency: 5,
    maxSingleShotSize: 20 * 1024 * 1024,
    onProgress: (ev: any) => {
      setProgress((ev.loadedBytes / file.size) * 100);
    },
  };

  // create container client
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);
  await blockBlobClient.uploadData(file, uploadOptions);
};

export const uploadToBlobStorage = async (
  file: File,
  name: string,
  state: BlockState,
  setProgress: (state: number) => void
): Promise<any> => {
  const appState = getParent<AppState>(state);
  const uuid = uuidv4();

  name = `${uuid}_${name}`;

  // Get token
  let tokenResponse: AxiosResponse<TokenResponse, any>;
  try {
    tokenResponse = await dmsAPI(
      appState.authentication.accessToken
    ).getToken();
  } catch (error) {
    return Promise.reject(new Error('Failed with ' + error));
  }
  if (!tokenResponse) {
    return Promise.reject(new Error('Could not get token'));
  }

  // Upload doc to azure blob storage
  const url = new URL(tokenResponse.data.endpointUrl);
  const origin = url.origin;
  const pathArray = url.pathname.split('/');
  const containerName = pathArray[1];
  const sas = tokenResponse.data.sasToken;

  await upload(origin, containerName, sas, file, name, setProgress);

  const uploadReportPayload = {
    businessPartnerId: appState.introducer.bpId,
    fileName: name,
    proposalId: state.proposal.id.toString(),
  };

  try {
    await dmsAPI(appState.authentication.accessToken).createUploadReport(
      uploadReportPayload
    );
  } catch (error) {
    return Promise.reject(new Error('Failed with ' + error));
  }
  return true;
};

const amountFinancedBand = (amountFinanced: number): string => {
  if (amountFinanced < 25000) {
    return '10k-25k';
  } else if (amountFinanced < 50000) {
    return '25k-50k';
  } else if (amountFinanced < 100000) {
    return '50k-100k';
  } else if (amountFinanced < 250000) {
    return '100l-250k';
  } else if (amountFinanced < 500000) {
    return 'over_250k';
  } else if (amountFinanced < 1000000) {
    return '_500k-_1m';
  } else {
    return 'over__1m';
  }
};

export const finaliseProposal = async (
  state: BlockState,
  setSnackbar,
  setSnackbarOpen
): Promise<any> => {
  const uploads = [];
  if (state.zendeskUploadId) {
    uploads.push(state.zendeskUploadId);
  }

  const payload = {
    ticket: {
      comment: {
        body: 'New Block proposal created',
        uploads: uploads,
      },
      customFields: [
        {
          id: config.zendesk?.proposalIdField,
          value: [state.proposal.id],
        },
        {
          id: config.zendesk?.salesChannelField,
          value: ['Block'],
        },
        {
          id: config.zendesk?.amountFinancedField,
          value: [
            amountFinancedBand(
              state.proposal.currentCalculation.amountFinanced
            ),
          ],
        },
        {
          id: config.zendesk?.requestField,
          value: ['Other'],
        },
        {
          id: config.zendesk?.agreementField,
          value: [state.proposal.id],
        },
        {
          id: config.zendesk?.bpcRequestField,
          value: ['document'],
        },
      ],
      groupId: config.zendesk?.groupId,
      requester: { name: 'Block portal' },
      subject: `Block proposal: ${state.proposal.id}, ${state.customer.name}`,
      ticketFormId: config.zendesk?.ticketFormId,
    },
  };
  await state.createTicket(payload);
  setSnackbar({
    message: 'Submitted successfully',
    severity: 'success',
  });
  setSnackbarOpen(true);
};
