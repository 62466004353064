import React from 'react';
import logo from 'media/Siemens-Logo.png';
import healthineersLogo from 'media/SiemensHealthineers-Logo.png';

interface Props {
  isHealthineers?: boolean;
}

export default ({ isHealthineers }: Props) => {
  return <img alt="Logo" src={isHealthineers ? healthineersLogo : logo} />;
};
