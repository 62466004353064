import { Box, makeStyles } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { COLORS } from '../../../constants/style-constants';
import theme from '../../../constants/theme';

const useStyles = makeStyles({
  root: {
    '& .figure': {
      color: '#000028',
      margin: 0,
    },
    '& .heading': {
      '& img': {
        left: -22,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      color: COLORS.brownGrey,
      fontSize: 13,
      margin: theme.spacing(0, 0, 0.5),
      position: 'relative',
    },
    borderRight: 'none !important',
    color: COLORS.greyishBrown,
    flexBasis: 0,
    flexGrow: 1,
    fontSize: 16,
    padding: theme.spacing(0.5, 1.5),
    [theme.breakpoints.up('sm') as string]: {
      padding: theme.spacing(1.5),
    },
    wordBreak: 'break-word',
  },
});

interface Props {
  title?: React.ReactNode;
  children: ReactNode;
  id?: string;
}

const CardDetail = ({ title, children, id }: Props): ReactElement => (
  <Box className={useStyles().root} id={id}>
    {title && <Box className="heading">{title}</Box>}
    <Box className="figure">{children}</Box>
  </Box>
);

export default CardDetail;
