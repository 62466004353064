import { observer } from 'mobx-react';
import React, { useState } from 'react';
import i18n from 'i18next';
import { Select } from '../../components/Select';
import { makeStyles } from '@material-ui/core';
import {
  UKFlag,
  FinlandFlag,
  NorwayFlag,
  SwedenFlag,
} from '../../images/Flags';

const useStyles = makeStyles({
  flag: {
    '& svg': {
      height: 25,
      width: 25,
    },
  },
  root: {
    '& label': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },
  select: {
    '& .MuiInput-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      fontSize: 13,
      height: 24,
      marginTop: 0,
      padding: 0,
    },
    '& .MuiTextField-root': {
      margin: 0,
    },
    width: 180,
  },
});

const LanguageOptions = observer(() => {
  const [currentLanguage, setCurrentLanguage] = useState<string>('en');

  const classes = useStyles();

  const changeLanguage = (lng?: any): void => {
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
  };

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Finnish', value: 'fi' },
    { label: 'Norwegian', value: 'nb' },
    { label: 'Swedish', value: 'sv' },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.flag}>
        {currentLanguage == 'en' && <UKFlag />}
        {currentLanguage == 'fi' && <FinlandFlag />}
        {currentLanguage == 'nb' && <NorwayFlag />}
        {currentLanguage == 'sv' && <SwedenFlag />}
      </div>
      <Select
        className={classes.select}
        options={languageOptions}
        label="Language"
        onChange={({ value }: any): void => {
          changeLanguage(value);
        }}
        value={languageOptions.find(
          (options) => options.value === currentLanguage
        )}
        isSearchable={false}
      />
    </div>
  );
});

export default LanguageOptions;
