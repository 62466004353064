import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& svg': {
      margin: '0 1px',
    },
    display: 'flex',
  },
}));

interface Props {
  colour?: string;
  height?: number;
  width?: number;
}

export const SiemensLogo = ({ colour, height, width }: Props): ReactElement => {
  const classes = useStyles();
  const fillColour = colour ? colour : '#009999';
  const svgHeight = height ? height : 19;
  const svgWidth = width ? width : 111;
  return (
    <div className={classes.root}>
      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox="0 0 111 19"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.523037 17.5753V14.2691C2.40609 14.8613 4.07252 15.1572 5.52184 15.1572C7.52323 15.1572 8.52392 14.629 8.52392 13.5724C8.52392 13.1789 8.37833 12.8479 8.08635 12.5802C7.78779 12.296 7.02328 11.9014 5.79443 11.3967C3.58989 10.4911 2.15227 9.71852 1.48364 9.07976C0.616837 8.23641 0.183594 7.17278 0.183594 5.88757C0.183594 4.23259 0.815019 2.97109 2.07691 2.10435C3.32484 1.24498 4.94991 0.815781 6.9545 0.815781C8.05909 0.815781 9.65753 1.01893 11.7487 1.4257V4.60572C10.1929 3.9849 8.74872 3.6749 7.41435 3.6749C5.53531 3.6749 4.59603 4.19046 4.59603 5.22365C4.59603 5.61008 4.78524 5.9249 5.16412 6.16906C5.47919 6.3666 6.3476 6.78426 7.76727 7.42286C9.81146 8.32998 11.1723 9.11837 11.8502 9.78902C12.6548 10.5845 13.0577 11.614 13.0577 12.8756C13.0577 14.6892 12.2684 16.0731 10.6905 17.0262C9.41221 17.7994 7.75444 18.1842 5.71874 18.1842C3.99956 18.184 2.26723 17.9815 0.523037 17.5753Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7005 1.10687H15.7002H20.3798V17.8457H15.7005V1.10687Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6714 17.8457V1.10687H36.673V4.13437H29.1813V7.91406H35.7024V10.6759H29.1813V14.6483H36.866V17.8457H24.6714Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9209 17.8457V1.10687H45.9879L50.203 11.802L54.5238 1.10687H60.2868V17.8457H55.8496V5.99426L50.9369 18.0158H48.0363L43.2178 5.99426V17.8457H39.9209Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.5791 17.8457V1.10687H76.5802V4.13437H69.0887V7.91406H75.6103V10.6759H69.0887V14.6483H76.774V17.8457H64.5791Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.854 17.8457V1.10687H85.2774L91.0311 12.3126V1.10687H94.3267V17.8457H89.0581L83.1513 6.48962V17.8457H79.854Z"
          fill={fillColour}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.6492 17.5753V14.2691C99.5157 14.8613 101.182 15.1572 102.648 15.1572C104.65 15.1572 105.65 14.629 105.65 13.5724C105.65 13.1789 105.508 12.8479 105.225 12.5802C104.925 12.296 104.158 11.9014 102.922 11.3967C100.723 10.4989 99.2848 9.72621 98.6098 9.07976C97.7432 8.24378 97.3105 7.17646 97.3105 5.87619C97.3105 4.22843 97.9409 2.97109 99.2035 2.10435C100.45 1.24498 102.077 0.815781 104.082 0.815781C105.209 0.815781 106.669 0.994575 108.461 1.35393L108.875 1.4257V4.60572C107.319 3.9849 105.87 3.6749 104.529 3.6749C102.657 3.6749 101.724 4.19046 101.724 5.22365C101.724 5.61008 101.912 5.9249 102.29 6.16906C102.59 6.35923 103.462 6.77673 104.905 7.42286C106.934 8.32998 108.292 9.11837 108.976 9.78902C109.782 10.5845 110.184 11.614 110.184 12.8756C110.184 14.6892 109.399 16.0731 107.829 17.0262C106.543 17.7994 104.881 18.1842 102.846 18.1842C101.124 18.184 99.3919 17.9815 97.6492 17.5753Z"
          fill={fillColour}
        />
      </svg>
    </div>
  );
};

export const SiemensLogoSmall = ({}: Props): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <svg
        width="38"
        height="36"
        viewBox="0 0 38 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="37.2984" height="36" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3506 26.3689V22.9426C14.2957 23.5562 16.0171 23.8629 17.5142 23.8629C19.5815 23.8629 20.6152 23.3155 20.6152 22.2205C20.6152 21.8127 20.4648 21.4697 20.1632 21.1922C19.8548 20.8977 19.0651 20.4887 17.7957 19.9657C15.5185 19.0271 14.0336 18.2265 13.3429 17.5645C12.4475 16.6905 12 15.5882 12 14.2562C12 12.5411 12.6522 11.2337 13.9557 10.3354C15.2448 9.44481 16.9234 9 18.994 9C20.135 9 21.7861 9.21053 23.9462 9.6321V12.9278C22.3392 12.2844 20.8474 11.9631 19.469 11.9631C17.5281 11.9631 16.5578 12.4974 16.5578 13.5682C16.5578 13.9686 16.7533 14.2949 17.1447 14.5479C17.4701 14.7527 18.3671 15.1855 19.8336 15.8473C21.9451 16.7874 23.3508 17.6045 24.0511 18.2995C24.8822 19.1239 25.2984 20.1908 25.2984 21.4984C25.2984 23.3779 24.483 24.8121 22.8531 25.7999C21.5327 26.6012 19.8203 27 17.7176 27C15.9417 26.9998 14.1523 26.79 12.3506 26.3689Z"
          fill="#009999"
        />
      </svg>
    </div>
  );
};
