// data.ts

//market products config file which can be updated after value changes in the API
const marketProductsConfig: any = {
  defaultValue: null,
  required: false,
  readonly: false,
  disabled: false,
  rendered: true,
  validValues: [
    {
      id: 473,
      name: 'Block Discounting',
    },
    {
      id: 476,
      name: 'Loan',
    },
    {
      id: 475,
      name: 'Hire Purchase',
    },
    {
      id: 483,
      name: 'Purchase of Receivable (of Leases, With Title, Undisclosed)',
    },
    {
      id: 474,
      name: 'Fixed Term Lease',
    },
    {
      id: 480,
      name: 'Minimum Term Lease',
    },
    {
      id: 486,
      name: 'Minimum Term Extra',
    },
  ],
};
export default marketProductsConfig;
