import { clone, getParent, Instance, types } from 'mobx-state-tree';
import { AppState } from '../state';
import { validate } from '../tools/Api';
import {
  Asset,
  AssetModel,
  AssetSubType,
  AssetType,
  AssetTypes,
  AssetTypesModel,
  Lessor,
  LessorModel,
  LoanPurpose,
  GetLoanPurposesResponseModel,
  Loan,
  LoanModel,
} from '../tools/SiemensApi';

const assetConditionModel = types.enumeration([
  'NEW',
  'REFURBISHED',
  'USED',
  'REMANUFACTURED',
  'PREREGISTERED',
]);
/*
this is coming out of the API but it is also incorrect
const assetConditionModel = types.enumeration([
  'NEW',
  'REFURBISHED',
  'USED',
  'REMANUFACTORED',
  'RETAINED',
  'EX_DEMO',
]);*/

//'NEW', 'REFURBISHED', 'USED', 'REMANUFACTORED', 'RETAINED', 'EX_DEMO'
export type AssetCondition = Instance<typeof assetConditionModel>;

export const AssetFormModel = types
  .model({
    assetCondition: types.union(assetConditionModel, types.null),
    assetName: types.string,
    assetTypesResult: types.union(AssetTypesModel, types.null),
    contractNumber: types.union(types.string, types.null),
    lessor: types.union(LessorModel, types.null),
    loanPurposesResult: types.union(GetLoanPurposesResponseModel, types.null),
    loanValue: types.union(types.number, types.null),
    quantity: types.union(types.number, types.null),
    selectedAssetSubTypeId: types.union(types.integer, types.null),
    selectedAssetTypeId: types.union(types.integer, types.null),
    selectedLoanPurposeId: types.union(types.integer, types.null),
    serviceFee: types.boolean,
    unitPrice: types.union(types.number, types.null),
  })
  .views((self) => ({
    get assetTypes(): AssetTypes['assetTypes'] | null {
      return (
        (self.assetTypesResult && self.assetTypesResult.assetTypes) || null
      );
    },
    get selectableAssetSubTypes(): Array<AssetSubType> {
      return (
        (self.assetTypesResult &&
          self.assetTypesResult.assetSubTypes &&
          self.assetTypesResult.assetSubTypes.get(
            '' + self.selectedAssetTypeId
          )) ||
        []
      );
    },
    get selectedAssetType(): AssetType | null {
      return (
        //TODO:ignore this error
        (self.assetTypesResult &&
          self.assetTypesResult.assetTypes &&
          self.assetTypesResult.assetTypes.find(
            (assetType: AssetType) => assetType.id === self.selectedAssetTypeId
          )) ||
        null
      );
    },
    get selectedLoanPurpose(): LoanPurpose | null {
      return (
        //TODO:ignore this error
        (self.loanPurposesResult &&
          self.loanPurposesResult.find(
            (purpose: LoanPurpose) => purpose.id === self.selectedLoanPurposeId
          )) ||
        null
      );
    },
  }))
  .views((self) => ({
    get feeIncluded(): boolean {
      return self.serviceFee && getParent<AppState>(self, 2).isBT;
    },
    get selectedAssetSubType(): AssetSubType | null {
      return (
        self.selectableAssetSubTypes.find(
          (assetSubType: AssetSubType) =>
            assetSubType.id === self.selectedAssetSubTypeId
        ) || null
      );
    },
    get total(): number | null {
      const quantity = self.quantity;
      const unitPrice = self.unitPrice;
      return quantity === null || unitPrice == null
        ? null
        : quantity * unitPrice;
    },
  }))
  .views((self) => ({
    get asset(): Asset | null {
      if (self.selectedAssetSubTypeId) {
        const asset: Asset = AssetModel.create({
          assetSubTypeId: self.selectedAssetSubTypeId,
          condition: self.assetCondition, //updated condition to reflect new API
          description: self.assetName,
          quantity: 1,
          totalValue: getParent<AppState>(self, 2).calculator.assetValue || 0,
        });
        const valid = validate(asset, 'Asset');
        return valid ? asset : null;
      } else {
        return null;
      }
    },
    get loan(): Loan | null {
      if (self.selectedLoanPurposeId && self.selectedLoanPurpose) {
        const loan: Loan = LoanModel.create({
          loanPurpose: clone(self.selectedLoanPurpose),
          value: getParent<AppState>(self, 2).calculator.assetValue || 0,
        });
        const valid = validate(loan, 'Loan');
        return valid ? loan : null;
      } else {
        return null;
      }
    },
  }))

  .actions((self) => ({
    setAssetCondition(condition: AssetCondition) {
      self.assetCondition = condition;
    },
    setAssetName(assetName: string) {
      self.assetName = assetName;
    },
    setContractNumber(contractNumber: string | null) {
      self.contractNumber = contractNumber;
    },
    setLessor(lessor: Lessor | null) {
      self.lessor = lessor;
    },
    setQuantity(quantity: number | null) {
      self.quantity = quantity;
    },
    setSelectedAssetSubType(selectedAssetSubTypeId: AssetSubType['id'] | null) {
      self.selectedAssetSubTypeId = selectedAssetSubTypeId;
    },
    setSelectedAssetType(selectedAssetTypeId: AssetType['id']) {
      self.selectedAssetTypeId = selectedAssetTypeId;
    },
    setSelectedLoanPurpose(loanPurposeId: number) {
      self.selectedLoanPurposeId = loanPurposeId;
    },
    setServiceFee(serviceFee: boolean) {
      self.serviceFee = serviceFee;
    },
    setUnitPrice(unitPrice: number | null) {
      self.unitPrice = unitPrice;
    },
  }));

export type AssetFormState = Instance<typeof AssetFormModel>;
