import { makeStyles, Drawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../constants/style-constants';
import theme from '../../constants/theme';
import { AppState } from '../../state';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  NotificationIcon,
  LogOutIcon,
  NewProposalIcon,
  DraftsIcon,
  ProposalsIcon,
  ActivatedIcon,
} from '../../images/SidebarIcons';
import { useTranslation } from 'react-i18next';
import { auth0LogOut } from '../../tools/Auth';
import MessageUpdate from '../../components/MessageUpdate';
import config from '../../config';
import { SiemensLogo } from '../../images/SiemensLogo';
import LanguageSwitcher from './LanguageSwitcher';
import _ from 'lodash';

const useStyles = makeStyles({
  Container: {
    color: '#4B5563',
    padding: '40px 25px 40px 25px',
    width: 'inherit',
  },
  Footer: {
    bottom: 40,
    position: 'fixed',
    width: 'inherit',
  },
  ListItem: {
    '& svg': {
      '&.noFill': {
        stroke: '#000000',
      },
      fill: '#000000',
      height: 21,
      width: 17,
    },
    '&:hover, &.Mui-selected': {
      '& svg': {
        '&.noFill': {
          stroke: '#000028',
        },
        fill: '#000028',
      },
      backgroundColor: '#00FFB9 !important',
      color: '#000028',
      fontWeight: 700,
    },
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    marginBottom: 25,
    width: 205,
  },
  ListItemText: {
    '& span': {
      color: 'inherit',
      fontWeight: 'inherit',
      marginLeft: 10,
      padding: 0,
    },
  },
  Logo: {
    marginLeft: 10,
  },
  Logout: {
    '& svg': {
      marginRight: 10,
    },
    cursor: 'pointer',
    fontSize: 14,
    width: '100%',
  },
  Navigation: {
    '& ul': {
      marginBottom: 100,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: 100,
    },
  },
  Title: {
    alignItems: 'center',
    color: '#6B7280',
    display: 'flex',
    fontSize: '16px',
    fontWeight: 700,
    marginLeft: 10,
    marginTop: 10,
  },
  root: {
    '& .MuiDrawer-paper': {
      boxSizing: 'content-box',
      width: 255,
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'unset',
    },
    background: COLORS.white,
    position: 'relative',
    width: '100%',
  },
});

interface Props {
  isCalculator: boolean;
  resetCalculator: () => void;
  state: AppState;
  handleDrawerToggle: () => void;
  closeDrawer: () => void;
  drawerState: boolean;
}

export default observer(
  ({
    isCalculator,
    handleDrawerToggle,
    closeDrawer,
    drawerState,
    state,
  }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let initialMenu = 0;
    if (isCalculator) {
      initialMenu = 1;
    }

    useEffect(() => {
      //const page = location.pathname.split('/').pop();
      if (location.pathname.startsWith('/app/myprops')) {
        const filter = state.dashboard.activeFilter;
        if (filter === 'active') {
          setMenuSelected(4);
          //this is a good place to reset the loadingCount
          //TODO:remeber loading count is being set here
        }
        if (filter === 'pending') {
          setMenuSelected(3);
        }
        if (filter === 'draft') {
          setMenuSelected(2);
        }
      }
      if (location.pathname.startsWith('/app/block')) {
        setMenuSelected(6);
      }
      if (location.pathname.startsWith('/app/konica')) {
        setMenuSelected(5);
      }
      if (isCalculator) {
        setMenuSelected(1);
      }
    });

    const [menuSelected, setMenuSelected] = useState(initialMenu);
    const [messageUpdates, setMessageUpdates] = useState(true);

    const logOut = async () => {
      setMenuSelected(4);
      sessionStorage.clear();
      auth0LogOut(window.location.origin);
    };

    const showMessages = (): void => {
      setMenuSelected(5);
      setMessageUpdates(!messageUpdates);
    };

    const showBlockMenu = () => {
      if (
        state.calculator &&
        state.calculator.marketProducts &&
        _.some(state.calculator.marketProducts, { id: 473 })
      ) {
        return true;
      }
      return false;
    };

    const onlyBlock = () => {
      if (
        state.calculator &&
        state.calculator.marketProducts &&
        state.calculator.marketProducts.length == 1 &&
        _.some(state.calculator.marketProducts, { id: 473 })
      ) {
        return true;
      }
      return false;
    };

    const classes = useStyles();

    const sidebar = (
      <div className={classes.Container}>
        <div className={classes.Logo}>
          <SiemensLogo colour="#009999" height={19} width={111} />
        </div>
        <div className={classes.Title}>MySFS</div>
        <div className={classes.Navigation}>
          <List>
            {!onlyBlock() && (
              <ListItem
                selected={menuSelected === 1}
                className={classes.ListItem}
                onClick={() => {
                  setMenuSelected(1);
                  closeDrawer();
                  navigate('/app/calculator/reset');
                }}
              >
                <NewProposalIcon />
                <ListItemText
                  className={classes.ListItemText}
                  primary={t('newApplication', 'New Application')}
                />
              </ListItem>
            )}
            {!onlyBlock() && (
              <>
                {/*
                <ListItem
                  selected={menuSelected === 2}
                  className={classes.ListItem}
                  onClick={() => {
                    setMenuSelected(2);
                    closeDrawer();
                    navigate('/app/myprops/reset/draft');
                  }}
                >
                  <DraftsIcon />
                  <ListItemText
                    className={classes.ListItemText}
                    primary={t('Drafts', 'Drafts')}
                  />
                </ListItem>

                <ListItem
                  selected={menuSelected === 3}
                  className={classes.ListItem}
                  onClick={() => {
                    setMenuSelected(3);
                    closeDrawer();
                    navigate('/app/myprops/reset/pending');
                  }}
                >
                  <ProposalsIcon />
                  <ListItemText
                    className={classes.ListItemText}
                    primary={t('Proposals', 'Proposals')}
                  />
                </ListItem>*/}
                <ListItem
                  selected={menuSelected === 4}
                  className={classes.ListItem}
                  onClick={() => {
                    setMenuSelected(4);
                    closeDrawer();
                    navigate('/app/myprops/reset/active');
                  }}
                >
                  <ActivatedIcon />
                  <ListItemText
                    className={classes.ListItemText}
                    primary={t('Proposals', 'Proposals')}
                  />
                </ListItem>
              </>
            )}
            {state.introducer && state.introducer.bpId === 2205676 && (
              <ListItem
                selected={menuSelected === 5}
                className={classes.ListItem}
                onClick={() => {
                  setMenuSelected(5);
                  closeDrawer();
                  navigate('/app/konica');
                }}
              >
                <NewProposalIcon />
                <ListItemText
                  className={classes.ListItemText}
                  primary="Search & Quote"
                />
              </ListItem>
            )}
            {showBlockMenu() && (
              <ListItem
                selected={menuSelected === 6}
                className={classes.ListItem}
                onClick={() => {
                  setMenuSelected(6);
                  navigate('/app/block');
                }}
              >
                <NewProposalIcon />
                <ListItemText
                  className={classes.ListItemText}
                  primary={t('block', 'Block')}
                />
              </ListItem>
            )}

            {config.includeNotification && (
              <ListItem
                selected={menuSelected === 5}
                className={classes.ListItem}
                onClick={showMessages}
              >
                <NotificationIcon />
                <MessageUpdate
                  hideMessage={messageUpdates}
                  showMessages={showMessages}
                />
                <ListItemText
                  className={classes.ListItemText}
                  primary={t('messages', 'Messages')}
                />
              </ListItem>
            )}
          </List>
        </div>
        <div className={classes.Footer}>
          {false && <LanguageSwitcher />}
          <div className={classes.Logout} onClick={logOut}>
            <LogOutIcon />
            Logout
          </div>
        </div>
      </div>
    );

    return (
      <div className={classes.root}>
        <div>
          <div>
            <Hidden mdUp={true} implementation="css">
              <Drawer
                variant="temporary"
                open={drawerState}
                onClose={handleDrawerToggle}
              >
                {sidebar}
              </Drawer>
            </Hidden>

            <Hidden smDown={true} implementation="css">
              <Drawer variant="permanent" open={true}>
                {sidebar}
              </Drawer>
            </Hidden>
          </div>
        </div>
      </div>
    );
  }
);
