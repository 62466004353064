import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import theme from '../constants/theme';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { DialogContentProps } from '@material-ui/core/DialogContent';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-label': {
      fontSize: '1em',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25em',
      },
    },
    '& .button': {
      height: 'auto',
      margin: '8px 8px',
      minWidth: 80,
      [theme.breakpoints.up('sm')]: {
        margin: '16px 8px',
      },
    },
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
});

interface Props {
  open: boolean;
  title?: string;
  handleClose: () => void;
  onOk: () => void;
  okCaption?: string;
  onCancel: () => void;
  cancelCaption?: string;
}

export default ({
  handleClose,
  open,
  title,
  children,
  onOk,
  okCaption,
  cancelCaption,
  onCancel,
}: Props & DialogContentProps) => {
  const { t } = useTranslation();
  return (
    <Dialog classes={useStyles()} onClose={handleClose} open={open}>
      <DialogTitle>{title || t('authError', 'Auth Error')}</DialogTitle>
      <DialogContent className={useStyles().root}>{children}</DialogContent>
      <DialogActions classes={useStyles()}>
        <Button
          className="button"
          onClick={onOk}
          label={okCaption || t('retry', 'Retry')}
        />
        <Button
          className="button"
          onClick={onCancel}
          label={cancelCaption || t('cancel', 'Cancel')}
        />
      </DialogActions>
    </Dialog>
  );
};
