import React from 'react';
import Panel from '../../components/Panel';
import { Container, makeStyles } from '@material-ui/core';
import Button from '../../components/Button';
import { COLORS, MAX_WIDTH } from '../../constants/style-constants';
import theme from '../../constants/theme';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  background: {
    '& .logo': {
      '& img': {
        width: 165,
      },
    },
    '& .menuIcon': {
      height: 40,
      left: 10,
      position: 'absolute',
      width: 40,
    },
    '& div': {
      flex: '1',
    },
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1.5em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      '& .menuIcon': {
        display: 'none',
      },
      minWidth: 300,
    },
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  content: {
    '& .title': {
      color: COLORS.black,
      fontSize: '1.5rem',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    maxWidth: MAX_WIDTH,
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      padding: 25,
    },
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  headerContainer: {
    '& > *': {
      flex: 1,
    },
    fontSize: '1.5rem',
    margin: '0 auto',
    maxWidth: MAX_WIDTH,
    padding: '0 24px',
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    width: '100%',
  },
});

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  function login() {
    navigate('/');
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.background}>
          <div className={'logo'}>
            <Logo />
          </div>
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes.content}>
          <Panel title={t('loggedOut', 'LOGGED OUT')}>
            <div className={classes.cardContent}>
              <Container className={'title'}>
                {t(
                  'haveBeenLoggedOut',
                  'You have been logged out of the application'
                )}
              </Container>
              <Button onClick={() => login()} label={t('login', 'Login')} />
            </div>
          </Panel>
        </div>
      </div>
    </>
  );
};
