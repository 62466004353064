import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Select } from '../../components/Select';
import {
  getIntroducerOfficesOfCurrentUser,
  Introducer,
  Office,
} from '../../tools/SiemensApi';
import Button from '../../components/Button';
import { MAX_WIDTH } from '../../constants/style-constants';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';
import { CalculatorState } from '../../model/Calculator.model';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  content: {
    '& .MuiInput-root': {
      backgroundColor: '#fff',
      fontSize: 16,
    },
    '& .MuiInputLabel-formControl': {
      color: '#000028',
      fontSize: 15,
    },
    '& .MuiTextField-root': {
      margin: '0 0 20px 0',
    },
    '& .select__indicators': {
      flexBasis: '15%',
    },
    '& .warning': {
      color: '#6B7280',
      fontSize: 12,
      marginBottom: 10,
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: MAX_WIDTH,
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 25,
    },
    position: 'relative',
    textAlign: 'left',
  },
  main: {
    backgroundColor: '#fff',
    border: 'solid 1px #e5e5e5',
    borderRadius: 6,
  },
});

interface Props {
  reset: (
    introducer?: Introducer,
    office?: Office,
    introducerCount?: number,
    officeCount?: number
  ) => void;
  introducer: Introducer | null;
  office: Office | null;
  state: CalculatorState;
  close: () => void;
}

export default ({
  reset,
  introducer: currentIntroducer,
  office: currentOffice,
  state,
  close,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chosenIntroducer, setIntroducer] = useState<Introducer | null>(null);
  const [chosenOffice, setOffice] = useState<Office | null>(null);
  const [offices, setOffices] = useState<Array<Office>>([]);
  const appState = getParent<AppState>(state);

  const introducers = getParent<AppState>(state).introducers;

  const introducerOptions = introducers.map((introducer) => ({
    label: introducer.name,
    value: introducer.id,
  }));

  const officeOptions = offices.map((office) => ({
    label: office.name!,
    value: office.id,
  }));

  const loadOffices = async () => {
    setOffices([]);
    if (chosenIntroducer) {
      const { data } = await getIntroducerOfficesOfCurrentUser({
        introducerId: chosenIntroducer.id,
      });
      setOffices(data);
    }
  };

  useEffect(() => {
    if (offices.length) {
      if (
        !chosenOffice ||
        !offices.map((office) => office.id).includes(chosenOffice.id)
      ) {
        setOffice(offices[0]);
      }
    } else {
      setOffice(null);
    }
  }, [offices]);

  useEffect(() => {
    loadOffices();
  }, [chosenIntroducer]);

  useEffect(() => {
    (async () => {
      setIntroducer(currentIntroducer);
      setOffice(currentOffice);
    })();
  }, []);

  const handleIntroducerChange = async (option: any) => {
    setIntroducer(
      introducers.find((introducer) => introducer.id === option.value)!
    );
  };

  const handleOfficeChange = async (option: any) => {
    setOffice(offices.find((office) => office.id === option.value)!);
  };

  const onlyBlock = () => {
    if (
      appState.calculator &&
      appState.calculator.marketProducts &&
      appState.calculator.marketProducts.length == 1 &&
      _.some(appState.calculator.marketProducts, { id: 473 })
    ) {
      return true;
    }
    return false;
  };

  const handleCloseClick = async () => {
    if (
      currentIntroducer &&
      chosenIntroducer &&
      currentOffice &&
      chosenOffice
    ) {
      if (
        currentIntroducer.id !== chosenIntroducer.id ||
        currentOffice.id !== chosenOffice.id
      ) {
        close();
        appState.setIsLoading(true);
        await reset(
          chosenIntroducer,
          chosenOffice,
          introducers.length,
          offices.length
        );
        state.setSettingsSaved(true);
        appState.setIsLoading(false);
        if (onlyBlock()) {
          navigate('/app/block');
        } else {
          navigate('/app/calculator');
        }
      }
    }
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <div className={classes.content}>
          <Select
            options={chosenIntroducer ? introducerOptions : []}
            label={t('introducer', 'Introducer')}
            placeholder={t('selectIntroducer', 'Select introducer')}
            onChange={handleIntroducerChange}
            value={
              introducerOptions.find(
                (option) =>
                  !!chosenIntroducer && option.value === chosenIntroducer.id
              ) || null
            }
            disabled={!chosenIntroducer}
          />
          <Select
            options={!!chosenOffice ? officeOptions : []}
            label={t('office', 'Office')}
            placeholder={t('selectOffice', 'Select office')}
            onChange={handleOfficeChange}
            value={
              officeOptions.find(
                (option) => !!chosenOffice && option.value === chosenOffice.id
              ) || null
            }
            disabled={!chosenOffice}
          />
          <div className="warning">
            {t(
              'warningChange',
              'Note: Changing introducer or office will reset your current deal'
            )}
          </div>
          <Button
            disabled={!chosenIntroducer || !chosenOffice}
            onClick={() => handleCloseClick()}
            label={t('save', 'Save')}
          />
        </div>
      </div>
    </>
  );
};
