import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles<Record<string, never>, { colour: string }>({
  root: ({ colour }) => ({
    backgroundColor: colour ? colour : '#9CA3AF',
    borderRadius: 26,
    color: 'white',
    fontSize: 13,
    fontWeight: 700,
    height: 26,
    margin: '0 4px',
    padding: '4px 8px 5px',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  }),
});

interface Props {
  label: string;
  colour?: string;
}

const StatusBadge = ({ label, colour }: Props): ReactElement => {
  if (label === 'Incomplete') {
    colour = '#9CA3AF';
  } else if (label === 'Accepted') {
    colour = '#00D7A0';
  } else if (label == 'Declined') {
    colour = '#FF6464';
  }

  const classes = useStyles({ colour });
  return <div className={classes.root}>{label}</div>;
};

export default StatusBadge;
