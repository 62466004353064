import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { formatCurrency } from '../tools';
import Input, { Props as InputProps } from './Input';

type InheritedProps = Omit<InputProps, 'value' | 'onChange' | 'validate'>;

export interface Props extends InheritedProps {
  value: number | null;
  onChange?: (value: number | null) => void;
  validate?: (value: number | null) => string;
  format?: string;
}

export default observer(
  ({ value, onChange, validate, format, onFocus, ...inputProps }: Props) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [beingEdited, setBeingEdited] = useState<boolean>(false);

    const getNumber = (rawValue: string): number | null => {
      return rawValue === ''
        ? null
        : parseFloat(rawValue.replace(/[^\d.]/g, ''));
    };

    const changeHandler = (event: any) => {
      const rawValue = event.target.value.replace(/[^\d.,]/g, '');
      setInputValue(rawValue);
      const newValue = getNumber(rawValue);
      if (
        (newValue === null || Number.isFinite(newValue)) &&
        value !== newValue &&
        onChange
      ) {
        onChange(newValue);
      }
    };

    const setDisplayedValue = (displayedValue: number | null) => {
      setInputValue(
        displayedValue === null
          ? ''
          : format
          ? formatCurrency(format, displayedValue)
          : displayedValue.toLocaleString()
      );
    };

    useEffect(() => {
      if (!beingEdited) {
        setDisplayedValue(value);
      }
    });

    const validateProp = () => (validate ? validate(value) : '');
    return (
      <Input
        inputMode="numeric"
        {...inputProps}
        validate={validateProp}
        value={inputValue}
        onChange={changeHandler}
        onFocus={(event: any) => {
          setBeingEdited(true);
          if (onFocus) {
            onFocus(event);
          }
        }}
        onBlur={() => setBeingEdited(false)}
      />
    );
  }
);
