/* tslint:disable */
/* eslint-disable */
/**
 * Document Upload service API
 * MySFS Document Upload service API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Represents a response containing an error.
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Error}
     * @memberof ErrorResponse
     */
    'error': Error;
}
/**
 * Error object
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem.
     * @type {number}
     * @memberof ModelError
     */
    'status': number;
    /**
     * A short unique identifier of the problem type. It SHOULD NOT change from occurrence to occurrence of the problem.
     * @type {string}
     * @memberof ModelError
     */
    'code': string;
    /**
     * A human readable explanation specific to this occurrence of the error.
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * The target of the error.
     * @type {string}
     * @memberof ModelError
     */
    'target'?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the error.
     * @type {string}
     * @memberof ModelError
     */
    'instance'?: string;
    /**
     * An array of details about specific errors that led to this reported error.
     * @type {Array<Error>}
     * @memberof ModelError
     */
    'details'?: Array<Error>;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'sasToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'expiryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'endpointUrl'?: string;
}
/**
 * 
 * @export
 * @interface UploadReport
 */
export interface UploadReport {
    /**
     * Unique file name. Client is responsible for sending unique file name in the request.
     * @type {string}
     * @memberof UploadReport
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadReport
     */
    'businessPartnerId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadReport
     */
    'proposalId': string;
    /**
     * Unique identifier of the upload
     * @type {string}
     * @memberof UploadReport
     */
    'uploadId'?: string;
}

/**
 * DmsApi - axios parameter creator
 * @export
 */
export const DmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this operation to report a successful file upload to Azure Blob Storage by sending necessary metadata.It triggers internally the forwarding of the uploaded file to the actual destination systems.
         * @summary Creates a file upload report
         * @param {UploadReport} [uploadReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadReport: async (uploadReport?: UploadReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this operation to get SAS token for uploading a file in Azure Blob Storage Service.
         * @summary Get Azure blob storage SAS token for uploading a document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DmsApi - functional programming interface
 * @export
 */
export const DmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DmsApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this operation to report a successful file upload to Azure Blob Storage by sending necessary metadata.It triggers internally the forwarding of the uploaded file to the actual destination systems.
         * @summary Creates a file upload report
         * @param {UploadReport} [uploadReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadReport(uploadReport?: UploadReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadReport(uploadReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this operation to get SAS token for uploading a file in Azure Blob Storage Service.
         * @summary Get Azure blob storage SAS token for uploading a document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DmsApi - factory interface
 * @export
 */
export const DmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DmsApiFp(configuration)
    return {
        /**
         * Use this operation to report a successful file upload to Azure Blob Storage by sending necessary metadata.It triggers internally the forwarding of the uploaded file to the actual destination systems.
         * @summary Creates a file upload report
         * @param {UploadReport} [uploadReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadReport(uploadReport?: UploadReport, options?: any): AxiosPromise<UploadReport> {
            return localVarFp.createUploadReport(uploadReport, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this operation to get SAS token for uploading a file in Azure Blob Storage Service.
         * @summary Get Azure blob storage SAS token for uploading a document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.getToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DmsApi - object-oriented interface
 * @export
 * @class DmsApi
 * @extends {BaseAPI}
 */
export class DmsApi extends BaseAPI {
    /**
     * Use this operation to report a successful file upload to Azure Blob Storage by sending necessary metadata.It triggers internally the forwarding of the uploaded file to the actual destination systems.
     * @summary Creates a file upload report
     * @param {UploadReport} [uploadReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DmsApi
     */
    public createUploadReport(uploadReport?: UploadReport, options?: AxiosRequestConfig) {
        return DmsApiFp(this.configuration).createUploadReport(uploadReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this operation to get SAS token for uploading a file in Azure Blob Storage Service.
     * @summary Get Azure blob storage SAS token for uploading a document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DmsApi
     */
    public getToken(options?: AxiosRequestConfig) {
        return DmsApiFp(this.configuration).getToken(options).then((request) => request(this.axios, this.basePath));
    }
}


