import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import theme from '../../../constants/theme';
import { BlockState } from '../../../model/Block.model';
import TextField from '@material-ui/core/TextField';
import Panel from '../../../components/Panel';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '../../../components/Button';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { uploadToBlobStorage, finaliseProposal, getBase64 } from './Tools';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DropzoneArea } from 'material-ui-dropzone';
import { useDebounce } from 'use-debounce';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export interface BlockDataInterface {
  amountFinanced: string | null; //this needs to be a string as block data returns aF as a string
  numberOfAgreements: string | null; //this needs to be a string as
  term: string | null; //converting all the number values to string might cause further type issues
  replacementPaperLabel: string | null;
}

const initialBlockData = {
  amountFinanced: null,
  numberOfAgreements: null,
  replacementPaperLabel: null,
  term: null,
};

const useStyles = makeStyles({
  content: {
    '& .documentList': {
      '& .docName': {
        paddingTop: 4,
        width: 200,
      },
      display: 'flex',
      marginBottom: 16,
      marginTop: 16,
    },
    '& .proposalDetails': {
      //margin: 20,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      padding: 25,
    },
    position: 'relative',
    width: '100%',
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  documentTypes: {
    '& .MuiList-padding': {
      paddingTop: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginRight: 15,
  },
  dropZone: {
    '& .MuiDropzonePreviewList-root': {
      justifyContent: 'center',
    },
    display: 'flex',
    flexBasis: '80%',
    flexDirection: 'column',
  },
  ListItemText: {
    '& span': {
      color: 'inherit',
      fontWeight: 'inherit',
      marginLeft: 10,
      padding: 0,
    },
  },
  ListItem: {
    '&:hover, &.Mui-selected': {
      backgroundColor: '#00FFB9 !important',
      color: '#000028',
      fontWeight: 700,
    },
    border: 'solid #D1D5DB 1px',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    marginBottom: 25,
    width: 205,
  },
  main: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#DDD',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#009999',
    },
    '& .MuiButtonBase-root': {
      textAlign: 'center',
    },
    '& .navigation': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      width: '100%',
    },
    '& .successBox': {
      backgroundColor: 'green',
      borderRadius: 6,
      color: 'white',
      fontSize: 20,
      height: 50,
      paddingTop: 10,
      textAlign: 'center',
    },
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    margin: 'auto',
    maxWidth: 950,
    paddingBottom: '40vh',
    position: 'relative',
    width: '100%',
  },
  previewChip: {
    maxWidth: 210,
    minWidth: 160,
  },
  typeSwitcher: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
      width: 60,
    },
    '& div': {
      paddingTop: 8,
    },
    display: 'flex',
    fontSize: 16,
    fontWeight: 'normal',
  },
  uploadButton: {
    '& div': {
      maxWidth: 'unset',
    },
    marginBottom: 10,
    marginTop: 10,
  },
  uploadedDocs: {
    borderTop: 'solid black 1px',
    paddingBottom: 10,
    paddingTop: 10,
  },
  uploader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
});

interface Props {
  state: BlockState;
}

interface DocType {
  code?: number | null;
  id: number | null;
  name: string;
}

export default observer(({ state }: Props) => {
  const classes = useStyles();

  const [blockData, setBlockData] = useState<BlockDataInterface>(
    initialBlockData
  );
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    message: 'Error',
    severity: 'success',
  });
  const [docLoader, setDocLoader] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [replacementPaper, setReplacementPaper] = useState(false);
  const [docTypeSelected, setDocTypeSelected] = useState();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [progress, setProgress] = React.useState(0);

  const handleSnackbarClose = (event: any, reason: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const updateData = (newValue: any): void => {
    setBlockData({
      ...blockData,
      [newValue.target.name]: newValue.target.value,
    });
  };

  const saveProposal = async (): Promise<void> => {
    setButtonLoader(true);
    try {
      await state.getCustomer();
    } catch (err) {
      setButtonLoader(false);
    }
    if (
      state.customer &&
      state.customer.businessPartnerRef.registrationNumber
    ) {
      try {
        await state.initializeProposal(blockData);
        setSnackbar({
          message: 'Proposal created successfully',
          severity: 'success',
        });
        setButtonLoader(false);
        setSnackbarOpen(true);
      } catch (err) {
        setSnackbar({
          message: 'Error creating proposal',
          severity: 'error',
        });
        setButtonLoader(false);
        setSnackbarOpen(true);
      }
    }
  };

  const alertUpload = (
    message: string,
    severity: string,
    error?: { message: any }
  ): void => {
    setSnackbar({ message: message, severity: severity });
    setSnackbarOpen(true);
    setDocLoader(false);
    setSnackbarOpen(true);
    setDocTypeSelected(null);
    setKey(key + 1);
    setSelectedFile(null);
  };

  const handleDropzoneFiles = (files): void => {
    if (files[0]) {
      setSelectedFile(files[0]);
    }
  };

  const uploadFile = async (
    file: any,
    alias: string,
    name: string,
    content: any,
    docType: DocType,
    destination: string
  ): Promise<any> => {
    const payload = {
      alias: alias,
      fileContent: content,
      filename: name,
      type: docType,
    };
    if (destination === 'dms') {
      uploadToBlobStorage(file, name, state, setProgress)
        .then(() => {
          alertUpload('File uploaded successfully', 'success');
          state.setHasAdditionalDocuments(true);
        })
        .catch((e) => {
          alertUpload('Error uploading file', 'error', e);
        });
    } else if (destination === 'siesmart') {
      await state
        .uploadDocSiesmart(payload)
        .then(() => {
          alertUpload('File uploaded successfully', 'success');
        })
        .catch((e) => {
          alertUpload('Error uploading file', 'error', e);
        });
      await state.uploadDocZendesk(file, name);
    }
  };

  const Alert = (props: any): ReactElement => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const reset = (): void => {
    setBlockData(initialBlockData);
    state.reset();
  };

  const handleFiles = (): void => {
    if (selectedFile && docTypeSelected) {
      setDocLoader(true);
      const doc: DocType = {
        id: docTypeSelected,
        name: '',
      };
      const file = selectedFile;
      let destination: string;
      destination = 'dms';

      if (docTypeSelected === 458) {
        destination = 'siesmart';
      }

      if (
        file.type === 'application/pdf' ||
        file.type === 'application/zip' ||
        file.type === 'application/x-zip-compressed'
      ) {
        const alias = file.name.split('.')[0];
        const fileName = file.name
          .replace(/[.](?=.*[.])/g, '-')
          .replace(/[\])}[{(]/g, '');
        try {
          getBase64(file).then((data) => {
            return uploadFile(
              file,
              alias,
              fileName,
              data.split(',')[1],
              doc,
              destination
            );
          });
        } catch (e) {
          alertUpload('Error uploading file', 'error', e);
        }
      }
    }
  };

  const documentType = [
    { id: 458, name: 'Listing schedule' },
    { id: 467, name: 'Supporting docs' },
  ];

  const dropZoneText = (): string => {
    if (docTypeSelected) {
      let returnText: string;
      docTypeSelected === 458
        ? (returnText = 'Drag and drop a file here or click - PDF only')
        : (returnText = 'Drag and drop a file here or click - zip only');
      return returnText;
    } else {
      return 'Select a file type on the left first';
    }
  };

  const dropZoneAllowedFiles = (): Array<string> => {
    if (docTypeSelected === 458) {
      return ['application/pdf'];
    } else if (docTypeSelected === 467) {
      return ['application/zip', 'application/x-zip-compressed'];
    } else {
      return ['app'];
    }
  };

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.content}>
          {!replacementPaper && (
            <Panel title={'Block details'}>
              {!state.proposal && (
                <>
                  <TextField
                    name="amountFinanced"
                    label="Block Valuation Amount"
                    margin="normal"
                    type="number"
                    value={
                      blockData.amountFinanced ? blockData.amountFinanced : ''
                    }
                    onChange={updateData}
                  />
                  <TextField
                    name="term"
                    label="Repayment term (in months)"
                    margin="normal"
                    type="number"
                    value={blockData.term ? blockData.term : ''}
                    onChange={updateData}
                  />
                  <Button
                    disabled={
                      (!blockData.amountFinanced && !blockData.term) ||
                      state.proposal
                    }
                    onClick={() => saveProposal()}
                    loading={buttonLoader}
                    label="Save"
                  />
                </>
              )}
              {state.proposal && state.proposal.id && (
                <div className="proposalDetails">
                  Proposal Number: <strong>{state.proposal.id}</strong>
                  <p />
                  Block Valuation Amount:{' '}
                  <strong>
                    £{state.proposal.currentCalculation.amountFinanced}
                  </strong>
                  <p />
                  Repayment term (in months):{' '}
                  <strong>{state.proposal.currentCalculation.term}</strong>
                  <p />
                  <div>
                    The proposal has been created. Now please upload your
                    supporting documents below
                  </div>
                  <div className="documentList">
                    <div className="docName">Listing schedule</div>
                    <div className="docIcon">
                      <CheckCircleOutlineIcon
                        style={{
                          color: state.hasFinanceAgreement ? 'green' : '#ddd',
                        }}
                      />
                    </div>
                  </div>
                  <div className="documentList">
                    <div className="docName">Additional documents</div>
                    <div className="docIcon">
                      <CheckCircleOutlineIcon
                        style={{
                          color: state.hasAdditionalDocuments
                            ? 'green'
                            : '#ddd',
                        }}
                      />
                    </div>
                  </div>
                  {!state.ticketCreated && (
                    <>
                      <div style={{ marginBottom: 20 }}>
                        Once you are finished uploading your docs please click
                        'Submit' below:
                      </div>
                      <Button
                        onClick={() =>
                          finaliseProposal(state, setSnackbar, setSnackbarOpen)
                        }
                        disabled={
                          !state.hasFinanceAgreement ||
                          state.ticketCreated ||
                          !state.hasAdditionalDocuments
                        }
                        label={'Submit'}
                      />
                    </>
                  )}
                  {state.hasFinanceAgreement &&
                    state.ticketCreated &&
                    state.hasAdditionalDocuments && (
                      <div className="successBox">
                        Block deal successfully uploaded
                      </div>
                    )}
                </div>
              )}
            </Panel>
          )}

          {((!replacementPaper && state.proposal) || replacementPaper) && (
            <>
              <Panel title={'Document upload'}>
                <div className={classes.document}>
                  <div>
                    <div>
                      Select the document type from the list on the left, choose
                      your file with the box on the right, then click 'Upload'
                    </div>
                    <div className={classes.uploader}>
                      <div className={classes.documentTypes}>
                        <List>
                          {documentType.map((option) => (
                            <ListItem
                              key={option.id}
                              selected={docTypeSelected === option.id}
                              className={classes.ListItem}
                              onClick={() => {
                                setDocTypeSelected(option.id);
                              }}
                            >
                              <ListItemText
                                className={classes.ListItemText}
                                primary={option.name}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>

                      <div className={classes.dropZone}>
                        <DropzoneArea
                          key={debounceKey}
                          //dropzoneProps={{ disabled: !docTypeSelected}}
                          onChange={(files) => handleDropzoneFiles(files)}
                          acceptedFiles={dropZoneAllowedFiles()}
                          filesLimit={1}
                          showPreviewsInDropzone={true}
                          useChipsForPreview
                          previewGridProps={{
                            container: { direction: 'row', spacing: 1 },
                          }}
                          previewChipProps={{
                            classes: { root: classes.previewChip },
                          }}
                          dropzoneText={dropZoneText()}
                          showAlerts={['error']}
                          clearOnUnmount={true}
                          maxFileSize={8589930194}
                        />
                      </div>
                    </div>
                    <div className={classes.uploadButton}>
                      <Button
                        disabled={!(selectedFile && docTypeSelected)}
                        onClick={() => handleFiles()}
                        label={'Upload'}
                        loading={docLoader}
                      />
                    </div>
                    {docTypeSelected === 467 && (
                      <div className="progress">
                        <Box sx={{ width: '100%' }}>
                          <LinearProgressWithLabel value={progress} />
                        </Box>
                      </div>
                    )}
                  </div>
                </div>
              </Panel>
              <div className="navigation">
                <Button onClick={() => reset()} label={'Reset'} />
              </div>
            </>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
});
