import React, { FunctionComponent } from 'react';

export const ErrorMessage: FunctionComponent<{}> = ({ children }) => (
  <div
    style={{
      fontSize: 24,
      padding: 10,
      textAlign: 'center',
    }}
  >
    {children}
  </div>
);
