import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { getParent } from 'mobx-state-tree';
import React, { ChangeEvent, useState, useEffect } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Panel from '../../components/Panel';
import { Select, SelectOptions } from '../../components/Select';
import { COLORS } from '../../constants/style-constants';
import theme from '../../constants/theme';
import {
  CompanyTypeQuery,
  CustomerForm,
  ErrorType,
} from '../../model/CustomerForm.model';
import { AppState } from '../../state';
import { textDifference } from '../../tools';
import {
  CustomerSearchResultItem,
  findCustomersOfIntroducer,
  FindCustomersOfIntroducerParametersModel,
  FindCustomersOfIntroducerResponseModel,
  FindCustomersOfIntroducerResponseType,
  IntroducerContact,
} from '../../tools/SiemensApi';
import { useTranslation } from 'react-i18next';
import CustomerSelector from './components/CustomerSelector';

const useStyles = makeStyles({
  advancedSearch: {
    color: '#009999',
    fontSize: 13,
    fontWeight: 700,
    marginTop: 9,
    textAlign: 'right',
  },
  advancedSearchForm: {
    marginBottom: 25,
  },
  companyNameRegNumber: {
    cursor: 'pointer',
    flexDirection: 'column',
    marginBottom: 25,
    '& .inputs': {
      '& .input': {
        display: 'flex',
        flex: 1,
      },
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  },
  errorMessage: {
    backgroundColor: 'red',
    borderRadius: 4,
    color: 'white',
    fontWeight: 600,
    height: 40,
    lineHeight: '40px',
    marginBottom: 10,
    textAlign: 'center',
    width: '100%',
  },
  formGroup: {
    '& .input': {
      display: 'flex',
      flex: 1,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: 25,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  input: {
    flexGrow: 1,
  },
  magnify: {
    '&:hover': { cursor: 'pointer' },
    color: COLORS.brownGrey,
    transform: 'scale(1.5)',
  },
  or: {
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(4),
    },
    color: COLORS.slate,
    fontWeight: 'bold',
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
});

interface Props {
  next: () => void;
  state: CustomerForm;
  reference: any;
}

export default observer(({ next, state, reference }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullSearch, setFullSearch] = useState<boolean>(true);
  const [customerSearchOpen, setCustomerSearchOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);

  const parentState: AppState = getParent<AppState>(state, 2);

  useEffect(() => {
    if (state.customer && state.customer.name) {
      state.setNameQuery(state.customer.name);
    }
  }, [state.customer]);

  if (state.salesContactPersonId === 0) {
    state.setSalesPersonContactId(0);
  }

  const handleNameChange = (
    event: ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    const value = event.target.value;
    state.setNameQuery(value);
  };

  const handleRegistrationNumberChange = (event: any) => {
    const value = event.target.value;
    state.setRegistrationNumberQuery(value);
  };

  const handlePostcodeChange = (event: any) => {
    const value = event.target.value;
    state.setPostcodeQuery(value);
  };

  const handleSalesContactChange = async (option: any) => {
    const salesContactId = option.value;
    state.setSalesPersonContactId(salesContactId!);
  };

  const findByCompanyType = (companyType: 'LIMITED' | 'NONLIMITED') => {
    const { nameQuery, registrationNumberQuery, postcodeQuery } = state;
    return findCustomersOfIntroducer(
      FindCustomersOfIntroducerParametersModel.create({
        companyType,
        introducerId: parentState.introducer!.id,
        name: nameQuery,
        postalCode: postcodeQuery.replace(' ', ''),
        registrationNumber: registrationNumberQuery,
      })
    );
  };

  const customerCmp = (
    customerA: CustomerSearchResultItem,
    customerB: CustomerSearchResultItem
  ): number => {
    let textDifferenceA;
    let textDifferenceB;
    const { nameQuery, registrationNumberQuery } = state;
    if (nameQuery) {
      textDifferenceA = textDifference(customerA.name!, nameQuery);
      textDifferenceB = textDifference(customerB.name!, nameQuery);
    } else {
      textDifferenceA = textDifference(
        customerA.businessPartnerRef!.registrationNumber,
        registrationNumberQuery
      );
      textDifferenceB = textDifference(
        customerB.businessPartnerRef!.registrationNumber,
        registrationNumberQuery
      );
    }
    return textDifferenceA - textDifferenceB;
  };

  const find = async () => {
    if (
      !state.nameQuery &&
      !state.registrationNumberQuery &&
      !state.postcodeQuery
    ) {
      return;
    }

    setSearchError(false);
    setErrorMessage('');

    setIsLoading(true);
    state.setSentNameQuery(state.nameQuery);
    state.setSentCompanyTypeQuery(state.companyTypeQuery);
    state.setSentRegistrationNumberQuery(state.registrationNumberQuery);
    state.setSentPostcodeQuery(state.postcodeQuery);

    try {
      const companyTypes: Array<'LIMITED' | 'NONLIMITED'> = [
        state.companyTypeQuery,
      ];
      const requests = companyTypes.map(findByCompanyType);
      const results = await Promise.all(requests);
      const customers = results
        .map<Array<CustomerSearchResultItem>>((result) => result.data)
        .flat();
      state.setErrorType(null);
      if (results.length > 1) {
        customers.sort(customerCmp);
      }
      setCustomers(FindCustomersOfIntroducerResponseModel.create(customers));
      setCustomerSearchOpen(true);
    } catch (error) {
      if (error.response) {
        const errorType: ErrorType = error.response.data.errors[0]
          .errorType as ErrorType;
        setSearchError(true);
        if (
          error.response.data.errors[0].errorType === 'InvalidParameter_name'
        ) {
          setErrorMessage(
            'Please provide either registration number or customer name.'
          );
        } else {
          setErrorMessage(error.response.data.errors[0].errorMessage);
        }

        state.setErrorType(errorType);
      } else {
        state.setErrorType(null);
      }
      setCustomers(([] as any) as FindCustomersOfIntroducerResponseType);
    }
  };

  const setCustomers = (
    customers: FindCustomersOfIntroducerResponseType
  ): void => {
    state.setCustomers(customers);
    state.setCustomer(null);
    setIsLoading(false);
  };

  const companyTypeOptions = [
    { label: t('limited', 'Limited'), value: 'LIMITED' },
    { label: t('nonLimited', 'Non-Limited'), value: 'NONLIMITED' },
  ];

  const salesContactOptions: SelectOptions = (parentState.salesContacts as Array<
    IntroducerContact
  >).map((introducerContact: IntroducerContact) => ({
    label: introducerContact.firstName + ' ' + introducerContact.lastName,
    value: introducerContact.id!,
  }));

  const closeCustomerSearch = (): void => {
    setCustomerSearchOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <div ref={reference} style={{ width: '100%' }}>
        <Panel title={t('chooseCustomer', 'Choose customer')} number={2}>
          <Box className={classes.companyNameRegNumber}>
            <div className="inputs">
              <Input
                className="input"
                value={state.nameQuery}
                label={t('companyName', 'Customer name')}
                onChange={handleNameChange}
                disabled={state.customer}
                inputProps={{
                  onKeyPress: (
                    event: React.KeyboardEvent<HTMLInputElement>
                  ) => {
                    if (event.key === 'Enter' && event.currentTarget.value) {
                      find();
                      event.preventDefault();
                    }
                  },
                }}
              />
              <Input
                className="input"
                value={state.registrationNumberQuery}
                label={t('regNumber', 'Registration number')}
                onChange={handleRegistrationNumberChange}
                inputProps={{
                  onKeyPress: (
                    event: React.KeyboardEvent<HTMLInputElement>
                  ) => {
                    if (event.key === 'Enter' && event.currentTarget.value) {
                      find();
                      event.preventDefault();
                    }
                  },
                }}
              />
            </div>
            {/*<div*/}
            {/*  className={classes.advancedSearch}*/}
            {/*  onClick={() => setFullSearch(!fullSearch)}*/}
            {/*>*/}
            {/*  Advanced search*/}
            {/*</div>*/}
          </Box>

          {fullSearch && (
            <div className={classes.advancedSearchForm}>
              <Box className={classes.companyNameRegNumber}>
                <div className="inputs">
                  <Input
                    className="input"
                    value={state.postcodeQuery}
                    label={t('postcode', 'Postcode')}
                    onChange={handlePostcodeChange}
                    inputProps={{
                      onKeyPress: (
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (
                          event.key === 'Enter' &&
                          event.currentTarget.value
                        ) {
                          find();
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                  <Select
                    className="input"
                    options={companyTypeOptions}
                    label={t('companyType', 'Company type')}
                    onChange={({ value }: any) => {
                      state.setCompanyType(value as CompanyTypeQuery);
                    }}
                    value={companyTypeOptions.find(
                      (options) => options.value === state.companyTypeQuery
                    )}
                    isSearchable={false}
                  />
                  {getParent<AppState>(state, 2).isLeaseDeskUserRole && (
                    <Select
                      className={classes.input}
                      options={salesContactOptions}
                      label={t('salesContact', 'Sales Contact')}
                      value={salesContactOptions.find(
                        (options) =>
                          options.value === state.salesContactPersonId
                      )}
                      onChange={handleSalesContactChange}
                    />
                  )}
                </div>
              </Box>
            </div>
          )}
          {searchError && (
            <div className={classes.errorMessage}>{errorMessage}</div>
          )}

          {!state.customer && (
            <Button
              onClick={find}
              disabled={
                !state.nameQuery &&
                !state.registrationNumberQuery &&
                !state.postcodeQuery
              }
              label={t('search', 'Search')}
              style="search"
              loading={isLoading}
            />
          )}
        </Panel>
      </div>
      {customerSearchOpen && (
        <CustomerSelector
          next={next}
          state={state}
          closeCustomerSearch={closeCustomerSearch}
        />
      )}
    </>
  );
});
