import {
  AssetType,
  deleteSigningRequest,
  DeleteSigningRequestParametersModel,
  DeleteSigningRequestRequestBodyModel,
  DocumentIdentifier,
  getAssets,
  getDocuments,
  GetDocumentsParametersModel,
  Proposal,
  ProposalDocument,
  requestSignature,
  RequestSignatureParametersModel,
  RequestSignatureRequestBodyModel,
  Signatory,
} from '../../../tools/SiemensApi';

import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Checkbox from '../../../components/Checkbox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { formatCurrency, formatDate, scrollToBottom } from '../../../tools';
import Switch from '../../../components/Switch';
import Button from '../../../components/Button';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import Panel from '../../../components/Panel';
import {
  AssetArray,
  DashboardState,
  DocumentsArray,
} from '../../../model/Dashboard.model';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardDetail from '../namevalues/CardDetail';
import currencies from '../../../constants/currencies';
import { Assets } from '../namevalues/Assets';
import DialogActions from '@material-ui/core/DialogActions';
import StatusBadge from './StatusBadge';
import { DeleteIcon } from '../../../images/DashboardIcons';
import { useNavigate } from 'react-router-dom';
import theme from '../../../constants/theme';

import marketProductsConfig from '../../../tools/constants-config';

const useStyles = makeStyles({
  buttons: {
    '& .ccSwitch': {
      '& span': {
        fontSize: 13,
      },
    },
    alignItems: 'baseline',
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  documentBox: {
    '& .documentCheckbox': {
      margin: 0,
      padding: 0,
    },
    '& .name': {
      color: '#000028',
      fontSize: 18,
      fontWeight: 700,
    },
    '& .row': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    border: '1px solid #D1D5DB',
    borderRadius: 5,
    color: '#000028',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      flexBasis: '30%',
    },
    flexBasis: '100%',
    fontSize: 16,
    fontWeight: 700,
    //maxWidth: 300,
    padding: 16,
  },
  documentInfo: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
  },
  documents: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  root: {
    '& .name': {
      color: '#000028',
      fontSize: 18,
      fontWeight: 700,
    },

    border: '1px solid #D1D5DB',
    borderRadius: 5,
    color: '#374151',
    flex: 1,
    flexBasis: '50%',
    fontSize: 14,
    maxWidth: 300,
    padding: 20,
  },
});

interface SelectedSignatories {
  documentId: number;
  signatory: number;
}

interface Props {
  state: DashboardState;
  activeProposal: Proposal;
  ifMatch: string;
  proposalId: number;
  setErrorMessage: (message) => void;
  fetchProposalAndDocuments: (hideLoading) => void;
  getAssetType: (assetTypeId: number) => AssetType | undefined;
}

const Documents = ({
  state,
  activeProposal,
  ifMatch,
  proposalId,
  setErrorMessage,
  fetchProposalAndDocuments,
  getAssetType,
}: Props) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appState = getParent<AppState>(state);

  const [documents, setDocuments] = useState<DocumentsArray | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<
    Set<ProposalDocument>
  >(new Set<ProposalDocument>());
  const [documentSignatories, setDocumentSignatories] = useState<
    Set<SelectedSignatories>
  >(new Set());
  const [ccUser, setCcUser] = useState<boolean>(true);
  const [selectedSignatories, setSelectedSignatories] = useState<
    Set<Signatory>
  >(new Set());
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState<boolean>(false);
  const [assets, setAssets] = useState<AssetArray | null>(null);

  const loanMarketProductId = marketProductsConfig.validValues[1].id;

  useEffect(() => {
    fetchDocumentsAndAssets();
  }, []);

  useEffect(() => {
    // @ts-ignore
    setSelectedSignatories(new Set(activeProposal.signatories));
  }, [activeProposal]);

  const fetchDocumentsAndAssets = async () => {
    setSelectedSignatories(new Set(activeProposal.signatories)); //Why does this work in the use effect but not below?
    //TODO: are documents and assets not being fetched?
    let documentsResponse;

    try {
      documentsResponse = await getDocuments(
        GetDocumentsParametersModel.create({
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        })
      );
    } catch (error) {
      documentsResponse = error.response;
    }

    if (documentsResponse) {
      documentsResponse.data.forEach((d: ProposalDocument) => {
        if (
          d.eligibleForSigning && //TODO replace signingEnabled with eligbleForSigning
          !d.signingRequest &&
          d.required &&
          d.sendSigningRequestAllowed
        ) {
          //selectedDocuments.add(d);
        }

        // This is currently disabled, will turn back on when the API has been updated to
        // return the correct signatory ID in the documents data
        // if (d.signingRequest && d.signingRequest.signatories) {
        //   documentSignatories.add({
        //     documentId: d.id!.value,
        //     signatory: d.signingRequest.signatories[0]!.jobTitle.id });
        //   setDocumentSignatories(new Set(documentSignatories));
        // }
      });
      setDocuments(documentsResponse.data);
    }

    // Get assets
    if (!assets) {
      let getAssetsResponse;

      try {
        getAssetsResponse = await getAssets({
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        });
      } catch (error) {
        getAssetsResponse = error.response;
      }

      setAssets(getAssetsResponse.data.data! as any);
    }
  };

  const findIdInSet = (id: number) => {
    const isChecked = Array.from(selectedDocuments).find(
      (item) => item.id!.value === id
    );

    return isChecked ? true : false;
  };

  const formatDocStatus = (text: any) => {
    let output = text.replace(/_/g, ' ').toLowerCase();
    output = output.charAt(0).toUpperCase() + output.slice(1);
    return output;
  };

  const handleCheckboxChange = (document: ProposalDocument) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      selectedDocuments.add(document);
    } else {
      selectedDocuments.delete(document);
    }
    setSelectedDocuments(new Set(selectedDocuments));
  };

  const hideEmailMessage = () => {
    setShowEmailError(false);
    setShowEmailSuccess(false);
  };

  const removeSigningRequest = async (documentId: DocumentIdentifier) => {
    try {
      await deleteSigningRequest(
        DeleteSigningRequestParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),
        DeleteSigningRequestRequestBodyModel.create({
          type: documentId.type,
          value: documentId.value,
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    await fetchDocumentsAndAssets();
  };

  const emailMessage = (messageType: string) => {
    fetchProposalAndDocuments(true);
    //setButtonLoaderOverride(false);
    if (messageType === 'success') {
      setShowEmailSuccess(true);
    }
    if (messageType === 'error') {
      setShowEmailError(true);
    }
    scrollToBottom();
    setTimeout(() => {
      hideEmailMessage();
    }, 5000);
  };

  const handleSignatorySelectChange = (
    value: number,
    document: ProposalDocument
  ) => {
    documentSignatories.forEach((doc: any) => {
      if (doc.documentId === document.id!.value) {
        documentSignatories.delete(doc);
      }
    });

    if (value) {
      documentSignatories.add({
        documentId: document.id!.value,
        signatory: value,
      });
    }
    setDocumentSignatories(new Set(documentSignatories));
  };

  const getSignatoryId = (document: ProposalDocument) => {
    const doc = Array.from(documentSignatories).find(
      (item) => item.documentId === document.id!.value
    );

    return doc ? doc.signatory : '';
  };

  const preSendCheck = () => {
    if (!activeProposal) {
      return null;
    }
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const confirmEdit = async () => {
    setConfirmOpen(false);
    await state.deleteProposal(proposalId, ifMatch);
    navigate(`/app/calculator/expire/${proposalId}`);
  };

  const confirmSend = () => {
    setConfirmOpen(false);
    sendDocuments();
  };

  const sendDocuments = async () => {
    if (!activeProposal) {
      return null;
    }

    appState.setIsLoading(true);

    let hasError = false;
    for (const document of selectedDocuments) {
      let signatoryID;
      const docSignatoryID = getSignatoryId(document);
      if (docSignatoryID) {
        signatoryID = [docSignatoryID];
      } else {
        if (Array.from(selectedSignatories).length === 1) {
          signatoryID = Array.from(selectedSignatories).map(
            (signatory) => signatory.id
          ) as Array<number>;
        }
      }

      try {
        await requestSignature(
          RequestSignatureParametersModel.create({
            ifMatch,
            introducerId: getParent<AppState>(state).introducer!.id,
            proposalId,
          }),
          RequestSignatureRequestBodyModel.create({
            documentIds: [document.id!],
            emailsInCC: ccUser ? getParent<AppState>(state).user!.email : null,
            customerSignatoryIds: signatoryID, //TODO: ask mark customer signantory IDs and vendor ids are serapated which to use
          })
        );
      } catch (error) {
        appState.setIsLoading(false);
        hasError = true;
      }
    }
    appState.setIsLoading(false);
    if (hasError) {
      emailMessage('error');
    } else {
      emailMessage('success');
    }
  };

  const numberOfDocuments =
    documents &&
    documents.filter(
      (document) =>
        document.eligibleForSigning &&
        document.required &&
        (document.sendSigningRequestAllowed || document.signingRequest)
    ).length;

  return (
    <>
      <Panel className={classes.root} title={t('documents', 'Documents')}>
        {numberOfDocuments == 0 && (
          <div>
            There are no documents available to be sent for this proposal
          </div>
        )}
        {numberOfDocuments > 0 && (
          <>
            <div className={classes.documents}>
              {documents &&
                documents
                  .filter(
                    (document) =>
                      document.eligibleForSigning &&
                      document.required &&
                      (document.sendSigningRequestAllowed ||
                        document.signingRequest)
                  )
                  .map((document: ProposalDocument, index) => {
                    const { value: id } = document.id!;
                    return (
                      <div key={index} className={classes.documentBox}>
                        <div className={'row'}>
                          <Checkbox
                            className="documentCheckbox"
                            checked={
                              findIdInSet(id) && !document.signingRequest
                            }
                            onChange={handleCheckboxChange(document)}
                            disabled={!!document.signingRequest}
                            value={'' + id}
                          />
                          {document.documentType!.name}
                          {document.signingRequest && (
                            <div className={'content'}>
                              <Tooltip title="This will invalidate the document that has been sent, allowing you to send to someone else">
                                <div>
                                  <IconButton
                                    color="primary"
                                    onClick={() =>
                                      removeSigningRequest(
                                        document.id as DocumentIdentifier
                                      )
                                    }
                                    disabled={!document.signingRequest}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        </div>

                        {Array.from(selectedSignatories).length > 1 && (
                          <div className={'container'}>
                            <div
                              className={'header ' + (index === 0 ? 'top' : '')}
                            >
                              Signee
                            </div>
                            <div className={'content'}>
                              <TextField
                                onChange={(event: ChangeEvent<any>) => {
                                  handleSignatorySelectChange(
                                    event.target.value,
                                    document
                                  );
                                }}
                                select={true}
                                label=""
                                value={getSignatoryId(document)}
                                disabled={!!document.signingRequest}
                                classes={{
                                  root: 'documentSignee',
                                }}
                              >
                                <MenuItem value="" />
                                {activeProposal!
                                  .signatories!.slice()
                                  .filter((item) => item.type === 'CUSTOMER')
                                  .map((item, idx) => (
                                    <MenuItem key={idx} value={item.id!}>
                                      {item.firstName + ' ' + item.lastName}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </div>
                          </div>
                        )}

                        <div>
                          <StatusBadge
                            label={
                              document.signingRequest
                                ? formatDocStatus(
                                    document.signingRequest.status
                                  )
                                : t('notSent', 'Not sent')
                            }
                          />
                          {document.signingRequest && (
                            <div className={classes.documentInfo}>
                              <div>
                                Sent:{' '}
                                {formatDate(
                                  document.signingRequest.sendDate as string
                                )}
                              </div>
                              {document.signingRequest
                                .signatories!.slice()
                                .map((item, i) => {
                                  return (
                                    <div key={i}>
                                      {t('sentTo', 'Sent to')}: {item.email}
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div className={classes.buttons}>
              <Switch
                className="ccSwitch"
                onClick={() => setCcUser(!ccUser)}
                checked={ccUser}
                label={t('ccMyselfx', 'Send copy to me')}
              />
              <Button
                disabled={
                  !activeProposal.signatories!.length ||
                  !Array.from(selectedDocuments).length ||
                  (Array.from(selectedSignatories).length > 1 &&
                    Array.from(selectedDocuments).length !==
                      Array.from(documentSignatories).length) ||
                  // checkSignatoriesAndDocs() ||
                  showEmailError
                }
                onClick={() => preSendCheck()}
                //buttonLoaderOverride={buttonLoaderOverride}
                label={t('sendDocumentsX', 'Send selected documents')}
              />
              {showEmailSuccess && (
                <ErrorMessage>
                  <p>
                    {t('documentsSent', 'Emails have been successfully sent')}
                  </p>
                </ErrorMessage>
              )}
              {showEmailError && (
                <ErrorMessage>
                  <p>
                    {t(
                      'documentsSendError',
                      'There has been an error sending the emails'
                    )}
                  </p>
                </ErrorMessage>
              )}
            </div>
          </>
        )}
      </Panel>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {t('confirmDetails', 'Confirm Details')}
        </DialogTitle>
        <DialogContent className="alert-dialog-content">
          <DialogContentText id="alert-dialog-description">
            {t(
              'pleaseConfirmDetails',
              'Please confirm the details below before sending the documents'
            )}
          </DialogContentText>
          {activeProposal && (
            <>
              <CardDetail title={t('deliveryAddress', 'Equipment location')}>
                {activeProposal.customer!.invoiceAddress!.address1
                  ? `${activeProposal.customer!.invoiceAddress!.address1}, `
                  : ''}
                {activeProposal.customer!.invoiceAddress!.address2
                  ? `${activeProposal.customer!.invoiceAddress!.address2}, `
                  : ''}
                {activeProposal.customer!.invoiceAddress!.city
                  ? `${activeProposal.customer!.invoiceAddress!.city}, `
                  : ''}
                {activeProposal.customer!.invoiceAddress!.postalCode
                  ? activeProposal.customer!.invoiceAddress!.postalCode
                  : ''}
              </CardDetail>

              <CardDetail title={t('amountFinanced', 'Amount Financed')}>{`${
                currencies[activeProposal.currency!.currencyCode!]!.symbol
              }${formatCurrency(
                activeProposal.currency!.format!,
                (activeProposal.currentCalculation!.marketProductId ===
                loanMarketProductId //MARKET PRODUCTS HARDCODED
                  ? activeProposal.loanSummary!.totalLoanValue
                  : activeProposal.assetSummary!.totalAssetValue) || 0
              )}`}</CardDetail>
              <CardDetail title={t('rental', 'Rental')}>{`${
                currencies[activeProposal.currency!.currencyCode!]!.symbol
              }${formatCurrency(
                activeProposal.currency!.format!,
                activeProposal.currentCalculation!.payment!
                  .avgPaymentPerPeriod || 0 //TODO:convert this to avg payment per period
              )}`}</CardDetail>
              {assets && assets.length > 0 && (
                <CardDetail title={t('assetDetails', 'Asset Details')}>
                  <Assets assets={assets} getAssetType={getAssetType} />
                </CardDetail>
              )}
              <CardDetail title={t('term', 'Term')}>
                {`${activeProposal!.currentCalculation!.term || 0} `}{' '}
                {t('months', 'Months')}
              </CardDetail>
            </>
          )}
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={confirmEdit} label={t('edit', 'Edit')} />

          <Button
            onClick={confirmSend}
            autoFocus={true}
            label={t('confirm', 'Confirm')}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Documents;
