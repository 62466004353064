import { clone, getParent, Instance, types } from 'mobx-state-tree';
import { AppState } from '../state';
import {
  Lessor,
  LessorModel,
  Upgrade,
  UpgradeModel,
} from '../tools/SiemensApi';

export const FinanceFormModel = types
  .model({
    contractNumber: types.union(types.string, types.null),
    isUpgrade: types.boolean,
    lessor: types.union(LessorModel, types.null),
    maintenanceValue: types.union(types.number, types.null),
    quantity: types.union(types.number, types.null),
    quoteTotalValue: types.union(types.number, types.null),
    serviceFee: types.boolean,
    upgradeValue: types.union(types.number, types.null),
  })
  .views((self) => ({
    get feeIncluded(): boolean {
      return self.serviceFee && getParent<AppState>(self, 2).isBT;
    },
  }))
  .views((self) => ({
    get upgrade(): Upgrade | null {
      if (self.lessor) {
        return UpgradeModel.create({
          contractNumber: self.contractNumber,
          lessor: clone(self.lessor),
          quoteTotalValue: self.upgradeValue,
        });
      } else {
        return null;
      }
    },
  }))
  .actions((self) => ({
    setContractNumber(contractNumber: string | null) {
      self.contractNumber = contractNumber;
    },
    setIsUpgrade(isUpgrade: boolean) {
      self.isUpgrade = isUpgrade;
    },
    setLessor(lessor: Lessor | null) {
      // @ts-ignore
      self.lessor = lessor ? { ...lessor } : null;
    },
    setMaintenanceValue(maintenanceValue: number | null) {
      self.maintenanceValue = maintenanceValue;
    },
    setQuantity(quantity: number | null) {
      self.quantity = quantity;
    },
    setServiceFee(serviceFee: boolean) {
      self.serviceFee = serviceFee;
    },
    setUpgradeValue(upgradeValue: number | null) {
      self.upgradeValue = upgradeValue;
    },
  }))
  .actions((self) => ({
    resetUpgrade() {
      self.setLessor(null);
      self.setContractNumber(null);
      self.setUpgradeValue(null);
    },
  }));

export type AssetFormState = Instance<typeof FinanceFormModel>;
