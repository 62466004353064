import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { StateInterface, officeConfigItem } from './Konica';
import Button from '../../../components/Button';
import { KonicaState } from '../../../model/Konica.model';
import konicaFooter from './Konica footer.jpg';
import SiemensBanner from './SiemensBanner.jpg';
import konicaLogo from './KonicaLogo.jpg';
import konicaSignature from './KonicaSignature.jpg';
import data from './data.json';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';

interface Props {
  localState: StateInterface;
  state: KonicaState;
  valid: boolean;
  officeConfig: officeConfigItem;
}

const print = async (
  localState: StateInterface,
  state: KonicaState,
  officeConfig: officeConfigItem
) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const todayString = dd + '/' + mm + '/' + yyyy;
  const appState = getParent<AppState>(state);

  const formatDate = (date) => {
    const splitDate = date.split('-');
    return splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];
  };

  const paymentPeriod = (period) => {
    if (period === 12) {
      return 'monthly';
    }
    if (period === 4) {
      return 'quarterly';
    }
    if (period === 1) {
      return 'annually';
    }
  };

  if (localState.onBaseNumber && !state.proposal.label) {
    // Patch prop with OnBaseNumber
    await state.patchProposalWithOnBase(
      state.proposal.id,
      localState.onBaseNumber
    );
  }

  const addCurrency = async (value) => {
    if (!value) {
      return null;
    } else {
      try {
        return '£' + Number(value).toFixed(2);
      } catch {
        return null;
      }
    }
  };

  const doc = new jsPDF('p', 'mm', 'a4');

  doc.setFont('Helvetica', '', 'bold');

  const banner_img = new Image();
  banner_img.src = SiemensBanner;
  doc.addImage(banner_img, 'jpg', 15, 10, 180, 30);

  const konicaLogo_img = new Image();
  konicaLogo_img.src = konicaLogo;
  doc.addImage(konicaLogo_img, 'jpg', 150, 50, 35, 25);

  doc.setTextColor('#64AAC3');
  doc.text('Konica assignment deal', 15, 50);

  doc.setTextColor('#64AAC3');

  doc.setFontSize(11);

  doc.text('Deal type', 15, 60);
  doc.text('Payment period', 15, 67);
  doc.text('Term', 15, 74);
  doc.text('Framework', 15, 81);
  doc.text('OnBase number', 15, 88);
  doc.text('Date', 15, 95);
  doc.text('Proposal number', 15, 102);

  doc.setTextColor('#000000');
  doc.setFont('Helvetica', '', 'normal');
  doc.text(localState.dealType, 50, 60);
  doc.text(
    data.paymentsPerYear.find(
      (x) => x.value === localState.paymentsPerYear.toString()
    ).name,
    50,
    67
  );
  doc.text(localState.term.toString() + ' months', 50, 74);
  doc.text(localState.framework ? localState.framework : 'N/A', 50, 81);
  doc.text(localState.onBaseNumber, 50, 88);
  doc.text(todayString, 50, 95);
  doc.text(state.proposal.id.toString(), 50, 102);

  doc.autoTable({
    body: [
      [
        'New equipment',
        await addCurrency(localState.newEquipment.price),
        localState.newEquipment.rate,
        await addCurrency(localState.newEquipment.rental),
        state.baseRateRV.toFixed(2),
        await addCurrency(localState.newEquipment.subTotal),
      ],
      [
        'Software',
        await addCurrency(localState.software.price),
        localState.software.rate,
        await addCurrency(localState.software.rental),
        localState.software.price ? state.baseRateNonRV.toFixed(2) : '',
        await addCurrency(localState.software.subTotal),
      ],
      [
        'Upgrade / Settlement',
        await addCurrency(localState.upgrade.price),
        localState.upgrade.rate,
        await addCurrency(localState.upgrade.rental),
        localState.upgrade.price ? state.baseRateNonRV.toFixed(2) : '',
        await addCurrency(localState.upgrade.subTotal),
      ],
      [
        'Non RV equipment',
        await addCurrency(localState.nonRvEquipment.price),
        localState.nonRvEquipment.rate,
        await addCurrency(localState.nonRvEquipment.rental),
        localState.nonRvEquipment.price ? state.baseRateNonRV.toFixed(2) : '',
        await addCurrency(localState.nonRvEquipment.subTotal),
      ],
      [
        'Total',
        await addCurrency(localState.total.price),
        '',
        await addCurrency(localState.total.rental),
        '',
        await addCurrency(localState.total.subTotal),
      ],
    ],
    columnStyles: {
      0: {
        cellWidth: 50,
        hAlign: 'left',
        textColor: '#64AAC3',
      },
    },
    head: [['', 'Price', 'Sales rate', 'Rental', 'Base rate', 'Sub total']],
    startY: 120,
    styles: { fontStyle: 'bold', lineColor: '#000000', lineWidth: 0.2 },
  });

  const tableBodyArray = [
    ['Equipment price', await addCurrency(localState.total.price)],
    ['RV (8%)', await addCurrency(localState.newEquipment.price * 0.08)],
    ['Commission', await addCurrency(localState.total.commission)],
    ['Invoice Total', await addCurrency(localState.total.subTotal)],
  ];

  if (
    localState.dealType === 'Framework' ||
    localState.commercialDealType === 'commercialStandard' ||
    localState.commercialDealType === 'smra'
  ) {
    tableBodyArray.splice(2, 1);
  }

  doc.autoTable({
    body: tableBodyArray,
    columnStyles: {
      0: {
        cellWidth: 50,
        hAlign: 'left',
        textColor: '#64AAC3',
      },
    },
    head: [['', '']],
    startY: 180,
    styles: { fontStyle: 'bold', lineColor: '#000000', lineWidth: 0.2 },
  });

  if (officeConfig['letter'] || localState.commercialDealType === 'smra') {
    doc.addPage('a4', 'p');

    doc.setFont('Helvetica', '', 'normal');
    doc.setFontSize(9);
    // doc.addImage(logo, 'PNG', 155, 15, 40, 6);
    doc.addImage(konicaLogo_img, 'jpg', 155, 15, 35, 25);

    doc.text('Siemens Financial Services', 15, 25);
    doc.text('Sefton Park', 15, 28);
    doc.text('Bells Hill', 15, 31);
    doc.text('Stoke Poges', 15, 34);
    doc.text('Buckinghamshire', 15, 37);
    doc.text('SL2 4JS', 15, 40);

    doc.text('Dear Sirs', 15, 47);

    const para1 = doc.splitTextToSize(
      'We, Konica Minolta Business Solutions (UK) Limited ("KMBS") offer to sell the Equipment and Offered Receivables to Siemens Financial Services Limited ("SFS") pursuant to the terms of a Trading Agreement between KMBS and SFS dated 18th Jan 2021 (the "Agreement"). This letter shall constitute an "Assignment Agreement" for the purpose of the Agreement and shall incorporate the terms thereof. Unless expressed otherwise, terms used herein shall bear the same meanings as set out in the Agreement.',
      170
    );
    doc.text(para1, 15, 55, { align: 'left' });

    // const urnText =
    //   localState.dealType === 'Framework'
    //     ? ' with URN/PO No. ' + localState.URN
    //       ? localState.URN
    //       : 'N/A'
    //     : '';

    const urnText =
      localState.dealType === 'Framework'
        ? localState.URN
          ? ' with URN/PO No.: ' + localState.URN
          : ' with URN/PO No.: N/A'
        : '';

    const customerNameText = doc.splitTextToSize(
      '"Customer" means ' +
        state.proposal.customer.businessPartner.name +
        urnText,
      170
    );
    doc.text(customerNameText, 15, 75, { align: 'left' });

    const executedText =
      localState.dealType === 'Framework'
        ? ' executed between KMBS and the customer pursuant to the Framework Agreement;'
        : ' executed between KMBS and the customer;';

    const agreementText =
      localState.dealType === 'Framework' ? 'call off' : 'Rental agreement';

    const customerText = doc.splitTextToSize(
      '"Customer Agreement" means a ' +
        agreementText +
        ' (a copy of which is attached hereto) dated ' +
        formatDate(localState.lastSignatureDate) +
        ' and identified by KMBS reference number ' +
        localState.onBaseNumber +
        executedText,
      170
    );

    doc.text(customerText, 15, 83, { align: 'left' });

    const equipmentTextInsert =
      localState.dealType === 'Framework'
        ? '"Equipment or schedule ref" '
        : '"Equipment details" ';

    const equipmentText = doc.splitTextToSize(
      equipmentTextInsert + localState.equipment,
      170
    );

    //doc.text('"Equipment details" ' + localState.equipment, 15, 90);
    doc.text(equipmentText, 15, 92, { align: 'left' });

    {
      localState.dealType === 'Framework' &&
        doc.text(
          '"Framework agreement" means a Framework Agreement for ' +
            localState.framework,
          15,
          110
        );
    }

    const termText =
      localState.dealType === 'Framework'
        ? '"Initial Term" means '
        : '"Minimum Term" means ';

    doc.text(
      termText + localState.term + ' months commencing from ' + todayString,
      15,
      120
    );

    doc.text(
      '"Offer price" means ' +
        (await addCurrency(localState.total.price)) +
        ' + VAT ',
      15,
      125
    );

    doc.text('"Offered Receivables" means the rentals', 15, 130);

    doc.text(
      '"Rentals" means ' +
        localState.term +
        ' rentals each in the amount of ' +
        (await addCurrency(localState.total.rental)) +
        ' + VAT payable ' +
        paymentPeriod(localState.paymentsPerYear) +
        ' in advance',
      15,
      135
    );

    doc.text('Commencing from ' + todayString, 15, 140);

    doc.text(
      '"Residual value" means ' +
        (await addCurrency(localState.newEquipment.price * 0.08)) +
        ' + VAT (if any) ',
      15,
      145
    );

    doc.setFont('Helvetica', '', 'bold');
    doc.text('Assignment', 15, 154);

    doc.setFont('Helvetica', '', 'normal');
    doc.text(
      'On payment of the Offer Price in accordance with the provisions of the Agreement KMBS with full title guarantee:',
      15,
      159
    );

    const para2 = doc.splitTextToSize(
      '(a) transfers title to the Equipment to SFS absolutely and free from encumbrances; and',
      170
    );
    doc.text(para2, 15, 164, { align: 'left' });

    doc.text('(b)', 15, 169);
    const para3 = doc.splitTextToSize(
      'absolutely and unconditionally assigns to SFS all of its rights to, benefit of and interest, present or future in the Offered Receivables, including but not limited to, all claims for damages and other rights and remedies in respect of these Offered Receivables or the failure to pay the Offered Receivables, including claims for interest, all monies which are now or may at any time be or become due or owing by each and every Customer in respect of or arising out of the Offered Receivables, together with the benefit of (i) all common law and equitable rights reserved by KMBS and relating to each and every Customer Agreement and (ii) all securites indemnities and guarantees in respect of the Offered Receivables.',
      170
    );
    doc.text(para3, 20, 169, { align: 'left' });

    const para4 = doc.splitTextToSize(
      'We attach copies of the documentation and an invoice.',
      170
    );
    doc.text(para4, 15, 194, { align: 'left' });

    const para5 = doc.splitTextToSize(
      'The Vendor has requested that SFS purchase the Equipment and the Offered Receivables notwithstanding the fact that the documentation comprising and related to the Customer Agreement is subject to a number of flaws, errors and omissions (the "Known Documentation Issues") and has agreed to retain all risk relating to the Known Documentation issues.  Therefore, notwithstanding the existence of the Known Documentation issues and /or SFS\' knowledge of the Known Documentation issues;',
      170
    );

    const para6 = doc.splitTextToSize(
      'i)    SFS shall be entitled to rely on the representation and warranty set out in clauses 12.1(k) of the Agreement;',
      170
    );

    const para7 = doc.splitTextToSize(
      'ii)   The indemnity set out in clause 18.2 of the Agreement shall apply in the event that SFS suffers or incurs any losses (including loss of profit) costs, claims and/or expenses as a result of the Known Documentation issues.',
      170
    );

    {
      localState.commercialDealType === 'smra' &&
        doc.text(para5, 15, 201, { align: 'left' });
    }

    {
      localState.commercialDealType === 'smra' &&
        doc.text(para6, 15, 221, { align: 'left' });
    }

    {
      localState.commercialDealType === 'smra' &&
        doc.text(para7, 15, 226, { align: 'left' });
    }

    doc.text('Yours faithfully, Henry Rabey', 15, 239);
    //doc.text('Henry Rabey', 15, 245);

    const konicaSignature_img = new Image();
    konicaSignature_img.src = konicaSignature;
    doc.addImage(konicaSignature_img, 'jpg', 15, 240, 25, 10);

    doc.text(
      'For and on behalf of Konica Minolta Business Solutions (UK) Limited',
      15,
      254
    );
    // doc.text('Konica Minolta Business Solutions (UK) Limited', 15, 255);

    const footer_img = new Image();
    footer_img.src = konicaFooter;
    doc.addImage(footer_img, 'jpg', 15, 260, 200, 30);
  }

  try {
    appState.setIsLoading(true);
    const getBase64 = (file: File | Blob): Promise<any> => {
      return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const file = doc.output('blob');

    const base64File = await getBase64(file);

    if (base64File) {
      const docType = {
        id: 467,
        name: 'Pre-contractual supporting document',
      };

      const payload = {
        alias: 'Rate Calculator/Onbase number',
        fileContent: base64File.split(',')[1],
        filename: `Rate Calculator_Onbase ${localState.onBaseNumber}.pdf`,
        type: docType,
      };

      await state.uploadDocSiesmart(payload);
      appState.setIsLoading(false);
    }
  } catch (err) {
    appState.setIsLoading(false);
  }

  doc.save('Quote ' + localState.onBaseNumber);
};

function CreateQuotePDF({ localState, state, valid, officeConfig }: Props) {
  return (
    <Button
      onClick={() => print(localState, state, officeConfig)}
      disabled={!valid}
      label="Create PDF"
    />
  );
}

export default CreateQuotePDF;
