import React, { ReactElement, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../constants/theme';
import { createPortal } from 'react-dom';

const useStyles = makeStyles<{}, { width: number }>({
  closeButton: {
    backgroundColor: '#3c464b',
    borderRadius: 15,
    color: '#fff',
    cursor: 'pointer',
    fontSize: 21,
    fontWeight: 100,
    height: 30,
    lineHeight: '27px',
    textAlign: 'center',
    width: 30,
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    left: 15,
    position: 'relative',
    top: -15,
    width: '100%',
  },
  modalBody: {
    boxSizing: 'border-box',
    fontSize: 14,
    height: '100%',
    lineHeight: 1.4,
    marginTop: -20,
    padding: '10px 30px 30px',
  },
  modalContent: ({ width }) => ({
    backgroundColor: '#fefefe',
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    boxShadow: '9px 15px 48px 2px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    fontFamily: 'helvetica, sans-serif',
    margin: '50px 20px',
    maxWidth: 720,
    width: width ? width : 'unset',
    [theme.breakpoints.up('sm')]: {
      margin: '50px auto',
    },
  }),
  modalHeading: {
    borderLeft: '3px solid #00ffb9',
    color: '#000028',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '17px',
    marginBottom: 10,
    paddingLeft: 10,
    textTransform: 'none',
  },
  root: {
    '& ul': {
      '& li': {
        marginBottom: 8,
      },
      '& li::marker': {
        color: '#009999',
        content: '"• "',
      },
      listStyle: 'none',
      marginTop: 15,
      paddingInlineStart: 10,
    },
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    height: '100vh',
    left: 0,
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 9999999,
  },
});

interface Props {
  closeModal: () => void;
  content: string | ReactElement;
  header?: string;
  width?: number;
}

const createRootElement = (id): HTMLDivElement => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem): void => {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling
  );
};

const usePortal = (id): HTMLDivElement => {
  const rootElemRef = useRef(null);

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`);
      const parentElem = existingParent || createRootElement(id);

      if (!existingParent) {
        addRootElement(parentElem);
      }

      parentElem.appendChild(rootElemRef.current);

      return function removeElement() {
        rootElemRef.current.remove();
        if (!parentElem.childElementCount) {
          parentElem.remove();
        }
      };
    },
    [id]
  );

  const getRootElem = (): HTMLDivElement => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  return getRootElem();
};

const close = (e, closeModal, seed): void => {
  const divClicked = e.target.id;
  if (
    divClicked === 'sfs_close_modal_' + seed ||
    divClicked === 'sfs_modal_' + seed
  ) {
    closeModal(false);
  }
};

const Modal = ({ closeModal, content, header, width }: Props): ReactElement => {
  const classes = useStyles({ width });
  const seed = Math.floor(Math.random() * 1000);
  const divId = 'sfs_modal_' + seed;
  const closeId = 'sfs_close_modal_' + seed;

  const main = (): React.ReactNode => {
    return (
      <div
        id={divId}
        className={classes.root}
        onClick={(event) => close(event, closeModal, seed)}
        role="modal"
      >
        <div className={classes.modalContent}>
          <div className={classes.closeContainer}>
            <div className={classes.closeButton} id={closeId}>
              &times;
            </div>
          </div>
          <div className={classes.modalBody}>
            {header && <div className={classes.modalHeading}>{header}</div>}
            {content}
          </div>
        </div>
      </div>
    );
  };
  const portalId = divId + '_portal';
  const target = usePortal(portalId);
  return createPortal(main(), target);
};

export default Modal;
