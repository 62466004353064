import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { scroll } from '../tools';

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface Props {
  location: RouteComponentProps['location'];
}

export default observer(({ location }: Props) => {
  const prevProps = usePrevious(location);

  useEffect(() => {
    if (location !== prevProps) {
      scroll(0);
    }
  });

  return null;
});
