import format from 'number-format.js/lib/format.es5';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import Levenshtein from 'levenshtein';

export const get = lodashGet;

const isInteger = (value: any): boolean => {
  const roundedValue = +('' + value.toFixed(5));
  return (
    typeof value === 'number' &&
    isFinite(roundedValue) &&
    Math.floor(roundedValue) === roundedValue
  );
};

export const formatCurrency = (
  formatString: string | null | undefined,
  value: number | null | undefined,
  symbol = ''
): string => {
  if (!formatString || !value) {
    return '';
  }
  let result = format(formatString, value);
  if (isInteger(value)) {
    result = result.replace(/[.,]\d*$/, '');
  }
  return symbol + result;
};

export const scroll = (y: number): void => {
  // we won't scroll on window as ios does not allow if not having had touch event before.
  const scrollElement = document.getElementById('reactRoot');
  if (scrollElement) {
    // const scroller = zenscroll.createScroller(scrollElement);
    // scroller.toY(y);
    window.scrollTo(0, y);
  }
};

export const scrollToBottom = (): void => {
  const scrollElement = document.getElementById('reactRoot');
  if (scrollElement) {
    scroll(scrollElement.scrollHeight - window.innerHeight);
  }
};

export const formatDate = (date: string): string => {
  const [month, day, year] = new Date(date.slice(0, 10))
    .toDateString()
    .split(' ')
    .slice(1);
  return `${day} ${month} ${year}`;
};

export const proposalStatus = (status: string | null | undefined): string => {
  if (!status) {
    return 'Unknown';
  }

  const proposalStatuses = [
    { label: 'Incomplete', value: 'OFFER' },
    { label: 'Prospect', value: 'PROSPECT' },
    { label: 'More Information Required', value: 'MORE_INFORMATION_REQUIRED' },
    { label: 'Pending Decision', value: 'WAITING_FOR_DECISION' },
    { label: 'Pending Decision', value: 'PENDING' },
    { label: 'Waiting For Print', value: 'WAITING_FOR_PRINT' },
    { label: 'Waiting', value: 'WAITING' },
    { label: 'E-Documents Sent', value: 'WAITING_FOR_E_SIGNATURE' },
    { label: 'Documents Arrived', value: 'DOCUMENTS_ARRIVED' },
    { label: 'Documents Arrived', value: 'DOCUMENTS_COMPLETED' },
    { label: 'Activated', value: 'CONTRACT_STARTED' },
    { label: 'Documents Arrived', value: 'WAITING_BACKEND' },
    { label: 'Activated', value: 'ACTIVATED' },
    { label: 'Activated', value: 'PAID' },
    { label: 'Expired', value: 'EXPIRED' },
    { label: 'Declined', value: 'DECLINED' },
    { label: 'Accepted', value: 'CREDIT_APPROVED' },
    { label: 'Waiting', value: 'DEAL_AMENDMENT' },
  ];

  const proposalStatusItem = proposalStatuses.find((options) => {
    return options.value === status;
  });

  return proposalStatusItem ? proposalStatusItem.label : 'Unknown';
};

export const isSendDocumentsStatus = (
  status: string | null | undefined
): boolean => {
  return status === 'Accepted' || status === 'Waiting For E Signature';
};

export const textDifference = (a: string, b: string): number => {
  const levenshtein = new Levenshtein(a, b);
  return levenshtein.distance;
};

export const isEqual = lodashIsEqual;

export const isInternetExplorer = (): boolean =>
  /MSIE|Trident/.test(window.navigator.userAgent);

export const getCookie = (cname: string): string => {
  const name = cname + '=';
  const cookieList = decodeURIComponent(document.cookie)!.split(';');

  for (let cookieObj of cookieList) {
    while (cookieObj.charAt(0) === ' ') {
      cookieObj = cookieObj.substring(1);
    }
    if (cookieObj.indexOf(name) === 0) {
      return cookieObj.substring(name.length, cookieObj.length);
    }
  }
  return '';
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expires: Date
): void => {
  document.cookie = cookieName + '=' + cookieValue + ';expires=' + expires;
};
