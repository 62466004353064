import { SelectProps } from '@material-ui/core/Select';
import { observer } from 'mobx-react';
import React from 'react';
import { CalculatorState } from '../model/Calculator.model';
import { PriceList } from '../tools/SiemensApi';
import { Select, SelectOption, SelectOptions } from './Select';
import { useTranslation } from 'react-i18next';

interface OwnProps extends Omit<SelectProps, 'label' | 'onChange'> {
  label?: string;
  onChange?: (value: SelectOption) => void;
  translation?: { [key: string]: string };
  state: CalculatorState;
}

type Props = OwnProps;

export const PriceListSelect = observer(
  ({ label, translation, onChange, state, ...props }: Props) => {
    if (
      !state.calculation ||
      !state.calculation.config ||
      !state.calculation.sheet ||
      !state.calculation.config.pricelistsConfig
    ) {
      return <></>;
    }
    const { t } = useTranslation();
    const sheet = state.calculation.sheet;
    const mapFunction = (pricelist: PriceList) => ({
      label: (translation && translation[pricelist.name]) || pricelist.name,
      value: pricelist.id,
    });
    const priceLists: Array<PriceList> =
      state.calculation.config.pricelistsConfig.validValues;
    const pricelistOptions: SelectOptions = priceLists.map(mapFunction);
    const pricelistOption: SelectOption = pricelistOptions.find(
      (pricelist) => pricelist.value === sheet.introducerPricelistId
    )!;
    return (
      <Select<number>
        options={pricelistOptions
          .slice()
          .sort((optionA, optionsB) =>
            optionA.label.localeCompare(optionsB.label)
          )}
        label={label || t('rateCard', 'Rate card')}
        onChange={(option) => {
          if (option) {
            if ('value' in option) {
              state.setIntroducerPricelistId(option.value);
              if (onChange) {
                onChange(option);
              }
            }
          }
        }}
        // @ts-ignore
        value={pricelistOption}
        isSearchable={false}
        {...props}
      />
    );
  }
);
