import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem, makeStyles } from '@material-ui/core';
import CreatePDF from './CreatePDF';
import data from './data.json';
import theme from '../../../constants/theme';
import { MAX_WIDTH } from '../../../constants/style-constants';
import Panel from '../../../components/Panel';

export interface StateInterface {
  capitalCost: number | null;
  customer: string;
  numberOfPayments: number;
  paymentsPerYear: number | null;
  reference: string;
  salesManager: string;
  term: number | null;
}

const initialState = {
  capitalCost: null,
  customer: '',
  numberOfPayments: 0,
  paymentsPerYear: null,
  reference: '',
  salesManager: '',
  term: null,
};

const useStyles = makeStyles({
  '.MuiSelect-select:focus': {
    backgroundColor: 'inherit',
  },
  calculator: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '10px',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    maxWidth: MAX_WIDTH,
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      padding: '10px 25px',
    },
    position: 'relative',
    width: '100%',
  },
  main: {
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    paddingBottom: '40vh',
    position: 'relative',
    width: '100%',
  },
  rental: {
    backgroundColor: 'aliceblue',
    height: 90,
    margin: '16px 24px',
    padding: 8,
  },
  rentalHeader: {
    textAlign: 'left',
  },
  rentalValue: {
    fontSize: 24,
  },
});

const Healthineers = (): ReactElement => {
  const [state, setState] = useState<StateInterface>(initialState);
  const [rental, setRental] = useState(0);

  const calculateRental = () => {
    if (state.capitalCost && state.paymentsPerYear && state.term) {
      const ir = 0.04 / state.paymentsPerYear; // interest rate per period
      const np = state.paymentsPerYear * state.term; // number of periods
      const pv = state.capitalCost; // present value
      const fv = 0; // future value
      const type = 1; // when payment is due. 0 = end of period, 1 = beginning of period
      let pmt;

      if (ir === 0) {
        return -(pv + fv) / np;
      }

      const pvif = Math.pow(1 + ir, np);
      pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

      if (type === 1) {
        pmt /= 1 + ir;
      }

      pmt = -Math.round(pmt * 100) / 100;

      if (!isNaN(pmt)) {
        setRental(pmt);
      }
    }
  };

  useEffect(() => {
    calculateRental();
  }, [state]);

  const updateState = async (newValue: any) => {
    if (newValue.hasOwnProperty('target')) {
      await new Promise((resolve) =>
        resolve(
          setState({
            ...state,
            [newValue.target.name]: newValue.target.value,
          })
        )
      );
    } else {
      await new Promise((resolve) =>
        resolve(setState({ ...state, ...newValue }))
      );
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <Panel title={'Quick quote'}>
          <form className={classes.calculator} autoComplete="off">
            <TextField
              name="salesManager"
              id="outlined-select-currency"
              select={true}
              label="Regional Sales Manager"
              value={state.salesManager}
              onChange={updateState}
            >
              {data.salesManager.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="customer"
              id="outlined-select-currency"
              select={true}
              label="Customer"
              value={state.customer}
              onChange={updateState}
            >
              {data.customer.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="reference"
              label="Project reference"
              margin="normal"
              value={state.reference}
              onChange={updateState}
            />
            <TextField
              name="capitalCost"
              label="Capital cost"
              margin="normal"
              type="number"
              value={state.capitalCost}
              onChange={updateState}
            />

            <TextField
              name="term"
              id="outlined-select-currency"
              select={true}
              label="Term (years)"
              value={state.term}
              onChange={updateState}
            >
              {data.term.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="paymentsPerYear"
              id="outlined-select-currency"
              select={true}
              label="Frequency of payment"
              value={state.paymentsPerYear}
              onChange={updateState}
            >
              {data.paymentsPerYear.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <div className={classes.rental}>
              <div className={classes.rentalHeader}>Repayment (£)</div>
              <div className={classes.rentalValue}>{rental.toFixed(2)}</div>
            </div>
            <CreatePDF state={state} rental={rental} />
          </form>
        </Panel>
      </div>
    </div>
  );
};

export default Healthineers;
