import React from 'react';
import { BusinessPartner } from '../../../tools/SiemensApi';
import CardDetail from './CardDetail';
import { useTranslation } from 'react-i18next';

interface Props {
  businessPartner: BusinessPartner;
}

const Address = ({ businessPartner }: Props) => {
  const { t } = useTranslation();
  return (
    <CardDetail title={<>{t('address', 'Customer address')}</>}>
      {businessPartner && businessPartner.name ? (
        <address>
          {businessPartner.name}
          <br />
          {businessPartner.officialAddress.address1}
          <br />
          {businessPartner.officialAddress!.address2 ? (
            <>
              {businessPartner.officialAddress.address2}
              <br />
            </>
          ) : (
            ''
          )}
          {businessPartner.officialAddress!.city!},{' '}
          {businessPartner.officialAddress!.postalCode!}
        </address>
      ) : (
        <div>T.B.C</div>
      )}
    </CardDetail>
  );
};
export default Address;
