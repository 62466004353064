import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../images/Spinner';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import { AppState } from '../state';

// @ts-ignore
const dotStyles = makeStyles({
  '@keyframes blink': {
    '0%': { opacity: '.2' },
    '100%': { opacity: '.2' },
    '20%': { opacity: '1' },
  },
  root: {
    '& span': {
      animationDuration: '1.4s',
      animationFillMode: 'both',
      animationIterationCount: 'infinite',
      animationName: '$blink',
      fontSize: 40,
    },
    '& span:nth-child(2)': {
      animationDelay: '.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '.4s',
    },
  },
});

const useStyles = makeStyles({
  root: {
    '& .button': {
      bottom: 20,
      position: 'fixed',
      right: 20,
      width: 200,
    },
    '& .content': {
      '& svg': {
        height: 80,
        width: 80,
      },
      backgroundColor: '#00BEDC',
      padding: 10,
      position: 'fixed',
      textAlign: 'center',
      top: 0,
      width: '100%',
    },
    '& img': {
      width: 100,
    },
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    color: '#000028',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 20,
    height: '100vh',
    letterSpacing: 0.77,
    position: 'fixed',
    width: '100%',
    zIndex: 9999,
  },
});

interface Props {
  text?: string;
  dots?: boolean;
  state: AppState;
}

const ContentLoaderOverlay = ({ text = '', dots, state }: Props) => {
  const { t } = useTranslation();
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResetButton(true);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  const reset = () => {
    state.setIsLoading(false);
  };

  if (dots) {
    return (
      <Container classes={dotStyles()}>
        {text && <div>{text}</div>}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Container>
    );
  } else {
    return (
      <Container classes={useStyles()}>
        <div className="content">
          {text && <div>{text}</div>}
          <Spinner />
        </div>
        {showResetButton && (
          <Button
            onClick={reset}
            className={'button'}
            label={t('Reset', 'Reset')}
            style="search"
          />
        )}
      </Container>
    );
  }
};

export default ContentLoaderOverlay;
