import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  accordion: {
    background: '#fff !important',
    border: 'solid #000028 1px !important',
    color: '#000028 !important',
  },
  cancel: {
    background: 'none',
    marginTop: 0,
  },
  complete: {
    background: '#000028 !important',
    color: '#fff !important',
  },
  continue: {
    marginTop: '40px',
  },
  details: {
    height: 'unset',
    marginTop: 0,
    maxWidth: 100,
    padding: 8,
  },
  root: {
    '&.disabled': {
      background: '#ddd',
      color: '#9ca0a2',
      cursor: 'not-allowed',
    },
    '& svg': {
      '&.noFill': {
        stroke: '#000000',
      },
      fill: '#000000',
      height: 21,
    },
    background: 'linear-gradient(90deg, #00ffb9 0%, #00e6dc 100.25%)',
    border: 'unset',
    borderRadius: '4px',
    boxSizing: 'border-box',
    color: '#000028',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '18px',
    maxWidth: '200px',
    padding: '12px',
    textAlign: 'center',
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  search: {
    background: '#000028',
    color: '#fff',
    maxWidth: '100%',
  },
}));

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  label: string;
  style?: string;
  loading?: boolean;
  icon?: ReactElement;
  className?: string;
}

const NavButton = (props: Props): ReactElement => {
  const classes = useStyles();
  const { disabled, onClick, label, style, loading, icon, className } = props;

  let isDisabled = disabled;
  // force button to be disabled if loading
  if (loading) {
    isDisabled = true;
  }

  const styleValue = style ? (classes as any)[style] : '';
  const disabledValue = isDisabled ? 'disabled' : '';

  return (
    <div
      className={`${styleValue} ${classes.root} ${className} ${disabledValue}`}
      onClick={isDisabled ? null : onClick}
    >
      {loading ? <CircularProgress color="primary" size={20} /> : label}
      {icon ? icon : ''}
    </div>
  );
};
export default NavButton;
