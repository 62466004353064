import { Box, Container, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { CalculatorState } from '../../model/Calculator.model';
import config from '../../config';
import theme from '../../constants/theme';
import Panel from '../../components/Panel';
import icon from 'media/Completed.svg';
import { useTranslation } from 'react-i18next';
import CreatePDF from '../../components/ApprovalPDF';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';

const useStyles = makeStyles({
  navigation: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'space-evenly',
    marginTop: 20,
    [theme.breakpoints.up('sm') as string]: {
      flexDirection: 'row',
    },
  },
  root: {
    '& .MuiButtonBase-root': {
      marginTop: theme.spacing(5),
    },
    '& .conditions-title': {
      fontWeight: 600,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
      textDecoration: 'underline',
      textTransform: 'uppercase',
    },
    '& .title': {
      fontSize: 24,
      fontWeight: 700,
    },
    '& img': {
      height: 120,
      margin: theme.spacing(5),
    },
    '& svg': {
      margin: theme.spacing(5),
    },
    textAlign: 'center',
  },
});

interface Props {
  reset: () => void;
  state: CalculatorState;
}

const Success = ({ reset, state }: Props): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (!state.proposal) {
    return <>{t('noProposal', 'No proposal known')}</>;
  }
  const classes = useStyles();

  let conditionText;
  if (state.proposal.decision && state.proposal.decision.condition) {
    conditionText = state.proposal.decision.condition.split('\n');
  }

  const openSendDocuments = (proposalId: number): void => {
    navigate(`/app/myprops/${proposalId}/further-contract-information`);
  };

  return (
    <Panel>
      <Box className={classes.root}>
        <img src={icon} alt="Success Icon" />
        <Container className={'title'}>
          {t('applicationApproved', { propId: state.proposal.id })}
        </Container>
        {conditionText && (
          <Container className={'conditions'}>
            <div className={'conditions-title'}>
              {t('conditions', 'Conditions')}
            </div>
            {conditionText.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </Container>
        )}
        <div className={classes.navigation}>
          <Button
            onClick={reset}
            label={t('newApplication', 'New application')}
          />

          {config.allowSendDocuments && state.proposal && state.proposal.id && (
            <Button
              onClick={(): void => openSendDocuments(state.proposal!.id!)}
              label={t('sendDocuments', 'Send documents')}
            />
          )}

          {getParent<AppState>(state).isSiemensDI && (
            <CreatePDF proposal={state.proposal} />
          )}
        </div>
      </Box>
    </Panel>
  );
};

export default Success;
