import { makeStyles, Portal } from '@material-ui/core';
import spinner from 'media/whitespinner.svg';
import * as React from 'react';
import { COLORS } from '../../constants/style-constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<{}, { open: boolean }>({
  blocker: ({ open }) => ({
    backgroundColor: COLORS.black,
    height: open ? '100%' : 0,
    left: 0,
    opacity: open ? 0.5 : 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transition: open ? 'opacity 1s' : 'opacity 1s, height 1s step-end',
    zIndex: 1000000,
  }),
  message: ({ open }) => ({
    '& img': {
      height: 40,
      margin: '0 0.5em',
      verticalAlign: 'middle',
    },
    '& span': {
      color: COLORS.white,
      fontSize: 20,
      fontWeight: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
      verticalAlign: 'middle',
    },
    background: COLORS.scarlet,
    borderRadius: '15px 15px 0 0',
    bottom: 0,
    position: 'fixed',
    textAlign: 'center',
    transform: open ? 'translateY(0)' : 'translateY(100%)',
    transition: open
      ? 'visibility, transform 1s'
      : 'visibility 1s step-end, transform 1s',
    visibility: open ? 'visible' : 'hidden',
    width: '100%',
    zIndex: 1000001,
  }),
});

interface Props {
  open: boolean;
}

export default ({ open }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ open });
  return (
    <Portal>
      <>
        <div className={classes.blocker} />
        <div className={classes.message}>
          <span>{t('reconnecting', 'Reconnecting')}</span>
          <img src={spinner} />
        </div>
      </>
    </Portal>
  );
};
