import { makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Panel from '../../components/Panel';
import { scroll } from '../../tools';

const useStyles = makeStyles({
  root: {
    '& .LegalText': {
      '& .atBottom:after': {
        opacity: 0,
      },
      '& .atTop:before': {
        opacity: 0,
      },
      '& .letter-list': {
        counterReset: 'listStyle',
      },
      '& .letter-list > li:before': {
        content: '"(" counter(listStyle, lower-alpha) ")"',
        marginRight: '1em',
      },
      '& .letter-list li': {
        counterIncrement: 'listStyle',
        marginLeft: '1em',
      },
      '& .roman-list': {
        counterReset: 'listStyle',
      },
      '& .roman-list > li:before': {
        content: '"(" counter(listStyle, lower-roman) ")"',
        marginRight: '1em',
      },
      '& .roman-list li': {
        counterIncrement: 'listStyle',
        marginLeft: '1em',
      },
      '& .scroller': {
        '& .scroller:before': {
          background: 'linear-gradient(white, rgba(255, 255, 255, 0))',
          content: '',
          height: '6rem',
          left: 0,
          position: 'absolute',
          top: 0,
          transition: 'opacity 1s',
          width: '100%',
        },
        '& :after': {
          background: 'linear-gradient(rgba(255, 255, 255, 0), white)',
          bottom: 0,
          content: '',
          height: '6rem',
          left: 0,
          position: 'absolute',
          transition: 'opacity 1s',
          width: '100%',
        },
        height: '20rem',
        overflow: 'auto',
      },
      '& li.li2': {
        fontSize: 12,
        margin: '0px 0px 9px 0px',
      },
      '& li.li3': {
        fontSize: 12,
        margin: '0px 0px 0px 0px',
      },
      '& ol': {
        counterReset: 'item',
      },
      '& ol ol > li': {
        display: 'block',
      },
      '& ol ol.number-list > li:before': {
        content: 'counters(item, ".") ". "',
        marginLeft: -20,
      },
      '& ol.number-list > li': {
        counterIncrement: 'item',
      },
      '& p.p1': {
        fontSize: 12,
        margin: '0px 0px 9px 0px',
        textAlign: 'center',
      },
      '& p.p2': {
        fontSize: 12,
        margin: '0px 0px 9px 0px',
      },
      '& p.p3': {
        fontSize: 12,
        margin: '0px 0px 0px 0px',
      },
      '& p.p4': {
        fontSize: 12,
        margin: '0px 0px 9px 0px',
        minHeight: 14,
      },

      '& span.s1': {
        fontSize: 12,
      },
      '& ul.ul1': {
        listStyleType: 'disc',
      },
      margin: '2em 0',
      padding: '0 0 0 2em',
      position: 'relative',
    },
    fontSize: 12,
    width: '100%',
  },
});

interface Props {
  handleAgreed: () => void;
}

export default ({ handleAgreed }: Props) => {
  const [agreed, setAgreed] = useState<boolean>(false);
  const [atTop, setAtTop] = useState<boolean>(true);
  const [atBottom, setAtBottom] = useState<boolean>(false);

  const agree = () => {
    handleAgreed();
    scroll(0);
  };

  const calculate = (event: any, element: HTMLDivElement) => {
    setAtTop(element.scrollTop < 8);
    setAtBottom(
      element.scrollTop + element.clientHeight >= element.scrollHeight - 8
    );
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="main-content">
        <Panel title="Terms and Conditions">
          <Typography variant={'body1'}>
            Please agree to the following terms and conditions:
          </Typography>
          <div className="LegalText">
            <div
              className={
                'scroller ' +
                cn({
                  atBottom,
                  atTop,
                })
              }
              onScroll={(event: any) => calculate(event, event.target)}
            >
              <ol className="number-list">
                <li>
                  <b>About our Terms</b>
                  <ol className="number-list">
                    <li>
                      These Terms explain how you may use SieSmart, My SFS and
                      the SieSmart API (the <b>SFS Technology</b>), each of
                      which simplifies the lease administration process and
                      helps you to take full advantage of our services.
                    </li>
                    <li>
                      You should read these Terms carefully before using the SFS
                      Technology. By accessing or using the SFS Technology or
                      otherwise indicating your consent, you acknowledge that
                      you have understood and agree to be bound by these Terms
                      and the documents referred to in them.  If at any time you
                      do not agree to these Terms, you must immediately
                      terminate all use of the SFS Technology. If you have any
                      questions about the SFS Technology, please contact us
                      through your SFS representative.
                    </li>
                    <li>
                      <b>Definitions</b>

                      <p className="p2">
                        <b>Confidential Information</b> means any and all
                        information made available to you through the SFS
                        Technology which is not already generally available to
                        the public.
                      </p>

                      <p className="p2">
                        <b>Customer</b> means the counterparty to any asset
                        finance agreement.
                      </p>

                      <p className="p2">
                        <b>IP Rights</b> means rights such as copyright, trade
                        marks, domain names, design rights, database rights,
                        patents and all other intellectual property rights of
                        any kind whether or not they are registered or
                        unregistered (anywhere in the world).
                      </p>

                      <p className="p2">
                        <b>Licence </b>has the meaning given to it in clause
                        4.1.
                      </p>

                      <p className="p2">
                        <b>My SFS</b> means our Progressive Web Application
                        which allows you to access our services from mobile and
                        desktop devices.
                      </p>

                      <p className="p2">
                        <b>OWASP Top Ten</b> means the standard awareness
                        document for developers and web application security
                        published by the Open Web Application Security Project
                        and available at https://owasp.org/www-project-top-ten/.
                      </p>

                      <p className="p2">
                        <b>SieSmart</b> means the web platform which allows you
                        to access our services online, as further described in
                        clause 2 below.{' '}
                      </p>

                      <p className="p2">
                        <b>SieSmart API</b> means our application programming
                        interface, which is to be used as an interface between
                        SieSmart and your applications.
                      </p>

                      <p className="p2">
                        <b>SieSmart API Documentation </b>means the documents
                        (in whatever media) that accompany the SieSmart API
                        including our policies for use of the SieSmart API.
                      </p>

                      <p className="p2">
                        <b>SFS Content </b>means all data, text, images, video,
                        audio or other multimedia content, software or other
                        information or material which is uploaded to or
                        processed via the SFS Technology other than the User
                        Content, including but not limited to any materials and
                        data provided by us and accessed by you, such as online
                        forms, tables and these Terms.
                      </p>

                      <p className="p2">
                        <b>Terms</b> means these terms and conditions of use as
                        updated from time to time under clause 8.
                      </p>

                      <p className="p2">
                        <b>User Content</b> means any text, images, video, audio
                        or other multimedia content, software or other
                        information or material which you process through,
                        submit or upload to or on the SFS Technology.
                      </p>

                      <p className="p2">
                        <b>We, us </b>or <b>our</b> means Siemens Financial
                        Services Limited (company registration number 00646166
                        and the registered office of which is at Sefton Park,
                        Bells Hill, Stoke Poges, Buckinghamshire, SL2 4JS .
                        References to &quot;us&quot; in these Terms also
                        includes our group companies from time to time.
                      </p>

                      <p className="p2">
                        <b>You </b>or <b>your</b> means the person accessing or
                        using the SFS Technology or the SFS Content.
                      </p>
                    </li>
                  </ol>
                </li>

                <li>
                  Using the SFS Technology
                  <ol className="number-list">
                    <li>
                      The SFS Technology is for your commercial use only and is
                      designed to accelerate the lease administration process
                      through the use of SieSmart to generate lease quotations. 
                      It is not intended to operate as a replacement for any of
                      the more detailed terms and processes set out in any
                      trading agreement or similar arrangement between us.
                    </li>
                    <li>
                      You agree that you will only use the SFS Technology in
                      accordance with these Terms and such other instructions
                      from us concerning your use of the SFS Technology as may
                      be amended or revised by us at our discretion at any time.
                    </li>
                    <li>
                      You agree that you will only use the SieSmart API in
                      accordance with the SieSmart API Documentation and that
                      you will notify us as soon as you suspect or become aware
                      of any use of the SieSmart API otherwise than in
                      accordance with the SieSmart API Documentation. In using
                      the SieSmart API you agree that you will act in accordance
                      with best practice including but not limited to the OWASP
                      Top Ten.
                    </li>
                    <li>
                      You will not attempt to circumvent any limitations placed
                      by us on use of the SieSmart API without our consent. Such
                      limitations are imposed by us at our sole discretion.
                    </li>
                    <li>
                      You agree that you are solely responsible for:
                      <ol type="a" className="letter-list">
                        <li>
                          all costs and expenses you may incur in relation to
                          your use of the SFS Technology;
                        </li>
                        <li>
                          keeping your user name, password and other credentials
                          confidential and ensuring that such information is not
                          stolen, mislaid or used in an unauthorised manner
                        </li>
                        <li>
                          any activities and actions that take place through
                          your account by use of your credentials (and that you
                          will be liable for all actions and activities that
                          take place through your account by use of your
                          credentials) whether authorised or not;
                        </li>
                        <li>
                          your own hardware, the User Content and any other data
                          uploaded through or to the SFS Technology; and
                        </li>
                        <li>
                          third parties unlawfully obtaining access to your
                          accounts in order to abuse the nature and intent of
                          the SFS Technology.
                        </li>
                      </ol>
                    </li>

                    <li>
                      You further agree that:
                      <ol type="a" className="letter-list">
                        <li>
                          you will only use the SFS Technology for the purpose
                          of introducing Customers to us and/or our group
                          companies and not for any other purpose unless you
                          obtain our prior written consent;
                        </li>
                        <li>
                          you will keep all Confidential Information strictly
                          confidential, and that you will not disclose such
                          information to any other person;
                        </li>
                        <li>
                          you will inform us immediately if you have any reason
                          to believe that there has been any unauthorised use of
                          your user name, password and/or other account
                          details.; and
                        </li>
                        <li>
                          you are satisfied that the SFS Technology is suitable
                          for the purpose for which you propose to use it.
                        </li>
                      </ol>
                    </li>

                    <li>
                      You will ensure that your use of the SFS Technology is in
                      accordance with all relevant legislation, regulations,
                      codes of practice, guidance and other requirements of any
                      relevant government, governmental or regulatory agency or
                      other relevant body.
                    </li>

                    <li>
                      You will not use the SFS Technology:
                      <ol type="a" className="letter-list">
                        <li>
                          in a manner that will cause interruption, damage,
                          impairment or reduction in the quality or depth of the
                          SFS Technology, servers or networks providing the SFS
                          Technology or the services that we offer to you,
                          Customers or other suppliers through the SFS
                          Technology;
                        </li>
                        <li>
                          in connection with a criminal offence under the
                          applicable national laws or regulations or against
                          public order or applicable ethical standards and
                          codes;
                        </li>
                        <li>
                          in any way which causes or is intended to cause
                          annoyance, inconvenience or needless anxiety;
                        </li>
                        <li>
                          for any unlawful purpose whatsoever, including fraud
                          or terrorism;
                        </li>
                        <li>
                          in any way which is abusive, harmful, threatening or
                          defamatory or any other way that may cause offence;
                        </li>
                        <li>
                          in any way that could be harmful to our systems or
                          data (including uploading any material that otherwise
                          contains a virus or other malicious code);
                        </li>
                        <li>
                          in any way which breaches or could potentially breach
                          a legal duty to a third party (including a duty of
                          confidentiality) or which infringes or could
                          potentially infringe a person's right to privacy;
                        </li>
                        <li>
                          in any way which promotes discrimination or is likely
                          to incite hatred; or
                        </li>
                        <li>
                          in any way which may infringe the intellectual
                          property rights of third parties or which promotes any
                          unlawful act.
                        </li>
                      </ol>
                    </li>

                    <li>
                      You will not:
                      <ol type="a" className="letter-list">
                        <li>
                          distribute, license, sell, rent, lease or otherwise
                          deal in or encumber the SFS Technology;
                        </li>
                        <li>
                          modify, add to, or otherwise enhance the SFS
                          Technology;
                        </li>
                        <li>
                          build databases or create permanent copies of data
                          scraped from the SFS Technology;
                        </li>
                        <li>
                          reverse engineer or extract the source code from the
                          SFS Technology; or
                        </li>
                        <li>copy the SFS Technology.</li>
                      </ol>
                    </li>

                    <li>
                      Your use of the SFS Technology is intended only for access
                      from within the UK. If you choose to access the SFS
                      Technology from locations outside the UK, you are
                      responsible for compliance with local laws where they are
                      applicable.
                    </li>

                    <li>
                      We reserve the right to:
                      <ol type="a" className="letter-list">
                        <li>
                          terminate your Licence and your access to the SFS
                          Technology immediately if:
                          <ol type="i" className="roman-list">
                            <li>
                              you do not properly perform your obligations under
                              these Terms;
                            </li>
                            <li>
                              you fail to comply with the instructions for use
                              of the services from time to time in force as
                              displayed on the SFS Technology or in the SieSmart
                              API Documentation; or
                            </li>
                            <li>
                              any representations or warranties you have made to
                              us are or become incorrect,
                            </li>
                          </ol>
                        </li>

                        <li>
                          monitor your use of the SFS Technology in order to
                          ensure quality, improve our products and services and
                          to verify compliance with these Terms;
                        </li>
                        <li>
                          make modifications to the SFS Technology at any time
                          for any reason; and
                        </li>
                        <li>
                          terminate or withdraw any of the SFS Technology at any
                          time without prior notice and for any reason.
                        </li>
                      </ol>
                    </li>

                    <li>
                      On termination or discontinuation of access to the SFS
                      Technology you will immediately delete any stored SFS
                      Content.
                    </li>
                  </ol>
                </li>

                <li>
                  <b>Data Protection and Use of Information</b>

                  <p className="p2">
                    For the purposes of this clause 3, the following definitions
                    shall apply:
                  </p>

                  <p className="p2">
                    <b>Controller</b> has the meaning given in applicable Data
                    Protection Laws from time to time;
                  </p>

                  <p className="p2">
                    <b>Data Protection Laws</b> means, as binding on either
                    party or the service provided under these Terms:
                  </p>

                  <ol type="a" className="letter-list">
                    <li>the GDPR;</li>
                    <li>the Data Protection Act 2018;</li>
                    <li>any laws which implement any such laws; and</li>
                    <li>
                      any laws that replace, extend, re-enact, consolidate or
                      amend any of the foregoing;
                    </li>
                  </ol>

                  <p className="p2">
                    <b>Data Subject</b> has the meaning given in applicable Data
                    Protection Laws from time to time;
                  </p>

                  <p className="p2">
                    <b>GDPR</b> means the General Data Protection Regulation,
                    Regulation (EU) 2016/679;
                  </p>

                  <p className="p2">
                    <b>Personal Data</b> has the meaning given in applicable
                    Data Protection Laws from time to time;
                  </p>

                  <p className="p2">
                    <b>Processing</b> has the meaning given in applicable Data
                    Protection Laws from time to time (and related expressions,
                    including process, processed, processing, and processes
                    shall be construed accordingly);
                  </p>

                  <p className="p2">
                    <b>Processor</b> has the meaning given in applicable Data
                    Protection Laws from time to time; and
                  </p>

                  <p className="p2">
                    <b>Protected Data</b> means Personal Data received from or
                    on behalf of you in connection with the performance of our
                    obligations under these Terms.
                  </p>

                  <ol className="number-list">
                    <li>
                      We understand that our use of Personal Data requires
                      trust. We are committed to the highest standards of data
                      privacy and will only use Protected Data for clearly
                      described purposes and in accordance with Data Subjects’
                      data protection rights. We will comply with our
                      obligations under Data Protection Laws in respect of
                      Protected Data. We may disclose Protected Data for the
                      purposes of checking credit, arranging insurance,
                      administering the customer agreement and preventing fraud,
                      to credit reference agencies, insurers and other finance
                      companies. We will not use Protected Data for marketing or
                      other purposes or disclose that information to other
                      companies for such purposes, without first giving the
                      Customer the opportunity of opting out. For further
                      details about our use of Personal Data please read our{' '}
                      <a href="https://new.siemens.com/uk/en/general/privacy-notice.html">
                        Privacy Notice
                      </a>
                      and{' '}
                      <a href="https://www.siemens.com/uk/en/home/products/financing/fair-processing-notice.html">
                        Fair Processing Notice
                      </a>
                      .
                    </li>

                    <li>
                      For the purposes of these Terms you and we are deemed to
                      be separate Controllers, in respect of the Processing of
                      Protected Data in connection with this Agreement. You and
                      we shall comply with Data Protection Laws in Processing
                      Protected Data. You will not by your act or omission cause
                      us to breach Data Protection Laws. You will ensure that
                      you have obtained appropriate consents (or otherwise have
                      a lawful basis under GDPR) for providing the Protected
                      Data to us. Furthermore, you shall provide appropriate
                      fair processing information (including our fair processing
                      notices as updated from time to time) to Data Subjects
                      identified in the Protected Data.
                    </li>

                    <li>
                      On the basis of your business relationship with us, we
                      will continue to keep you informed by email about our
                      financial products which you may wish to recommend to your
                      clients. If you would like to opt out of marketing
                      communications, you can click the 'Unsubscribe' link at
                      the bottom of any marketing email we send to you.
                    </li>
                  </ol>
                </li>

                <li>
                  <b>Ownership and Intellectual Property Rights</b>
                  <ol className="number-list">
                    <li>
                      We grant you a limited, non-exclusive, non-sublicensable,
                      fully paid, non-transferable, royalty-free licence to use
                      the SFS Technology and the SFS Content within the UK and
                      only for the purposes authorised in clause 2 of these
                      Terms (<b>Licence</b>). Using the SFS Technology in an
                      unlawful way is expressly prohibited under these Terms and
                      may result in civil and criminal penalties. Your Licence
                      will continue indefinitely until such time as it is
                      terminated in accordance with clause 1.1 below.
                    </li>

                    <li>
                      Except for the Licence, all IP Rights in and to the SFS
                      Technology and the SFS Content shall vest and remain
                      vested in us. To the extent that you acquire any IP Rights
                      in the SFS Technology or the SFS Content, you will assign
                      or procure the assignment of such IP Rights with full
                      title guarantee (including by way of present assignment of
                      future IP Rights) to us or to anyone else we nominate. You
                      will execute all such documents and do such things as we
                      may consider necessary to give effect to this clause.
                    </li>

                    <li>
                      You grant to us a royalty-free, perpetual, irrevocable,
                      non-exclusive, transferable licence to use, reproduce,
                      modify, publish, edit, translate, distribute, perform and
                      display any User Content and any data or analytics
                      generated from the same.
                    </li>

                    <li>
                      SieSmart and MySFS are trade marks of Siemens Financial
                      Services GmbH. Other trade marks, logos and trade names
                      may also be used within the SFS Technology. The use of any
                      trade marks, logos and trade names is strictly prohibited
                      unless you have our prior written permission.
                    </li>
                  </ol>
                </li>

                <li>
                  <b>Links to Third Party Websites</b>
                  <ol className="number-list">
                    <li>
                      We may provide links to sites on the Internet via the SFS
                      Technology which are operated by other people. If you use
                      these links, you will be leaving the SFS Technology and we
                      therefore take no responsibility in respect of linked
                      sites.
                    </li>
                  </ol>
                </li>

                <li>
                  <b>Warranties</b>
                  <ol className="number-list">
                    <li>
                      You warrant that:
                      <ol type="a" className="letter-list">
                        <li>
                          you have the right, power and authority to agree to
                          these Terms and to grant to us the rights contemplated
                          in these Terms;
                        </li>

                        <li>
                          you hold all rights and have obtained all licences and
                          consents required to use the SFS Technology; and
                        </li>

                        <li>
                          your use of the SFS Technology will not infringe any
                          rights of any third party or our rights, nor will it
                          breach any applicable laws or regulations, including
                          Data Protection Laws.{' '}
                        </li>
                      </ol>
                    </li>

                    <li>
                      The SFS Technology is provided on an ‘AS IS’ basis and you
                      acknowledge and agree that the SFS Technology may not be
                      free of bugs or errors and agrees that the existence of
                      minor bugs or errors shall not constitute a breach of
                      these Terms. We do not warrant that the SFS Technology
                      will be uninterrupted or error-free or compatible with
                      third party software or equipment.
                    </li>

                    <li>
                      Neither we nor our suppliers give any warranties nor make
                      any representations about the SFS Content or about results
                      to be obtained from using the SFS Technology and shall not
                      be liable for any loss or damage arising out of any virus
                      or other malicious code.
                    </li>

                    <li>
                      Any warranties we provide under these Terms will be
                      subject to you using the SFS Technology in compliance with
                      these Terms and the SieSmart API Documentation, and we
                      will not be liable under these Terms for, or required to
                      remedy, any problem arising from:
                      <ol type="a" className="letter-list">
                        <li>
                          any modification made to any part of the SFS
                          Technology by anyone other than us without our express
                          prior written consent; or
                        </li>

                        <li>
                          any defect or error wholly caused by any equipment or
                          third-party software used in connection with the SFS
                          Technology.
                        </li>
                      </ol>
                    </li>

                    <li>
                      Subject to these express Terms and to the maximum extent
                      permitted by applicable law, we hereby disclaim all other
                      warranties, terms and conditions, either express, implied
                      or statutory in relation to the SFS Technology.
                    </li>

                    <li>
                      You should notify us as soon as possible of any fault or
                      problem you are experiencing with the SFS Technology by
                      contacting your SFS representative who will be able to
                      conduct the lease administration process for your lease
                      business if the SFS Technology is not available. Any
                      errors will be corrected at our sole discretion. We may,
                      but are not obliged to, use any feedback or suggestions
                      you provide including by way of publicity.
                    </li>
                  </ol>
                </li>

                <li>
                  <b>Rights of third parties</b>
                  <p className="p2">
                    No one other than a party to these Terms has any right to
                    enforce any of these Terms.
                  </p>
                </li>

                <li>
                  <b>Variation</b>
                  <p className="p2">
                    These Terms are dated June 2020. No changes to these Terms
                    are valid or have any effect unless agreed by us in writing.
                    We reserve the right to vary these Terms from time to time.
                    Our updated terms will be displayed on SieSmart and My SFS
                    and communicated to you directly if needed and by continuing
                    to use and access the SFS Technology following such changes,
                    you agree to be bound by any variation made by us. It is
                    your responsibility to check these Terms to verify such
                    variations.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setAgreed(event.target.checked)
            }
            checked={agreed}
            label="I agree to the Terms and Conditions."
          />
          <Button onClick={agree} disabled={!agreed} label={'Submit'} />
        </Panel>
      </div>
    </div>
  );
};
