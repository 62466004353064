//import makeInspectable from 'mobx-devtools-mst';
import {
  flow,
  Instance,
  onSnapshot,
  types,
  addMiddleware,
} from 'mobx-state-tree';
import * as mobxUtils from 'mobx-utils';
import * as mobx from 'mobx';
import config from './config';
import { calculatorDefaults, CalculatorModel } from './model/Calculator.model';
import { createCurrency, Currency } from './model/Currency';
import { dashboardDefaults, DashboardModel } from './model/Dashboard.model';
import { BlockModel } from './model/Block.model';
import { setState } from './tools/Api';
import { Authentication } from './tools/Auth';
import {
  ContractUpgradeQuoteModel,
  Currency as ApiCurrency,
  getIntroducerOfficesOfCurrentUser,
  getIntroducersOfCurrentUser,
  getLessors,
  UserProfileModel,
  getUpgradeQuotes,
  GetUpgradeQuotesParametersModel,
  getUserProfile,
  Introducer,
  IntroducerModel,
  Office,
  OfficeModel,
  updateUserProfile,
  UpdateUserProfileRequestBodyModel,
  UserProfile,
  FindContractsResponseModel,
  findContracts,
  FindContractsParametersModel,
  getIntroducerSalesContactsOfOffice,
  IntroducerContactModel,
  IntroducerContact,
  getProposalConfig,
  ProposalConfigModel,
  fetchUpgradeQuote,
  FetchUpgradeQuoteParametersModel,
} from './tools/SiemensApi';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { KonicaModel } from './model/Konica.model';

const AuthenticationModel = types.model({
  accessToken: types.string,
  expiryTime: types.integer,
});

const StateModel = types
  .model('State', {
    authentication: types.maybeNull(AuthenticationModel),
    block: BlockModel,
    calculator: CalculatorModel,
    contractObj: types.maybeNull(FindContractsResponseModel),
    dashboard: DashboardModel,
    errorDialog: types.boolean,
    errorMessage: types.string,
    introducer: types.maybeNull(IntroducerModel),
    introducerCount: types.integer,
    introducers: types.array(IntroducerModel),
    konica: KonicaModel,
    landingPage: types.string,
    loading: types.boolean,
    office: types.maybeNull(OfficeModel),
    officeCount: types.integer,
    onLine: types.boolean,
    proposalConfig: types.maybeNull(ProposalConfigModel),
    salesContacts: types.maybeNull(types.array(IntroducerContactModel)),
    showModal: types.boolean,
    upgradeQuote: types.maybeNull(ContractUpgradeQuoteModel),
    user: types.maybeNull(UserProfileModel),
  })
  .views((self) => ({
    get currency(): Currency {
      let currency;
      try {
        currency = self.calculator.calculation!.config!.currency;
      } catch {
        throw new Error('No currency found');
      }

      return createCurrency(currency as ApiCurrency);
    },

    get hasReadTermsAndConditions(): boolean {
      return !!self.user;
    },

    get isAuthenticated(): boolean {
      // Check whether the current time is past the
      // access token's expiry time
      if (self.authentication) {
        const expiryTime = self.authentication.expiryTime;
        return mobxUtils.now() < expiryTime;
      } else {
        return false;
      }
    },

    get isBT(): boolean {
      return (
        !!self.introducer && self.introducer.bpId === config.btIntroducerId
      );
    },
    get isLoading(): boolean {
      return (
        self.dashboard!.lastProposalsCallId !== null ||
        self.dashboard!.lastContractsCallId !== null
      );
    },
    get isSiemensDI(): boolean {
      return (
        !!self.introducer && self.introducer.bpId === config.diIntroducerId
      );
    },

    get isLeaseDeskUserRole(): boolean {
      if (self.user) {
        const resultingObj = (self.salesContacts as Array<
          IntroducerContact
        >).find(
          (introducerContact) =>
            introducerContact.firstName + ' ' + introducerContact.lastName ===
              self.user!.firstName! + ' ' + self.user!.lastName! &&
            introducerContact.role! === 'ROC_LED'
        );
        if (resultingObj != null) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get deposit(): number | null {
      const assetForm = self.calculator.assetForm;
      const total = assetForm.total || 0;
      const amountFinanced = 0;
      return total - amountFinanced;
    },
  }))
  .actions((self) => ({
    reset(keep: Array<string> = ['landingPage']) {
      Object.assign(
        self,
        Object.fromEntries(
          Object.entries(stateReset).filter(
            ([key, value]) => !keep.includes(key)
          )
        )
      );
    },
  }))
  .actions((self) => ({
    initialize: flow(function* initialize(
      newIntroducer?: Introducer,
      newOffice?: Office,
      introducerCount?: number,
      officeCount?: number
    ) {
      let introducer = mobx.toJS(newIntroducer);
      self.introducerCount = introducerCount ? introducerCount : 0;
      self.officeCount = officeCount ? officeCount : 0;

      if (!introducer) {
        const { data: introducers } = yield getIntroducersOfCurrentUser();
        self.introducers = introducers;
        self.introducerCount = introducers.length;
        introducer = introducers[0];
      }
      if (!introducer) {
        throw new Error('no introducer');
      }
      self.introducer = introducer!;

      let office = newOffice;
      if (!office) {
        const { data: offices } = yield getIntroducerOfficesOfCurrentUser({
          introducerId: introducer.id,
        });
        self.officeCount = offices.length;
        office = offices[0];
      }
      if (!office) {
        throw new Error('no office');
      }
      self.office = office!;

      if (introducer.id && office.id) {
        const {
          data: salesContacts,
        } = yield getIntroducerSalesContactsOfOffice({
          introducerId: introducer.id,
          officeId: office.id,
        });
        self.salesContacts = salesContacts.salesContacts;
      }

      if (introducer.id) {
        const { data: proposalConfig } = yield getProposalConfig({
          introducerId: introducer.id,
        });
        self.proposalConfig = proposalConfig;
      }

      yield self.calculator.initialize();
    }),
    setAuthentication(authentication: Authentication | null) {
      self.authentication = authentication;
    },
    setErrorDialog(value: boolean) {
      self.errorDialog = value;
    },
    setErrorMessage(errorMessage: string) {
      self.errorMessage = errorMessage;
    },
    setLandingPage(page: string) {
      self.landingPage = page;
    },
    setUser(user: UserProfile) {
      self.user = user;
    },
    setShowModal(showModal: boolean) {
      self.showModal = showModal;
    },
    tryPersistedState(persistedState: any) {
      try {
        Object.assign(self, persistedState);
      } catch (error) {
        self.reset();
      }
    },
  }))
  .actions((self) => ({
    async init(authentication?: Authentication, landingPage?: string) {
      localStorage.clear();
      const persistedState = parseState();
      if (authentication) {
        self.setAuthentication(authentication);
        const { data } = await getUserProfile();
        const userProfile: UserProfile = data;
        if (
          persistedState &&
          persistedState.user &&
          persistedState.user.gid === userProfile.gid
        ) {
          self.tryPersistedState(persistedState);
        } else {
          self.reset();
        }
        self.setAuthentication(authentication);
        self.setUser(userProfile);
      } else {
        self.tryPersistedState(persistedState);
      }
      if (landingPage && landingPage === '/app/block') {
        self.setLandingPage(landingPage);
      }
      if (
        self.isAuthenticated &&
        self.hasReadTermsAndConditions &&
        (!self.introducer || !self.office)
      ) {
        await self.initialize();
        // Log User Profile & Introducer details in GA
        const gaUserInfo = `gid:${self.user!.gid}|\
          userName:${self.user!.firstName} ${self.user!.lastName}|\
          introducerId:${self.introducer!.bpId}`;
        ReactGA.event({
          action: 'User Login Action',
          category: 'User Login',
          label: gaUserInfo,
        });
      }
    },
    save() {
      const { onLine, ...stateCopy } = { ...self };
      sessionStorage.setItem('state', JSON.stringify(stateCopy));
    },
  }))
  .actions((self) => ({
    getLessors: async () => {
      return getLessors({ introducerId: self.introducer!.id });
    },
    setHasReadTermsAndConditions: flow(function* () {
      const response = yield updateUserProfile(
        {},
        UpdateUserProfileRequestBodyModel.create()
      );
      self.user = response!.data;
    }),
    updateOnlineStatus(event: Event) {
      self.onLine = navigator.onLine;
    },
  }))
  .actions((self) => ({
    getUpgradeQuote: flow(function* getUpgradeQuote(contractNo: number) {
      let promiseCall: ReturnType<typeof getUpgradeQuotes> | null = null;
      let upgradeQuoteObj;

      try {
        promiseCall = fetchUpgradeQuote(
          FetchUpgradeQuoteParametersModel.create({
            contractId: contractNo,
            introducerId: self.introducer!.id,
          }),
          {}
        );

        upgradeQuoteObj = yield promiseCall;

        if (upgradeQuoteObj && upgradeQuoteObj.data[0]) {
          upgradeQuoteObj = upgradeQuoteObj.data[0]!;
        }
      } catch (error) {
        upgradeQuoteObj = error.response;
      }
      return upgradeQuoteObj;
    }),
  }))
  .actions((self) => ({
    getContractObj: flow(function* findContractByContractNumber(
      contractNo: string
    ) {
      let promiseCall: ReturnType<typeof findContracts> | null = null;
      let contractObj;
      try {
        promiseCall = findContracts(
          FindContractsParametersModel.create({
            contrNo: contractNo,
            introducerId: self.introducer!.id,
          })
        );
        contractObj = yield promiseCall;

        if (contractObj && contractObj.data.data[0]) {
          contractObj = contractObj.data.data[0]!;
        }
      } catch (error) {
        contractObj = error.response;
      }
      return contractObj;
    }),
    setIsLoading(value: boolean) {
      self.loading = value;
    },
  }));

const parseState = () => {
  const json = sessionStorage.getItem('state');
  try {
    return json ? JSON.parse(json) : null;
  } catch (error) {
    return null;
  }
};

const stateReset = {
  block: BlockModel.create(),
  calculator: calculatorDefaults,
  contractObj: null,
  dashboard: dashboardDefaults,
  errorDialog: false,
  errorMessage: '',
  introducer: null,
  introducerCount: 0,
  introducers: [],
  konica: KonicaModel.create(),
  landingPage: '',
  loading: false,
  office: null,
  officeCount: 0,
  offices: [],
  proposalConfig: null,
  reporting: {
    proposals: [],
    proposalsCounts: {},
  },
  salesContacts: [],
  showModal: false,
  upgradeQuote: null,
  user: null,
};

export type AppState = Instance<typeof StateModel>; //ignore this error not an issue

export const getState = (): AppState => {
  const state = StateModel.create({
    ...stateReset,
    onLine: navigator.onLine,
  });

  addMiddleware(state, (call, next, abort) => {
    if (call.type === 'flow_throw') {
      state.setErrorDialog(true);
      state.setErrorMessage(call.args[0].message);
      Sentry.captureException(call.args[0].message);
      if (config.env === 'development') {
        console.log(call.args[0].message);
      }
      return abort('value');
    }
    next(call);
  });

  //makeInspectable(state);

  setState(state);

  window.addEventListener('online', state.updateOnlineStatus);
  window.addEventListener('offline', state.updateOnlineStatus);

  onSnapshot(state, () => {
    state.save();
  });

  return state;
};
//no more linting issue here
