import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import theme from '../../constants/theme';
import { DashboardState, FilterModel } from '../../model/Dashboard.model';
import Header from './components/Header';
import Proposals from './Proposals';
import MoreDetails from './FurtherContractInformation';
import config from '../../config';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import Reset from '../Dashboard/Reset';
import { MAX_WIDTH } from '../../constants/style-constants';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';

const useStyles = makeStyles({
  main: {
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 'auto',

    paddingBottom: 20,
    paddingTop: 20,
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 950,
      width: '80%',
    },
  },
  root: {
    '& .accountStatus': {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .details-style': {
      margin: '0 10px 0 10px',
    },
    maxWidth: MAX_WIDTH,
    padding: 0,
  },
});

interface Props {
  openProposal: (proposalId?: number) => void;
  state: DashboardState;
}

export default observer(
  ({ openProposal, state, state: { getFilteredProposals } }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const appState = getParent<AppState>(state);

    const getMoreProposalsClick = () => {
      appState.setIsLoading(true);
      state.incrementLoadingCount();

      state.getProposals('', true); //we need to pass in load count here
    };

    const amendProposal = async (proposalId: number) => {
      await state.deleteProposal(proposalId);
      navigate(`/app/calculator/expire/${proposalId}`);
    };

    const deleteProposal = async (proposalId: number) => {
      await state.deleteProposal(proposalId);
      navigate('/app/myprops');
    };

    const openSendDocuments = (proposalId: number): void => {
      navigate(`/app/myprops/${proposalId}/further-contract-information`);
    };

    const numActiveDeals = getFilteredProposals('active').length;
    const numProposals = getFilteredProposals('pending').length;
    const numDrafts = getFilteredProposals('draft').length;

    const filters = [
      {
        count: numDrafts,
        filter: FilterModel.create('draft'),
        label: t('drafts', 'Drafts'),
      },
      {
        count: numProposals,
        filter: FilterModel.create('pending'),
        label: t('proposals', 'Proposals'),
      },
      {
        count: numActiveDeals,
        filter: FilterModel.create('active'),
        isShown: config.includeActiveDeals,
        label: t('activated', 'Activated'),
      },
    ];

    const proposalsPath = ``;
    const goProposals = () => {
      navigate(proposalsPath);
    };

    const backButton = (
      <Button onClick={goProposals} label={t('backToAll', 'Back to all')} />
    );

    let subPage = false;
    if (location.pathname.match('myprops/')) {
      subPage = true;
    }

    return (
      <div className={classes.main}>
        {!subPage && (
          <>
            <Header
              openProposal={openProposal}
              state={state}
              filters={filters}
              goProposals={goProposals}
              getMoreProposals={getMoreProposalsClick}
            />
          </>
        )}
        {state.error && (
          <div id="error">There has been an error loading your proposals</div>
        )}
        <Routes>
          <Route
            path={proposalsPath}
            element={
              <Proposals
                openProposal={openProposal}
                deleteProposal={deleteProposal}
                amendProposal={amendProposal}
                openMoreDetails={openSendDocuments}
                getMoreProposals={getMoreProposalsClick}
                state={state}
                filters={filters}
              />
            }
          />
          <Route path="reset">
            <Route path=":filter" element={<Reset state={state} />} />
          </Route>
          <Route
            path=":propId/further-contract-information"
            element={
              <MoreDetails
                backButton={backButton}
                state={state}
                getAssetType={state.getAssetType}
              />
            }
          />
        </Routes>
      </div>
    );
  }
);
