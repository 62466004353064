import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import Button from '../../components/Button';
import ErrorDialog from '../../components/ErrorDialog';
import Panel from '../../components/Panel';
import currencies from '../../constants/currencies';
import { COLORS } from '../../constants/style-constants';
import theme from '../../constants/theme';
import { formatCurrency } from '../../tools';
import {
  CalculationSheet,
  checkPreconditionsForDecision,
  getProposal,
  requestCreditDecision,
} from '../../tools/SiemensApi';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../state';
import { useTranslation, Trans } from 'react-i18next';
import { ErrorMessage } from '../../components/ErrorMessage';
import * as Sentry from '@sentry/browser';
import Input from '../../components/Input';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import marketProductsConfig from '../../tools/constants-config';

const useStyles = makeStyles({
  rental: {
    '& .label': {
      color: COLORS.black,
      fontSize: 16,
      fontWeight: 700,
    },
    '& .value': {
      color: COLORS.black,
      fontSize: 33,
    },
    backgroundColor: '#F3F3F0',
    borderRadius: 5,
    flexBasis: 0,
    flexGrow: 1,
    height: 100,
    marginBottom: theme.spacing(2),
    paddingTop: 15,
    textAlign: 'center',
  },
  root: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      '& .MuiCardContent-root': {},
    },
  },
  summaryBox: {
    borderBottom: 'solid #D1D5DB 1px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  termsConditions: {
    '& h1': {
      color: COLORS.blueBayoux,
      fontSize: '0.75em',
      fontWeight: 'bold',
      letterSpacing: 2,
      textTransform: 'uppercase',
    },
    '& p': {
      '& a': {
        color: COLORS.green,
        fontWeight: 'bold',
        textDecoration: 'none',
      },
      color: COLORS.slate,
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    fontSize: '0.75em',
    margin: theme.spacing(3, 0),
  },
});

const useCellStyles = makeStyles<
  {},
  {
    total?: boolean;
    width?: number;
  }
>({
  root: ({ width }) => ({
    '& .label': {
      color: COLORS.petrol,
      fontSize: 15,
    },
    '& .value': {
      color: COLORS.black,
      fontSize: 24,
    },
    flexBasis: 0,
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    minWidth: width === undefined ? 'auto' : width,
  }),
});

const Cell = ({
  label,
  value,
  total,
  width,
}: {
  label: string;
  value: string;
  total?: boolean;
  width?: number;
  indent?: boolean;
}) => (
  <div className={useCellStyles({ total, width }).root}>
    <div className={'label'}>{label}</div>
    <div className={'value'}>{value}</div>
  </div>
);

export default observer(({ state, next, reference }: any) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const appState = getParent<AppState>(state);
  const navigate = useNavigate();

  const loanMarketProductId = marketProductsConfig.validValues[1].id;

  const submit = async () => {
    const proposalId = state.proposal!.id!;

    if (appState.introducer && appState.introducer.bpId === 2205676) {
      navigate(`/app/konica/${proposalId}`);
      return;
    }

    appState.setIsLoading(true);

    if (
      state.officeId &&
      state.introducerId &&
      ['OFFER', 'PROSPECT', 'EXPIRED'].includes(
        state.proposal!.status!.status as string
      )
    ) {
      const { introducerId } = state;

      try {
        ReactGA.event({
          action: 'credit check start time',
          category: 'credit check timing',
          label: `${proposalId}` + '|Start time: ' + new Date(),
        });

        // Perform updates if current state differs from SieSmart
        if (
          !_.isEqual(
            _.omit(state.proposal.currentCalculation, ['id', 'created']),
            _.omit(state.calculation.sheet, ['id', 'created'])
          )
        ) {
          if (state.marketProduct === loanMarketProductId) {
            //MARKET PRODUCTS HARDCODED
            await state.saveLoanToDraft();
          } else {
            await state.saveAssetToDraft();
          }

          if (state.financeForm.isUpgrade) {
            await state.deleteUpgrades(state.proposal!.id!);
            await state.saveUpgradeToDraft();
          } else {
            if (state.proposal!.upgradeSummary!.numberOfUpgrades) {
              await state.deleteUpgrades(state.proposal!.id!);
            }
          }

          await state.saveCalculationToDraft(state.operation);
        }
        // End of updates

        const checkPreconditionsForDecisionResponse = await checkPreconditionsForDecision(
          {
            introducerId,
            proposalId,
          }
        );

        if (!checkPreconditionsForDecisionResponse.data.requestAllowed) {
          const error = checkPreconditionsForDecisionResponse.data
            .preconditionViolations![0].userMessage!;
          const gaErrorDesc = `introducerId:${
            getParent<AppState>(state).introducer!.bpId
          }|\
          salesContactId:${getParent<AppState>(state).user!.gid}|\
          proposalId:${proposalId}|\
          errorMessage:${error}`;
          // Log the error as GA exception event
          ReactGA.exception({
            description: gaErrorDesc,
            fatal: false,
          });
          setErrorMessage(error);
          appState.setIsLoading(false);
          setIsError(true);

          return null;
        }

        const findProposalByIdResponse = await getProposal({
          introducerId,
          proposalId,
        });
        const ifMatch = findProposalByIdResponse.headers.etag;

        const propComments = comments ? comments : 'no comments';
        const { data: proposal } = await requestCreditDecision(
          {
            ifMatch,
            introducerId,
            proposalId,
          },
          {
            commentToRiskAnalyst: propComments,
            siemensMayContactCustomer: true,
            siemensMayRequestCreditData: false,
            prelease: false,
          } //TODO: this could be a problem
        );

        state.setProposal(proposal);

        const success =
          proposal.decision && proposal.decision.decisionStatus === 'ACCEPTED';

        const creditDecisionGALabel = `introducerId: ${
          getParent<AppState>(state).introducer!.bpId
        }|\
        salesContactId:${getParent<AppState>(state).user!.gid}|\
        proposalId:${proposalId}|\
        decisionStatus:${proposal.decision!.decisionStatus}|\
        decisionRequestedDate:${proposal.decision!.decisionRequestedDate}|\
        decisionReason:${proposal.decision!.reason}`;

        ReactGA.event({
          action: 'CreditDecisionRequest',
          category: `CreditDecision${success ? 'Success' : 'Referred'}`,
          label: creditDecisionGALabel,
          value: state.proposal!.assetSummary!.totalAssetValue,
        });

        if (success) {
          appState.setIsLoading(false);
          next('success');
        } else {
          appState.setIsLoading(false);
          next('referred');
        }
      } catch (error) {
        appState.setIsLoading(false);
        setIsApiError(true);
        Sentry.captureException(error);
        return;
      }
      ReactGA.event({
        action: 'credit check end time',
        category: 'credit check timing',
        label: `${proposalId}` + '|End time: ' + new Date(),
        nonInteraction: true,
      });
    }
  };

  /*if (
    !state.customerForm.customer ||
    !state.customerForm.customer.name ||
    (state.hasAsset &&
      (!state.assetForm.selectedAssetType ||
        !state.assetForm.selectedAssetSubType)) ||
    !state.calculation ||
    !state.calculation.sheet ||
    !state.calculation.config ||
    !state.calculation.config.currency ||
    !state.calculation.config.currency.currencyCode ||
    !state.calculation.config.currency.format ||
    !state.amountFinanced
  ) {
    return <></>;
  }*/

  //const customerName = state.customerForm.customer.name;
  const assetName =
    state.hasAsset &&
    state.assetForm.selectedAssetType &&
    state.assetForm.selectedAssetSubType
      ? state.assetForm.selectedAssetType.name +
        ' / ' +
        state.assetForm.selectedAssetSubType.name
      : 'N/A';
  const sheet: CalculationSheet = state.calculation.sheet;
  const symbol =
    currencies[state.calculation.config.currency.currencyCode].symbol;

  const totalAmountFinanced = state.amountFinanced || 0;
  const isUpgrade = state.financeForm.isUpgrade;

  const handleCommentsChange = (event: any) => {
    const value = event.target.value;
    setComments(value);
  };

  const salesPersonName = 'test';
  // state.proposal!.salesContact!.firstName +
  // ' ' +
  // state.proposal!.salesContact!.lastName;

  const userName =
    getParent<AppState>(state).user!.firstName +
    ' ' +
    getParent<AppState>(state).user!.lastName;

  const classes = useStyles();

  if (!totalAmountFinanced) {
    return <></>;
  }

  const isProposalValid = () => {
    if (
      !state.customerForm.customer ||
      !state.customerForm.customer.name ||
      (state.hasAsset &&
        (!state.assetForm.selectedAssetType ||
          !state.assetForm.selectedAssetSubType ||
          !state.assetForm.assetName)) ||
      (state.hasAsset &&
        state.proposal &&
        state.proposal.assetSummary &&
        state.proposal.assetSummary.numberOfAssets == 0) ||
      (state.marketProduct === loanMarketProductId && //MARKET PRODUCTS HARDCODED
        state.proposal.loanSummary &&
        state.proposal.loanSummary.numberOfLoans == 0) ||
      !state.calculation ||
      !state.calculation.sheet ||
      !state.calculation.config ||
      !state.amountFinanced
    ) {
      return false;
    }
    return true;
  };

  return (
    <div ref={reference} style={{ width: '100%' }}>
      <Panel className={classes.root} title={t('summary', 'Summary')}>
        {state.proposal && state.proposal.id && (
          <div className={classes.summaryBox}>
            <Cell
              label={t('proposalId', 'Proposal ID')}
              value={state.proposal.id}
            />
          </div>
        )}
        {state.proposal &&
          state.customerForm.customer &&
          state.customerForm.customer.name && (
            <div className={classes.summaryBox}>
              <Cell
                label={t('customer', 'Customer')}
                value={state.customerForm.customer.name}
              />
            </div>
          )}
        {state.hasAsset &&
          state.proposal &&
          state.proposal.assetSummary &&
          state.proposal.assetSummary.numberOfAssets > 0 && (
            <div className={classes.summaryBox}>
              <Cell label={t('asset', 'Asset')} value={assetName} />
            </div>
          )}
        {state.assetForm.loan && state.assetForm.loan.loanPurpose && (
          <Cell
            label={t('loanPurpose', 'Loan Purpose')}
            value={state.assetForm.loan.loanPurpose.name}
          />
        )}
        {totalAmountFinanced && sheet.term && (
          <div className={classes.summaryBox}>
            <Cell
              label={t('amountFinanced', 'Amount financed')}
              value={`${symbol}${formatCurrency(
                state.calculation.config.currency.format,
                totalAmountFinanced
              )}`}
            />
            <Cell
              label={t('termLength', 'Term length')}
              value={sheet.term + ' ' + t('months', 'Months')}
            />
          </div>
        )}
        {state.rental !== null &&
        state.rental > 0 && ( //it works for zero values when it shouldn't - if state rental is zero it should not show
            //rental can be zero and not null. When zero it should not show.
            <div className={classes.rental}>
              <div
                className={'label'}
              >{`${sheet.payment.paymentLines[0].period
                .label!.replace(/annually/i, t('annual', 'Annual'))
                .replace(/quarter/i, t('quarterly', 'Quarterly'))} rental${
                state.assetForm.feeIncluded
                  ? t('includingServiceFee', ' (including service fee)')
                  : ''
              }`}</div>
              <div className={'value'}>{`${symbol}${formatCurrency(
                state.calculation.config.currency.format,
                state.rental || 0
              )}`}</div>
            </div>
          )}
        {isProposalValid() && (
          <>
            <Input
              label={t('comments', 'Comments')}
              value={comments}
              onChange={handleCommentsChange}
            />
            <Box className={classes.termsConditions}>
              <Trans i18nKey="submitTandCs">
                <h1>Terms & Conditions</h1>
                By applying for this financial product, you are agreeing to our
                <a
                  target="_blank"
                  href="https://www.siemens.com/uk/en/home/general/legal.html#tabentry-478438873-679549479"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
                and that you have read the
                <a
                  target="_blank"
                  href="https://www.siemens.com/uk/en/home/general/legal.html#item1-399195215"
                  rel="noreferrer"
                >
                  data use policy
                </a>
                , including our
                <a
                  target="_blank"
                  href="https://www.siemens.com/uk/en/home/general/legal.html#tabentry-1877058998"
                  rel="noreferrer"
                >
                  cookie policy
                </a>
                and our
                <a
                  target="_blank"
                  href="https://www.siemens.com/uk/en/home/products/financing/fair-processing-notice.html"
                  rel="noreferrer"
                >
                  fair use policy
                </a>
                .
              </Trans>
            </Box>
            <Button
              onClick={submit}
              disabled={!isProposalValid}
              label={t('submitApplication', 'Submit application')}
              style="search"
            />
          </>
        )}
        {isApiError && (
          <ErrorMessage>
            <Trans i18nKey="submitError">
              There has been an error submitting this application
              <br />
              Please try again
              <br />
              If the problem persists please contact Siemens Support
            </Trans>
          </ErrorMessage>
        )}
        <ErrorDialog
          open={isError}
          onOk={() => setIsError(false)}
          handleClose={() => setIsError(false)}
          okCaption="OK"
          title={t('preconditionResponse', 'Precondition Decision Response')}
          state={appState}
        >
          <p>{errorMessage}</p>
          <p>
            For help, please email{' '}
            <a href="mailto:mysfs.uk@siemens.com">mysfs.uk@siemens.com</a>
          </p>
        </ErrorDialog>
      </Panel>
    </div>
  );
});
