import {
  Boardmember,
  createSignatory,
  CreateSignatoryParametersModel,
  CreateSignatoryRequestBodyModel,
  getCustomerBoardmembers,
  GetCustomerBoardmembersParametersModel,
  Proposal,
} from '../../../tools/SiemensApi';
import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  BoardMembersArray,
  DashboardState,
} from '../../../model/Dashboard.model';
import { useTranslation } from 'react-i18next';
import { getParent } from 'mobx-state-tree';
import { AppState } from '../../../state';
import ContactBox from './ContactBox';
import Panel from '../../../components/Panel';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 20,
  },
});

interface Props {
  state: DashboardState;
  setErrorMessage: (message: any) => void;
  setActiveProposal: (proposal: Proposal) => void;
  ifMatch: string;
  proposalId: number;
  fetchProposalAndDocuments: (hideLoading) => void;
}

const BoardMembers = ({
  state,
  setErrorMessage,
  ifMatch,
  proposalId,
  fetchProposalAndDocuments,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({});

  const [boardMembers, setBoardMembers] = useState<BoardMembersArray | null>(
    null
  );

  useEffect(() => {
    fetchBoardMembers();
  }, []);

  const fetchBoardMembers = async () => {
    // Get board members

    let boardMembersResponse;

    try {
      boardMembersResponse = await getCustomerBoardmembers(
        GetCustomerBoardmembersParametersModel.create({
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        })
      );
    } catch (error) {
      boardMembersResponse = error.response;
    }

    setBoardMembers(boardMembersResponse.data);
  };

  const addBoardmemberAsSignatory = async (boardMember: Boardmember) => {
    try {
      await createSignatory(
        CreateSignatoryParametersModel.create({
          ifMatch,
          introducerId: getParent<AppState>(state).introducer!.id,
          proposalId,
        }),
        // @ts-ignore
        CreateSignatoryRequestBodyModel.create({
          boardmember: true,
          boardmemberId: boardMember.id,
          email: boardMember.email!,
          firstName: boardMember.firstName!,
          jobTitle: boardMember.jobTitle!,
          lastName: boardMember.lastName!,
          phone: boardMember.phone!,
          type: 'CUSTOMER',
        })
      );
    } catch (error) {
      setErrorMessage(
        error.response.data.errors.map(
          ({ errorMessage: e }: any, index: number) => (
            <Box key={index}>{e}</Box>
          )
        )
      );
      return;
    }

    //const proposal = response.data;
    //setActiveProposal(proposal);
    //state.setProposalsInfo(proposalId, response.data);
    fetchProposalAndDocuments(true);
  };

  const validateBoardMember = (boardMember: Boardmember) => {
    let isValid = false;
    const toValidate = boardMember;

    if (
      boardMember &&
      toValidate.firstName &&
      toValidate.lastName &&
      toValidate.phone &&
      toValidate.phone.length > 0 &&
      toValidate.email &&
      toValidate.email.length > 0
    ) {
      isValid = true;
    }

    return isValid;
  };

  if (
    !boardMembers ||
    (boardMembers &&
      boardMembers.filter((item) => validateBoardMember(item as Boardmember))
        .length === 0)
  ) {
    return <></>;
  }

  return (
    <Panel title={t('contacts', 'Contacts')}>
      <div className={classes.root}>
        {boardMembers &&
          boardMembers
            .slice()
            .filter((item) => validateBoardMember(item as Boardmember))
            .map((item, index) => {
              return (
                <ContactBox
                  key={index}
                  contact={item}
                  contactType="boardMember"
                  addBoardmemberAsSignatory={addBoardmemberAsSignatory}
                  validateBoardMember={validateBoardMember}
                />
              );
            })}
      </div>
    </Panel>
  );
};

export default BoardMembers;
