import loading from 'media/spinner.svg';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { COLORS } from '../constants/style-constants';

export default (): ReactElement =>
  ReactDOM.createPortal(
    <div
      style={{
        background: `fixed ${COLORS.white} url(${loading}) center no-repeat`,
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    />,
    document.getElementById('portalRoot')!
  );
