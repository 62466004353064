import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import 'core-js';
import { configure } from 'mobx';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import 'zenscroll';
import config from './config';
import theme from './constants/theme';
import SinglePageApp from './containers/App/SinglePageApp';
import TopRouting from './containers/App/TopRouting';
import { getState } from './state';
import { I18nextProvider } from 'react-i18next';
// @ts-ignore
import i18n from './translations/i18n';
import ErrorBoundary from './containers/App/ErrorBoundary';
import * as Sentry from '@sentry/browser';

OfflinePluginRuntime.install();

ReactGA.initialize(config.analyticsId);
configure({
  enforceActions: 'observed',
});

Sentry.init({
  beforeSend(event, hint) {
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id });
    // }
    return event;
  },
  dsn: 'https://03ef3a90a48240f1a0313de810843446@crash.siemens.com/101',
  environment: config.env,
  ignoreErrors: ['Failed to update a ServiceWorker for scope'],
  whitelistUrls: [
    'https://mysfs-dev.siemens.com',
    'https://uat.siesmart.info',
    'https://mysfs.siemens.com', // ,
    // 'http://localhost:3000'
  ],
});

const main = async () => {
  const state = getState();
  const landingPage = window.location.pathname;
  await state.init(null, landingPage);

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <SinglePageApp>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter basename={config.routePath}>
            {/*<ErrorBoundary>*/}
            <TopRouting state={state} />
            {/*</ErrorBoundary>*/}
          </BrowserRouter>
        </ThemeProvider>
      </SinglePageApp>
    </I18nextProvider>,
    document.getElementById('reactRoot')
  );
};

main();
