import currencies from '../constants/currencies';
import { Currency as ApiCurrency } from '../tools/SiemensApi';

export interface Currency {
  code: string;
  format: string;
  symbol: string;
}

export const createCurrency = (currency: ApiCurrency): Currency => {
  if (currency.currencyCode && currency.format) {
    try {
      return {
        code: currency.currencyCode,
        format: currency.format,
        symbol: currencies[currency.currencyCode].symbol,
      };
    } catch {
      throw new Error('No currency found');
    }
  } else {
    throw new Error('No currency found');
  }
};
