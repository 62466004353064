import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Blocker from '../../components/Blocker';
import { Authentication, handleAuthentication } from '../../tools/Auth';
import { Trans, useTranslation } from 'react-i18next';
import Auth0ErrorDialog from '../../components/Auth0ErrorDialog';
import * as Sentry from '@sentry/browser';

interface Props {
  setAuthentication: (authentication: Authentication) => void;
}

export const Callback = ({ setAuthentication }: Props) => {
  const [error, setError] = React.useState<null | Error>(null);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      let authentication: Authentication;
      try {
        authentication = await handleAuthentication(location.hash);
      } catch (authError) {
        navigate('/');
        return;
      }
      try {
        await new Promise((resolve) =>
          resolve(setAuthentication(authentication))
        );
        navigate('/app');
      } catch (raisedError) {
        setError(raisedError);
        Sentry.captureException(raisedError);
      }
    })();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Blocker />
      <Auth0ErrorDialog
        open={!!error}
        onOk={() => navigate('/')}
        onCancel={() => navigate('/logout')}
        cancelCaption={t('logout', 'Logout')}
        handleClose={() => navigate('/')}
      >
        <Trans i18nKey="notAuthorised">
          <p>You are not authorised to use the app.</p>
          <p>Please contact Siemens Support to resolve this issue.</p>
        </Trans>
      </Auth0ErrorDialog>
    </>
  );
};
