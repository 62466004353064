import {
  clone,
  flow,
  getParent,
  getSnapshot,
  getType,
  Instance,
  types,
} from 'mobx-state-tree';
import { AppState } from '../state';
import {
  ProposalModel,
  uploadDocument,
  CalculationRequestModel,
  ProposalDocumentModel,
  getUploadedDocuments,
  findProposals,
  getProposal,
  performCalculation,
  updateProposal,
  UpdateProposalParametersModel,
  ProposalUpdateModel,
  expireProposal,
  ExpireProposalParametersModel,
  requestCreditDecision,
  addCalculation,
  AddCalculationParametersModel,
} from '../tools/SiemensApi';
import config from '../config';
import {
  Configuration,
  ConfigurationParameters,
} from '../../../generated-sources/dms-api';
import { TicketApi } from '../../../generated-sources/support-api';

export const zendeskTicketAPI = (token: string): TicketApi => {
  const apiConfigParams: ConfigurationParameters = {
    accessToken: token,
    basePath: config.backendApiUrl + '/support/v1',
  };

  return new TicketApi(new Configuration(apiConfigParams));
};

export const KonicaModel = types
  .model({
    baseRateNonRV: types.maybeNull(types.number),
    baseRateRV: types.maybeNull(types.number),
    documents: types.maybeNull(types.array(ProposalDocumentModel)),
    proposal: types.maybeNull(ProposalModel),
    ticketCreated: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get dealType(): string {
      if (
        self.proposal.currentCalculation.introducerPricelist.name
          .toLowerCase()
          .indexOf('commercial') > -1
      ) {
        return 'Commercial';
      } else {
        return 'Framework';
      }
    },
    get profile(): object {
      return { profile1: 1, profile2: 11 };
    },
  }))
  .actions((self) => ({
    reset() {
      Object.assign(self, konicaDefaults);
    },
    setBaseRateNonRV(value: number): void {
      self.baseRateNonRV = value;
    },
    setBaseRateRV(value: number): void {
      self.baseRateRV = value;
    },
  }))
  .actions((self) => ({
    getBaseRates: flow(function* getBaseRates() {
      const parentState = getParent<AppState>(self, 1);
      const calculatorState = parentState.calculator;

      let rvPricelist;
      let nonRvPriceList;

      if (self.dealType == 'Commercial') {
        rvPricelist = 19907;
        nonRvPriceList = 19910;
      } else if (self.dealType == 'Framework') {
        rvPricelist = 19908;
        nonRvPriceList = 19909;
      }

      //Non-RV base rates

      calculatorState.updateSheet({
        introducerPricelistId: nonRvPriceList,
        introducerPricelist: {
          id: nonRvPriceList,
          name: '',
        },
        amountFinanced: 1000,
        assetValue: 1000,
        residualValue: {
          amount: 0,
          percentage: 0,
          usePercentage: true,
          manualSet: true,
        },
        term: self.proposal.currentCalculation.term,
        vatAmount: 0,
      });

      const { data: nonRVresponse } = yield performCalculation(
        {
          introducerId: getParent<AppState>(self).introducer!.id,
          officeId: self.proposal.office.id,
        },
        CalculationRequestModel.create({
          operation: 'CALC_RENTAL',
          sheet: clone(parentState.calculator.calculation!.sheet!) as any,
        })
      );
      self.setBaseRateNonRV(nonRVresponse.sheet.selloutTariffRate);

      //RV rates
      calculatorState.updateSheet({
        introducerPricelistId: rvPricelist,
        introducerPricelist: {
          id: rvPricelist,
          name: '',
        },
        // residualValue: {
        //   amount: 0,
        //   percentage: 8,
        //   usePercentage: true,
        //   manualSet: true,
        // },
      });

      const { data: RVresponse } = yield performCalculation(
        {
          introducerId: getParent<AppState>(self).introducer!.id,
          officeId: self.proposal.office.id,
        },
        CalculationRequestModel.create({
          operation: 'CALC_RENTAL',
          sheet: clone(parentState.calculator.calculation!.sheet!) as any,
        })
      );
      self.setBaseRateRV(RVresponse.sheet.selloutTariffRate);
    }),

    findProposalId: flow(function* loadProposal(onBaseNumber: string) {
      try {
        const { data: proposals } = yield findProposals({
          introducerId: getParent<AppState>(self).introducer!.id,
          propLabel: onBaseNumber ? onBaseNumber : null,
        });
        if (proposals.data && proposals.data.length > 0) {
          return proposals.data[0].id;
        }
      } catch (error) {
        return null;
      }
    }),
    getDocuments: flow(function* getDocuments() {
      const uploadedDocuments: any = yield getUploadedDocuments({
        introducerId: getParent<AppState>(self).introducer.id,
        proposalId: self.proposal.id,
      });

      if (uploadedDocuments && uploadedDocuments.data) {
        self.documents = uploadedDocuments.data;
      }
    }),
  }))
  .actions((self) => ({
    getProposal: flow(function* loadProposal(
      proposalId: number,
      onBaseNumber: number
    ) {
      getParent<AppState>(self).setIsLoading(true);
      if (onBaseNumber) {
        try {
          const { data: proposals } = yield findProposals({
            introducerId: getParent<AppState>(self).introducer!.id,
            propLabel: onBaseNumber ? onBaseNumber : null,
          });
          if (proposals.data && proposals.data.length > 0) {
            proposalId = proposals.data[0].id;
          } else {
            self.proposal = null;
            getParent<AppState>(self).setIsLoading(false);
            return 'Onbase number not found';
          }
        } catch (error) {
          getParent<AppState>(self).setIsLoading(false);
          return null;
        }
      }

      if (!proposalId) {
        getParent<AppState>(self).setIsLoading(false);
        return 'Proposal number not found';
      }

      try {
        const { data: proposal } = yield getProposal({
          introducerId: getParent<AppState>(self).introducer!.id,
          proposalId,
        });

        if (!proposal) {
          throw 'Proposal not found';
        }
        if (
          proposal &&
          proposal.office.id !== getParent<AppState>(self).office.id
        ) {
          throw `Proposal created under different office (${proposal.office.name})`;
        }

        self.proposal = proposal!;
        yield self.getBaseRates();
        yield self.getDocuments();
        getParent<AppState>(self).setIsLoading(false);
      } catch (error) {
        let errorMessage;
        if (typeof error === 'object') {
          errorMessage = 'Proposal not found';
        } else {
          errorMessage = error ? error : 'Error';
        }
        getParent<AppState>(self).setIsLoading(false);
        return errorMessage;
      }
    }),
    patchProposalWithOnBase: flow(function* patchProposalWithOnBase(
      proposalId: number,
      onBaseNumber: string
    ) {
      const parentState = getParent<AppState>(self, 1);

      let ifMatch;
      getParent<AppState>(self).setIsLoading(true);
      try {
        const proposal: any = yield getProposal({
          introducerId: parentState.introducer!.id,
          proposalId,
        });
        ifMatch = proposal.headers.etag;
      } catch (error) {
        const response = error.response;
        getParent<AppState>(self).setIsLoading(false);
      }

      let proposalReadonly = false;
      if (
        self.proposal &&
        self.proposal.status &&
        self.proposal.status.proposalReadonly
      ) {
        proposalReadonly = true;
      }

      if (proposalReadonly) {
        try {
          const proposal: any = yield expireProposal(
            ExpireProposalParametersModel.create({
              ifMatch,
              introducerId: parentState.introducer.id,
              proposalId,
            })
          );
          ifMatch = proposal.headers.etag;
        } catch (error) {
          getParent<AppState>(self).setIsLoading(false);
        }
      }

      try {
        const proposal: any = yield updateProposal(
          UpdateProposalParametersModel.create({
            ifMatch,
            introducerId: parentState.introducer.id,
            proposalId,
          }),
          ProposalUpdateModel.create({
            label: onBaseNumber,
          })
        );
        ifMatch = proposal.headers.etag;
      } catch (error) {
        getParent<AppState>(self).setIsLoading(false);
      }

      if (proposalReadonly) {
        try {
          yield requestCreditDecision(
            {
              ifMatch,
              introducerId: parentState.introducer.id,
              proposalId,
            },
            {
              commentToRiskAnalyst: null,
              siemensMayContactCustomer: true,
            }
          );
        } catch (error) {
          getParent<AppState>(self).setIsLoading(false);
        }
      }

      getParent<AppState>(self).setIsLoading(false);
    }),
    saveCalculation: flow(function* saveCalculation(
      proposalId: number,
      rental: number,
      amountFinanced: number
    ) {
      const introducerId = getParent<AppState>(self).introducer.id;
      const currentCalculation = self.proposal.currentCalculation;

      let ifMatch;
      getParent<AppState>(self).setIsLoading(true);
      try {
        const proposal: any = yield getProposal({
          introducerId: getParent<AppState>(self).introducer!.id,
          proposalId,
        });
        ifMatch = proposal.headers.etag;
      } catch (error) {
        getParent<AppState>(self).setIsLoading(false);
        return 'Error - please try again';
      }

      //const payment = clone(currentCalculation.payment) as Payment;

      const paymentJSON = JSON.parse(
        JSON.stringify(currentCalculation.payment)
      );

      paymentJSON.paymentLines[0].value = rental;

      const clonedSheet = (node: any) =>
        getType(node).create(
          Object.assign({}, getSnapshot(node), {
            amountFinanced: amountFinanced,
            assetValue: amountFinanced,
            payment: paymentJSON,
          })
        );

      const sheet = clonedSheet(self.proposal.currentCalculation!);

      try {
        const calculationResponse = yield addCalculation(
          AddCalculationParametersModel.create({
            ifMatch,
            introducerId,
            proposalId,
          }),
          CalculationRequestModel.create({
            operation: 'CALC_INTEREST_RATE',
            sheet: sheet,
          })
        );

        if (calculationResponse) {
          self.proposal = calculationResponse.data.proposal;
          getParent<AppState>(self).setIsLoading(false);
        }
      } catch (error) {
        getParent<AppState>(self).setIsLoading(false);
        return 'Error - please try again';
      }
    }),
  }))
  .actions((self) => ({
    createTicket: flow(function* createTicket(payload: any) {
      const ticket = yield zendeskTicketAPI(
        getParent<AppState>(self).authentication.accessToken
      ).createTicket(payload);
      if (ticket) {
        self.ticketCreated = true;
      }
    }),
    uploadDocSiesmart: flow(function* uploadDoc(payload) {
      let document;
      try {
        document = yield uploadDocument(
          {
            introducerId: getParent<AppState>(self).introducer.id,
            proposalId: self.proposal.id,
          },
          payload
        );
      } catch (error) {
        return false;
      }

      if (document) {
        const uploadedDocuments: any = yield getUploadedDocuments({
          introducerId: getParent<AppState>(self).introducer.id,
          proposalId: self.proposal.id,
        });

        if (uploadedDocuments && uploadedDocuments.data) {
          self.documents = uploadedDocuments.data;
        }
      }
      return true;
    }),
  }));

export type KonicaState = Instance<typeof KonicaModel>;

export const konicaDefaults = {
  baseRateNonRV: null,
  baseRateRV: null,
  documents: null,
  proposal: null,
  ticketCreated: null,
};
