import { Box } from '@material-ui/core';
import React from 'react';
import { AssetArray } from '../../../model/Dashboard.model';
import { Asset } from '../../../tools/SiemensApi';

interface Props {
  assets: AssetArray | null;
}

export const SerialNumbers = ({ assets }: Props) => {
  if (!assets) {
    return (
      <>
        <Box>T.B.C.</Box>
      </>
    );
  } else {
    const listItems = assets.reduce<Array<React.ReactNode>>(
      (list, asset: Asset) =>
        asset.serialNumber
          ? [...list, <Box key={asset.id!}>{asset.serialNumber}</Box>]
          : list,
      []
    );
    return <>{listItems.length ? listItems : <Box>T.B.C.</Box>}</>;
  }
};
